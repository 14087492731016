import { IS_DEV } from '@/lib/configs/constant'

export const TailwindScreenDevTool = IS_DEV
  ? () => {
      if (!IS_DEV) return null

      return (
        <div className="fixed left-2 top-2 z-[100] w-8 rounded-full border-border bg-foreground py-1 text-center text-xs font-semibold text-background">
          <span className="block md:hidden">sm</span>
          <span className="hidden md:block lg:hidden">md</span>
          <span className="hidden lg:block xl:hidden">lg</span>
          <span className="hidden xl:block 2xl:hidden">xl</span>
          <span className="3xl:hidden hidden 2xl:block">2xl</span>
        </div>
      )
    }
  : () => null

import { useMemo } from 'react'

import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, redirect, useParams } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import { formatDate } from 'date-fns'
import { z } from 'zod'

import { ListUserAffiliatedQuery } from '@/_gql/graphql'

import {
  affiliateDetailCountQry,
  affiliatedUserListQry,
} from '@/services/affiliator/api'

import { AdminPage } from '@/components/layout/admin/page'
import { Badge } from '@/components/ui/badge'
import { Card, CardWithAccent } from '@/components/ui/card'
import { DataTable } from '@/components/ui/data-table/data-table'
import { useDataTable } from '@/components/ui/data-table/use-data-table'
import { Skeleton } from '@/components/ui/skeleton'

import { cx } from '@/lib/cva-config'

const searchParamsSchema = z.object({
  referralCode: z.string().nullish(),
  name: z.string().nullish(),
})

export const Route = createFileRoute('/admin-v2/referral/$id')({
  validateSearch: searchParamsSchema,
  loaderDeps: ({ search }) => search,
  loader: async ({ context: { queryClient }, params, deps }) => {
    if (!deps.referralCode || !deps.name) {
      throw redirect({ to: '/admin-v2/referral', from: Route.fullPath })
    }

    await queryClient.ensureQueryData(
      affiliateDetailCountQry({
        referralCode: deps.referralCode,
        userId: Number(params.id),
      })
    )
  },
  component: RouteComponent,
})

function RouteComponent() {
  const { referralCode, name } = Route.useSearch()
  const { id } = Route.useParams()
  const { data } = useSuspenseQuery(
    affiliateDetailCountQry({
      referralCode: referralCode!,
      userId: Number(id),
    })
  )

  return (
    <AdminPage>
      <AdminPage.Breadcrumb
        items={[
          ['Daftar Referrer', '/admin-v2/referral'],
          ['Detail Referrel', Route.fullPath],
        ]}
      />
      <AdminPage.Header title="Detail Referrel" desc={name} />
      <div className="flex flex-wrap gap-4">
        <CardWithAccent className="flex flex-1 flex-col justify-center space-y-2 px-3 py-4">
          <h1 className="text-sm text-muted-foreground">Poin Saat ini</h1>
          <div className="flex items-center justify-between gap-4">
            <p className="text-2xl font-semibold text-yes-600">
              {data.poin_affiliator}
            </p>
          </div>
        </CardWithAccent>
        <CardWithAccent className="flex flex-1 flex-col justify-center space-y-2 px-3 py-4">
          <h1 className="text-nowrap text-sm text-muted-foreground">
            Jumlah User Referal
          </h1>
          <p className="text-2xl font-semibold">
            {data.user_affiliated_applied + data.user_affiliated_hired}
          </p>
        </CardWithAccent>
        <CardWithAccent className="flex flex-1 flex-col justify-center space-y-2 px-3 py-4">
          <h1 className="text-nowrap text-sm text-muted-foreground">
            Pelamar Pekerjaan
          </h1>
          <p className="text-2xl font-semibold">
            {data.user_affiliated_applied}
          </p>
        </CardWithAccent>
        <CardWithAccent className="flex flex-1 flex-col justify-center space-y-2 px-3 py-4">
          <h1 className="text-nowrap text-sm text-muted-foreground">
            Diterima Pekerjaan
          </h1>
          <p className="text-2xl font-semibold">{data.user_affiliated_hired}</p>
        </CardWithAccent>
      </div>
      <UserTable />
    </AdminPage>
  )
}

const ch =
  createColumnHelper<ListUserAffiliatedQuery['listUserAffiliated'][number]>()

function getColumns() {
  return [
    ch.accessor('name', { header: 'Nama User' }),
    ch.accessor('created_at', {
      header: 'Tanggal Daftar',
      cell({ row }) {
        return (
          <span>{formatDate(row.original.created_at, 'dd/MM/yyyy HH:mm')}</span>
        )
      },
    }),
    ch.accessor('status_account', {
      header: 'Status',
      cell: ({ row }) => {
        return <StatusTag status={row.original.status_account as Status} />
      },
    }),
  ]
}

function UserTable() {
  const { referralCode } = Route.useSearch()
  const { data, isLoading } = useQuery(
    affiliatedUserListQry({ referralCode: referralCode! })
  )
  const columns = useMemo(getColumns, [])
  const { table } = useDataTable({ columns, data: data || [] })

  return (
    <Card className="p-2">
      {isLoading && <Skeleton className="h-36" />}
      {!isLoading && <DataTable table={table} />}
    </Card>
  )
}

type Status = 'hired' | 'applied' | 'registered' | 'rejected'

const STATUS_NAME = {
  registered: 'Akun Terdaftar',
  applied: 'Melamar Pekerjaan',
  hired: 'Diterima Pekerjaan',
  rejected: 'Ditolak Pekerjaan',
} satisfies Record<Status, string>

function StatusTag({ status }: { status: Status }) {
  return (
    <Badge
      className={cx(
        {
          'bg-[#CDFFD2] text-[#029E4A]': status === 'hired',
          'bg-red-500': status === 'rejected',
          'bg-[#FFF494] text-[#978803]': status === 'applied',
          'bg-[#A9EAFF] text-[#0093C2]': status === 'registered',
        },
        'text-xs font-semibold'
      )}
    >
      {STATUS_NAME[status] || status}
    </Badge>
  )
}

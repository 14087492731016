import { formatDate } from 'date-fns'

import { queryClient } from '../configs/tanstack-query'

export const REGEXP_ONLY_DIGITS = '^\\d+$'

export function formatBytes(
  bytes: number,
  opts: {
    decimals?: number
    sizeType?: 'accurate' | 'normal'
  } = {}
) {
  const { decimals = 0, sizeType = 'normal' } = opts

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const accurateSizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB']
  if (bytes === 0) return '0 Byte'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${
    sizeType === 'accurate'
      ? (accurateSizes[i] ?? 'Bytes')
      : (sizes[i] ?? 'Bytes')
  }`
}

/**
 * Stole this from the @radix-ui/primitive
 * @see https://github.com/radix-ui/primitives/blob/main/packages/core/primitive/src/primitive.tsx
 */
export function composeEventHandlers<E>(
  originalEventHandler?: (event: E) => void,
  ourEventHandler?: (event: E) => void,
  { checkForDefaultPrevented = true } = {}
) {
  return function handleEvent(event: E) {
    originalEventHandler?.(event)

    if (
      checkForDefaultPrevented === false ||
      !(event as unknown as Event).defaultPrevented
    ) {
      return ourEventHandler?.(event)
    }
  }
}

export function getFilename(url: string) {
  return url.substring(url.lastIndexOf('/') + 1)
}

export function checkBooleanValue(params?: boolean) {
  if (params === true) {
    return 'Ya'
  } else if (params === false) {
    return 'Tidak'
  } else {
    return '-'
  }
}

export function checkEverValue(params?: boolean) {
  if (params === true) {
    return 'Pernah'
  } else if (params === false) {
    return 'Belum'
  } else {
    return '-'
  }
}

export function checkHaveValue(params?: boolean) {
  if (params === true) {
    return 'Punya'
  } else if (params === false) {
    return 'Tidak'
  } else {
    return '-'
  }
}

export function normalizeEnum<T>(enumValue: T): T {
  return String(enumValue || '').toUpperCase() as T
}

export function valueWithFallback<T>(
  value: T | undefined | null,
  fallback?: T
): T {
  return (value || fallback) as T
}

export function valueDateWithFallback(
  value: Date | undefined | null | string,
  fallback?: Date
): Date {
  return (value || fallback) as Date
}

export function valueNumberWithFallback(
  value: number | undefined | null | string,
  fallback: number | string = 0
): number {
  return Number(value || fallback) || 0
}

export function formatDateTimeServer(date: Date | string) {
  return formatDate(date, 'yyyy-MM-dd HH:mm:ss.SSS')
}

export function formatDateOnly(date: Date | string) {
  return formatDate(date, 'yyyy-MM-dd')
}

export function getFallbackName(name?: string, fallback = 'unknown') {
  const isEmail = name?.includes('@')
  if (isEmail) name = name?.split('@')[0]
  const names = (name || fallback).split(' ').filter(Boolean)
  let fallbackName = fallback
  if (names.length > 1) {
    fallbackName = names[0]!.charAt(0) + names[1]!.charAt(0)
  } else {
    fallbackName = names[0]!.slice(0, 2)
  }
  return fallbackName
}

export function invalidateQueryKeys(queryKeys: string[], qc = queryClient) {
  const set = new Set(queryKeys)
  qc.invalidateQueries({
    predicate: (query) =>
      query.queryKey.some((key) => typeof key === 'string' && set.has(key)),
    type: 'all',
    exact: false,
    refetchType: 'all',
  })
}

export function decodeUnicode(str: string) {
  return str
    .replace(/\\u003c/g, '<')
    .replace(/\\u003e/g, '>')
    .replace(/\\u0022/g, '"')
}

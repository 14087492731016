import { useEffect, useMemo, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createFileRoute,
  Link,
  useNavigate,
  useRouter,
  useSearch,
} from '@tanstack/react-router'
import {
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnDef,
} from '@tanstack/react-table'
import { formatDate } from 'date-fns'
import { ArrowLeft, ArrowRight, Eye, ListFilter } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import {
  EducationLevel,
  MaritalStatus,
  SearchUserParticipantRequest,
  SearchUserParticipantResponse,
  StatusProfile,
  UpsertUserStatusProfileRequest,
  User,
  UserEducation,
} from '@/_gql/graphql'

import { GqlUpsertUserStatusProfile } from '@/services/admin/gql'
import { queryGetParticipants } from '@/services/admin/query'
import { gqlFetch } from '@/services/graphql/fetcher'
import {
  EDUCATION_LEVEL_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  RANGE_AGE,
  STATUS_PROFILE_OPTIONS,
  TOTAL_EXPERIENCE_INMONTH,
} from '@/services/user/constant'

import { FormItemTemplate } from '@/components/form/template'
import { HomeIcon } from '@/components/icons/common'
import { AdminPage } from '@/components/layout/admin/page'
import { Badge } from '@/components/ui/badge'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Checkbox } from '@/components/ui/checkbox'
import { CheckboxPrimary } from '@/components/ui/checkbox-primary'
import { DataTableColumnHeader } from '@/components/ui/data-table/column-header'
import { DatePickerRange } from '@/components/ui/date-picker-range'
import { DebouncedInput } from '@/components/ui/debounce-input'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { FormFieldSelect } from '@/components/ui/form-field/select'
import { Sheet, SheetResponsive, SheetTrigger } from '@/components/ui/sheet'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Textarea } from '@/components/ui/textarea'

import { fuzzyFilterFn } from '@/lib/utils/datatable'

type ParamsDto = z.infer<typeof ParamsDto>
const ParamsDto = z.object({
  marital_status: z.nativeEnum(MaritalStatus).or(z.string()).optional(),
  total_experience: z.string().optional(),
  education_level: z.nativeEnum(EducationLevel).or(z.string()).optional(),
  range_age: z.string().optional(),
  registerDate: z.tuple([z.coerce.date(), z.coerce.date()]).nullable().optional(),
  applyDate: z.tuple([z.coerce.date(), z.coerce.date()]).nullable().optional(),
})

export const Route = createFileRoute('/admin-v2/participant/')({
  validateSearch: ParamsDto,
  component: RouteComponent,
})

function RouteComponent() {
  const router = useRouter()

  const [openFilter, setOpenFilter] = useState(false)
  const [openChangeStatus, setOpenStatus] = useState(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const search = Route.useSearch()

  const age = search?.range_age
    ? (search.range_age as unknown as string).split('-')
    : null

  const request: SearchUserParticipantRequest = {
    education_level: search?.education_level as EducationLevel || undefined,
    marital_status: search?.marital_status as MaritalStatus || undefined,
    max_age: age && age.length === 2 ? Number(age[1]) : undefined,
    min_age: age && age.length === 2 ? Number(age[0]) : undefined,
    total_experience_in_months: Number(search?.total_experience) || undefined,
    ...(search.applyDate?.length === 2
      ? {
        start_apply_date: formatDate(search.applyDate[0], 'yyyy-MM-dd'),
        end_apply_date: formatDate(search.applyDate[1], 'yyyy-MM-dd'),
      }
      : {}),
    ...(search.registerDate?.length === 2
      ? {
        start_register_date: formatDate(search.registerDate[0], 'yyyy-MM-dd'),
        end_register_date: formatDate(search.registerDate[1], 'yyyy-MM-dd'),
      }
      : {}),
  }
  const [globalFilter, setGlobalFilter] = useState('')

  const { data, refetch } = useQuery(queryGetParticipants(request))

  const { mutateAsync } = useMutation({
    mutationFn: async (data: UpsertUserStatusProfileRequest[]) => {
      await gqlFetch({
        query: GqlUpsertUserStatusProfile,
        variables: { request: data },
      })
      await refetch()
    },
    onSuccess: () => {
      router.invalidate()
    },
  })

  const handleCheckboxChange = (id: number) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    )
  }

  const handleSelectAllChange = (ids: number[]) => {
    setSelectedIds(ids)
  }
  const onHandleChangeStatus = (status?: StatusProfile, reason?: string) => {
    const payloadData: UpsertUserStatusProfileRequest[] = []

    if (status) {
      selectedIds.forEach((id) => {
        payloadData.push({
          status: status,
          reason: status == StatusProfile.SubmissionRejected ? reason : '',
          user_id: String(id),
        })
      })
    }

    toast.promise(mutateAsync(payloadData), {
      loading: 'Loading...',
      success: 'Ubah Status Berhasil',
      error: 'Gagal',
    })

    setOpenStatus(false)
    refetch()
    setSelectedIds([])
  }

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <AdminPage>
      <AdminPage.Breadcrumb
        items={[['Daftar Peserta', '/admin-v2/participant']]}
      />
      <AdminPage.Header
        title="Daftar Peserta"
        // TODO
        // @ts-expect-error
        desc={`Jumlah peserta saat ini: ${(data?.data || []).length} orang`}
      />
      <div className="mt-4 w-full">
        <Card className="w-full py-4">
          <CardContent>
            <div className="flex items-center justify-between">
              <div>
                <DebouncedInput
                  value={globalFilter ?? ''}
                  onChange={(value) => setGlobalFilter(String(value))}
                  placeholder="Cari .."
                />
              </div>
              <div>
                <FilterTable
                  openFilter={openFilter}
                  setOpenFilter={setOpenFilter}
                />
              </div>
            </div>
            <div className="mt-4 overflow-auto">
              <TableParticipant
                // TODO
                // @ts-expect-error
                data={data}
                handleCheckboxAllChange={handleSelectAllChange}
                handleCheckboxChange={handleCheckboxChange}
                selectedIds={selectedIds}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </div>
          </CardContent>
        </Card>
      </div>
      {selectedIds.length > 0 ? (
        <>
          <div className="fixed bottom-0 left-0 z-30 flex w-screen justify-end gap-4 bg-white p-4 pl-64 drop-shadow-lg">
            <Button variant={'outline_yes'} onClick={() => setSelectedIds([])}>
              Batalkan
            </Button>
            <ChangeStatus
              open={openChangeStatus}
              setOpen={setOpenStatus}
              onHandleChangeStatus={onHandleChangeStatus}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </AdminPage>
  )
}

export function BreadcrumbClass() {
  return (
    <Breadcrumb className="text-white">
      <BreadcrumbList className="text-[#475467]">
        <BreadcrumbItem>
          <BreadcrumbLink href="/">
            <HomeIcon color="#475467" />
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator className="text-[#475467]" />
        <BreadcrumbItem>
          <div className="rounded-md bg-[#FFF4F5] p-2 text-primary-yes">
            Daftar Peserta
          </div>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}

interface FilterTableProps {
  openFilter: boolean
  setOpenFilter: (state: boolean) => void
}

export function FilterTable(props: FilterTableProps) {
  const search = Route.useSearch()
  const { openFilter, setOpenFilter } = props
  const navigate = Route.useNavigate()
  const form = useForm<ParamsDto>({
    resolver: zodResolver(ParamsDto),
    defaultValues: getDefaultValues(search),
  })

  const handleOnSubmit = form.handleSubmit(async (data) => {
    navigate({ search: (prev) => ({ ...prev, ...data }) })
    setOpenFilter(false)
  })

  const clearFilter = () => {
    navigate({ to: '/admin-v2/participant', search: {} })

    form.reset(getDefaultValues())


    setOpenFilter(false)
  }



  return (
    <Sheet open={openFilter} onOpenChange={setOpenFilter}>
      <SheetTrigger asChild>
        <Button variant={'outline_yes'}>
          <ListFilter /> Filter
        </Button>
      </SheetTrigger>
      <SheetResponsive className="p-0">
        <Form {...form}>
          <form
            onSubmit={handleOnSubmit}
            className="relative flex h-full flex-col pt-8"
          >
            <div
              className="relative flex max-h-[70vh] w-full flex-1 flex-col gap-6 overflow-y-auto px-4 md:max-h-full"
            // style={{ maxHeight: '60vh' }}
            >
              <FormField
                control={form.control}
                name={`marital_status`}
                render={({ field }) => (
                  <FormFieldset label="Status Pernikahan" className="w-full">
                    <FormFieldSelect
                      className="w-full"
                      placeholder="Sudah menikah / belum"
                      items={MARITAL_STATUS_OPTIONS}
                      value={field.value ?? ""}
                      onValueChange={field.onChange}
                    />
                  </FormFieldset>
                )}
              />
              <FormField
                control={form.control}
                name={`total_experience`}
                render={({ field }) => (
                  <FormFieldset
                    label="Jumlah Pengalaman Kerja"
                    className="w-full"
                  >
                    <FormFieldSelect
                      className="w-full"
                      placeholder="Dibawah 6 bulan / 1 tahun / 1.5 tahun "
                      items={TOTAL_EXPERIENCE_INMONTH}
                      value={field.value ?? ""}
                      onValueChange={field.onChange}
                    />
                  </FormFieldset>
                )}
              />
              <FormField
                control={form.control}
                name={`education_level`}
                render={({ field }) => (
                  <FormFieldset label="Pendidikan Terakhir" className="w-full">
                    <FormFieldSelect
                      className="w-full"
                      placeholder="S1 / D4 / SMK"
                      items={EDUCATION_LEVEL_OPTIONS}
                      value={field.value ?? ""}
                      onValueChange={field.onChange}
                    />
                  </FormFieldset>
                )}
              />

              <FormField
                control={form.control}
                name={`range_age`}
                render={({ field }) => (
                  <FormFieldset label="Usia" className="w-full">
                    <FormFieldSelect
                      className="w-full"
                      placeholder="18-24 Tahun / 26-30 Tahun / 31 Tahun ke ..."
                      items={RANGE_AGE}
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormFieldset>
                )}
              />

              <FormField
                control={form.control}
                name={`applyDate`}
                render={({ field }) => (
                  <FormFieldset label="Tanggal Apply">
                    <DatePickerRange
                      buttonClassName="w-full"
                      value={
                        field.value
                          ? {
                            from: field.value[0],
                            to: field.value[1],
                          }
                          : undefined
                      }
                      onChange={(value) => {
                        if (!value) field.onChange(undefined)
                        const { from, to } = value || {}
                        field.onChange([from, to || from])
                      }}
                    />
                  </FormFieldset>
                )}
              />

              <FormField
                control={form.control}
                name={'registerDate'}
                render={({ field }) => (
                  <FormFieldset label="Tanggal Daftar">
                    <DatePickerRange
                      buttonClassName="w-full"
                      value={
                        field.value
                          ? {
                            from: field.value[0],
                            to: field.value[1],
                          }
                          : undefined
                      }
                      onChange={(value) => {
                        if (!value) field.onChange(undefined)
                        const { from, to } = value || {}
                        field.onChange([from, to || from])
                      }}
                    />
                  </FormFieldset>
                )}
              />
            </div>
            <div className="z-20 flex justify-end gap-4 p-4">
              <Button
                type="button"
                variant={'outline_yes'}
                onClick={clearFilter}
              >
                Hapus Filter
              </Button>
              <Button
                type="submit"
                className="bg-primary-yes hover:bg-primary-yes"
              >
                Terapkan Filter
              </Button>
            </div>
          </form>
        </Form>
      </SheetResponsive>
    </Sheet>
  )
}

function getColumns(): ColumnDef<FlattenData>[] {
  return [
    { accessorKey: 'id', header: 'No' },
    {
      accessorKey: 'name',
      meta: {
        columnName: 'Name',
      },
      filterFn: 'includesStringSensitive',
      header: ({ column }) => {
        return <DataTableColumnHeader column={column} />
      },
    },
    {
      accessorKey: 'email',
      meta: {
        columnName: 'Email',
      },
      filterFn: 'includesStringSensitive',
      header: ({ column }) => {
        return <DataTableColumnHeader column={column} />
      },
    },
    {
      accessorKey: 'phoneNumber',
      meta: {
        columnName: 'Nomor HP',
      },
      filterFn: 'includesStringSensitive',
      header: ({ column }) => {
        return <DataTableColumnHeader column={column} />
      },
    },
    {
      accessorKey: 'birthdate',
      meta: {
        columnName: 'Tanggal Lahir',
      },
      header: ({ column }) => {
        return <DataTableColumnHeader column={column} />
      },
    },
    {
      accessorKey: 'last_education',
      meta: {
        columnName: 'Pendidikan Terakhir',
      },
      header: ({ column }) => {
        return <DataTableColumnHeader column={column} />
      },
    },
    {
      accessorKey: 'address',
      meta: {
        columnName: 'Domisili',
      },
      header: ({ column }) => {
        return <DataTableColumnHeader column={column} />
      },
    },
    {
      accessorKey: 'status',
      meta: {
        columnName: 'Status',
      },
      header: ({ column }) => {
        return <DataTableColumnHeader column={column} />
      },
      cell: ({ cell }) => {
        const { status } = cell.row.original
        return <>{BadgeStatus(status)}</>
      },
    },
  ]
}

interface TableParticipantProps {
  data: SearchUserParticipantResponse
  handleCheckboxChange: (id: number) => void
  handleCheckboxAllChange: (ids: number[]) => void
  selectedIds: number[]
  globalFilter: string
  setGlobalFilter: (state: string) => void
}
export function TableParticipant({
  data,
  handleCheckboxChange,
  handleCheckboxAllChange,
  selectedIds,
  globalFilter,
  setGlobalFilter,
}: TableParticipantProps) {
  const flattenedData = useMemo(
    () => flattenData(data?.data ?? []),
    [data?.data]
  )
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const columns = useMemo(getColumns, [])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, // Default page size
  })

  const table = useReactTable({
    data: flattenedData || [],
    columns,
    state: { pagination, globalFilter, columnFilters },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: false,
    filterFns: {
      fuzzy: fuzzyFilterFn,
    },
    globalFilterFn: 'fuzzy',
  })

  const pageIndex = table.getState().pagination.pageIndex
  const pageSize = table.getState().pagination.pageSize
  const totalRows = table.getFilteredRowModel().rows.length

  const startRow = pageIndex * pageSize + 1
  const endRow = Math.min((pageIndex + 1) * pageSize, totalRows)
  const totalPages = table.getPageCount()

  function handleCheckAllDataSelect() {
    if (selectedIds.length === totalRows) {
      handleCheckboxAllChange([])
    } else {
      const filteredData = table.getFilteredRowModel().rows

      const ids = filteredData.map((data) => data.original.id)
      handleCheckboxAllChange(ids)
    }
  }

  return (
    <>
      <div>
        <Table>
          <TableHeader className="bg-primary-yes">
            <TableRow className="text-white">
              <TableHead className="w-[10px] items-center text-white">
                <div className="flex items-center justify-center">
                  <Checkbox
                    className="bg-white"
                    checked={selectedIds.length === totalRows}
                    onCheckedChange={() => handleCheckAllDataSelect()}
                  />
                </div>
              </TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <>
                  {headerGroup.headers.map((header) => (
                    <TableHead className="text-white">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </TableHead>
                  ))}
                </>
              ))}

              <TableHead className="text-center text-white">Action</TableHead>
            </TableRow>
          </TableHeader>
          {data && data?.data?.length == 0 && (
            <TableRow>
              <TableCell colSpan={8}>
                <div className="flex justify-center">Tidak ada data</div>
              </TableCell>
            </TableRow>
          )}
          <TableBody>
            {data && data?.data?.length > 0 ? (
              <>
                {table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell className="text-center font-medium">
                      <CheckboxPrimary
                        checked={selectedIds.includes(row.getValue('id'))}
                        onCheckedChange={() =>
                          handleCheckboxChange(row.getValue('id'))
                        }
                      />
                    </TableCell>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Link
                        to={'/admin-v2/participant/$participantId'}
                        params={{ participantId: String(row.original.id) }}
                      >
                        <Eye className="cursor-pointer text-primary-yes" />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <div className="mt-5 flex items-center justify-between">
          <div className="flex flex-row items-center gap-4">
            <div>Show Data</div>
            <div className="border-gray rounded-md border px-2 py-2 text-primary-yes">
              <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => table.setPageSize(Number(e.target.value))}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div className="text-sm">
              {`${startRow}-${endRow}`} data from {totalRows} data
            </div>
          </div>
          <div>
            <div className="border-gray flex divide-x rounded-md border">
              <button
                className="flex cursor-pointer items-center gap-2 px-3 py-2 text-primary-yes hover:bg-primary-yes hover:text-white"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <ArrowLeft className="h-4 w-4" />
                <div>{'Previous'}</div>
              </button>
              {Array.from({ length: totalPages }, (_, index) => {
                const showPageButton =
                  index === 0 ||
                  index === totalPages - 1 ||
                  Math.abs(index - table.getState().pagination.pageIndex) <= 1

                if (showPageButton) {
                  return (
                    <button
                      className={`px-4 ${table.getState().pagination.pageIndex === index ? 'bg-primary-yes text-white' : 'text-primary-yes'} cursor-pointer hover:bg-primary-yes hover:text-white`}
                      key={index}
                      onClick={() => table.setPageIndex(index)}
                      disabled={table.getState().pagination.pageIndex === index}
                    >
                      {index + 1}
                    </button>
                  )
                }

                return null
              })}

              <button
                className="flex cursor-pointer items-center gap-2 px-3 py-2 text-primary-yes hover:bg-primary-yes hover:text-white"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <div>{'Next'}</div>
                <ArrowRight className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export function BadgeStatus(status?: string) {
  const getStatus =
    status && status.toUpperCase()
      ? status.toUpperCase()
      : StatusProfile.FillingSubmissionStage
  let classNameBackground = ''
  const getLabel = STATUS_PROFILE_OPTIONS.find(
    (status) => status.value == getStatus
  )
  switch (getStatus) {
    case StatusProfile.FillingSubmissionStage:
      classNameBackground = 'bg-[#FFF494] text-[#978803] hover:bg-[#FFF494]'
      break
    case StatusProfile.SubmissionApproved:
      classNameBackground = 'bg-[#CDFFD2] text-[#029E4A] hover:bg-[#CDFFD2]'
      break
    case StatusProfile.SubmissionRejected:
      classNameBackground = 'bg-[#FFCDD1] text-[#BC0000] hover:bg-[#FFCDD1]'
      break
    case StatusProfile.SubmissionVerificationStage:
      classNameBackground = 'bg-[#A9EAFF] text-[#0093C2] hover:bg-[#A9EAFF]'
      break
    default:
      classNameBackground = 'bg-[#FFF494] text-[#978803] hover:bg-[#FFF494]'
  }

  return <Badge className={classNameBackground}>{getLabel?.label}</Badge>
}

interface ChangeStatusDialogProps {
  onHandleChangeStatus: (status: StatusProfile, reason?: string) => void
  setOpen: (state: boolean) => void
  open: boolean
}

interface ChangeStatus extends z.infer<typeof ChangeStatusDto> { }
const ChangeStatusDto = z.object({
  status: z.nativeEnum(StatusProfile).optional(),
  reason: z.string().optional(),
})
const ChangeStatus = ({
  onHandleChangeStatus,
  open,
  setOpen,
}: ChangeStatusDialogProps) => {
  const form = useForm<ChangeStatus>({
    defaultValues: {
      status: StatusProfile.FillingSubmissionStage,
      reason: '',
    },
  })

  const status = form.watch('status')
  const reason = form.watch('reason')

  const onHandleChangeStatusButton = () => {
    if (status) [onHandleChangeStatus(status, reason)]
  }

  const onHandleSubmitStatus = form.handleSubmit((data) => {
    console.log(data)
  })
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="flex justify-start" asChild>
        <Button className="bg-primary-yes hover:bg-primary-yes">
          Ubah Status
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-xl">
        <Form {...form}>
          <form onSubmit={onHandleSubmitStatus}>
            <div className="flex text-lg font-semibold">Ubah Status</div>

            <div className="mt-4">
              <FormField
                control={form.control}
                name={`status`}
                render={({ field }) => (
                  <FormFieldset label="Status" className="w-full">
                    <FormFieldSelect
                      className="w-full"
                      placeholder="Status Profile"
                      items={STATUS_PROFILE_OPTIONS}
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormFieldset>
                )}
              />

              <div
                className={`mt-4 ${status == StatusProfile.SubmissionRejected ? '' : 'hidden'}`}
              >
                <FormField
                  control={form.control}
                  name="reason"
                  render={({ field }) => (
                    <FormItemTemplate.General label="Alasan Penolakan">
                      <Textarea
                        placeholder="Sertakan alasan penolakan di sini"
                        {...field}
                      />
                    </FormItemTemplate.General>
                  )}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-between gap-4">
              <Button variant={'outline_yes'} onClick={() => setOpen(false)}>
                Batal
              </Button>
              <Button
                type="submit"
                className="bg-primary-yes hover:bg-primary-yes"
                onClick={onHandleChangeStatusButton}
              >
                Ubah Status
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

function getLastEduction(user_education: (UserEducation | null)[]) {
  if (user_education && user_education.length > 0) {
    const levelEducation = user_education[user_education.length - 1]?.level
    const findLevelEducation = EDUCATION_LEVEL_OPTIONS.find(
      (edu) => edu.value === levelEducation?.toUpperCase()
    )
    return findLevelEducation?.label
  }

  return ''
}

interface FlattenData {
  id: number
  name: string
  birthdate: string
  last_education: string | undefined
  address: string | undefined
  status: any
}

function flattenData(data: User[]): FlattenData[] {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    address: item.user_personal_info?.address
      ? `${item.user_personal_info?.city.name}, ${item.user_personal_info?.province.name}`
      : '-',
    birthdate: item.user_personal_info?.birthdate
      ? formatDate(item.user_personal_info.birthdate, 'MM/dd/yyyy')
      : 'N/A',
    last_education: getLastEduction(
      item?.user_education?.filter(Boolean) || []
    ),
    status: item?.user_status_profile?.status,
    createdAt: item.created_at,
    email: item.email,
    phoneNumber: item.handphone_number || '-',
  }))
}

function getDefaultValues(init?: ParamsDto): ParamsDto {
  return init ?? {
    'applyDate': null,
    'education_level': "",
    'marital_status': "",
    'range_age': "",
    'registerDate': null,
    'total_experience': ""
  }
}
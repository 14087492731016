import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import {
  createFileRoute,
  Link,
  redirect,
  useRouter,
} from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { UserOtpSentBy } from '@/_gql/graphql'

import { GqlAuthSendOTP, GqlAuthUpdatePhoneNumber } from '@/services/auth/gql'
import { queryAuthProfile } from '@/services/auth/query'
import { useAuth } from '@/services/auth/use-auth'
import { GraphQLError } from '@/services/graphql/error'
import { gqlFetch } from '@/services/graphql/fetcher'

import { ButtonLoading } from '@/components/ui/button'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { Input } from '@/components/ui/input'

import { capturePosthogEvent } from '@/lib/posthog'
import { AuthCard } from './-components/auth-card'
import { AuthContainer } from './-components/auth-container'

export const Route = createFileRoute('/auth/verification/phone')({
  beforeLoad: () => {
    const { isAuthenticated } = useAuth.getState()
    if (!isAuthenticated) return redirect({ to: '/auth/login' })
  },
  loader: async ({ context: { queryClient: qc } }) => {
    const res = await qc.fetchQuery(queryAuthProfile())
    if (res.getProfile.user.is_verified) throw redirect({ to: '/dashboard' })

    return {
      phone: res.getProfile.user.handphone_number,
    }
  },
  component: Component,
})

function Component() {
  const router = useRouter()
  const navigate = Route.useNavigate()
  const { phone: defaultPhone } = Route.useLoaderData()
  const form = useForm<FormDto>({
    mode: 'onBlur',
    resolver: zodResolver(FormDto),
    defaultValues: getDefaultValues(defaultPhone || ''),
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = form

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (data: FormDto) => {
      const { phone } = data
      if (!phone) throw new Error('Invalid phone')

      if (phone !== defaultPhone) {
        await gqlFetch({
          query: GqlAuthUpdatePhoneNumber,
          variables: { request: { handphone_number: phone } },
        })
      }

      capturePosthogEvent('auth:verification:phone', { phone })
      router.invalidate()

      await gqlFetch({
        query: GqlAuthSendOTP,
        variables: { request: { sent_by: UserOtpSentBy.Whatsapp } },
      })
      capturePosthogEvent('auth:verification:req_otp', {})

      return { phone }
    },
    onError: (error) => {
      if (GraphQLError.is(error)) {
        const msg = error.getMessage()
        setError('root.server', { message: msg })
      }
    },
    onSuccess: ({ phone }) => {
      navigate({ to: '/auth/verification/otp', search: { phone } })
    },
  })

  const handleOnSubmit = handleSubmit((data) => {
    toast.promise(mutateAsync(data), {
      id: 'send-otp',
      loading: 'Meminta kode OTP...',
      success: 'Kode OTP telah dikirim',
      error: 'Gagal mengirim kode OTP',
    })
  }, console.error)

  return (
    <AuthContainer className="">
      <Form {...form}>
        <AuthCard className="max-w-screen-sm">
          <form onSubmit={handleOnSubmit}>
            <AuthCard.Header>
              <AuthCard.Title>Lanjutkan untuk masuk</AuthCard.Title>
              <AuthCard.Subtitle>
                Halo, Selamat datang di YES Academy!
              </AuthCard.Subtitle>
            </AuthCard.Header>
            <AuthCard.Content className="">
              <FormField
                control={control}
                name="phone"
                render={({ field }) => (
                  <FormFieldset label="Nomor HP">
                    <Input placeholder="Masukkan nomor hp" {...field} />
                  </FormFieldset>
                )}
              />
              {errors.root?.server?.message && (
                <div className="rounded-md border border-red-500 bg-red-200/5 p-2 text-sm text-red-500">
                  <p>{errors.root.server.message}</p>
                </div>
              )}
            </AuthCard.Content>
            <AuthCard.Footer className="flex-col gap-3 pt-3">
              <ButtonLoading
                className="w-full rounded-xl bg-gradient-primary"
                isLoading={isPending}
                type="submit"
                disabled
              >
                Kirim Kode OTP
              </ButtonLoading>
              <p className="my-1 w-full text-center text-sm">
                Nomor hp sudah terdaftar? Silakan&nbsp;
                <Link to="/auth/login" className="text-blue-500 underline">
                  Masuk
                </Link>
              </p>
            </AuthCard.Footer>
          </form>
        </AuthCard>
      </Form>
    </AuthContainer>
  )
}

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z.object({
  phone: z
    .string()
    .min(10, 'Phone number must be at least 10 digits')
    .max(15, 'Phone number must be at most 15 digits')
    .regex(/^\d+$/, 'Phone number must contain only digits'),
})

function getDefaultValues(phone = ''): FormDto {
  return {
    phone,
  }
}

import {
    createFileRoute,
    Link,
    LinkProps,
    Outlet,
    useLocation,
} from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { Container } from '@/components/layout/container'
import * as Accordion from '@radix-ui/react-accordion'
import { cx } from '@/lib/cva-config'

import { HamburgerMenuIcon } from '@radix-ui/react-icons'

export const Route = createFileRoute('/_user/profile')({

    component: Component,
})

interface NavItem {
    label: string
    to: LinkProps['to']
    isActive: boolean
}

function getNav(pathname: string): NavItem[] {
    return [
        {
            label: 'Unggah CV',
            to: '/profile',
            isActive: pathname === '/profile',
        },
        {
            label: 'Informasi Pribadi',
            to: '/profile/personal',
            isActive: pathname.startsWith('/profile/personal'),
        },
        {
            label: 'Riwayat Pekerjaan & Pendidikan',
            to: '/profile/experience',
            isActive: pathname.startsWith('/profile/experience'),
        },
        {
            label: 'Lisensi & Sertifikat',
            to: '/profile/licences',
            isActive: pathname.startsWith('/profile/licences'),
        },
        {
            label: 'Bahasa',
            to: '/profile/languages',
            isActive: pathname.startsWith('/profile/languages'),
        },
    ]
}

function Component() {
    return (
        <Container className="relative flex flex-col gap-4 py-8 lg:flex-row">
            <div className="top-20 h-min w-full flex-shrink-0 lg:sticky lg:w-80">
                <NavDesktop />
                <NavMobile />
            </div>
            <div className="flex-grow">
                <Outlet />
            </div>
        </Container>
    )
}

function NavDesktop() {
    const { pathname } = useLocation()
    const nav = getNav(pathname)

    return (
        <div className="hidden h-min flex-col gap-2 rounded-xl bg-gradient-primary px-3 py-4 lg:flex">
            {nav.map(({ label, to, isActive }) => (
                <Link
                    to={to}
                    key={to}
                    className={cx(
                        'rounded-lg p-2 pl-3 font-medium text-white transition-colors duration-300 hover:bg-white/30',
                        isActive && 'bg-white text-primary-yes hover:bg-white',
                    )}
                >
                    {label}
                </Link>
            ))}
        </div>
    )
}

function NavMobile() {
    const { pathname } = useLocation()
    const [value, setValue] = useState<string | undefined>('')
    const nav = getNav(pathname)
    const active = nav.find(({ isActive }) => isActive) || nav[0]

    useEffect(() => {
        setValue('')
    }, [pathname])

    useEffect(() => {
        const handleResize = () => setValue('')
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        // <Select.Root>
        //   <Select.Trigger className="flex w-full items-center justify-between rounded-md bg-gradient-primary px-4 py-6 text-white md:px-6">
        //     <span className="text-sm">{active?.label}</span>
        //     <HamburgerMenuIcon />
        //   </Select.Trigger>
        //   <Select.Portal>
        //     <Select.Content
        //       position="popper"
        //       className="relative z-50 mt-2 bg-gradient-primary shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        //     >
        //       <Select.Viewport className="w-screen max-w-full">
        //         {nav.map(({ label, to, isActive }) => (
        //           <Select.Item key={to}>
        //             <Link
        //               to={to}
        //               className={cx(
        //                 'w-full rounded-sm p-1.5 text-sm text-white transition-colors duration-300 hover:bg-white/30 md:p-2',
        //                 isActive && 'bg-white text-primary-yes hover:bg-white'
        //               )}
        //             >
        //               {label}
        //             </Link>
        //           </Select.Item>
        //         ))}
        //       </Select.Viewport>
        //     </Select.Content>
        //   </Select.Portal>
        // </Select.Root>
        <Accordion.Root
            type="single"
            collapsible
            className="relative lg:hidden"
            value={value}
            onValueChange={setValue}
        >
            <Accordion.Item value="item-1">
                <Accordion.Header className="rounded-md bg-gradient-primary text-white">
                    <Accordion.Trigger className="flex w-full items-center justify-between px-4 py-6 md:px-6">
                        <span className="text-sm">{active?.label}</span>
                        <HamburgerMenuIcon />
                    </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content className="absolute z-10 mt-2 w-full overflow-hidden rounded-md bg-gradient-primary text-white shadow-2xl data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                    <div className="flex w-full flex-col gap-1.5 p-2 md:p-4">
                        {nav.map(({ label, to, isActive }) => (
                            <Link
                                to={to}
                                key={to}
                                className={cx(
                                    'w-full rounded-sm p-1.5 text-sm text-white transition-colors duration-300 hover:bg-white/30 md:p-2',
                                    isActive && 'bg-white text-primary-yes hover:bg-white',
                                )}
                            >
                                {label}
                            </Link>
                        ))}
                    </div>
                </Accordion.Content>
            </Accordion.Item>
        </Accordion.Root>
    )
}

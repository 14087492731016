import { createContext, useContext, useMemo, useState } from 'react'

import { CheckIcon, Cross2Icon, Pencil2Icon } from '@radix-ui/react-icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import { cx } from 'cva'
import { toast } from 'sonner'

import {
  GetPointExchangeHistoryQuery,
  PointExchangeHistoryStatus,
} from '@/_gql/graphql'

import { listPointExchangeHistoryQry } from '@/services/affiliator/api'
import { GqlUpsertPointExchange } from '@/services/affiliator/query'
import { gqlFetch } from '@/services/graphql/fetcher'

import { AdminPage } from '@/components/layout/admin/page'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import {
  Credenza,
  CredenzaBody,
  CredenzaClose,
  CredenzaContent,
  CredenzaDescription,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
} from '@/components/ui/credenza'
import { DataTable } from '@/components/ui/data-table/data-table'
import { useDataTable } from '@/components/ui/data-table/use-data-table'

import { invalidateQueryKeys } from '@/lib/utils/utils'

export const Route = createFileRoute('/admin-v2/referral/point-history')({
  component: RouteComponent,
})

function RouteComponent() {
  return (
    <PromptProvider>
      <AdminPage>
        <AdminPage.Breadcrumb
          items={[['Riwayat Tukar Poin', Route.fullPath]]}
        />
        <AdminPage.Header title="Riwayat Tukar Poin" />
        <PointHistoryTable />
      </AdminPage>
    </PromptProvider>
  )
}

type RowItem = GetPointExchangeHistoryQuery['getPointExchangeHistory'][number]

const ch = createColumnHelper<RowItem>()

function getColumns() {
  return [
    ch.accessor('user.name', { header: 'Nama Referrer' }),
    ch.accessor('ewallet.name', { header: 'E-Wallet' }),
    ch.accessor('no_hp', { header: 'Nomor E-Wallet' }),
    ch.accessor('point', {
      header: 'Poin',
      cell({ row }) {
        return (
          <p className="font-semibold text-primary-yes">
            {Math.abs(row.original.point)}
          </p>
        )
      },
    }),
    ch.accessor('status', {
      header: 'Status Penukaran',
      cell({ row }) {
        return <StatusBadge status={row.original.status} />
      },
    }),
    ch.display({
      id: 'action',
      header: () => <p className="text-center">Aksi</p>,
      cell({ row }) {
        const [_, setState] = usePromptCtx()

        if (row.original.status !== PointExchangeHistoryStatus.Ongoing)
          return <p className="text-center">-</p>

        return (
          <div>
            <Button
              size={'icon'}
              variant={'ghost'}
              className="text-yes-600"
              onClick={() =>
                setState({
                  action: 'approve',
                  data: row.original,
                })
              }
            >
              <CheckIcon />
            </Button>
            <Button
              size={'icon'}
              variant={'ghost'}
              className="text-yes-600"
              onClick={() =>
                setState({
                  action: 'reject',
                  data: row.original,
                })
              }
            >
              <Cross2Icon />
            </Button>
          </div>
        )
      },
    }),
  ]
}

function PointHistoryTable() {
  const { data } = useQuery(listPointExchangeHistoryQry())
  const columns = useMemo(getColumns, [])
  const { table } = useDataTable({
    data: data?.getPointExchangeHistory || [],
    columns,
  })

  return (
    <Card>
      <CardContent className="space-y-3 p-2">
        <DataTable table={table} />
      </CardContent>
    </Card>
  )
}

function StatusBadge({ status }: { status: RowItem['status'] }) {
  return (
    <Badge
      className={cx('text-xs', {
        'bg-green-200 text-green-700 hover:bg-green-300':
          status === PointExchangeHistoryStatus.Completed,
        'bg-yellow-200 text-yellow-700 hover:bg-yellow-300':
          status === PointExchangeHistoryStatus.Ongoing,
        'bg-red-200 text-red-700 hover:bg-red-300':
          status === PointExchangeHistoryStatus.Failed,
      })}
    >
      {status}
    </Badge>
  )
}

type PromptCtxValue = {
  action: 'approve' | 'reject'
  data: RowItem
} | null

const PromptCtx = createContext<
  [PromptCtxValue, React.Dispatch<React.SetStateAction<PromptCtxValue>>]
>(null!)

function usePromptCtx() {
  const ctx = useContext(PromptCtx)
  if (!ctx) throw new Error('usePromptCtx must be used within PromptProvider')
  return ctx
}

function PromptProvider({ children }: { children: React.ReactNode }) {
  const [prompt, setPrompt] = useState<PromptCtxValue>(null)

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async () => {
      if (!prompt) return
      await gqlFetch({
        query: GqlUpsertPointExchange,
        variables: {
          data: {
            id: prompt.data.id,
            ewallet_id: prompt.data.ewallet_id,
            point: Math.abs(prompt.data.point),
            no_hp: prompt.data.no_hp,
            status:
              prompt.action === 'approve'
                ? PointExchangeHistoryStatus.Completed
                : PointExchangeHistoryStatus.Failed,
            user_id: prompt.data.user_id,
          },
        },
      })
    },
    onSuccess() {
      invalidateQueryKeys(['listPointExchange'])
      setPrompt(null)
    },
  })

  const content = useMemo(() => {
    if (prompt) {
      const isApprove = prompt.action === 'approve'
      return (
        <CredenzaContent>
          <CredenzaHeader>
            <CredenzaTitle className="text-center">
              Konfirmasi {isApprove ? 'Transfer' : 'Penolakan'}
            </CredenzaTitle>
            <CredenzaDescription className="hidden">
              Tukar point descrption
            </CredenzaDescription>
          </CredenzaHeader>
          <CredenzaBody className="mt-4 flex flex-col items-center gap-2">
            <div className="my-8 max-w-40">
              <img src="/affiliator/withdraw.png" />
            </div>
            <p className="text-center">
              Apakah anda yakin telah {isApprove ? 'mentransfer' : 'menolak'} ke
              user
              <span className="text-primary-yes">
                {prompt?.data.user.name}
              </span>{' '}
              dengan nomor e-wallet{' '}
              <span className="text-primary-yes">{prompt?.data.no_hp}</span>{' '}
              sejumlah{' '}
              <span className="text-nowrap font-semibold">
                {new Intl.NumberFormat('id-ID', {
                  style: 'currency',
                  currency: 'IDR',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(Math.abs(prompt?.data.point || 0))}
              </span>
              ?<span className="font-bold text-destructive">*</span>
            </p>
            {isApprove && (
              <p className="text-center text-sm text-gray-500">
                *Pastikan transaksi sudah dilakukan sebelum melanjutkan
              </p>
            )}
          </CredenzaBody>
          <CredenzaFooter className="mt-8">
            <CredenzaClose asChild>
              <Button variant="outline" size={'sm'}>
                Batal
              </Button>
            </CredenzaClose>
            <Button
              size="sm"
              onClick={() =>
                toast.promise(mutateAsync(), {
                  loading: 'Loading...',
                  success: 'Berhasil tukar poin',
                  error: 'Gagal tukar poin',
                })
              }
              disabled={isPending}
            >
              Ya, {isApprove ? 'Tukar Sekarang' : 'Tolak Permintaan'}
            </Button>
          </CredenzaFooter>
        </CredenzaContent>
      )
    }
  }, [prompt, mutateAsync, isPending])

  return (
    <PromptCtx.Provider value={[prompt, setPrompt]}>
      {children}
      <Credenza open={!!prompt} onOpenChange={() => setPrompt(null)}>
        {content}
      </Credenza>
    </PromptCtx.Provider>
  )
}

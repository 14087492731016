import { useMemo, useState } from 'react'

import { PlusIcon } from '@radix-ui/react-icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createFileRoute, Link } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import { zodValidator } from '@tanstack/zod-adapter'
import { EditIcon, Trash2Icon } from 'lucide-react'
import { z } from 'zod'

import { JobCompanyListQuery } from '@/_gql/graphql'

import { gqlFetch } from '@/services/graphql/fetcher'
import { GqlJobCompanyDelete } from '@/services/job/gql'
import { queryJobCompanyList } from '@/services/job/query'

import { AdminPage } from '@/components/layout/admin/page'
import { Button, ButtonLoading } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { DataTable } from '@/components/ui/data-table/data-table'
import { useDataTable } from '@/components/ui/data-table/use-data-table'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Skeleton } from '@/components/ui/skeleton'

import { invalidateQueryKeys } from '@/lib/utils/utils'

import { useFilters } from '@/hooks/use-filter'

const searchParamsSchema = z.object({
  s: z.string().nullish(),
})

export const Route = createFileRoute('/admin-v2/master/company/')({
  validateSearch: zodValidator(searchParamsSchema),
  component: RouteComponent,
})

function RouteComponent() {
  const { filters, setFilters } = useFilters('/admin-v2/master/company/')

  const { data, isLoading } = useQuery(queryJobCompanyList())
  const columns = useMemo(getColumns, [])
  const { table } = useDataTable({
    data: data || [],
    state: {
      globalFilter: filters.s,
    },
    columns,
  })

  return (
    <AdminPage>
      <AdminPage.Breadcrumb
        items={[['Master Perusahaan', '/admin-v2/master/company']]}
      />
      <AdminPage.Header
        title="Master Perusahaan"
        desc={
          isLoading ? (
            <Skeleton className="h-5 w-32" />
          ) : (
            `Jumlah perusahaan: ${data?.length || 0} list`
          )
        }
        action={
          <Button size="sm" asChild>
            <Link from={Route.fullPath} to="/admin-v2/master/company/create">
              <PlusIcon />
              Tambah Perusahaan
            </Link>
          </Button>
        }
      />
      <Card className="max-w-full overflow-auto p-2">
        <CardContent className="relative space-y-2 p-0">
          <div>
            <Input
              className="max-w-80"
              placeholder="Cari perusahaan"
              defaultValue={filters.s || undefined}
              onChange={(e) => {
                setFilters({ s: e.target.value })
              }}
            />
          </div>
          <DataTable table={table} />
        </CardContent>
      </Card>
    </AdminPage>
  )
}
type CompanyItem = JobCompanyListQuery['getJobCompany']['data'][number]

const column = createColumnHelper<CompanyItem>()

function getColumns() {
  return [
    column.accessor('id', {
      header: 'ID',
      size: 1,
      cell: ({ cell }) => {
        return <div className="w-">{cell.row.original.id}</div>
      },
    }),
    column.accessor('name', {
      header: 'Nama perusahaan',
    }),
    column.display({
      id: 'actions',
      header: 'Aksi',
      cell: ({ cell }) => {
        const { id, name } = cell.row.original
        return (
          <div className="flex gap-1.5">
            <Button
              size="icon"
              className="size-8 text-primary-yes"
              variant="ghost"
              asChild
            >
              <Link
                from={Route.fullPath}
                to="/admin-v2/master/company/$id"
                params={{ id: String(id) }}
              >
                <EditIcon />
              </Link>
            </Button>
            <RemoveActionButton company={{ id, name }} />
          </div>
        )
      },
    }),
  ]
}

interface DialogProps {
  company: {
    id: number
    name: string
  }
}

function RemoveActionButton({ company }: DialogProps) {
  const [open, setOpen] = useState(false)

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async () => {
      await gqlFetch({
        query: GqlJobCompanyDelete,
        variables: { id: company.id },
      })

      setOpen(false)
      invalidateQueryKeys(['company-list', 'company-detail'])
    },
  })

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="icon" className="size-8 text-primary-yes" variant="ghost">
          <Trash2Icon />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete company</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete this company? This action cannot be
            undone.
          </DialogDescription>
          <p className="w-min max-w-full text-nowrap rounded-md bg-slate-200 p-1.5 text-sm font-medium text-slate-700">
            {company.name}
          </p>
        </DialogHeader>
        <DialogFooter>
          <ButtonLoading onClick={() => mutateAsync()} isLoading={isPending}>
            Delete
          </ButtonLoading>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

import { createFileRoute, useRouter } from '@tanstack/react-router'
import { useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { FileOrUrl } from '@/lib/utils/zod'
// import { CardProfile } from './-components/card-profile'
import { CardProfile } from '../digishoku/profile/-components/card-profile'
import { Button } from '@/components/ui/button'
import { PlusIcon } from '@radix-ui/react-icons'
import { Trash2Icon } from 'lucide-react'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { FormFieldSelect } from '@/components/ui/form-field/select'
import { Input } from '@/components/ui/input'
import { FormFieldDatePicker } from '@/components/ui/form-field/date-picker'
import { Checkbox } from '@/components/ui/checkbox'
import { FileUploader } from '@/components/ui/form-field/file-uploader'
import { queryUserAttachment, queryUserLanguage } from '@/services/user/query'
import { GetUserAttachmentQuery, GetUserLanguageQuery } from '@/_gql/graphql'
import { normalizeEnum, formatDateTimeServer, formatDateOnly } from '@/lib/utils/utils'
import { Language, AttachmentType } from '@/_gql/graphql'
import { ATTACHMENT_OPTIONS, ATTACHMENT_SIMPLIFY_OPTIONS, CERTIFICATE_TYPE } from '@/services/user/constant'
import { apiClient } from '@/services/rest/http'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'


export const Route = createFileRoute('/_user/profile/licences')({
    loader: async ({ context: { queryClient: qc } }) => {
        const [attachment] = await Promise.all([
            qc.fetchQuery(queryUserAttachment())
        ])

        return {
            attachment,
            async refetch() {
                await qc.refetchQueries(queryUserAttachment())
            },
        }
    },
    component: RouteComponent,
})

function RouteComponent() {
    const { attachment, refetch } = Route.useLoaderData()
    const form = useForm<FormDto>({
        resolver: zodResolver(FormDto),
        defaultValues: getDefaultValues(attachment),
    })
    const navigate = Route.useNavigate()
    const router = useRouter()




    const { mutateAsync: mutateAttachment } = useMutation({
        mutationKey: ['upsert-attachment'],
        mutationFn: async (data: FormDto['licences']) => {
            await apiClient.post(
                '/api/user/attachment/upsert',
                constructFormDataAttachment(data),
            )

            await refetch()
            navigate({ to: '/profile/languages' })
            router.invalidate()
        },
    })

    const handleOnSubmit = form.handleSubmit((data) => {
        const attachmentPayload = data.licences.map((y) => ({
            licences_name: y.licences_name,
            id: y.id,
            attachment_type: y.attachment_type,
            file: y.file,
            start_date: y.start_date,
            end_date: y.end_date
        }))

        toast.promise(mutateAttachment(attachmentPayload), {
            loading: 'Menyimpan informasi Lisensi',
            success: 'Informasi Lisensi berhasil disimpan',
            error: 'Terjadi kesalahan saat menyimpan informasi',
        })
    }, console.error)
    const licencesFields = useFieldArray({
        control: form.control,
        name: 'licences',
        keyName: 'eId',
    })

    const isEmpty = licencesFields.fields.length === 0


    const showRemove = licencesFields.fields.length > 1

    return (
        <>
            <Form {...form}>
                <form onSubmit={handleOnSubmit}>

                    <CardProfile title="Lisensi / Sertifikat">
                        <CardProfile.Content className='flex flex-col'>
                            {isEmpty ? (<EmptyState onAdd={() => licencesFields.append(defaultItem())} />) : (
                                <>
                                    {licencesFields.fields.map((field, idx) => (
                                        <LicencesFormItem
                                            key={field.eId}
                                            idx={idx}
                                            showRemove={showRemove}
                                            onRemove={() => {
                                                licencesFields.remove(idx)
                                            }}
                                        />
                                    ))}
                                    <div className='col-span-3'>
                                        <Button
                                            className="w-full rounded-xl border-primary-yes text-primary-yes md:w-auto"
                                            variant="outline"
                                            type="button"
                                            onClick={() => licencesFields.append(defaultItem())}
                                        >
                                            <PlusIcon />
                                            Tambah Lisensi
                                        </Button>
                                    </div>
                                </>
                            )}



                        </CardProfile.Content>
                        <CardProfile.Footer>

                            <CardProfile.SubmissionButton />
                        </CardProfile.Footer>
                    </CardProfile>
                </form>

            </Form>
        </>
    )
}

type LanguageData = GetUserLanguageQuery['getUserLanguage']['data'] | null
type AttachmentData = GetUserAttachmentQuery['getUserAttachment']['data'] | null

function getDefaultValues(initialAttachment?: AttachmentData): FormDto {

    const CERTIFICATE_TYPE_LOWER_CASE = CERTIFICATE_TYPE.map(x => x.toLocaleLowerCase())
    //FILTER ATTACHMENT SERTIFIKAT ONLY
    const filterAttachment = (initialAttachment ?? []).filter(attachment => CERTIFICATE_TYPE_LOWER_CASE.includes(attachment.attachment_type))
    const data: FormDto = {
        licences: filterAttachment.map((x: any) => ({
            ...x,
            id: x.id || undefined,
            licences_name: x.name,
            attachment_type: normalizeEnum(x.attachment_type) ?? undefined,
            start_date: x.start_date ? new Date(x.start_date) : undefined!,
            end_date: x.end_date ? new Date(x.end_date) : null,
            file: [x.url].filter(Boolean),
            created_at: x.created_at ? new Date(x.created_at) : undefined!,
        })) || [],
    }


    return data
}
interface FormDto extends z.infer<typeof FormDto> { }
const FormDto = z.object({
    licences: z.array(
        z.object({
            id: z.onumber(),
            licences_name: z.string(),
            start_date: z.coerce.date().optional(),
            end_date: z.coerce.date().nullish(),
            file: z
                .array(FileOrUrl)
                .min(1, { message: 'Attachment file is required' }),
            attachment_type: z.nativeEnum(AttachmentType).optional(),
            created_at: z.coerce.date().optional(),
        }),
    ),
})

interface LicencesFormItemProps {
    idx: number
    showRemove: boolean
    onRemove?: () => void
}

function LicencesFormItem(props: LicencesFormItemProps) {
    const { idx, showRemove, onRemove } = props
    const form = useFormContext<FormDto>()




    return (
        <div className="relative grid grid-cols-2 gap-x-2 gap-y-6 rounded-md border border-border p-4 py-6 md:grid-cols-6">
            {showRemove && (
                <div className="absolute right-0 top-0">
                    <Button variant="ghost" size="icon" onClick={onRemove} type="button">
                        <Trash2Icon className="size-16 text-primary-yes" />
                    </Button>
                </div>
            )}

            <FormField
                control={form.control}
                name={`licences.${idx}.licences_name`}
                render={({ field }) => (
                    <FormFieldset label="Lisensi / Sertifikat Keahlian" className="col-span-full">
                        <Input placeholder="Masukkan Lisensi / Sertifikat Keahlian" {...field} />
                    </FormFieldset>
                )}
            />



            <FormField
                control={form.control}
                name={`licences.${idx}.attachment_type`}
                render={({ field }) => (
                    <FormFieldset
                        label="Berkas"
                        className="col-span-full "
                    >
                        <FormFieldSelect
                            placeholder="Pilih kategory berkas"
                            items={ATTACHMENT_SIMPLIFY_OPTIONS}
                            value={field.value}
                            onValueChange={field.onChange}
                        />
                    </FormFieldset>
                )}
            />





            <FormField
                control={form.control}
                name={`licences.${idx}.file`}
                render={({ field }) => (
                    <FormFieldset label="File" className="col-span-full" hideLabel>
                        <FileUploader
                            ref={field.ref}
                            value={field.value ?? undefined}
                            onValueChange={field.onChange}
                            fileRuleInfo="PNG, JPG, PDF,Docx up to 10MB"
                            multiple={false}
                            maxSize={1024 * 1024 * 10}
                            accept={{
                                'image/*': ['.png', '.jpg', '.jpeg'],
                                'application/pdf': ['.pdf'],
                                'application/msword': ['.docx'],
                            }}
                        />
                    </FormFieldset>
                )}
            />


            <div className="col-span-3 space-y-2">
                <FormField
                    control={form.control}
                    name={`licences.${idx}.start_date`}
                    render={({ field }) => (
                        <FormFieldset label="Tanggal mulai" noFormControl>
                            <FormFieldDatePicker
                                date={field.value}
                                onSelect={field.onChange}
                            />
                        </FormFieldset>
                    )}
                />
                <div className="flex items-center space-x-2">
                    <Checkbox
                        id="until-now"
                        checked={form.watch(`licences.${idx}.end_date`) === null}
                        onCheckedChange={(checked) => {
                            form.setValue(
                                `licences.${idx}.end_date`,
                                checked ? null : undefined!,
                            )
                        }}
                    />
                    <label
                        htmlFor="until-now"
                        className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                        Saya masih mengikuti sertifikasi / lisensi ini
                    </label>
                </div>
            </div>

            <FormField
                control={form.control}
                name={`licences.${idx}.end_date`}
                render={({ field }) => (
                    <FormFieldset
                        label="Tanggal berakhir"
                        className="col-span-3"
                        noFormControl
                    >
                        <FormFieldDatePicker
                            date={field.value ? field.value : undefined}
                            onSelect={field.onChange}
                            disabled={form.watch(`licences.${idx}.end_date`) === null}
                        />
                    </FormFieldset>
                )}
            />


        </div>
    )
}


function defaultItem(): any {
    return {
        id: undefined!,
        licences_name: '',
        attachment_type: undefined,
        file: [],
        start_date: undefined!,
        end_date: undefined!,
        created_at: undefined!,
    }
}


interface AttachementFormItem {
    idx: number
    showRemove: boolean
    onRemove?: () => void
    disableSelect?: boolean
}



interface EmptyStateProps {
    onAdd: () => void
}

function EmptyState({ onAdd }: EmptyStateProps) {
    return (
        <div className="col-span-full flex flex-col items-center justify-center gap-2 rounded-xl border py-12">
            <p>Punya kemampuan lisensi keahlian?</p>
            <Button
                size="sm"
                type="button"
                className="rounded-lg bg-gradient-primary"
                onClick={onAdd}
            >
                Tambah Lisensi
            </Button>
        </div>
    )
}


function constructFormDataAttachment(data: FormDto['licences']) {
    const formData = new FormData()

    for (const attachment of data) {
        if (!attachment.file || !attachment.file.length) {
            throw new Error('Attachment file is required')
        }

        if (attachment.id) formData.append('id', String(attachment.id))
        if (attachment.attachment_type) formData.append('attachment_type', attachment.attachment_type)


        if (attachment.licences_name) formData.append('name', attachment.licences_name)
        const file = attachment.file?.[0]
        formData.append('url', typeof file === 'string' && file ? file : '')
        formData.append('file', file instanceof File ? file : new File([], 'null'))
        if (attachment.start_date) formData.append('start_date', formatDateTimeServer(attachment?.start_date))
        if (attachment.end_date) formData.append('end_date', formatDateTimeServer(attachment?.end_date))
        if (attachment.created_at)
            formData.append('created_at', formatDateTimeServer(attachment.created_at))
    }

    return formData
}
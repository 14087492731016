import { createFileRoute, redirect } from '@tanstack/react-router'

import { useAuth } from '@/services/auth/use-auth'

import { AdminLayout } from '@/components/layout/admin/layout'

export const Route = createFileRoute('/admin-v2')({
  beforeLoad: async ({ preload }) => {
    if (preload) return
    const auth = useAuth.getState()
    const isAdmin = auth.isAdmin()
    if (!isAdmin) throw redirect({ to: '/dashboard' })
  },
  component: RouteComponent,
})

function RouteComponent() {
  return <AdminLayout />
}

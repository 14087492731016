import { createFileRoute, redirect } from '@tanstack/react-router'
import { queryGetJobUser } from '@/services/job/query'
import { toast } from 'sonner'
import { AdminPage } from '@/components/layout/admin/page'
import { JobUser } from '@/_gql/graphql'
import { useFilters } from '@/hooks/use-filter'
import { useState, useMemo } from 'react'
import { useDataTable } from '@/components/ui/data-table/use-data-table'
import { Card, CardContent } from '@/components/ui/card'
import { DebouncedInput } from '@/components/ui/debounce-input'
import { DataTable } from '@/components/ui/data-table/data-table'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { Link } from '@tanstack/react-router'
import { Button } from '@/components/ui/button'
import { Eye } from 'lucide-react'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { FormFieldSelect } from '@/components/ui/form-field/select'
import { LANGUAGE_OPTIONS } from '@/services/user/constant'
import { z } from 'zod'

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { zodValidator } from '@tanstack/zod-adapter'

const searchParamsSchema = z.object({
    language: z.string().nullish(),
    language_level: z.string().nullish(),

})

export const Route = createFileRoute('/admin-v2/job/applicant/vacancy/$jobId/')(
    {
        validateSearch: zodValidator(searchParamsSchema),
        loaderDeps: ({ search: { language, language_level } }) => ({
            language,
            language_level
        }),
        async loader({ params, context, deps: { language, language_level } }) {
            const { jobId } = params
            const { queryClient } = context
            const request = {
                company_id: null,
                start_apply_date: null,
                end_apply_date: null,
                is_recommended_by_ai: null,
                search: '',
            }
            const applicant = await queryClient
                .fetchQuery(queryGetJobUser(request))
                .catch(() => null)
            const filterApplicant = applicant?.filter(x => {
                const jobMatch = x.job_id === Number(jobId);


                const languageMatch = language && language_level
                    ? x.user.user_language.some((lang: any) =>
                        lang.language.toLowerCase() === language?.toLowerCase() &&
                        lang.language_level.toLowerCase() === language_level?.toLowerCase()
                    )
                    : true; // If no user_language, don't filter by language

                return jobMatch && languageMatch;
            })

            if (!applicant) {
                toast.error('User not found')
                throw redirect({ to: '/admin-v2/job/applicant' })
            }

            return {
                applicant: filterApplicant,

            }


        },
        component: RouteComponent,
    },
)

function RouteComponent() {
    const { applicant } = Route.useLoaderData()

    console.log(applicant, 'halo')
    return (
        <>
            <AdminPage>
                <AdminPage.Breadcrumb
                    items={[
                        ['Master Job Listing', '/admin-v2/job/vacancy'],
                        ['List Job Listing', '/admin-v2/job/applicant/vacancy'],
                        ['List Pelamar', '/admin-v2/job/applicant'],

                    ]}
                />
                <AdminPage.Header
                    title="List Pelamar"
                    desc={
                        `Jumlah pelamar: ${applicant?.length || 0} list`
                    }
                />

                {/* TODO */}
                {/* @ts-expect-error */}
                <ApplicantListSection data={applicant || []} />
            </AdminPage>
        </>
    )
}

interface ApplicantListSectionProps {
    data?: JobUser[] | undefined
}

function ApplicantListSection({ data }: ApplicantListSectionProps) {
    const { filters, setFilters, resetFilters } = useFilters(
        '/admin-v2/job/applicant/vacancy/$jobId/'
    )
    const [openFilter, setOpenFilter] = useState(false)

    const [globalFilter, setGlobalFilter] = useState('')

    const columns = useMemo(getColumns, [])

    const { table } = useDataTable({
        data: data || [],
        columns,
        manualPagination: false,
        globalFilterFn: 'fuzzy',
        onGlobalFilterChange: setGlobalFilter,
        state: { globalFilter },
    })

    const handleFilterLanguageChange = (value: string) => {
        if (value === "none") {
            resetFilters()
        } else {
            setFilters({
                ...filters,
                language: value
            })
        }

    };

    const handleFilterLanguageLevelChange = (value: string) => {
        setFilters({
            ...filters,
            language_level: value
        })
    };


    return (
        <Card>
            <CardContent className="overflow-auto p-4">
                <div className="mb-4 flex justify-between gap-4">
                    <div className="flex w-full gap-6">
                        <div className="w-1/2">
                            <DebouncedInput
                                className="lg:w-full"
                                value={globalFilter ?? ''}
                                onChange={(value) => setGlobalFilter(String(value))}
                                placeholder="Cari .."
                            />
                        </div>
                        <div className='w-1/2 flex gap-6'>
                            <div className='w-1/2'>
                                <Select onValueChange={handleFilterLanguageChange} value={filters.language ?? ''}>
                                    <SelectTrigger
                                        className="data-[placeholder]:text-gray-300">
                                        <SelectValue
                                            placeholder={'Filter Language'}
                                        />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem key="none" value="none">
                                            None
                                        </SelectItem>
                                        {LANGUAGE_OPTIONS.map((item) => {
                                            return (
                                                <SelectItem key={item.value} value={item.value}>
                                                    {item.label}
                                                </SelectItem>
                                            )
                                        })}
                                    </SelectContent>
                                </Select>
                            </div>
                            {
                                filters.language ? (
                                    <div className='w-1/2'>
                                        <Select onValueChange={handleFilterLanguageLevelChange} value={filters.language_level ?? ''}>
                                            <SelectTrigger
                                                className="data-[placeholder]:text-gray-300">
                                                <SelectValue
                                                    placeholder={'Filter Language Level'}
                                                />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {LANGUAGE_OPTIONS.find(lang => lang.value == filters.language)?.level.map((item) => {
                                                    return (
                                                        <SelectItem key={item.value} value={item.value}>
                                                            {item.label}
                                                        </SelectItem>
                                                    )
                                                })}
                                            </SelectContent>
                                        </Select>
                                    </div>

                                ) : (<></>)
                            }
                        </div>
                        {/* <div className="w-1/2">
                            <FilterCompany
                                defaultValue={filters.job_id}
                                setFilter={setFilters}
                            />
                        </div> */}
                    </div>
                    {/* <div>
                        <FilterTable
                            openFilter={openFilter}
                            setOpenFilter={setOpenFilter}
                            resetFilter={resetFilters}
                            setFilter={setFilters}
                            defaultValue={{
                                from: new Date(filters.start_apply_date ?? ''),
                                to: new Date(filters.end_apply_date ?? ''),
                            }}
                        />
                    </div> */}
                </div>
                <DataTable table={table} />
            </CardContent>
        </Card>
    )
}

const column = createColumnHelper<any>()

function getColumns() {
    return [
        column.accessor('id', {
            header: 'No',
        }),
        column.accessor('user.name', {
            header: 'Pelamar',
        }),
        column.accessor('job.title', {
            header: 'Job Listing',
        }),
        column.accessor('job.job_company.name', {
            header: 'Perusahaan',
        }),

        column.accessor('job_user_selection_phase[0].created_at', {
            header: 'Tanggal Apply',
            cell: (cell) => {
                const { job_user_selection_phase } = cell.row.original
                const apply_date = job_user_selection_phase[0]?.created_at
                const date = apply_date ? format(apply_date, 'dd LLL yyyy') : ''

                return <div>{date}</div>
            },
        }),
        column.accessor('test_result', {
            header: 'Hasil Rekomendasi',
            cell: (cell) => {
                const data = cell.row.original
                const last_job_user_selection_phase =
                    data?.last_job_user_selection_phase
                const result =
                    last_job_user_selection_phase?.ai_recommender?.is_recommended_by_ai
                const ai_cv_recommender =
                    last_job_user_selection_phase?.job_selection_phase
                        ?.master_job_selection_phase?.ai_cv_recommender

                if (
                    last_job_user_selection_phase?.ai_recommender &&
                    result &&
                    ai_cv_recommender
                ) {
                    return <div className="text-[#34C759]">Direkomendasikan</div>
                } else if (
                    last_job_user_selection_phase?.ai_recommender &&
                    !result &&
                    ai_cv_recommender
                ) {
                    return <div className="text-[#FF3B30]">Gagal</div>
                } else {
                    return <div>Tidak memakai AI untuk rekomendasi</div>
                }
            },
        }),
        column.display({
            id: 'actions',
            header: 'Aksi',
            cell: ({ cell }) => {
                const { id, title } = cell.row.original
                return (
                    <div className="flex gap-1.5">
                        {/* <Button
              size="icon"
              className="size-8 text-primary-yes"
              variant="ghost"
            >
              <Check />
            </Button>
            <Button
              size="icon"
              className="size-8 text-primary-yes"
              variant="ghost"
            >
              <X />
            </Button> */}
                        <Link to={`/admin-v2/job/applicant/${id}`}>
                            <Button
                                size="icon"
                                className="size-8 text-primary-yes"
                                variant="ghost"
                            >
                                <Eye />
                            </Button>
                        </Link>
                    </div>
                )
            },
        }),
    ]
}

import { ComponentProps, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createFileRoute,
  Link,
  redirect,
  useRouter,
} from '@tanstack/react-router'
import placeholder from '@tiptap/extension-placeholder'
import { formatDate } from 'date-fns'
import { useForm, useWatch } from 'react-hook-form'
import { toast } from 'sonner'
import { useDebounce } from 'use-debounce'
import { z } from 'zod'

import {
  JobUserSelectionPhaseHrdRoom,
  JobUserSelectionPhaseStatus,
  SentHrdRoomTestTokenMutationVariables,
} from '@/_gql/graphql'

import { GraphQLError } from '@/services/graphql/error'
import { gqlFetch } from '@/services/graphql/fetcher'
import {
  GqlChangeStatusJobUserSelection,
  GqlSentHRDRoomTestToken,
} from '@/services/job/gql'
import {
  queryGetJobUserDetail,
  queryHRDRoomGetModules,
  queryHRDRoomGetTests,
  queryJobUserSelectionPhaseHrdRoom,
} from '@/services/job/query'
import { apiClient } from '@/services/rest/http'
import {
  ATTACHMENT_OPTIONS,
  EDUCATION_LEVEL_OPTIONS,
  EMPLOYMENT_STATUS_OPTIONS,
  HEALTH_STATUS_OPTIONS,
  LOCATION_TYPE_OPTIONS,
  RELIGION_OPTIONS,
} from '@/services/user/constant'

import { HomeIcon } from '@/components/icons/common'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { Button, ButtonLoading } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { FormFieldSelectCombobox } from '@/components/ui/form-field/select-combobox'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'

// import { BadgeStatus } from '@/routes/admin-v2/participant'
import { Attachment } from '@/routes/admin-v2/participant/$participantId/-components/attachment'
import { Education } from '@/routes/admin-v2/participant/$participantId/-components/education'
import { EmploymentHistory } from '@/routes/admin-v2/participant/$participantId/-components/employment-history'
import { Family } from '@/routes/admin-v2/participant/$participantId/-components/family'
import { HealthHistory } from '@/routes/admin-v2/participant/$participantId/-components/health-history'
import { Language } from '@/routes/admin-v2/participant/$participantId/-components/language'
import { PersonalInformation } from '@/routes/admin-v2/participant/$participantId/-components/personal-information'

import { queryClient } from '@/lib/configs/tanstack-query'

export const Route = createFileRoute('/admin-v2/job/applicant/$applicantId/')({
  async loader({ params, context }) {
    const { applicantId } = params
    const { queryClient } = context
    const participant = await queryClient
      .fetchQuery(queryGetJobUserDetail(String(applicantId)))
      .catch(() => null)

    if (!participant) {
      toast.error('User not found')
      throw redirect({ to: '/admin-v2/job/applicant' })
    }

    return {
      participant,
      refetch: async () => {
        queryClient.refetchQueries(queryGetJobUserDetail(String(applicantId)))
      },
    }
  },
  component: RouteComponent,
})

function RouteComponent() {
  const router = useRouter()

  const { participant, refetch } = Route.useLoaderData()
  const user_personal_info = participant?.user?.user_personal_info
  const user_status_profile = participant?.user.user_status_profile

  const name = user_personal_info?.name
  const email = participant?.user?.email
  const id = participant?.user.id
  const handphone_number = participant?.user.handphone_number

  const user_education = participant?.user.user_education.map((t) => ({
    level: EDUCATION_LEVEL_OPTIONS.find(
      (x) => x.value == t?.level.toUpperCase()
    )?.label,
    major: t?.major,
    school: t?.school,
    start_date: t?.start_date
      ? formatDate(t?.start_date, 'MM/dd/yyyy')
      : 'sekarang',
    graduation_date: t?.graduation_date
      ? formatDate(t?.graduation_date, 'MM/dd/yyyy')
      : 'sekarang',
  }))

  const healt_history = participant?.user.user_health.map((t) => ({
    name: t?.name,
    year: t?.year,
    status: HEALTH_STATUS_OPTIONS.find(
      (x) => x.value == t?.status.toUpperCase()
    )?.label,
    explanation: t?.explaination,
  }))

  const attachment = participant?.user?.user_attachment.map((t) => ({
    attachment_type: ATTACHMENT_OPTIONS.find(
      (x) => x.value == t?.attachment_type.toUpperCase()
    )?.label,
    url: t?.url,
  }))
  const family = participant?.user.user_family.map((t) => ({
    name: t?.name,
    relation: t?.relation,
    birthdate: t?.birthdate,
    city: t?.city,
    province: t?.province,
    religion: RELIGION_OPTIONS.find(
      (x) => x.value == t?.religion?.toUpperCase()
    )?.label,
    country: t?.country,
    nationality: t?.nationality,
    phone_number: t?.phone_number,
  }))

  const language = participant?.user?.user_language?.map((t) => ({
    language: t?.language,
    language_level: t?.language_level,
    url: t?.url,
  }))

  const employment_history_value = participant?.user?.user_experience.map(
    (t) => {
      const start_date = t?.start_date
        ? formatDate(t.start_date, 'MM/dd/yyyy')
        : 'sekarang'
      const end_date = t?.end_date
        ? formatDate(t.end_date, 'MM/dd/yyyy')
        : 'sekarang'

      return {
        title: t?.title,
        company_name: t?.company_name,
        employment_type: t?.employment_type
        ? EMPLOYMENT_STATUS_OPTIONS.find((x) => x.value === t.employment_type.toUpperCase())?.label
        : undefined,
        date: `${start_date} - ${end_date}`,

        location: t?.location,
        location_type: t?.location_type
                ? LOCATION_TYPE_OPTIONS.find((x) => x.value === t.location_type.toUpperCase())?.label
                : undefined,
      }
    }
  )
  return (
    <div className="w-full px-4 pb-24">
      <BreadcrumbClass />
      <div className="mt-6 flex items-center justify-between">
        <div className="text-xl font-semibold">{name}</div>
        {/* <div>
          <span className="text-sm"> Status:</span>{' '}
          {BadgeStatus(user_status_profile?.status)}
        </div> */}
      </div>
      <div className="mt-6">
        <JobPhaseDetails />
      </div>
      <div className="mt-6">
        <PersonalInformation
          name={user_personal_info?.name}
          handphone_number={handphone_number}
          is_single_child={user_personal_info?.is_single_child}
          address={user_personal_info?.address}
          birthdate={user_personal_info?.birthdate}
          blood_type={user_personal_info?.blood_type}
          city={user_personal_info?.city}
          driver_license={user_personal_info?.driver_license}
          email={email}
          gender={user_personal_info?.gender}
          height={user_personal_info?.height}
          is_ever_been_going_abroad={
            user_personal_info?.is_ever_been_going_abroad
          }
          is_ever_proposing_passport={
            user_personal_info?.is_ever_proposing_passport
          }
          is_having_passport={user_personal_info?.is_having_passport}
          marital_status={user_personal_info?.marital_status}
          province={user_personal_info?.province}
          religion={user_personal_info?.religion}
          weight={user_personal_info?.weight}
          photo={user_personal_info?.photo}
          country={user_personal_info?.country}
          nationality={user_personal_info?.nationality}
        />
      </div>
      <div className="mt-6">
        <Education user_education={user_education} />
      </div>
      <div className="mt-6">
        <EmploymentHistory user_experience={employment_history_value} />
      </div>
      <div className="mt-6">
        <HealthHistory user_health={healt_history} />
      </div>
      <div className="mt-6">
        <Attachment attachment={attachment} />
      </div>
      <div className="mt-6">
        {/* TODO */}
        {/* @ts-expect-error */}
        <Family family={family} />
      </div>
      <div className="mt-6">
        {/* TODO */}
        {/* @ts-expect-error */}
        <Language language={language} />
      </div>
      <div className="mt-6">
        <RecomendationAI />
      </div>
    </div>
  )
}

export function BreadcrumbClass() {
  return (
    <Breadcrumb className="text-white">
      <BreadcrumbList className="text-[#475467]">
        <BreadcrumbItem>
          <BreadcrumbLink href="/">
            <HomeIcon color="#475467" />
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator className="text-[#475467]" />

        <BreadcrumbItem>
          <div>Master Job Listing</div>
        </BreadcrumbItem>
        <BreadcrumbSeparator className="text-[#475467]" />
        <BreadcrumbItem>
          <div>...</div>
        </BreadcrumbItem>
        <BreadcrumbSeparator className="text-[#475467]" />

        <BreadcrumbItem>
          <div className="rounded-md bg-[#FFF4F5] p-2 text-primary-yes">
            Daftar Pelamar
          </div>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}

function JobPhaseDetails() {
  const { participant } = Route.useLoaderData()
  const { job, job_user_selection_phase } = participant

  const [statusPhaseChange, setStatusPhaseChange] = useState<string>('')
  const [isOpenChangePhase, setIsOpenChangePhase] = useState(false)
  const [idJobSelectionPhase, setIdJobSelectionPhase] = useState(0)
  const [isOpenHRDRoom, setIsOpenHRDRoom] = useState(false)
  const [jobUserSelectionPhaseId, setJobUserSelectionPhaseId] = useState(0)
  const [hrdRoomId, setHrdRoomId] = useState(0)

  if (job_user_selection_phase && job_user_selection_phase.length == 0) {
    return <></>
  }

  const changeStatusUserPhase = (id: number, status: string) => {
    setIdJobSelectionPhase(id)
    setStatusPhaseChange(status)
    setIsOpenChangePhase(true)
  }
  return (
    <>
      <DialogConfirmationChangeStatus
        id={idJobSelectionPhase}
        open={isOpenChangePhase}
        setOpen={setIsOpenChangePhase}
        status={statusPhaseChange}
      />
      <HRDRoom
        open={isOpenHRDRoom}
        setOpen={setIsOpenHRDRoom}
        id={jobUserSelectionPhaseId}
        hrdRoomId={hrdRoomId}
      />
      <Card>
        <CardHeader className="flex flex-row gap-x-4">
          <div className="aspect-square w-16 overflow-hidden rounded-md">
            <img src={job?.job_company?.logo} alt="logo" />
          </div>
          <div>
            <h2 className="text-lg font-semibold">{job.title}</h2>
            <p>{job.job_company?.name}</p>
          </div>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="font-semibold text-black">Fase</TableHead>
                <TableHead className="font-semibold text-black">
                  Keterangan
                </TableHead>
                <TableHead className="font-semibold text-black">
                  Nilai AI
                </TableHead>
                <TableHead className="max-w-8 text-wrap font-semibold text-black">
                  Rekomendasi AI
                </TableHead>
                <TableHead className="font-semibold text-black">
                  Notes AI
                </TableHead>
                <TableHead className="font-semibold text-black">
                  HRD Room
                </TableHead>
                <TableHead className="font-semibold text-black">
                  Status
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {job_user_selection_phase.map((job_phase, index) => (
                <TableRow className="hover:bg-inherit">
                  <TableCell className="align-top">
                    {
                      job_phase.job_selection_phase.master_job_selection_phase
                        .phase
                    }
                  </TableCell>

                  <TableCell className="max-w-14 text-wrap align-top">
                    {
                      job_phase.job_selection_phase.master_job_selection_phase
                        .description
                    }
                  </TableCell>
                  <TableCell className="max-w-14 text-wrap align-top">
                    {job_phase.ai_recommender?.ai_score}
                  </TableCell>
                  <TableCell className="max-w-14 text-wrap align-top">
                    {job_phase.ai_recommender &&
                    job_phase.ai_recommender?.is_recommended_by_ai &&
                    job_phase.job_selection_phase.master_job_selection_phase
                      .ai_cv_recommender ? (
                      <div className="text-[#34C759]">Direkomendasikan</div>
                    ) : job_phase.ai_recommender &&
                      !job_phase.ai_recommender?.is_recommended_by_ai &&
                      job_phase.job_selection_phase.master_job_selection_phase
                        .ai_cv_recommender ? (
                      <div className="text-[#FF3B30]">Gagal</div>
                    ) : (
                      <div>Tidak memakai AI untuk rekomendasi</div>
                    )}
                  </TableCell>
                  <TableCell className="max-w-14 text-wrap align-top">
                    {job_phase.ai_recommender?.ai_notes}
                  </TableCell>
                  <TableCell className="align-top">
                    {job_phase.job_selection_phase.master_job_selection_phase
                      .hrd_room && (
                      <Button
                        variant="primary"
                        onClick={() => {
                          setHrdRoomId(
                            job_phase?.job_user_selection_phase_hrdroom?.id || 0
                          )
                          setJobUserSelectionPhaseId(job_phase.id)
                          setIsOpenHRDRoom(true)
                        }}
                      >
                        {!job_phase.job_user_selection_phase_hrdroom
                          ? 'Kirimkan Tes Psikologi'
                          : 'Lihat Detail Tes'}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell className="align-top">
                    {index == job_user_selection_phase.length - 1 &&
                    job_phase.status == PHASE_OPTIONS.pending.toLowerCase() ? (
                      <Select
                        value={job_phase.status}
                        onValueChange={(value: string) =>
                          changeStatusUserPhase(job_phase.id, value)
                        }
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select status" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(PHASE_OPTIONS).map(
                            ([value, label]) => (
                              <SelectItem key={value} value={value}>
                                <PhaseStatus
                                  status={value as PhaseStatusProps['status']}
                                />
                              </SelectItem>
                            )
                          )}
                        </SelectContent>
                      </Select>
                    ) : (
                      <>
                        {/* @ts-expect-error */}
                        <PhaseStatus status={job_phase.status} />
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {/* <TableRow className="hover:bg-inherit">
              <TableCell className="align-top">Biodata Screening</TableCell>
              <TableCell className="max-w-14 text-wrap align-top">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem
                adipisci, vel totam similique voluptatibus aperiam reiciendis
                rerum accusamus? Quos iusto tempora libero! Iusto odit quod
                alias commodi distinctio, in quos laborum, officiis quasi amet
                ullam eum, eius maiores non! Esse?
              </TableCell>
              <TableCell className="align-top">
                <Select>
                  <SelectTrigger>
                    <SelectValue placeholder="Select status" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(PHASE_OPTIONS).map(([value, label]) => (
                      <SelectItem key={value} value={value}>
                        <PhaseStatus
                          status={value as PhaseStatusProps['status']}
                        />
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </TableCell>
            </TableRow> */}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  )
}

const PHASE_OPTIONS = {
  pending: 'Pending',
  approved: 'Lulus',
  rejected: 'Ditolak',
} satisfies Record<PhaseStatusProps['status'], string>

type PhaseStatusProps = {
  status: 'pending' | 'approved' | 'rejected'
}

export function PhaseStatus({ status }: PhaseStatusProps) {
  const label = PHASE_OPTIONS[status]
  return (
    <div className="flex items-center gap-2">
      {status === 'pending' && (
        <div className="rounded-full bg-[#FFF494] px-2 py-1 text-[#978803]">
          {label}
        </div>
      )}
      {status === 'approved' && (
        <div className="rounded-full bg-[#CDFFD2] px-2 py-1 text-[#029E4A]">
          {label}
        </div>
      )}
      {status === 'rejected' && (
        <div className="rounded-full bg-[#FFCDD1] px-2 py-1 text-[#BC0000]">
          {label}
        </div>
      )}
    </div>
  )
}

interface DialogConfirmationChangeStatusProps {
  id: number
  status: string
  open: boolean
  setOpen: (open: boolean) => void
}
function DialogConfirmationChangeStatus({
  id,
  status,
  open,
  setOpen,
}: DialogConfirmationChangeStatusProps) {
  const { applicantId } = Route.useParams()
  const { refetch } = Route.useLoaderData()
  const router = useRouter()

  const TOAST_ID = 'ChangeStatus'
  const { mutateAsync, isPending } = useMutation({
    mutationFn: async () => {
      await gqlFetch({
        query: GqlChangeStatusJobUserSelection,
        variables: {
          request: {
            job_user_selection_phase: {
              id,
              status: status.toUpperCase() as JobUserSelectionPhaseStatus,
            },
          },
        },
      })
      return 1
    },
    onSuccess: () => {
      toast.success('Status Berhasil diubah', { id: TOAST_ID })
      queryClient.refetchQueries(queryGetJobUserDetail(String(applicantId)))
      refetch()
      router.invalidate()
      setOpen(false)
    },
    onError: (error) => {
      let msg = error.message
      if (GraphQLError.is(error)) {
        msg = error.getMessage()
      }
      setOpen(false)
    },
  })

  const handleSubmitDialog = async () => {
    await mutateAsync()
  }

  if (status == 'approved') {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-2xl">
          <DialogHeader>
            {/* <DialogTitle>Select image</DialogTitle> */}
            <DialogDescription>
              <div className="flex flex-col items-center gap-4">
                <div className="flex w-72 justify-center text-center text-lg">
                  Konfirmasi Terima Pengajuan{' '}
                </div>
                <div>
                  <img src="/img/user-status/user-status-phase-acceptance.svg" />
                </div>
                <div className="px:16 flex justify-center text-center text-lg sm:px-24">
                  Apakah anda yakin akan menerima pengajuan ini?
                </div>
              </div>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="mt-6 flex gap-4 sm:justify-center sm:gap-0">
            <Button variant={'outline'} onClick={() => setOpen(false)}>
              Kembali
            </Button>
            <Button className="w-full sm:w-auto" onClick={handleSubmitDialog}>
              Ya, Terima Pengajuan
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }

  if (status == 'rejected') {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-2xl">
          <DialogHeader>
            {/* <DialogTitle>Select image</DialogTitle> */}
            <DialogDescription>
              <div className="flex flex-col items-center gap-4">
                <div className="flex w-72 justify-center text-center text-lg">
                  Konfirmasi Tolak Pengajuan{' '}
                </div>
                <div>
                  <img src="/img/user-status/user-status-phase-rejected.svg" />
                </div>
                <div className="px:16 flex justify-center text-center text-lg sm:px-24">
                  Apakah anda yakin akan tolak pengajuan ini?
                </div>
              </div>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="mt-6 flex gap-4 sm:justify-center sm:gap-0">
            <Button variant={'outline'} onClick={() => setOpen(false)}>
              Kembali
            </Button>
            <Button className="w-full sm:w-auto" onClick={handleSubmitDialog}>
              Ya, Tolak Pengajuan
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }
}

function RecomendationAI() {
  const { participant } = Route.useLoaderData()
  const { last_job_user_selection_phase } = participant
  const idAccordion = 'recomendation'
  const [show, setShow] = useState<boolean>(true)

  const ai_cv_recommender =
    last_job_user_selection_phase?.job_selection_phase
      ?.master_job_selection_phase?.ai_cv_recommender
  const ai_recomender = last_job_user_selection_phase?.ai_recommender
  const status = ai_recomender?.is_recommended_by_ai
  const score = ai_recomender?.ai_score
  const notes = ai_recomender?.ai_notes

  if (!ai_recomender || !ai_cv_recommender) {
    return <></>
  }

  return (
    <Accordion
      defaultValue={idAccordion}
      type="single"
      collapsible
      className="w-full rounded-md border-t-4 border-t-[#D91F2E] bg-white"
      onValueChange={(value) => setShow(value === idAccordion)}
    >
      <AccordionItem value={idAccordion} className="border-none p-0">
        <AccordionTrigger className="border-b border-b-[#EAECF0] p-6">
          <div className="flex w-full items-center justify-between">
            <div>Hasil Rekomendasi AI</div>
            <div className="text-xs">{show ? 'Sembunyikan' : ''}</div>
          </div>
        </AccordionTrigger>
        <AccordionContent className="w-full">
          <div className="grid w-full grid-cols-1 gap-6 p-6">
            <div className="flex flex-col">
              <div className="font-semibold">Hasil</div>
              <div>
                {status && ai_cv_recommender ? (
                  <div className="text-[#34C759]">Direkomendasikan</div>
                ) : !status && ai_cv_recommender ? (
                  <div className="text-[#FF3B30]">Gagal</div>
                ) : (
                  <div>Tidak memakai AI untuk rekomendasi</div>
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="font-semibold">Nilai</div>
              <div>{score}</div>
            </div>
            <div className="flex flex-col">
              <div className="font-semibold">Notes</div>
              <div>{notes}</div>
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

interface HRDRoomProps {
  open: boolean
  setOpen: (open: boolean) => void
  id: number
  hrdRoomId: number
}
function HRDRoom({ open, setOpen, id, hrdRoomId }: HRDRoomProps) {
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
  })

  const [sModule, setSModule] = useState('')
  const [sTest, setSTest] = useState('')

  const moduleId = useWatch({ control: form.control, name: 'module_id' })

  const queryGetModules = useQuery(queryHRDRoomGetModules())
  const queryGetTests = useQuery(queryHRDRoomGetTests(Number(moduleId)))
  const queryGetJobUserSelectionPhaseHrdRoom = useQuery(
    queryJobUserSelectionPhaseHrdRoom(hrdRoomId)
  )

  const { mutateAsync } = useMutation({
    mutationFn: async (data: FormDto) => {
      await gqlFetch({
        query: GqlSentHRDRoomTestToken,
        variables: { request: formDtoToPayload(id, data) },
      })
      setOpen(false)
    },
  })

  const handleSubmitDialog = form.handleSubmit((data) => {
    toast.promise(mutateAsync(data), {
      loading: 'Mengirimkan test ke kandidat!',
      success: 'Sukses mengirimkan test ke kandidat!',
      error: 'Terjadi kesalahan saat mengirimkan test ke kandidat!',
    })
  }, console.error)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {hrdRoomId !== 0 ? (
        <DialogContent className="sm:max-w-2xl">
          <DialogHeader>
            <DialogTitle>HRD Room</DialogTitle>
            <DialogDescription>
              <div className="grid w-5/6 grid-cols-4 gap-4 gap-y-6">
                <div className="flex flex-col">
                  <div className="font-semibold">ID</div>
                  <div>{queryGetJobUserSelectionPhaseHrdRoom?.data?.id}</div>
                </div>
                <div className="flex flex-col">
                  <div className="font-semibold">ID Modul</div>
                  <div>
                    {queryGetJobUserSelectionPhaseHrdRoom?.data?.module_id}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="font-semibold">Nama Modul</div>
                  <div>
                    {queryGetJobUserSelectionPhaseHrdRoom?.data?.module?.name}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="font-semibold">ID Tes</div>
                  <div>
                    {
                      queryGetJobUserSelectionPhaseHrdRoom?.data
                        ?.activation_code?.id
                    }
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="font-semibold">Nama Test</div>
                  <div>
                    {
                      queryGetJobUserSelectionPhaseHrdRoom?.data
                        ?.activation_code?.name
                    }
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="font-semibold">Token</div>
                  <div>
                    {queryGetJobUserSelectionPhaseHrdRoom?.data?.test_token}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="font-semibold">Status</div>
                  <div>
                    {queryGetJobUserSelectionPhaseHrdRoom?.data?.test?.status}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="font-semibold">Skor</div>
                  <div>
                    {queryGetJobUserSelectionPhaseHrdRoom?.data?.test?.score}
                  </div>
                </div>
              </div>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="mt-6 flex gap-4 sm:justify-center sm:gap-0">
            <Button variant={'outline'} onClick={() => setOpen(false)}>
              Kembali
            </Button>
            <Link
              to={queryGetJobUserSelectionPhaseHrdRoom?.data?.url}
              target="_blank"
            >
              <Button className="w-full sm:w-auto">Lihat URL Tes</Button>
            </Link>
          </DialogFooter>
        </DialogContent>
      ) : (
        <Form {...form}>
          <form onSubmit={handleSubmitDialog}>
            <DialogContent className="sm:max-w-2xl">
              <DialogHeader>
                <DialogTitle>HRD Room</DialogTitle>
                <DialogDescription>
                  <FormField
                    control={form.control}
                    name="module_id"
                    render={({ field }) => (
                      <FormFieldset
                        label="Modul"
                        className="col-span-full md:col-span-1"
                      >
                        <FormFieldSelectCombobox
                          placeholder="Pilih modul"
                          items={
                            queryGetModules.data?.map((module) => ({
                              label: module.name,
                              value: module.id.toString(),
                            })) || []
                          }
                          value={field.value}
                          disabled={queryGetModules.isLoading}
                          onValueChange={(val) => {
                            form.setValue('test_id', '')
                            field.onChange(val)
                          }}
                          search={sModule}
                          setSearch={setSModule}
                          isLoading={queryGetModules.isFetching}
                        />
                      </FormFieldset>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="test_id"
                    render={({ field }) => (
                      <FormFieldset
                        label="Tes"
                        className="col-span-full md:col-span-1"
                      >
                        <FormFieldSelectCombobox
                          placeholder="Pilih tes"
                          items={
                            queryGetTests.data?.map((test) => ({
                              label: test.name,
                              value: test.id.toString(),
                            })) || []
                          }
                          value={field.value}
                          disabled={queryGetTests.isLoading}
                          onValueChange={(val) => {
                            form.setValue('test_id', '')
                            field.onChange(val)
                          }}
                          search={sTest}
                          setSearch={setSTest}
                          isLoading={queryGetTests.isFetching}
                        />
                      </FormFieldset>
                    )}
                  />
                </DialogDescription>
              </DialogHeader>
              <DialogFooter className="mt-6 flex gap-4 sm:justify-center sm:gap-0">
                <Button variant={'outline'} onClick={() => setOpen(false)}>
                  Kembali
                </Button>
                <Button
                  type="submit"
                  onClick={() => handleSubmitDialog()}
                  className="w-full sm:w-auto"
                >
                  Kirimkan Test Psikologi
                </Button>
              </DialogFooter>
            </DialogContent>
          </form>
        </Form>
      )}
    </Dialog>
  )
}

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z.object({
  module_id: z.string().min(1, 'Module is required'),
  test_id: z.string().min(1, 'Test is required'),
})

function formDtoToPayload(
  id: number,
  data: FormDto
): SentHrdRoomTestTokenMutationVariables['request'] {
  return {
    job_user_selection_phase_id: id,
    module_id: Number(data.module_id),
    test_id: Number(data.test_id),
  }
}

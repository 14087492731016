import * as React from 'react'

import { CloudUpload, FileText, X } from 'lucide-react'
import Dropzone, {
  DropzoneRef,
  type DropzoneProps,
  type FileRejection,
} from 'react-dropzone'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'

import { useControllableState } from '@/lib/hooks/use-controllable-state'

import { formatBytes } from '@/lib/utils/utils'
import { FileOrUrl } from '@/lib/utils/zod'

import { cx } from '@/lib/cva-config'
import { ScrollArea } from '../scroll-area'

interface FileUploaderProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: FileOrUrl[]
  onValueChange?: (files: FileOrUrl[]) => void
  accept?: DropzoneProps['accept']
  maxSize?: DropzoneProps['maxSize']
  maxFileCount?: DropzoneProps['maxFiles']
  multiple?: boolean
  disabled?: boolean
  fileRuleInfo?: string
}

export const FileUploader = React.forwardRef<
  HTMLInputElement,
  FileUploaderProps
>(
  (
    {
      value: valueProp,
      onValueChange,
      accept = {
        'image/*': [],
      },
      maxSize = 1024 * 1024 * 2,
      maxFileCount = 1,
      multiple = false,
      disabled = false,
      className,
      fileRuleInfo = 'PNG, JPG up to 10MB',
      ...dropzoneProps
    },
    ref
  ) => {
    const dzRef = React.useRef<DropzoneRef>(null)

    const [files, setFiles] = useControllableState({
      prop: valueProp,
      onChange: onValueChange,
    })

    const onDrop = React.useCallback(
      (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        if (!multiple && maxFileCount === 1 && acceptedFiles.length > 1) {
          toast.error('Cannot upload more than 1 file at a time')
          return
        }

        if ((files?.length ?? 0) + acceptedFiles.length > maxFileCount) {
          toast.error(`Cannot upload more than ${maxFileCount} files`)
          return
        }

        const newFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )

        const updatedFiles = files ? [...files, ...newFiles] : newFiles

        setFiles(updatedFiles)

        if (rejectedFiles.length > 0) {
          rejectedFiles.forEach(({ file }) => {
            toast.error(`File ${file.name} was rejected`)
          })
        }
      },

      [files, maxFileCount, multiple, setFiles]
    )

    function onRemove(index: number) {
      if (!files) return
      const newFiles = files.filter((_, i) => i !== index)
      setFiles(newFiles)
      onValueChange?.(newFiles)
    }

    function onEdit(index: number) {
      onRemove(index)
      dzRef.current?.open()
      // if (!files) return
      // const newFiles = files.map((file, i) => {
      //   if (i === index) {
      //     return file
      //   }
      //   return undefined
      // })
      // setFiles(newFiles)
      // onValueChange?.(newFiles)
    }

    // Revoke preview url when component unmounts
    React.useEffect(() => {
      return () => {
        if (!files) return
        files.forEach((file) => {
          if (isFile(file) && isFileWithPreview(file)) {
            URL.revokeObjectURL(file.preview)
          }
        })
      }
    }, [])

    const isDisabled = disabled || (files?.length ?? 0) >= maxFileCount
    const container = React.useRef<HTMLDivElement>(null)

    return (
      <div className="relative flex flex-col gap-3" ref={container}>
        <input
          className="sr-only"
          aria-hidden
          ref={ref}
          tabIndex={-1}
          onFocus={() => container.current?.focus()}
        />
        <Dropzone
          onDrop={onDrop}
          accept={accept}
          maxSize={maxSize}
          maxFiles={maxFileCount}
          multiple={maxFileCount > 1 || multiple}
          disabled={isDisabled}
          ref={dzRef}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={cx(
                'group relative grid h-32 w-full cursor-pointer place-items-center rounded-md border border-input px-5 py-2 text-center transition hover:bg-muted/25',
                'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                isDragActive && 'border-muted-foreground/50',
                isDisabled && 'pointer-events-none opacity-60',
                className
              )}
              {...dropzoneProps}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <div className="flex flex-col items-center justify-center gap-2 sm:px-5">
                  <UploadIconButton />
                  <p className="font-medium text-muted-foreground">
                    Lepaskan disini
                  </p>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center gap-2 sm:px-5">
                  <UploadIconButton />
                  <div className="flex flex-col gap-px">
                    <p className="text-sm text-muted-foreground">
                      <span className="text-[#1C129D]">
                        Klik untuk upload&nbsp;
                      </span>
                      <span>atau seret dan lepas kesini</span>
                    </p>
                    <p className="text-xs text-muted-foreground/70">
                      {fileRuleInfo}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </Dropzone>
        {files?.length ? (
          <ScrollArea className="h-fit w-full">
            <div className="flex max-h-48 flex-col gap-2.5">
              {files?.map((file, index) => (
                <FileCard
                  key={index}
                  file={file}
                  multiple={maxFileCount > 1 || multiple}
                  onRemove={() => onRemove(index)}
                  onEdit={() => onEdit(index)}
                />
              ))}
            </div>
          </ScrollArea>
        ) : null}
      </div>
    )
  }
)

interface FileCardProps {
  file: FileOrUrl
  onRemove: () => void
  onEdit?: () => void
  multiple?: boolean
}

function FileCard({ file, onRemove, multiple, onEdit }: FileCardProps) {
  return (
    <div className="relative flex items-center gap-2.5 rounded-md border border-primary-yes p-2">
      <div className="flex flex-1 gap-2.5">
        {(isFile(file) && isFileWithPreview(file)) ||
        typeof file === 'string' ? (
          <FilePreview file={file} />
        ) : null}
        <div className="flex w-full flex-col justify-center gap-2">
          <div className="flex flex-col gap-px">
            <p className="line-clamp-1 text-sm font-medium text-foreground/80">
              {isFile(file) ? file.name : file}
            </p>
            {isFile(file) && (
              <p className="text-xs text-muted-foreground">
                {formatBytes(file.size)}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Button
          type="button"
          variant="outline"
          size="icon"
          className="size-7"
          onClick={onRemove}
        >
          <X className="size-4" aria-hidden="true" />
          <span className="sr-only">Remove file</span>
        </Button>
        {/* {multiple ? (
          <Button
            type="button"
            variant="outline"
            size="icon"
            className="size-7"
            onClick={onRemove}
          >
            <X className="size-4" aria-hidden="true" />
            <span className="sr-only">Remove file</span>
          </Button>
        ) : (
          <Button
            type="button"
            variant="outline"
            size="icon"
            className="size-7"
            onClick={onEdit}
          >
            <Pencil className="size-4" aria-hidden="true" />
            <span className="sr-only">Edit</span>
          </Button>
        )} */}
      </div>
    </div>
  )
}

function isFile(file: FileOrUrl): file is File {
  return file instanceof File
}

function isFileWithPreview(file: File): file is File & { preview: string } {
  return 'preview' in file && typeof file.preview === 'string'
}

interface FilePreviewProps {
  file: (File & { preview: string }) | string
}

function FilePreview({ file }: FilePreviewProps) {
  if (
    typeof file === 'string' &&
    ['.png', '.jpg', '.jpeg'].some((ext) => file.endsWith(ext))
  ) {
    return (
      <img
        src={file}
        alt={file}
        width={48}
        height={48}
        loading="lazy"
        className="aspect-square shrink-0 rounded-md object-cover"
      />
    )
  }

  if (file instanceof File && file.type.startsWith('image/')) {
    return (
      <img
        src={file.preview}
        alt={file.name}
        width={48}
        height={48}
        loading="lazy"
        className="aspect-square shrink-0 rounded-md object-cover"
      />
    )
  }

  return (
    <FileText className="size-10 text-muted-foreground" aria-hidden="true" />
  )
}

function UploadIconButton() {
  return (
    <div className="rounded-full border-[6px] border-slate-100 bg-[#F2F4F7] p-1">
      <CloudUpload className="size-5" />
    </div>
  )
}

import { graphql } from '@/_gql'

export const GqlUserParticipants = graphql(`
  query SearchUserParticipant($request: SearchUserParticipantRequest!) {
    searchUserParticipant(request: $request) {
      data {
        id
        name
        email
        handphone_number
        role
        is_verified
        user_personal_info {
          id
          user_id
          name
          address
          birthdate
          country_id
          province_id
          city_id
          gender
          is_single_child
          marital_status
          religion
          nationality_id
          driver_license
          blood_type
          height
          weight
          is_ever_been_going_abroad
          is_having_passport
          is_ever_proposing_passport
          country {
            id
            name
          }
          province {
            id
            name
          }
          city {
            id
            name
          }
        }
        user_experience {
          id
          user_id
          title
          company_name
          employment_type
          start_date
          end_date
          location
          location_type
          created_at
        }
        user_education {
          id
          user_id
          school
          level
          major
          start_date
          graduation_date
          created_at
        }
        user_status_profile {
          id
          user_id
          status
          reason
          approver_id
          created_at
        }
      }
    }
  }
`)

export const GqlUpsertUserStatusProfile = graphql(`
  mutation UpsertUserStatusProfile(
    $request: [UpsertUserStatusProfileRequest!]!
  ) {
    upsertUserStatusProfile(request: $request) {
      success
    }
  }
`)

export const GqlGetUserParticipant = graphql(`
  query GetUserParticipant($user_id: Int!) {
    getUserParticipant(user_id: $user_id) {
      data {
        id
        name
        email
        handphone_number
        role
        is_verified
        user_personal_info {
          id
          user_id
          name
          address
          birthdate
          country_id
          province_id
          city_id
          gender
          is_single_child
          marital_status
          religion
          nationality_id
          driver_license
          blood_type
          height
          weight
          is_ever_been_going_abroad
          is_having_passport
          is_ever_proposing_passport
          country {
            id
            name
          }
          province {
            id
            name
          }
          city {
            id
            name
          }
          photo
          nationality {
            id
            name
          }
        }
        user_education {
          id
          user_id
          school
          level
          major
          start_date
          graduation_date
          created_at
        }
        user_experience {
          id
          user_id
          title
          company_name
          employment_type
          start_date
          end_date
          location
          location_type
          created_at
        }
        user_health {
          id
          user_id
          status
          name
          year
          explaination
          created_at
        }
        user_family {
          id
          user_id
          name
          relation
          birthdate
          country_id
          province_id
          city_id
          religion
          nationality_id
          phone_number
          created_at
          country {
            id
            name
          }
          province {
            id
            name
          }
          city {
            id
            name
          }
          nationality {
            id
            name
          }
        }
        user_attachment {
          id
          user_id
          attachment_type
          url
          created_at
        }
        user_language {
          id
          user_id
          language
          language_level
          url
          created_at
        }
        user_status_profile {
          id
          user_id
          status
          reason
          approver_id
          created_at
        }
      }
    }
  }
`)

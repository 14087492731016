import type { QueryClient } from '@tanstack/react-query'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'

import { useAuth } from '@/services/auth/use-auth'

import { TailwindScreenDevTool } from '@/components/tools/tailwind-screen-dev-tools'
import { Toaster } from '@/components/ui/sonner'
import { TooltipProvider } from '@/components/ui/tooltip'

import { initPosthogIdentity } from '@/lib/posthog'

interface RouteContext {
  queryClient: QueryClient
}

export const Route = createRootRouteWithContext<RouteContext>()({
  beforeLoad: async ({ preload }) => {
    if (preload) return
    await useAuth.getState().revalidateSession()

    initPosthogIdentity()
  },
  component: Component,
})

function Component() {
  return (
    <TooltipProvider>
      <div>
        <div className="flex min-h-lvh flex-col">
          <Outlet />
          <Toaster position="top-center" />
          <TailwindScreenDevTool />
          {/* <TanStackRouterDevtools position="bottom-left" initialIsOpen={false}/>
      <ReactQueryDevtools buttonPosition="bottom-right" initialIsOpen={false}/> */}
        </div>
      </div>
    </TooltipProvider>
  )
}

import { useMemo, useState } from 'react'
import { createFileRoute, Link } from '@tanstack/react-router'
import { queryJobList, queryGetJobUser } from '@/services/job/query'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useDataTable } from '@/components/ui/data-table/use-data-table'
import { createColumnHelper } from '@tanstack/react-table'
import { useFilters } from '@/hooks/use-filter'
import { JobListQuery } from '@/_gql/graphql'
import { Badge } from '@/components/ui/badge'
import { toIdr } from '@/lib/utils/number'
import { Button, ButtonLoading } from '@/components/ui/button'
import { EditIcon, Eye, Trash2Icon } from 'lucide-react'
import { AdminPage } from '@/components/layout/admin/page'
import { Skeleton } from '@/components/ui/skeleton'
import { Card, CardContent } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { DataTable } from '@/components/ui/data-table/data-table'
import { z } from 'zod'
import { zodValidator } from '@tanstack/zod-adapter'


const searchParamsSchema = z.object({
  s: z.string().nullish(),
})
export const Route = createFileRoute('/admin-v2/job/applicant/vacancy/')({
  validateSearch: zodValidator(searchParamsSchema),
  component: RouteComponent,
})

function RouteComponent() {

  const request = {
    company_id: null,
    start_apply_date: null,
    end_apply_date: null,
    is_recommended_by_ai: null,
    search: '',
  }

  const { filters, setFilters } = useFilters('/admin-v2/job/applicant/vacancy/')
  const { data, isLoading } = useQuery(queryJobList())
  const { data: dataApplicant, refetch } = useQuery(queryGetJobUser(request))


  const activeJobList = useMemo(() => {
    return data ? data.sort((a, b) => Number(b.is_active) - Number(a.is_active)) : [];
  }, [data]);

  const columns = useMemo(getColumns, [])
  const { table } = useDataTable({
    data: activeJobList || [],
    state: {
      globalFilter: filters.s,
    },
    columns,
  })
  return (
    <AdminPage>
      <AdminPage.Breadcrumb
        items={[['Master Job Listing', '/admin-v2/job/vacancy'], ['List Job Listing', '/admin-v2/job/applicant/vacancy']]}
      />
      <AdminPage.Header
        title="List Pelamar"
        desc={
          isLoading ? (
            <Skeleton className="h-5 w-32" />
          ) : (
            `Jumlah pelamar: ${dataApplicant?.length || 0} list`
          )
        }
      />
      <Card>
        <CardContent className="relative space-y-2 p-2">
          <div>
            <Input
              className="max-w-80"
              placeholder="Cari lowongan"
              defaultValue={filters.s || undefined}
              onChange={(e) => {
                setFilters({ s: e.target.value })
              }}
            />
          </div>
          <DataTable table={table} />
        </CardContent>
      </Card>
    </AdminPage>
  )
}

type JobItem = JobListQuery['getJob']['data'][number]
const column = createColumnHelper<JobItem>()
function getColumns() {
  return [
    column.accessor('id', {
      header: 'ID',
    }),
    column.accessor('title', {
      header: 'Judul Pekerjaan',
    }),
    column.accessor('job_company.name', {
      header: 'Perusahaan',
      cell: ({ cell }) => {
        const { job_company } = cell.row.original
        return job_company?.name
      },
    }),
    column.accessor('job_skills', {
      header: 'Keterampilan',
      cell: ({ cell }) => {
        const skills = cell.row.original.job_skills?.filter(Boolean)

        if (!skills || !skills.length) return '-'

        return (
          <div className="flex max-w-sm flex-nowrap gap-2 overflow-x-auto">
            {skills.map((s) => (
              <Badge key={s.id} className="font-normal" variant="yes">
                {s.name}
              </Badge>
            ))}
          </div>
        )
      },
    }),
    // column.accessor((row) => `${row.min} - ${row.max}`, {
    //   header: 'Gaji',
    //   cell: ({ cell }) => {
    //     const { min, max } = cell.row.original
    //     return min && max ? `${toIdr(min)} - ${toIdr(max)}` : '-'
    //   },
    // }),
    column.accessor('is_active', {
      header: 'Aktif',
      cell: ({ cell }) => {
        const { is_active } = cell.row.original
        return is_active ? 'Ya' : 'Tidak'
      },
    }),
    column.display({
      id: 'actions',
      header: 'Aksi',
      cell: ({ cell }) => {
        const { id, title } = cell.row.original
        return (
          <div className="flex gap-1.5">
            <Button
              size="icon"
              className="size-8 text-primary-yes"
              variant="ghost"
              asChild
            >
              <Link
                from={Route.fullPath}
                to={`/admin-v2/job/applicant/vacancy/${id}`}
              >
                <Eye />
              </Link>
            </Button>

          </div>
        )
      },
    }),
  ]
}

import { queryOptions } from '@tanstack/react-query'

import { gqlFetchSafe } from '../graphql/fetcher'
import {
  GqlGenerateCv,
  GqlGetCv,
  GqlGetResume,
  GqlUserAttachments,
  GqlUserEducation,
  GqlUserExperience,
  GqlUserFamily,
  GqlUserHealth,
  GqlUserInformationGet,
  GqlUserLanguages,
  GqlUserSubmissionProgress,
} from './gql'

export function queryUserInformation() {
  return queryOptions({
    queryKey: ['user', 'information'],
    queryFn: async () => {
      const [res, err] = await gqlFetchSafe({
        query: GqlUserInformationGet,
      })

      let data
      if (err) {
        data = null
        console.info('User information not found')
      } else {
        data = res.getUserPersonalInfo.data
      }

      return data
    },
  })
}

export function queryUserEducation() {
  return queryOptions({
    queryKey: ['user', 'education'],
    queryFn: async () => {
      const [res, err] = await gqlFetchSafe({
        query: GqlUserEducation,
      })

      let data
      if (err) {
        data = null
        console.info('User education not found')
      } else {
        data = res.getUserEducation.data
      }

      return data
    },
  })
}

export function queryUserExperience() {
  return queryOptions({
    queryKey: ['user', 'experience'],
    queryFn: async () => {
      const [res, err] = await gqlFetchSafe({
        query: GqlUserExperience,
      })

      let data
      if (err) {
        data = null
        console.info('User experience not found')
      } else {
        data = res.getUserExperience.data
      }

      return data
    },
  })
}

export function queryUserHealth() {
  return queryOptions({
    queryKey: ['user', 'health'],
    queryFn: async () => {
      const [res, err] = await gqlFetchSafe({
        query: GqlUserHealth,
      })

      let data
      if (err) {
        data = null
        console.info('User health not found')
      } else {
        data = res.getUserHealth.data
      }

      return data
    },
  })
}

export function queryUserFamily() {
  return queryOptions({
    queryKey: ['user', 'family'],
    queryFn: async () => {
      const [res, err] = await gqlFetchSafe({
        query: GqlUserFamily,
      })

      let data
      if (err) {
        data = null
        console.info('User family not found')
      } else {
        data = res.getUserFamily.data
      }

      return data
    },
  })
}

export function queryUserAttachment() {
  return queryOptions({
    queryKey: ['user', 'attachment'],
    queryFn: async () => {
      const [res, err] = await gqlFetchSafe({
        query: GqlUserAttachments,
      })

      let data
      if (err) {
        data = null
        console.info('User Attachment not found')
      } else {
        data = res.getUserAttachment.data
      }

      return data
    },
  })
}

export function queryUserLanguage() {
  return queryOptions({
    queryKey: ['user', 'language'],
    queryFn: async () => {
      const [res, err] = await gqlFetchSafe({
        query: GqlUserLanguages,
      })

      let data
      if (err) {
        data = null
        console.info('User Language not found')
      } else {
        data = res.getUserLanguage.data
      }

      return data
    },
  })
}

export function queryUserSubmissionProgress(isSimplify?: boolean) {
  return queryOptions({
    queryKey: ['user', 'progress'],
    queryFn: async () => {
      const [res, err] = await gqlFetchSafe({
        query: GqlUserSubmissionProgress,
        variables: { is_simplify: isSimplify },
      })

      let data
      if (err) {
        data = null
        console.info('User progress submission not found')
      } else {
        data = res.getUserProgress.data
      }

      return data
    },
  })
}

export function queryGenerateCv() {
  return queryOptions({
    queryKey: ['generate', 'cv'],
    enabled: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const [res, err] = await gqlFetchSafe({
        query: GqlGenerateCv,
      })

      let data
      if (err) {
        data = null
        console.info('User Generate cv')
      } else {
        data = res.generateJapaneseCV
      }
      return data
    },
  })
}

export function queryGetResume() {
  return queryOptions({
    queryKey: ['get', 'resume'],
    enabled: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const [res, err] = await gqlFetchSafe({
        query: GqlGetResume,
      })

      let data
      if (err) {
        data = null
        console.info('User Get Resume')
      } else {
        data = res.getUserResume.data
      }
      return data
    },
  })
}

export function queryGetCv() {
  return queryOptions({
    queryKey: ['get', 'cv'],
    enabled: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const [res, err] = await gqlFetchSafe({
        query: GqlGetCv,
      })

      let data
      if (err) {
        data = null
        console.info('User Get CV')
      } else {
        data = res.getUserCV
      }
      return data
    },
  })
}

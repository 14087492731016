import { useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { formatDate } from 'date-fns'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { StatusProfile, UpsertUserStatusProfileRequest } from '@/_gql/graphql'

import { GqlUpsertUserStatusProfile } from '@/services/admin/gql'
import { queryDetailParticipants } from '@/services/admin/query'
import { gqlFetch } from '@/services/graphql/fetcher'
import {
  ATTACHMENT_OPTIONS,
  EDUCATION_LEVEL_OPTIONS,
  EMPLOYMENT_STATUS_OPTIONS,
  HEALTH_STATUS_OPTIONS,
  LOCATION_TYPE_OPTIONS,
  RELIGION_OPTIONS,
} from '@/services/user/constant'

import { FormItemTemplate } from '@/components/form/template'
import { HomeIcon } from '@/components/icons/common'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Form, FormField, FormItem } from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'

import { BadgeStatus } from '..'
import { Attachment } from './-components/attachment'
import { Education } from './-components/education'
import { EmploymentHistory } from './-components/employment-history'
import { Family } from './-components/family'
import { HealthHistory } from './-components/health-history'
import { Language } from './-components/language'
import { PersonalInformation } from './-components/personal-information'

export const Route = createFileRoute('/admin-v2/participant/$participantId/')({
  async loader({ params, context }) {
    const { participantId } = params
    const { queryClient } = context
    const participant = await queryClient.fetchQuery(
      queryDetailParticipants(Number(participantId))
    )
    return {
      participant,
    }
  },
  component: Component,
})

function Component() {
  const router = useRouter()
  const [openApprove, setOpenApprove] = useState(false)
  const [openReject, setOpenReject] = useState(false)

  const { participant } = Route.useLoaderData()
  const name = participant?.data?.name
  const email = participant?.data?.email
  const id = participant?.data.id
  const user_status_profile = participant?.data.user_status_profile

  const handphone_number = participant?.data.handphone_number
  const user_personal_info = participant?.data.user_personal_info
  const user_education = participant?.data.user_education.map((t) => ({
    level: t?.level?EDUCATION_LEVEL_OPTIONS.find(
      (x) => x.value == t?.level.toUpperCase()
    )?.label:undefined,
    major: t?.major,
    school: t?.school,
    start_date: t?.start_date
      ? formatDate(t?.start_date, 'MM/dd/yyyy')
      : 'sekarang',
    graduation_date: t?.graduation_date
      ? formatDate(t?.graduation_date, 'MM/dd/yyyy')
      : 'sekarang',
  }))

  const healt_history = participant?.data.user_health.map((t) => ({
    name: t?.name,
    year: t?.year,
    status: HEALTH_STATUS_OPTIONS.find(
      (x) => x.value == t?.status.toUpperCase()
    )?.label,
    explanation: t?.explaination,
  }))
  const attachment = participant?.data.user_attachment.map((t) => ({
    attachment_type: ATTACHMENT_OPTIONS.find(
      (x) => x.value == t?.attachment_type.toUpperCase()
    )?.label,
    url: t?.url,
  }))

  const family = participant?.data.user_family.map((t) => ({
    name: t?.name,
    relation: t?.relation,
    birthdate: t?.birthdate,
    city: t?.city,
    province: t?.province,
    religion: RELIGION_OPTIONS.find(
      (x) => x.value == t?.religion?.toUpperCase()
    )?.label,
    country: t?.country,
    nationality: t?.nationality,
    phone_number: t?.phone_number,
  }))

  const language = participant?.data.user_language?.map((t) => ({
    language: t?.language,
    language_level: t?.language_level,
    url: t?.url,
  }))

  const employment_history_value = participant?.data.user_experience.map(
    (t) => {
      const start_date = t?.start_date
        ? formatDate(t.start_date, 'MM/dd/yyyy')
        : 'sekarang'
      const end_date = t?.end_date
        ? formatDate(t.end_date, 'MM/dd/yyyy')
        : 'sekarang'

      return {
        title: t?.title,
        company_name: t?.company_name,
        employment_type: t?.employment_type
      ? EMPLOYMENT_STATUS_OPTIONS.find((x) => x.value === t.employment_type.toUpperCase())?.label
      : undefined,
        date: `${start_date} - ${end_date}`,

        location: t?.location,
        location_type: t?.location_type
        ? LOCATION_TYPE_OPTIONS.find((x) => x.value === t.location_type.toUpperCase())?.label
        : undefined,
      }
    }
  )

  const { mutateAsync } = useMutation({
    mutationFn: async (data: UpsertUserStatusProfileRequest) => {
      await gqlFetch({
        query: GqlUpsertUserStatusProfile,
        variables: { request: [data] },
      })
      // await refetch()
      // navigate({ to: '/profile/attachment' })
    },
    onSuccess: () => {
      router.invalidate()
    },
  })

  const handleHandleApprove = () => {
    const payload = {
      status: StatusProfile.SubmissionApproved,
      user_id: String(id),
      reason: '',
    }

    toast.promise(mutateAsync(payload), {
      loading: 'Loading...',
      success: 'Berhasil Terima Pengajuan',
      error: 'Gagal',
    })

    setOpenApprove(false)
  }

  const handleHandleReject = (reason: string) => {
    const payload = {
      status: StatusProfile.SubmissionRejected,
      user_id: String(id),
      reason: reason,
    }

    toast.promise(mutateAsync(payload), {
      loading: 'Loading...',
      success: 'Berhasil Tolak Pengajuan',
      error: 'Gagal',
    })

    setOpenReject(false)
  }

  return (
    <div className="w-full pb-24">
      <BreadcrumbClass />
      <div className="mt-6 flex items-center justify-between">
        <div className="text-xl font-semibold">{name}</div>
        <div>
          <span className="text-sm"> Status:</span>{' '}
          {BadgeStatus(user_status_profile?.status)}
        </div>
      </div>
      {user_status_profile?.status !== StatusProfile.SubmissionApproved &&
      user_status_profile?.reason?.length != 0 &&
      user_status_profile?.reason !== null ? (
        <div className="mt-4">
          <Card>
            <CardContent className="p-4 text-sm">
              <div>Reason:</div>
              <div>{user_status_profile?.reason}</div>
            </CardContent>
          </Card>
        </div>
      ) : (
        <></>
      )}
      <div className="mt-6">
        <PersonalInformation
          name={user_personal_info?.name}
          handphone_number={handphone_number}
          is_single_child={user_personal_info?.is_single_child}
          address={user_personal_info?.address}
          birthdate={user_personal_info?.birthdate}
          blood_type={user_personal_info?.blood_type}
          city={user_personal_info?.city}
          driver_license={user_personal_info?.driver_license}
          email={email}
          gender={user_personal_info?.gender}
          height={user_personal_info?.height}
          is_ever_been_going_abroad={
            user_personal_info?.is_ever_been_going_abroad
          }
          is_ever_proposing_passport={
            user_personal_info?.is_ever_proposing_passport
          }
          is_having_passport={user_personal_info?.is_having_passport}
          marital_status={user_personal_info?.marital_status}
          province={user_personal_info?.province}
          religion={user_personal_info?.religion}
          weight={user_personal_info?.weight}
          photo={user_personal_info?.photo}
          country={user_personal_info?.country}
          nationality={user_personal_info?.nationality}
        />
      </div>
      <div className="mt-6">
        <Education user_education={user_education} />
      </div>
      <div className="mt-6">
        <EmploymentHistory user_experience={employment_history_value} />
      </div>
      <div className="mt-6">
        <HealthHistory user_health={healt_history} />
      </div>
      <div className="mt-6">
        <Attachment attachment={attachment} />
      </div>
      <div className="mt-6">
        {/* TODO */}
        {/* @ts-expect-error */}
        <Family family={family} />
      </div>
      <div className="mt-6">
        {/* TODO */}
        {/* @ts-expect-error */}
        <Language language={language} />
      </div>
      {user_status_profile?.status?.toUpperCase() ==
      StatusProfile.SubmissionVerificationStage ? (
        <div className="fixed bottom-0 left-0 z-30 flex w-screen justify-end gap-4 bg-white p-4 pl-64 drop-shadow-lg">
          <RejectDialog
            onHandleReject={handleHandleReject}
            open={openReject}
            setOpen={setOpenReject}
          />
          <ApproveDialog
            onHandleApprove={handleHandleApprove}
            open={openApprove}
            setOpen={setOpenApprove}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export function BreadcrumbClass() {
  return (
    <Breadcrumb className="text-white">
      <BreadcrumbList className="text-[#475467]">
        <BreadcrumbItem>
          <BreadcrumbLink href="/">
            <HomeIcon color="#475467" />
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator className="text-[#475467]" />
        <BreadcrumbItem>
          <div className="rounded-md bg-[#FFF4F5] p-2 text-primary-yes">
            Daftar Peserta
          </div>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}

interface ApproveDialogProps {
  onHandleApprove: () => void
  setOpen: (state: boolean) => void
  open: boolean
}

const ApproveDialog = ({
  onHandleApprove,
  open,
  setOpen,
}: ApproveDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="bg-primary-yes hover:bg-primary-yes">
          Terima Pengajuan
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-xl">
        <div className="flex justify-center text-lg font-semibold">
          Konfirmasi Terima Pengajuan
        </div>
        <div className="flex justify-center">
          <img src="/img/approve.svg" />
        </div>
        <div className="flex justify-center">
          Apakah anda yakin akan menerima pengajuan ini?
        </div>
        <div className="flex justify-center gap-4">
          <Button variant={'outline_yes'} onClick={() => setOpen(false)}>
            Kembali
          </Button>
          <Button
            className="bg-primary-yes hover:bg-primary-yes"
            onClick={onHandleApprove}
          >
            Terima Pengajuan
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

interface RejectDialogProps {
  onHandleReject: (state: string) => void
  setOpen: (state: boolean) => void
  open: boolean
}

interface FormReasonDto extends z.infer<typeof FormReasonDto> {}
const FormReasonDto = z.object({
  reason: z.string(),
})
const RejectDialog = ({ onHandleReject, open, setOpen }: RejectDialogProps) => {
  const form = useForm<FormReasonDto>({
    defaultValues: {
      reason: '',
    },
  })
  const reason = form.watch('reason')

  const handleOnSubmit = () => {
    const values = form.getValues() // Get current form values
    if (values.reason) {
      onHandleReject(reason)
      // setOpen(false)  // Close dialog
    } else {
      form.setError('reason', { message: 'Reason is required' }) // Show error if empty
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant={'outline_yes'}>Tolak Pengajuan</Button>
      </DialogTrigger>
      <Form {...form}>
        <form>
          <DialogContent className="sm:max-w-xl">
            <div className="flex justify-center text-lg font-semibold">
              Konfirmasi Tolak Pengajuan
            </div>
            {/* <Textarea placeholder=''></Textarea> */}
            <FormField
              control={form.control}
              name="reason"
              render={({ field }) => (
                <FormItemTemplate.General label="Alasan Penolakan">
                  <Textarea
                    placeholder="Sertakan alasan penolakan di sini"
                    {...field}
                  />
                </FormItemTemplate.General>
              )}
            />
            <div className="flex justify-between gap-4">
              <Button
                type="button"
                variant={'outline_yes'}
                onClick={() => setOpen(false)}
              >
                Kembali
              </Button>
              <Button
                type="submit"
                className="bg-primary-yes hover:bg-primary-yes"
                onClick={handleOnSubmit}
              >
                Tolak Pengajuan
              </Button>
            </div>
          </DialogContent>
        </form>
      </Form>
    </Dialog>
  )
}

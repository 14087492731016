import { useEffect, useMemo, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  createFileRoute,
  Link,
  redirect,
  useRouter,
} from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { UserOtpSentBy } from '@/_gql/graphql'

import { GqlAuthSendOTP, GqlAuthVerifyOTP } from '@/services/auth/gql'
import { queryAuthProfile } from '@/services/auth/query'
import { useAuth } from '@/services/auth/use-auth'
import { gqlFetch } from '@/services/graphql/fetcher'

import { useCountdown } from '@/components/hooks/use-countdown'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  REGEXP_ONLY_DIGITS,
} from '@/components/ui/input-otp'

import { capturePosthogEvent } from '@/lib/posthog'
import { AuthCard } from './-components/auth-card'
import { AuthContainer } from './-components/auth-container'

interface SearchDto extends z.infer<typeof SearchDto> {}
const SearchDto = z.object({
  phone: z.string().nullish().default(''),
})

export const Route = createFileRoute('/auth/verification/otp')({
  validateSearch: (search): SearchDto => SearchDto.parse(search),
  loaderDeps: (opts) => opts.search,
  beforeLoad: () => {
    const { isAuthenticated } = useAuth.getState()
    if (!isAuthenticated) return redirect({ to: '/auth/login' })
  },
  loader: async ({ context: { queryClient: qc }, deps }) => {
    const res = await qc.fetchQuery(queryAuthProfile())
    if (res.getProfile.user.is_verified) throw redirect({ to: '/dashboard' })

    return {
      phone: deps?.phone || res.getProfile.user.handphone_number,
    }
  },
  component: Component,
})

function Component() {
  const router = useRouter()
  const { phone } = Route.useLoaderData()
  const revalidateSession = useAuth((s) => s.revalidateSession)
  const qc = useQueryClient()
  const navigate = Route.useNavigate()
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: { otp: '' },
  })

  const { mutateAsync: verifyOtp, isPending: verifyPending } = useMutation({
    mutationFn: async (data: FormDto) => {
      const otp = data.otp || undefined
      if (!otp) throw new Error('Invalid otp')

      await gqlFetch({
        query: GqlAuthVerifyOTP,
        variables: { request: { otp } },
      })
      await qc.refetchQueries(queryAuthProfile())
      await revalidateSession()
      router.invalidate()
      navigate({ to: '/auth/verification/success' })
    },
    onSuccess: () => {
      capturePosthogEvent('auth:verification:submit_otp:success', {})
    },
    onError: () => {
      capturePosthogEvent('auth:verification:submit_otp:fail', {})
    },
  })

  const {
    mutateAsync: resendOtp,
    isIdle: resendOtpIsIdle,
    isPending: resendOtpIsPending,
  } = useMutation({
    mutationFn: async () => {
      await gqlFetch({
        query: GqlAuthSendOTP,
        variables: { request: { sent_by: UserOtpSentBy.Whatsapp } },
      })
    },
    onSuccess() {
      toast.success('Berhasil mengirim OTP', { id: TOAST_ID })
      capturePosthogEvent('auth:verification:req_otp', {})
    },
    onError() {
      toast.error('Gagal mengirim OTP, coba metode lain', { id: TOAST_ID })
    },
    onSettled() {
      resetCountdown()
      startCountdown()
    },
  })

  const handleOnSubmit = form.handleSubmit(async (data) => {
    toast.promise(verifyOtp(data), {
      loading: 'Submitting OTP',
      success: 'Submit success',
      error: 'Oops.. OTP salah, harap coba lagi',
    })
  }, console.error)

  const [seconds, { startCountdown, resetCountdown }] = useCountdown({
    countStart: 60, // 1 Minutes
    countStop: 0,
    intervalMs: 1000,
  })

  const [isRetryEnabled, setIsRetryEnabled] = useState(false)

  useEffect(() => {
    startCountdown()
  }, [startCountdown])

  useEffect(() => {
    const isRetryEnabled = seconds <= 0
    setIsRetryEnabled(isRetryEnabled)
  }, [seconds])

  const timer = useMemo(() => {
    const minutes: number = Math.floor(seconds / 60)
    const remainingSeconds: number = seconds % 60
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
  }, [seconds])

  return (
    <AuthContainer>
      <Form {...form}>
        <AuthCard>
          <form onSubmit={handleOnSubmit}>
            <AuthCard.Header>
              <AuthCard.Title>Verifikasi OTP</AuthCard.Title>
              <AuthCard.Subtitle className="text-sm font-normal">
                Harap masukkan kode OTP yang sudah dikirimkan ke no.hp&nbsp;
                <strong>{phone}</strong>
              </AuthCard.Subtitle>
            </AuthCard.Header>

            <AuthCard.Content>
              <InputOTP
                value={form.watch('otp')}
                onChange={(otp) => form.setValue('otp', otp)}
                maxLength={6}
                pattern={REGEXP_ONLY_DIGITS}
                autoFocus
              >
                <InputOTPGroup className="grid w-full grid-cols-6 gap-2 [&>*]:aspect-square [&>*]:h-auto [&>*]:w-full">
                  <InputOTPSlot index={0} />
                  <InputOTPSlot index={1} />
                  <InputOTPSlot index={2} />
                  <InputOTPSlot index={3} />
                  <InputOTPSlot index={4} />
                  <InputOTPSlot index={5} />
                </InputOTPGroup>
              </InputOTP>
              <p className="text-sm">
                Kirim ulang OTP dalam <span className="font-bold">{timer}</span>
              </p>
              <p className="text-sm">
                Tidak menerima kode OTP?{' '}
                <button
                  disabled={!isRetryEnabled}
                  className={
                    'font-semibold text-[#D91F2E] underline disabled:opacity-60 disabled:hover:cursor-not-allowed'
                  }
                  onClick={() => resendOtp()}
                  type="button"
                >
                  Kirim Ulang
                </button>
              </p>
              <div className="flex w-full flex-row gap-x-2">
                <Button variant="outline" className="flex-1" asChild>
                  <Link from={Route.fullPath} to="/auth/verification/phone">
                    Kembali
                  </Link>
                </Button>
                <Button
                  className="flex-1 rounded-lg bg-gradient-to-r from-[#D91F2E] to-[#AE000E]"
                  disabled={form.watch('otp')?.length != 6 || verifyPending}
                >
                  Verifikasi Sekarang
                </Button>
              </div>
            </AuthCard.Content>
          </form>
        </AuthCard>
      </Form>
    </AuthContainer>
  )
}

const TOAST_ID = 'verify-otp'

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z.object({
  otp: z.string().length(6),
})

import { zodResolver } from '@hookform/resolvers/zod'
import { TabsList } from '@radix-ui/react-tabs'
import { useMutation } from '@tanstack/react-query'
import {
  createFileRoute,
  Link,
  redirect,
  useRouter,
} from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { GqlAuthRegister } from '@/services/auth/gql'
import { useAuth } from '@/services/auth/use-auth'
import { GraphQLError } from '@/services/graphql/error'
import { gqlFetch } from '@/services/graphql/fetcher'

import { ButtonLoading } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormField, FormItem } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { Input } from '@/components/ui/input'
import { InputPassword } from '@/components/ui/input-password'
import { Label } from '@/components/ui/label'
import { Tabs, TabsTrigger } from '@/components/ui/tabs'

import { capturePosthogEvent, usePostHog } from '@/lib/posthog'
import { AuthCard } from './-components/auth-card'
import { AuthContainer } from './-components/auth-container'
import { GoogleButton } from './-components/google-button'

const searchParams = z.object({
  code: z.string().optional(),
})

export const Route = createFileRoute('/auth/register')({
  validateSearch: searchParams,
  loaderDeps: (opts) => opts.search,
  beforeLoad: async () => {
    const { isAuthenticated } = useAuth.getState()
    if (isAuthenticated) return redirect({ to: '/dashboard' })
  },
  component: Component,
})

function Component() {
  const search = Route.useSearch()
  const navigate = Route.useNavigate()
  const router = useRouter()
  const authLogin = useAuth((s) => s.login)
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: getDefaultValues(search.code),
  })

  const ph = usePostHog()

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = form

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (data: FormDto) => {
      const { name, email, password, phone, referral } = data
      const res = await gqlFetch({
        query: GqlAuthRegister,
        variables: {
          request: {
            name,
            email,
            password,
            handphone_number: phone,
            refferal_code: referral.enabled ? referral.code : undefined,
          },
        },
      })

      await authLogin(res.register.token)
      router.invalidate()
      capturePosthogEvent('auth:register', { email, name })
      ph.sessionRecording?.startIfEnabledOrStop('event_trigger_matched')
      return 1
    },
    onError: (error) => {
      if (GraphQLError.is(error)) {
        const code = error.getErrorCode()
        if (code === 400)
          setError('email', { message: 'Email sudah digunakan' })
      } else {
        toast.error(error.message, { id: TOAST_ID })
      }
    },
    onSuccess: () => {
      navigate({ to: '/auth/verification/phone' })
      toast.success('Berhasil mendaftar', { id: TOAST_ID })
    },
  })

  const handleOnSubmit = handleSubmit(async (data) => {
    await mutateAsync(data).catch(console.error)
  }, console.error)

  return (
    <AuthContainer>
      <Form {...form}>
        <AuthCard>
          <form onSubmit={handleOnSubmit}>
            <AuthCard.Header>
              <AuthCard.Title>Daftar</AuthCard.Title>
              <AuthCard.Subtitle>
                Halo, Selamat datang di YES Academy!
              </AuthCard.Subtitle>
            </AuthCard.Header>
            <AuthCard.Content className="">
              <FormField
                control={control}
                name="name"
                render={({ field }) => (
                  <FormFieldset label="Nama">
                    <Input placeholder="Masukkan nama lengkap" {...field} />
                  </FormFieldset>
                )}
              />
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <FormFieldset label="Email">
                    <Input placeholder="Masukkan Email" {...field} />
                  </FormFieldset>
                )}
              />
              <FormField
                control={control}
                name="phone"
                render={({ field }) => (
                  <FormFieldset label="Nomor aktif whatsapp">
                    <Input
                      placeholder="Masukkan nomor hp"
                      {...field}
                      required
                    />
                  </FormFieldset>
                )}
              />
              <div className="flex w-full flex-row flex-wrap gap-4 gap-x-2 [&>*]:min-w-64 [&>*]:flex-1">
                <FormField
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <FormFieldset label="Password">
                      <InputPassword
                        placeholder="Masukkan password"
                        {...field}
                      />
                    </FormFieldset>
                  )}
                />
                <FormField
                  control={control}
                  name="passwordConfirmation"
                  render={({ field }) => (
                    <FormFieldset label="Konfirmasi Password">
                      <InputPassword
                        placeholder="Masukkan ulang password"
                        {...field}
                      />
                    </FormFieldset>
                  )}
                />
              </div>
              <div className="space-y-4">
                <div className="flex flex-wrap items-center justify-between gap-2">
                  <p className="text-sm">Punya Kode Referal?</p>
                  <Tabs
                    value={form.watch('referral.enabled') ? 'ya' : 'tidak'}
                    onValueChange={(value) =>
                      form.setValue('referral.enabled', value === 'ya')
                    }
                  >
                    <div className="w-full overflow-auto rounded-lg bg-white p-0.5 shadow-sm">
                      <TabsList>
                        {['ya', 'tidak'].map((value) => (
                          <TabsTrigger
                            key={value}
                            value={value}
                            className="w-20 rounded-md py-0.5 font-medium capitalize text-[#9E9E9E] transition-colors duration-300 data-[state=active]:bg-gradient-primary data-[state=active]:text-white"
                          >
                            {value}
                          </TabsTrigger>
                        ))}
                      </TabsList>
                    </div>
                  </Tabs>
                </div>
                {form.watch('referral.enabled') && (
                  <FormField
                    control={control}
                    name="referral.code"
                    render={({ field }) => (
                      <FormFieldset label="Kode Referal">
                        <Input placeholder="Masukkan kode referal" {...field} />
                      </FormFieldset>
                    )}
                  />
                )}
              </div>
              <FormField
                control={control}
                name="aggreement"
                render={({ field }) => (
                  <FormItem className="mt-4 flex items-center space-y-0">
                    <Checkbox
                      id={field.name}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <Label className="ml-1.5 font-normal" htmlFor={field.name}>
                      Saya menyetujui peraturan yang berlaku{' '}
                    </Label>
                  </FormItem>
                )}
              />
              <div>
                {errors.aggreement && (
                  <p className="text-sm text-red-500">
                    {errors.aggreement.message}
                  </p>
                )}
              </div>
            </AuthCard.Content>
            <AuthCard.Footer className="flex-col gap-3">
              <ButtonLoading
                className="w-full rounded-xl bg-gradient-primary"
                isLoading={isPending}
                type="submit"
              >
                Daftar Sekarang
              </ButtonLoading>
              <p className="my-1 w-full text-center text-sm">
                Atau daftar dengan menggunakan akun
              </p>
              <GoogleButton referralCode={search.code} type="register" />
              <div className="flex justify-center text-sm">
                Email sudah terdaftar? Silakan &nbsp;
                <Link
                  from={Route.fullPath}
                  to="/auth/login"
                  className="cursor-pointer font-semibold text-[#1C129D] underline"
                >
                  Masuk
                </Link>
              </div>
            </AuthCard.Footer>
          </form>
        </AuthCard>
      </Form>
    </AuthContainer>
  )
}

const TOAST_ID = 'register'

function getDefaultValues(referralCode?: string): FormDto {
  return {
    name: '',
    email: '',
    phone: '',
    password: '',
    passwordConfirmation: '',
    aggreement: false,
    referral: {
      enabled: !!referralCode,
      code: referralCode,
    },
  }
}

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z
  .object({
    name: z.string(),
    email: z.string().email('Email tidak valid'),
    phone: z
      .string()
      .min(10, 'Phone number must be at least 10 digits')
      .max(15, 'Phone number must be at most 15 digits')
      .regex(/^\d+$/, 'Phone number must contain only digits'),
    password: z.string().min(1, 'Password is too short'),
    passwordConfirmation: z.string(),
    aggreement: z
      .boolean()
      .refine(
        (val) => val === true,
        'You must accept the terms and conditions'
      ),
    referral: z.object({
      enabled: z.boolean(),
      code: z.string().optional(),
    }),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    path: ['passwordConfirmation'],
    message: 'Passwords do not match',
  })

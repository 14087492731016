import { createFileRoute, useRouter } from '@tanstack/react-router'
import { z } from 'zod'
import { FileOrUrl } from '@/lib/utils/zod'
import { Form, FormField } from '@/components/ui/form'
import { useFieldArray, useForm, useFormContext } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { CardProfile } from '../digishoku/profile/-components/card-profile'
import { Paperclip, FileText, X } from 'lucide-react'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog'
import { FileUploader } from '@/components/ui/form-field/file-uploader'
import { Button } from '@/components/ui/button'
import { useState } from 'react'
import { formatBytes } from '@/lib/utils/utils'
import { queryGetResume } from '@/services/user/query'
import { useMutation } from '@tanstack/react-query'
import { apiClient } from '@/services/rest/http'
import { toast } from 'sonner'


export const Route = createFileRoute('/_user/profile/')({
    loader: async ({ context: { queryClient: qc } }) => {
        const [resume] = await Promise.all([
            qc.fetchQuery(queryGetResume())
        ])

        return {
            resume,
            async refetch() {
                await qc.refetchQueries(queryGetResume())
            }
        }
    },
    component: RouteComponent,
})

interface FormDto extends z.infer<typeof FormDto> { }
const FormDto = z.object({
    file: z
        .array(FileOrUrl)
        .min(1, { message: 'Attachment CV is required' }),
    created_at: z.coerce.date().optional(),
})

enum TypeCv {
    "LinkedIn" = "LinkedIn",
    "General" = "General"
}
function RouteComponent() {
    const { resume, refetch } = Route.useLoaderData()

    const [dialogUpload, setDialogUpload] = useState(false)
    const [confirmUpdateResume, setConfirmUpdateResume] = useState(false)
    const [isLinkedin, setIsLinkedIn] = useState(false)
    const router = useRouter()
    const navigate = Route.useNavigate()
    const form = useForm<FormDto>({
        resolver: zodResolver(FormDto),
    })

    const { mutateAsync } = useMutation({
        mutationKey: ['upsert-attachment'],
        mutationFn: async ({ data, updateCurrentData = true }: { data: FormDto; updateCurrentData: boolean }) => {

            await apiClient.post(
                '/api/user/resume/upsert',
                constructFormData(data, updateCurrentData),
            )
            await refetch()
            router.invalidate()
            navigate({ to: '/profile/personal' })
        },
    })


    const handleUploadResumeApi = (data: FormDto, updateCurrentData: boolean = true) => {
        toast.promise(mutateAsync({ data, updateCurrentData }), {
            loading: 'Loading...',
            success: 'Resume berhasil disimpan',
            error: 'Gagal menyimpan resume',
        })
    }


    const handleOnSubmit = form.handleSubmit((data) => {
        if (resume && resume.url) {
            setConfirmUpdateResume(true)
        } else {
            handleUploadResumeApi(data)

        }
    })

    const handleUpload = (file?: FileOrUrl[]) => {
        if (file && file.length > 0) {
            form.setValue("file", file);
        }
    }

    const handleOpenDialog = (type: TypeCv) => {
        if (type == TypeCv.LinkedIn) {
            setIsLinkedIn(true)
        } else {
            setIsLinkedIn(false)
        }

        setDialogUpload(true)
    }

    const uploadedFile = form.watch("file");

    const handleRemove = () => {
        form.setValue("file", []); // Clear the file from the form
    };



    return (
        <Form {...form}>
            <UploadCVDialog
                open={dialogUpload}
                setOpen={setDialogUpload}
                handleUpload={handleUpload}
                isLinkedIn={isLinkedin}
            />
            <ConfirmUpdateResume
                open={confirmUpdateResume}
                setOpen={setConfirmUpdateResume}
                handleUpdateCv={handleOnSubmit}
                handleUploadResumeApi={handleUploadResumeApi}
            />
            <CardProfile title="Unggah CV">
                <form onSubmit={handleOnSubmit}>
                    {/* <CardProfile.Content> */}
                    <div className='p-4 !pt-0  md:p-6'>
                        <div className='w-full'>
                            Lengkapi data via linkedin atau unggah CV
                        </div>

                        {uploadedFile && uploadedFile.length > 0 ? (<>
                            <div className='mt-4'>
                                <FileCard
                                    file={uploadedFile[0] ?? undefined}
                                    onRemove={handleRemove}
                                />
                            </div>
                        </>) : (
                            <>
                                {resume && resume.url ? (<>
                                    <div className='mt-4'>
                                        <FileCard
                                            file={resume.url ?? undefined}
                                            onRemove={handleRemove}
                                        />
                                    </div>
                                </>) : (<></>)}
                                <div className='mt-4 flex justify-between gap-8 items-center'>
                                    <div className='w-full bg-[#2D64BC] rounded-lg h-72 cursor-pointer flex flex-col gap-2 items-center justify-center' onClick={() => handleOpenDialog(TypeCv.LinkedIn)}>
                                        <img alt='icon-linkedin' src='/icon/linkedin-icon.png' />
                                        <div className='text-white text-lg font-semibold'>Impor dari Linkedin</div>
                                    </div>
                                    <div>
                                        atau
                                    </div>
                                    <div className='w-full border-2 border-dashed border-primary-yes rounded-lg h-72 flex flex-col gap-2 items-center justify-center cursor-pointer' onClick={() => handleOpenDialog(TypeCv.General)}>
                                        <Paperclip className='text-primary-yes ' size={36} />
                                        <div className='text-primary-yes text-lg font-semibold'>
                                            Unggah CV / Resume
                                        </div>
                                    </div>
                                </div></>
                        )}

                    </div>



                    {/* </CardProfile.Content> */}
                    <CardProfile.Footer>

                        <CardProfile.SubmissionButton />
                    </CardProfile.Footer>
                </form>
            </CardProfile>
        </Form>
    )
}

interface UploadCVDialogPropso {
    open: boolean,
    setOpen: (open: boolean) => void
    handleUpload: (file?: FileOrUrl[]) => void,
    isLinkedIn: boolean
}

function UploadCVDialog({ open, setOpen, handleUpload, isLinkedIn }: UploadCVDialogPropso) {

    const [file, setFile] = useState<FileOrUrl[]>();

    // Handle file value change
    const handleFileChange = (newFile: FileOrUrl[]) => {
        console.log(newFile, 'hal')
        setFile(newFile);
    };

    const handleUploadFile = () => {
        handleUpload(file ?? undefined)
        setFile([])
        setOpen(false)
    }
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="sm:max-w-2xl px-8">
                <div className=' flex justify-center'>
                    <div className='text-center font-medium text-lg'>
                        Pastikan Data Sudah Final<br /> Sebelum Submit
                    </div>
                </div>
                {isLinkedIn ? (<>
                    <div className='p-2 text-sm'>
                        <ol className='list-decimal'>
                            <li>
                                Buka LinkedIn dan masuk ke akun Anda.
                            </li>
                            <li>
                                Pergi ke profil Anda dengan mengklik foto profil atau nama Anda.
                            </li>
                            <li>
                                Klik tombol "More" (Lebih banyak) yang berada di bawah foto profil.
                            </li>
                            <li>
                                Pilih "Save as PDF" (Simpan sebagai PDF).
                            </li>
                            <li>
                                CV Anda akan terunduh dalam format PDF yang berisi ringkasan profil, pengalaman, pendidikan, dan keterampilan.
                            </li>
                        </ol>
                    </div>
                </>) : (<></>)}
                <div className='text-sm'>
                    {isLinkedIn ? 'Unggah CV dari Linkedin' : 'Unggah CV'}
                </div>

                <div>
                    <FileUploader
                        value={file ?? undefined}
                        onValueChange={handleFileChange}
                        fileRuleInfo=" PDF up to 10MB"
                        multiple={false}
                        maxSize={1024 * 1024 * 10}
                        accept={{
                            'application/pdf': ['.pdf'],
                        }}
                    />
                </div>

                <div className='flex justify-center gap-4'>
                    <Button variant={'outline_yes'} onClick={() => setOpen(false)}>
                        Kembali
                    </Button>
                    <Button variant={'primary'} onClick={handleUploadFile}>
                        Simpan Perubahan
                    </Button>
                </div>
            </DialogContent>

        </Dialog>
    )
}

function isFile(file: FileOrUrl): file is File {
    return file instanceof File
}

function isFileWithPreview(file: File): file is File & { preview: string } {
    return 'preview' in file && typeof file.preview === 'string'
}

interface FilePreviewProps {
    file: (File & { preview: string }) | string
}

function FilePreview({ file }: FilePreviewProps) {
    if (
        typeof file === 'string' &&
        ['.png', '.jpg', '.jpeg'].some((ext) => file.endsWith(ext))
    ) {
        return (
            <img
                src={file}
                alt={file}
                width={48}
                height={48}
                loading="lazy"
                className="aspect-square shrink-0 rounded-md object-cover"
            />
        )
    }

    if (file instanceof File && file.type.startsWith('image/')) {
        return (
            <img
                src={file.preview}
                alt={file.name}
                width={48}
                height={48}
                loading="lazy"
                className="aspect-square shrink-0 rounded-md object-cover"
            />
        )
    }

    return (
        <FileText className="size-10 text-muted-foreground" aria-hidden="true" />
    )
}

interface ConfirmUpdateResumeProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    handleUpdateCv: () => void,
    handleUploadResumeApi: (data: FormDto, updateCurrentData: boolean) => void
}
function ConfirmUpdateResume({ open, setOpen, handleUpdateCv, handleUploadResumeApi }: ConfirmUpdateResumeProps) {

    const form = useFormContext<FormDto>()
    const handleUpdateData = (updateCurrentData = true) => {
        handleUploadResumeApi(form.getValues(), updateCurrentData)
        setOpen(false)
    }
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent >
                <div className='text-center font-medium text-lg'>
                    Konfirmasi Pembaruan Data
                </div>
                <div className='p-2 text-sm'>
                    <div>
                        Apakah Anda ingin memperbarui semua data atau hanya memperbarui Resume? Pilih opsi yang sesuai sebelum melanjutkan.
                    </div>
                </div>
                <div className='flex justify-center gap-4'>
                    <Button variant={'secondary'} onClick={() => setOpen(false)}>
                        Kembali
                    </Button>
                    <Button variant={'outline_yes'} onClick={() => handleUpdateData(false)}>
                        Hanya Update Resume
                    </Button>
                    <Button variant={'primary'} onClick={() => handleUpdateData(true)}>
                        Update Data
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

interface FileCardProps {
    file: FileOrUrl
    onRemove: () => void
    onEdit?: () => void
    multiple?: boolean
}

function FileCard({ file, onRemove, multiple, onEdit }: FileCardProps) {
    return (
        <div className="relative flex items-center gap-2.5 rounded-md border border-primary-yes p-2">
            <div className="flex flex-1 gap-2.5">
                {(isFile(file) && isFileWithPreview(file)) ||
                    typeof file === 'string' ? (
                    <FilePreview file={file} />
                ) : null}
                <div className="flex w-full flex-col justify-center gap-2">
                    <div className="flex flex-col gap-px">
                        <p className="line-clamp-1 text-sm font-medium text-foreground/80">
                            {isFile(file) ? file.name : file}
                        </p>
                        {isFile(file) && (
                            <p className="text-xs text-muted-foreground">
                                {formatBytes(file.size)}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex items-center gap-2">
                <Button
                    type="button"
                    variant="outline"
                    size="icon"
                    className="size-7"
                    onClick={onRemove}
                >
                    <X className="size-4" aria-hidden="true" />
                    <span className="sr-only">Remove file</span>
                </Button>

            </div>
        </div>
    )
}

function constructFormData(data: FormDto, updateCurrentData: boolean) {
    const formData = new FormData()

    formData.append('update_current_data', updateCurrentData.toString())

    const file = data.file?.[0]
    formData.append('file', file instanceof File ? file : new File([], 'null'))
    return formData
}
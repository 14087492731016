import { queryOptions } from '@tanstack/react-query'

import { gqlFetch } from '../graphql/fetcher'
import { GqlAuthProfile } from './gql'
import { useAuth } from './use-auth'

export function queryAuthProfile() {
  const token = useAuth.getState().getCookieToken()
  return queryOptions({
    queryKey: ['profile', token],
    queryFn: () =>
      gqlFetch({
        query: GqlAuthProfile,
        opts: { forceToken: token ?? undefined },
      }),
  })
}

import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { EyeOpenIcon, Pencil1Icon, PlusIcon } from '@radix-ui/react-icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createFileRoute, Link } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import { zodValidator } from '@tanstack/zod-adapter'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { GetAffiliatorQuery } from '@/_gql/graphql'

import { affiliatorListQry } from '@/services/affiliator/api'
import { GqlUpsertAffiliator } from '@/services/affiliator/query'
import { GraphQLError } from '@/services/graphql/error'
import { gqlFetch } from '@/services/graphql/fetcher'

import { AdminPage } from '@/components/layout/admin/page'
import { Button, ButtonLoading } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { DataTable } from '@/components/ui/data-table/data-table'
import { useDataTable } from '@/components/ui/data-table/use-data-table'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { Input } from '@/components/ui/input'
import { InputPassword } from '@/components/ui/input-password'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'
import { Skeleton } from '@/components/ui/skeleton'

import { invalidateQueryKeys } from '@/lib/utils/utils'

import { useFilters } from '@/hooks/use-filter'

const searchParamsSchema = z.object({
  s: z.string().nullish(),
})

export const Route = createFileRoute('/admin-v2/referral/')({
  validateSearch: zodValidator(searchParamsSchema),
  component: RouteComponent,
})

function RouteComponent() {
  return (
    <SheetCtxProvider>
      <AdminPage>
        <AdminPage.Breadcrumb
          items={[['Daftar Referrer', '/admin-v2/referral']]}
        />
        <AdminPage.Header
          title="Daftar Referrer"
          action={<AffiliateSheetCreateButton />}
        />
        <AffiliatorSheet />
        <ReferralTable />
      </AdminPage>
    </SheetCtxProvider>
  )
}

const ch = createColumnHelper<GetAffiliatorQuery['getAffiliator'][number]>()
function getColumns() {
  return [
    ch.accessor('name', { header: 'Nama Referrer' }),
    ch.accessor('email', { header: 'Email' }),
    ch.accessor('affiliated_user', { header: 'Jumlah akun terdaftar' }),
    ch.accessor('user_affiliator_poin.poin', { header: 'Poin Saat Ini' }),
    ch.display({
      id: 'action',
      header: 'Aksi',
      cell: ({ row }) => {
        const [_, setState] = useSheetCtx()
        return (
          <div>
            <Button
              variant="ghost"
              size="icon"
              className="text-yes-600 hover:text-yes-800"
              asChild
            >
              <Link
                from={Route.fullPath}
                to="/admin-v2/referral/$id"
                params={{ id: row.original.id.toString() }}
                search={{
                  referralCode: row.original.refferal_code,
                  name: row.original.name,
                }}
              >
                <EyeOpenIcon />
              </Link>
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="text-yes-600 hover:text-yes-800"
              onClick={() => setState(row.original.id)}
            >
              <Pencil1Icon />
            </Button>
          </div>
        )
      },
    }),
  ]
}

function ReferralTable() {
  const { filters, setFilters } = useFilters(Route.id)
  const { data, isLoading } = useQuery(affiliatorListQry())
  const columns = useMemo(getColumns, [])
  const { table } = useDataTable({
    data: data?.getAffiliator ?? [],
    state: { globalFilter: filters.s },
    columns,
  })

  if (isLoading) return null
  return (
    <Card>
      <CardContent className="space-y-3 p-2">
        <div>
          <Input
            className="max-w-80"
            placeholder="Cari user..."
            defaultValue={filters.s || undefined}
            onChange={(e) => {
              setFilters({ s: e.target.value })
            }}
          />
        </div>
        <DataTable table={table} />
      </CardContent>
    </Card>
  )
}

/**
 * val == null -> close
 * val <= -1 -> open (create)
 * val > 0 -> open (update)
 */
type SheetCtxValue = null | number
const SheetCtx = createContext<
  [SheetCtxValue, Dispatch<SetStateAction<SheetCtxValue>>]
>(null!)

function SheetCtxProvider({ children }: PropsWithChildren) {
  const state = useState<SheetCtxValue>(null)
  return <SheetCtx.Provider value={state}>{children}</SheetCtx.Provider>
}

function useSheetCtx() {
  const ctx = useContext(SheetCtx)
  if (!ctx) throw new Error()
  return ctx
}

function AffiliateSheetCreateButton() {
  const [, setState] = useSheetCtx()
  return (
    <Button size="sm" type="button" onClick={() => setState(-1)}>
      <PlusIcon />
      Tambah User
    </Button>
  )
}

function AffiliatorSheet() {
  const [state, setState] = useSheetCtx()
  const open = typeof state === 'number'
  const mode = open && state > 0 ? 'update' : 'create'

  const title = mode === 'create' ? 'Buat Akun Referrer' : 'Ubah Akun Referrer'

  const { data: initialData, isLoading } = useQuery({
    ...affiliatorListQry(),
    select: (data) => {
      const affiliator = data.getAffiliator.find((item) => item.id === state)
      return affiliator
    },
  })

  return (
    <Sheet open={open} onOpenChange={() => setState(null)}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
          <SheetDescription className="sr-only">
            upsert job category
          </SheetDescription>
        </SheetHeader>
        {mode === 'create' && <AffiliateForm />}
        {mode === 'update' && isLoading && <Skeleton className="mt-8 h-36" />}
        {mode === 'update' && initialData && (
          <AffiliateForm initialData={initialData} />
        )}
      </SheetContent>
    </Sheet>
  )
}

type FormDto = z.infer<typeof FormDto>
const FormDto = z
  .object({
    name: z.string().trim().min(1),
    email: z.string().email(),
    password: z.string().min(8),
    passwordConfirm: z.string().min(8),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    path: ['passwordConfirm'],
    message: 'Passwords do not match',
  })

function AffiliateForm({
  initialData,
}: {
  initialData?: GetAffiliatorQuery['getAffiliator'][number]
}) {
  const [, setState] = useSheetCtx()
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: {
      name: initialData?.name ?? '',
      email: initialData?.email ?? '',
      password: '',
    },
  })

  const { control } = form

  const { mutateAsync } = useMutation({
    mutationFn: async (data: FormDto) => {
      await gqlFetch({
        query: GqlUpsertAffiliator,
        variables: {
          request: {
            name: data.name,
            email: data.email,
            password: data.password,
            id: initialData?.id || undefined,
          },
        },
      })

      invalidateQueryKeys(['affiliatorList'])
      setState(null)
    },
    onError: (err) => {
      if (GraphQLError.is(err)) {
        const code = err.getErrorCode()
        if (code === 400)
          form.setError('email', { message: 'Email sudah digunakan' })
      }
    },
  })

  const onSubmit = form.handleSubmit((data) => {
    toast.promise(mutateAsync(data), {
      loading: 'Menyimpan data',
      success: 'Berhasil simpan',
      error: 'Gagal menyimpan',
    })
  }, console.error)

  return (
    <Form {...form}>
      <form onSubmit={onSubmit} className="pt-8">
        <div className="min-h-28 space-y-3">
          <FormField
            control={control}
            name="name"
            render={({ field }) => (
              <FormFieldset label="Nama">
                <Input placeholder="Nama affiliator" {...field} />
              </FormFieldset>
            )}
          />
          <FormField
            control={control}
            name="email"
            render={({ field }) => (
              <FormFieldset label="Email">
                <Input placeholder="Email affiliator" {...field} />
              </FormFieldset>
            )}
          />
          <FormField
            control={control}
            name="password"
            render={({ field }) => (
              <FormFieldset label="Kata Sandi">
                <InputPassword {...field} placeholder="Masukkan password" />
              </FormFieldset>
            )}
          />
          <FormField
            control={control}
            name="passwordConfirm"
            render={({ field }) => (
              <FormFieldset label="Konfirmasi Password">
                <InputPassword
                  placeholder="Masukkan ulang password"
                  {...field}
                />
              </FormFieldset>
            )}
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            type="button"
            onClick={() => setState(null)}
            variant="outline_yes"
          >
            Batal
          </Button>
          <ButtonLoading>Simpan</ButtonLoading>
        </div>
      </form>
    </Form>
  )
}

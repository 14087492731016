import { createContext, useContext, useEffect, useState } from 'react'

type State = {
  isDesktop: boolean
}

const MediaQueryContext = createContext<State>({} as any)

export function MediaQueryProvider({
  children,
  query,
}: {
  children: React.ReactNode
  query: string
}) {
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    function onChange(event: MediaQueryListEvent) {
      setIsDesktop(event.matches)
    }

    const result = matchMedia(query)
    result.addEventListener('change', onChange)
    setIsDesktop(result.matches)

    return () => result.removeEventListener('change', onChange)
  }, [query])

  return (
    <MediaQueryContext.Provider value={{ isDesktop }}>
      {children}
    </MediaQueryContext.Provider>
  )
}

export function useMediaQuery() {
  const context = useContext(MediaQueryContext)

  if (!context) {
    throw new Error('useMediaQuery must be used within MediaQueryProvider')
  }

  return context.isDesktop
}

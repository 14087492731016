import { graphql } from '@/_gql'

const GqlGetAffiliator = graphql(`
  query GetAffiliator {
    getAffiliator {
      id
      name
      email
      refferal_code
      affiliated_user
      user_affiliator_poin {
        poin
      }
    }
  }
`)

const GqlUpsertAffiliator = graphql(`
  mutation UpsertAffiliator($request: UpsertAffiliatorRequest!) {
    upsertAffiliator(request: $request) {
      success
    }
  }
`)

const GqlDetailUserAffiliatedCount = graphql(`
  query DetailUserAffiliatedCount($user_id: Int!, $referralCode: String!) {
    detailUserAffiliatedCount(user_id: $user_id, refferal_code: $referralCode) {
      poin_affiliator
      user_affiliated
      user_affiliated_applied
      user_affiliated_hired
    }
  }
`)

const GqlListUserAffiliated = graphql(`
  query ListUserAffiliated($referralCode: String!) {
    listUserAffiliated(refferal_code: $referralCode) {
      id
      name
      email
      refferal_code
      affiliated_user
      created_at
      user_affiliator_poin {
        poin
      }
      user_status_profile {
        status
      }
      status_account
    }
  }
`)

const GqlListEWallet = graphql(`
  query GetListEwallet {
    getListEwallet {
      id
      name
      created_at
    }
  }
`)

const GqlListPointExchangeHistory = graphql(`
  query GetPointExchangeHistory {
    getPointExchangeHistory {
      id
      user_id
      ewallet_id
      point
      status
      no_hp
      created_at
      ewallet {
        id
        name
        created_at
      }
      user {
        id
        name
        email
        handphone_number
        role
        is_verified
        is_testing
        created_at
        refferal_code
        affiliated_user
      }
    }
  }
`)

const GqlUpsertPointExchange = graphql(`
  mutation UpsertPoinExchangeHistory($data: UpsertPointExchangeHistoryInput!) {
    upsertPoinExchangeHistory(data: $data) {
      success
    }
  }
`)

export {
  GqlGetAffiliator,
  GqlUpsertAffiliator,
  GqlDetailUserAffiliatedCount,
  GqlListUserAffiliated,
  GqlListEWallet,
  GqlListPointExchangeHistory,
  GqlUpsertPointExchange,
}

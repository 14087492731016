import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'

import { useAuth } from '@/services/auth/use-auth'

import { MainLayout } from '@/components/layout/main-layout'
import { SiteFooterUser } from '@/components/layout/site-footer'
import { SiteHeader } from '@/components/layout/site-header'

export const Route = createFileRoute('/_user')({
  beforeLoad: ({ preload }) => {
    if (preload) return
    const { isAuthenticated, user, isAdmin } = useAuth.getState()

    if (!isAuthenticated || !user) {
      throw redirect({ to: '/' })
    }

    if (isAdmin()) {
      throw redirect({ to: '/admin-v2' })
    }

    if (!user.is_verified) {
      throw redirect({ to: '/auth/verification/phone' })
    }
  },
  component: Component,
})

function Component() {
  return (
    <MainLayout
      header={<SiteHeader typeNav="user" />}
      footer={<SiteFooterUser />}
    >
      <Outlet />
    </MainLayout>
  )
}

import { createFileRoute, redirect } from '@tanstack/react-router'

import { GqlAuthGoogleLogin } from '@/services/auth/gql'
import { useAuth } from '@/services/auth/use-auth'
import { gqlFetch } from '@/services/graphql/fetcher'

export const Route = createFileRoute('/auth/callback')({
  loader: async ({ location }) => {
    const sp = new URLSearchParams(location.hash)
    const access_token = sp.get('access_token')
    const stateStr = sp.get('state')

    let stateObj: null | { referralCode: string } = null
    try {
      if (stateStr) stateObj = JSON.parse(stateStr)
    } catch (error) {
      /* empty */
    }

    if (!access_token) {
      throw redirect({ to: '/auth/login' })
    }

    const res = await gqlFetch({
      query: GqlAuthGoogleLogin,
      variables: {
        request: {
          access_token,
          refferal_code: stateObj?.referralCode || undefined,
        },
      },
    })

    const { token, user } = res.googleLogin

    if (!token) {
      return {
        status: 'failed',
      }
    }

    const login = useAuth.getState().login
    await login(res.googleLogin.token)

    if (user.is_verified) {
      throw redirect({ to: '/dashboard', replace: true })
    } else {
      throw redirect({ to: '/auth/verification/phone' })
    }
  },
  component: () => {
    return null
  },
})

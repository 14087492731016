import {
  createRouter as createTanStackRouter,
  parseSearchWith,
  stringifySearchWith,
} from '@tanstack/react-router'
import * as JSURL2 from 'jsurl2'

import { routeTree } from '@/routeTree.gen'
import { queryClient } from './tanstack-query'

export function createRouter() {
  const router = createTanStackRouter({
    routeTree,
    defaultPreload: 'intent',
    defaultPreloadStaleTime: 0,
    defaultViewTransition: true,
    trailingSlash: 'never',
    notFoundMode: 'fuzzy',
    // Handled by react query
    defaultStaleTime: 0,
    defaultGcTime: 0,
    context: {
      queryClient,
    },
    parseSearch: parseSearchWith(JSURL2.parse),
    stringifySearch: stringifySearchWith(JSURL2.stringify, JSURL2.parse),
  })

  return router
}

declare module '@tanstack/react-router' {
  interface Register {
    router: ReturnType<typeof createRouter>
  }
}

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { PlusIcon, Trash2Icon } from 'lucide-react'
import { useFieldArray, useForm, useFormContext } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { AttachmentType, GetUserAttachmentQuery } from '@/_gql/graphql'

import { apiClient } from '@/services/rest/http'
import { ATTACHMENT_OPTIONS } from '@/services/user/constant'
import {
  queryUserAttachment,
  queryUserSubmissionProgress,
} from '@/services/user/query'

import { Button } from '@/components/ui/button'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { FileUploader } from '@/components/ui/form-field/file-uploader'
import { FormFieldSelect } from '@/components/ui/form-field/select'

import { formatDateTimeServer, normalizeEnum } from '@/lib/utils/utils'
import { FileOrUrl } from '@/lib/utils/zod'

import { CardProfile } from './-components/card-profile'

export const Route = createFileRoute('/_user/digishoku/profile/attachment')({
  loader: async ({ context: { queryClient: qc } }) => {
    const attachment = await qc.fetchQuery(queryUserAttachment())
    return {
      initialAttachment: attachment,
      refetch: async () => {
        await Promise.all([
          qc.refetchQueries(queryUserAttachment()),
          qc.refetchQueries(queryUserSubmissionProgress()),
        ])
      },
    }
  },
  component: Component,
})

function Component() {
  const { initialAttachment, refetch } = Route.useLoaderData()
  const router = useRouter()
  const navigate = Route.useNavigate()

  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: getDefaultValues(initialAttachment),
  })

  const attachmentFields = useFieldArray({
    control: form.control,
    name: 'attachment',
    keyName: 'eId',
  })

  const showRemove = attachmentFields.fields.length > 2

  const { mutateAsync } = useMutation({
    mutationKey: ['upsert-attachment'],
    mutationFn: async (data: FormDto) => {
      await apiClient.post(
        '/api/user/attachment/upsert',
        constructFormData(data),
      )

      await refetch()
      router.invalidate()
      navigate({ to: '/profile/languages' })
    },
  })

  const handleOnSubmit = form.handleSubmit((data) => {
    toast.promise(mutateAsync(data), {
      loading: 'Loading...',
      success: 'Berkas berhasil disimpan',
      error: 'Gagal menyimpan berkas',
    })
  }, console.error)

  return (
    <Form {...form}>
      <CardProfile title="Berkas lampiran">
        <form onSubmit={handleOnSubmit}>
          <CardProfile.Content>
            {attachmentFields.fields.map((attachmentField, idx) => {
              const selectedTypes = attachmentFields.fields.map(
                (f) => f.attachment_type,
              )
              const availableOptions = ATTACHMENT_OPTIONS.filter(
                (option) =>
                  !selectedTypes.includes(option.value) ||
                  option.value === attachmentField.attachment_type,
              )

              return (
                <AttachementFormItem
                  options={availableOptions}
                  key={attachmentField.eId}
                  idx={idx}
                  showRemove={showRemove}
                  onRemove={() => attachmentFields.remove(idx)}
                  disableSelect={idx < 2}
                />
              )
            })}
          </CardProfile.Content>
          <CardProfile.Footer>
            <Button
              className="w-full rounded-xl border-primary-yes text-primary-yes md:w-auto"
              variant="outline"
              type="button"
              onClick={() => {
                attachmentFields.append(defaultItem())
              }}
            >
              <PlusIcon className="mr-2" />
              Tambah berkas lampiran
            </Button>
            <CardProfile.SubmissionButton />
          </CardProfile.Footer>
        </form>
      </CardProfile>
    </Form>
  )
}

interface AttachementFormItem {
  idx: number
  showRemove: boolean
  onRemove?: () => void
  options: {
    label: string
    value: AttachmentType
  }[]
  disableSelect?: boolean
}

function AttachementFormItem({
  idx,
  options,
  showRemove,
  onRemove,
  disableSelect,
}: AttachementFormItem) {
  const form = useFormContext<FormDto>()

  return (
    <div
      key={idx}
      className="relative col-span-full flex w-full flex-col gap-4 rounded-md border p-4"
    >
      {showRemove && (
        <div className="absolute right-0 top-0">
          <Button variant="ghost" size="icon" onClick={onRemove} type="button">
            <Trash2Icon className="size-16 text-primary-yes" />
          </Button>
        </div>
      )}

      <FormField
        control={form.control}
        name={`attachment.${idx}.attachment_type`}
        render={({ field }) => (
          <FormFieldset label="Berkas" className="col-span-2">
            <FormFieldSelect
              placeholder="Pilih kategory berkas"
              items={options}
              value={field.value}
              onValueChange={field.onChange}
              disabled={disableSelect}
            />
          </FormFieldset>
        )}
      />
      <FormField
        control={form.control}
        name={`attachment.${idx}.file`}
        render={({ field }) => (
          <FormFieldset label="File" className="col-span-full" hideLabel>
            <FileUploader
              ref={field.ref}
              value={field.value ?? undefined}
              onValueChange={field.onChange}
              fileRuleInfo="PNG, JPG, PDF,Docx up to 10MB"
              multiple={false}
              maxSize={1024 * 1024 * 10}
              accept={{
                'image/*': ['.png', '.jpg', '.jpeg'],
                'application/pdf': ['.pdf'],
                'application/msword': ['.docx'],
              }}
            />
          </FormFieldset>
        )}
      />
    </div>
  )
}

/**
 * Form Schema
 */

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z.object({
  attachment: z.array(
    z.object({
      id: z.onumber(),
      attachment_type: z.nativeEnum(AttachmentType),
      file: z
        .array(FileOrUrl)
        .min(1, { message: 'Attachment file is required' }),
      created_at: z.coerce.date().optional(),
    }),
  ),
})

/**
 * Utils
 */

type AttachmentData = GetUserAttachmentQuery['getUserAttachment']['data'] | null
function getDefaultValues(initial?: AttachmentData): FormDto {
  const data: FormDto = {
    attachment: (initial ?? []).map((x) => ({
      ...x,
      id: x.id || undefined,
      attachment_type: normalizeEnum(x.attachment_type) || undefined!,
      file: [x.url].filter(Boolean),
      created_at: x.created_at ? new Date(x.created_at) : undefined!,
    })),
  }

  data.attachment.length === 0 &&
    data.attachment.push(
      defaultItem(AttachmentType.Degree),
      defaultItem(AttachmentType.Resume),
    )
  return data
}

function defaultItem(
  attachment_type?: AttachmentType,
): FormDto['attachment'][number] {
  return {
    attachment_type: attachment_type || AttachmentType.AwardCertificate,
    file: [],
  }
}

function constructFormData(data: FormDto) {
  const formData = new FormData()

  for (const attachment of data.attachment) {
    if (!attachment.file || !attachment.file.length) {
      throw new Error('Attachment file is required')
    }

    if (attachment.id) formData.append('id', String(attachment.id))

    formData.append('attachment_type', attachment.attachment_type)

    const file = attachment.file?.[0]
    formData.append('url', typeof file === 'string' && file ? file : '')
    formData.append('file', file instanceof File ? file : new File([], 'null'))

    if (attachment.created_at)
      formData.append('created_at', formatDateTimeServer(attachment.created_at))
  }

  return formData
}

import type { TypedDocumentString } from '@/_gql/graphql'

import { GRAPHQL_URI, GRAPHQL_URI_PROXY } from '@/lib/configs/constant'
import { useAuth } from '../auth/use-auth'
import { GraphQLError, HTTPError } from './error'
import type { GraphQLErrorBody, GraphQLRespone } from './types'

interface GqlFetchArgs<TResult, TVariables> {
  query: TypedDocumentString<TResult, TVariables>
  variables?: TVariables extends Record<string, never> ? undefined : TVariables
  opts?: {
    baseUrl?: string
    headers?: HeadersInit
    forceToken?: string
  }
}

export async function gqlFetch<TResult, TVariables>(
  args: GqlFetchArgs<TResult, TVariables>
): Promise<TResult> {
  const { query, variables, opts = {} } = args
  const { baseUrl = GRAPHQL_URI, headers = {}, forceToken } = opts

  const input: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/graphql-response+json',
      ...headers,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  }

  const token = forceToken || useAuth.getState().getCookieToken()
  if (token) {
    input.headers = new Headers(input.headers)
    input.headers.set('Authorization', `Bearer ${token}`)
  }

  const response = await fetch(baseUrl, input)

  if (!response.ok) {
    const body = await (async function serializeBody() {
      try {
        return await response.text()
      } catch {
        return ''
      }
    })()
    throw new HTTPError(input, response, body)
  }

  const body = (await response.json()) as GraphQLRespone<TResult>
  if ('errors' in body) {
    throw new GraphQLError(input, response, body as GraphQLErrorBody)
  }

  return body.data
}

export async function gqlFetchSafe<Result, Variables>(
  args: GqlFetchArgs<Result, Variables>
): Promise<[Result, null] | [null, GraphQLError | HTTPError]> {
  try {
    const result = await gqlFetch(args)
    return [result, null]
  } catch (error) {
    return [null, error as GraphQLError | HTTPError]
  }
}

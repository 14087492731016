import type { GraphQLErrorBody } from './types'

export class GraphQLError extends Error {
  constructor(
    private input: RequestInit,
    private response: Response,
    private body: GraphQLErrorBody
  ) {
    super('GraphQL error')
    this.debug()
    // Object.setPrototypeOf(this, GraphQLError.prototype)
  }

  static is(error: unknown): error is GraphQLError {
    return error instanceof GraphQLError
  }

  getErrorCode() {
    return this.body?.errors[0]?.extensions?.code
  }

  getMessage() {
    return this.body?.errors[0]?.message
  }

  debug() {
    console.log(
      JSON.stringify(
        {
          input: this.input,
          body: this.body,
        },
        null,
        2
      )
    )
  }
}

export class HTTPError extends Error {
  constructor(
    private input: RequestInit,
    public response: Response,
    private body?: string
  ) {
    const message = `HTTP error ${response.status}: ${response.statusText}`
    super(message)
  }
}

export const APP_VERSION = '0.0.1'

/** The URL of the application. */
export const APP_URL = import.meta.env.VITE_APP_URL || window.location.origin

export const IS_DEV = import.meta.env.DEV

export const API_URI = import.meta.env.VITE_API_URL
export const GRAPHQL_URI = import.meta.env.VITE_GRAPHQL_URI
export const GRAPHQL_URI_PROXY = `${window.origin}/query`

export const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID

/**
 * PostHog Configuration
 * local: rizqy@bitlabs.id
 * production: tech-team@bitlabs.id
 *
 * Use cloudflare proxy
 */
export const POSTHOG_HOST = import.meta.env.VITE_POSTHOG_HOST
export const POSTHOG_API_KEY = import.meta.env.VITE_POSTHOG_API_KEY!
// export const POSTHOG_PROXY = `${APP_URL}/posthog`
export const POSTHOG_PROXY = 'https://ingest.yes.co.id'

export const HRDROOM_URL = import.meta.env.VITE_HRDROOM_URL

import { useMemo } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { AxiosError } from 'axios'
import { PlusIcon, Trash2Icon } from 'lucide-react'
import {
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { GetUserLanguageQuery, Language, LanguageLevel } from '@/_gql/graphql'

import { apiClient } from '@/services/rest/http'
import { LANGUAGE_OPTIONS } from '@/services/user/constant'
import {
  queryUserLanguage,
  queryUserSubmissionProgress,
} from '@/services/user/query'

import { Button } from '@/components/ui/button'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { FileUploader } from '@/components/ui/form-field/file-uploader'
import { FormFieldSelect } from '@/components/ui/form-field/select'

import { formatDateTimeServer, normalizeEnum } from '@/lib/utils/utils'
import { FileOrUrl } from '@/lib/utils/zod'

import { cx } from '@/lib/cva-config'
import { CardProfile } from './-components/card-profile'

export const Route = createFileRoute('/_user/digishoku/profile/languages')({
  loader: async ({ context: { queryClient: qc } }) => {
    const languages = await qc.fetchQuery(queryUserLanguage())
    return {
      initialLanguage: languages,
      async refetch() {
        await Promise.all([
          qc.refetchQueries(queryUserLanguage()),
          qc.refetchQueries(queryUserSubmissionProgress()),
        ])
      },
    }
  },
  component: Component,
})

function Component() {
  const navigate = Route.useNavigate()
  const router = useRouter()
  const { initialLanguage, refetch } = Route.useLoaderData()
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: getDefaultValues(initialLanguage),
  })

  const languageFields = useFieldArray({
    control: form.control,
    name: 'language',
    keyName: 'fid',
  })

  const showRemove = true
  const isEmpty = languageFields.fields.length === 0

  const onAdd = () => {
    languageFields.append(defaultItem())
  }

  const { mutateAsync } = useMutation({
    mutationKey: ['upsert-language'],
    mutationFn: async (data: FormDto) => {
      await apiClient.post('/api/user/language/upsert', constructFormData(data))
      await refetch()

      router.invalidate()
      navigate({ to: '/dashboard' })
    },
  })

  const handleOnSubmit = form.handleSubmit((data) => {
    toast.promise(mutateAsync(data), {
      loading: 'Loading...',
      success: 'Bahasa berhasil disimpan',
      error: (err) => {
        if (err instanceof AxiosError) {
          err = err.toJSON()
        }
        return 'Gagal menyimpan Bahasa'
      },
    })
  }, console.error)

  return (
    <Form {...form}>
      <CardProfile title="Bahasa">
        <form onSubmit={handleOnSubmit}>
          <CardProfile.Content>
            {isEmpty ? (
              <EmptyState onAdd={onAdd} />
            ) : (
              languageFields.fields.map((field, idx) => {
                return (
                  <LanguageFormItem2
                    key={field.fid}
                    idx={idx}
                    showRemove={showRemove}
                    onRemove={() => {
                      languageFields.remove(idx)
                    }}
                  />
                )
              })
            )}
          </CardProfile.Content>
          <CardProfile.Footer>
            <Button
              className={cx(
                'w-full rounded-xl border-primary-yes text-primary-yes md:w-auto',
                isEmpty && 'hidden',
              )}
              variant="outline"
              type="button"
              onClick={onAdd}
            >
              <PlusIcon />
              Tambah Bahasa Asing
            </Button>
            <CardProfile.SubmissionButton />
          </CardProfile.Footer>
        </form>
      </CardProfile>
    </Form>
  )
}

interface LanguageFormItemProps {
  idx: number
  showRemove: boolean
  onRemove?: () => void
}

function LanguageFormItem2({
  idx,
  showRemove,
  onRemove,
}: LanguageFormItemProps) {
  const form = useFormContext<FormDto>()
  const { control } = form

  const langValue = useWatch({
    control,
    name: `language.${idx}.language`,
  })
  const langOptions = useMemo(() => {
    return LANGUAGE_OPTIONS.map((x) => ({ label: x.label, value: x.value }))
  }, [])

  const levelOptions = useMemo(() => {
    const selected = LANGUAGE_OPTIONS.find((x) => x.value === langValue)?.level
    return selected || []
  }, [langValue])

  return (
    <div
      key={idx}
      className="relative col-span-full grid grid-cols-subgrid gap-y-4 rounded-md border p-4"
    >
      {showRemove && (
        <div className="absolute right-0 top-0">
          <Button variant="ghost" size="icon" onClick={onRemove} type="button">
            <Trash2Icon className="size-16 text-primary-yes" />
          </Button>
        </div>
      )}

      <FormField
        control={form.control}
        name={`language.${idx}.language`}
        render={({ field }) => (
          <FormFieldset label="Bahasa" className="col-span-full md:col-span-3">
            <FormFieldSelect
              placeholder="Pilih bahasa"
              items={langOptions}
              value={field.value}
              onValueChange={(val) => {
                if (val === field.value) return
                form.setValue(
                  `language.${idx}.language_level`,
                  '' as LanguageLevel,
                )
                field.onChange(val)
              }}
            />
          </FormFieldset>
        )}
      />

      <FormField
        control={control}
        name={`language.${idx}.language_level`}
        render={({ field }) => (
          <FormFieldset
            label="Level Bahasa"
            className="col-span-full md:col-span-3"
          >
            <FormFieldSelect
              placeholder="Pilih level bahasa"
              items={levelOptions}
              value={field.value}
              onValueChange={field.onChange}
              disabled={levelOptions.length === 0}
            />
          </FormFieldset>
        )}
      />

      <FormField
        control={form.control}
        name={`language.${idx}.file`}
        render={({ field }) => (
          <FormFieldset label="Dokumen Pendukung" className="col-span-full">
            <FileUploader
              value={field.value || undefined}
              onValueChange={field.onChange}
              fileRuleInfo="PNG, JPG, PDF up to 20MB"
              maxSize={1024 * 1024 * 20}
              accept={{
                'image/*': ['.png', '.jpg'],
                'application/pdf': ['.pdf'],
              }}
            />
          </FormFieldset>
        )}
      />
    </div>
  )
}

interface EmptyStateProps {
  onAdd: () => void
}

function EmptyState({ onAdd }: EmptyStateProps) {
  return (
    <div className="col-span-full flex flex-col items-center justify-center gap-2 rounded-xl border py-12">
      <p>Punya kemampuan bahasa asing?</p>
      <Button
        size="sm"
        type="button"
        className="rounded-lg bg-gradient-primary"
        onClick={onAdd}
      >
        Tambah Bahasa
      </Button>
    </div>
  )
}

/**
 * Schema
 */

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z.object({
  language: z.array(
    z.object({
      id: z.number().optional(),
      language: z.nativeEnum(Language),
      language_level: z.nativeEnum(LanguageLevel),
      file: z.array(FileOrUrl).nullish(),
      created_at: z.coerce.date().optional(),
    }),
  ),
})

/**
 * Utils
 */
type LanguageData = GetUserLanguageQuery['getUserLanguage']['data'] | null

function getDefaultValues(initial?: LanguageData): FormDto {
  return {
    language:
      initial?.map((x) => ({
        ...x,
        id: x.id || undefined,
        language: normalizeEnum(x.language || undefined),
        language_level: normalizeEnum(x.language_level) || undefined!,
        file: x.url ? [x.url] : undefined!,
        created_at: x.created_at ? new Date(x.created_at) : undefined!,
      })) || [],
  }
}

function defaultItem(): FormDto['language'][number] {
  return {
    language: undefined!,
    language_level: undefined!,
  }
}

function constructFormData(data: FormDto): FormData {
  const formData = new FormData()
  data.language.forEach((item, idx) => {
    if (item.id) formData.append('id', String(item.id))
    formData.append(`language`, item.language.toLowerCase())
    formData.append(`language_level`, item.language_level.toLowerCase())

    const file = item.file?.[0]
    formData.append('url', typeof file === 'string' && file ? file : '')
    formData.append('file', file instanceof File ? file : new File([], 'null'))
    // if (file) {
    //   const isUrl = typeof file === 'string'
    //   if (isUrl) {
    //     formData.append('url', file)
    //   }

    //   if (file instanceof File) {
    //     formData.append('file', file)
    //   } else {
    //     formData.append('file', new File([], 'null'))
    //   }
    // }

    if (item.created_at)
      formData.append('created_at', formatDateTimeServer(item.created_at))
  })

  return formData
}

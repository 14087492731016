import { queryOptions } from '@tanstack/react-query'

import { SearchUserParticipantRequest } from '@/_gql/graphql'

import { gqlFetchSafe } from '../graphql/fetcher'
import { GqlGetUserParticipant, GqlUserParticipants } from './gql'

export function queryGetParticipants(request: SearchUserParticipantRequest) {
  return queryOptions({
    queryKey: ['admin', 'participants', JSON.stringify(request)],

    queryFn: async () => {
      const [res, err] = await gqlFetchSafe({
        query: GqlUserParticipants,
        variables: {
          request: request,
        },
      })

      if (err) {
        return []
      }

      return res.searchUserParticipant
    },
  })
}

export function queryDetailParticipants(userId: number) {
  return queryOptions({
    queryKey: ['admin', 'detail-participans', String(userId)],
    queryFn: async () => {
      const [res] = await gqlFetchSafe({
        query: GqlGetUserParticipant,
        variables: {
          user_id: userId,
        },
      })

      return res?.getUserParticipant
    },
  })
}

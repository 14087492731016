import {
  AttachmentType,
  BloodType,
  DriverLicense,
  EducationLevel,
  EmploymentType,
  Gender,
  HealthStatus,
  Language,
  LanguageLevel,
  LocationType,
  MaritalStatus,
  Relation,
  Religion,
  StatusProfile,
} from '@/_gql/graphql'

export const RELIGION_OPTIONS = [
  { label: 'Islam', value: Religion.Islam },
  { label: 'Kristen', value: Religion.Protestantism },
  { label: 'Katolik', value: Religion.RomanCatholic },
  { label: 'Budha', value: Religion.Buddhism },
  { label: 'Hindu', value: Religion.Hinduism },
  { label: 'Konghucu', value: Religion.Confucianism },
]

export const GENDER_OPTIONS = [
  { label: 'Laki-laki', value: Gender.Men },
  { label: 'Perempuan', value: Gender.Women },
]

export const MARITAL_STATUS_OPTIONS = [
  { label: 'Belum', value: MaritalStatus.Single },
  { label: 'Menikah', value: MaritalStatus.Married },
  { label: 'Cerai', value: MaritalStatus.Divorced },
]

export const DRIVER_LICENSE_OPTIONS = [
  { label: 'SIM A', value: DriverLicense.A },
  { label: 'SIM B1', value: DriverLicense.B1 },
]

export const BLOOD_TYPE_OPTIONS = [
  { label: 'A', value: BloodType.A },
  { label: 'AB', value: BloodType.Ab },
  { label: 'B', value: BloodType.B },
  { label: 'O', value: BloodType.O },
]

export const EDUCATION_LEVEL_OPTIONS = [
  { label: 'SMP', value: EducationLevel.JuniorHighSchool },
  { label: 'SMA', value: EducationLevel.SeniorHighSchool },
  { label: 'SMK', value: EducationLevel.VocationalHighSchool },
  { label: 'D1', value: EducationLevel.Diploma_1 },
  { label: 'D2', value: EducationLevel.Diploma_2 },
  { label: 'D3', value: EducationLevel.Diploma_3 },
  { label: 'D4', value: EducationLevel.Diploma_4 },
  { label: 'S1', value: EducationLevel.Bachelor },
  { label: 'S2', value: EducationLevel.Master },
  { label: 'S3', value: EducationLevel.Doctoral },
]

export const LOCATION_TYPE_OPTIONS = [
  { label: 'Hybrid', value: LocationType.Hybrid },
  { label: 'On-Site', value: LocationType.OnSite },
  { label: 'Remote', value: LocationType.Remote },
]

export const HEALTH_STATUS_OPTIONS = [
  { label: 'Sudah Sembuh', value: HealthStatus.AlreadyHealed },
  { label: 'Dalam Pemulihan', value: HealthStatus.InRecovery },
  { label: 'Masih Sakit', value: HealthStatus.StillSick },
]

export enum VALUE_HAS_BEEN_SICK {
  PERNAH = 'PERNAH',
  TIDAK_PERNAH = 'TIDAK_PERNAH',
}

export const HAS_BEEN_SICK = [
  { label: 'Ya', value: VALUE_HAS_BEEN_SICK.PERNAH },
  { label: 'Tidak', value: VALUE_HAS_BEEN_SICK.TIDAK_PERNAH },
]

export const EMPLOYMENT_STATUS_OPTIONS = [
  { label: 'Kontrak', value: EmploymentType.Contract },
  { label: 'Penuh waktu', value: EmploymentType.FullTime },
  { label: 'Paruh Waktu', value: EmploymentType.PartTime },
  { label: 'Magang', value: EmploymentType.Internship },
]

export const FAMILY_RELATION_OPTIONS = [
  { label: 'Ayah', value: Relation.Father },
  { label: 'Ibu', value: Relation.Mother },
  { label: 'Suami', value: Relation.Husband },
  { label: 'Istri', value: Relation.Wife },
  { label: 'Anak laki-laki', value: Relation.Son },
  { label: 'Anak perempuan', value: Relation.Daughter },
  { label: 'Kakak laki-laki', value: Relation.BigBrother },
  { label: 'Kakak perempuan', value: Relation.BigSister },
  { label: 'Adik laki-laki', value: Relation.LittleBrother },
  { label: 'Adik perempuan', value: Relation.LittleSister },
  { label: 'Saudara', value: Relation.Cousin },
]

export const ATTACHMENT_OPTIONS = [
  // { label: 'KTP (Wajib diisi)', value: AttachmentType.IdentityCard },
  // { label: 'Kartu keluarga (Wajib diisi)', value: AttachmentType.FamilyCard },
  { label: 'KTP', value: AttachmentType.IdentityCard },
  { label: 'Kartu keluarga', value: AttachmentType.FamilyCard },
  { label: 'Hasil test kesehatan', value: AttachmentType.HealthTestResult },
  { label: 'Ijazah terakhir (Wajib diisi)', value: AttachmentType.Degree },
  { label: 'Resume / CV (Wajib diisi)', value: AttachmentType.Resume },
  { label: 'Sertifikat penghargaan', value: AttachmentType.AwardCertificate },
  {
    label: 'Sertifikat kompetensi',
    value: AttachmentType.CompetencyCertificate,
  },
  { label: 'Paspor (jika ada)', value: AttachmentType.Passport },
  { label: 'Other', value: 'OTHER' },

]

export const CERTIFICATE_TYPE = [AttachmentType.AwardCertificate, AttachmentType.CompetencyCertificate, 'OTHER']
export const CERTIFICATE_TYPE_LOWER_CASE = CERTIFICATE_TYPE.map(x => x.toLocaleLowerCase())


export const ATTACHMENT_SIMPLIFY_OPTIONS = [
  { label: 'Sertifikat penghargaan', value: AttachmentType.AwardCertificate },
  {
    label: 'Sertifikat kompetensi',
    value: AttachmentType.CompetencyCertificate,
  },
  { label: 'Other', value: 'OTHER' },
]

export const LANGUAGE_OPTIONS = [
  {
    label: 'Bahasa Inggris',
    value: Language.English,
    level: [
      { label: 'A1 - Beginner', value: LanguageLevel.A1Beginner },
      { label: 'A2 - Elementary', value: LanguageLevel.A2Elementary },
      { label: 'B1 - Intermediate', value: LanguageLevel.B1Intermediate },
      {
        label: 'B2 - Upper Intermediate',
        value: LanguageLevel.B2UpperIntermediate,
      },
      { label: 'C1 - Advanced', value: LanguageLevel.C1Advanced },
      { label: 'C2 - Proficient', value: LanguageLevel.C2Proficient },
    ],
  },
  {
    label: 'Bahasa Jepang',
    value: Language.Japanese,
    level: [
      { label: 'N1', value: LanguageLevel.N1Advanced },
      {
        label: 'N2',
        value: LanguageLevel.N2UpperIntermediate,
      },
      { label: 'N3', value: LanguageLevel.N3Intermediate },
      { label: 'N4', value: LanguageLevel.N4Elementary },
      { label: 'N5', value: LanguageLevel.N5Beginner },
    ],
  },
]

export const TOTAL_EXPERIENCE_INMONTH = [
  { label: '<6 Bulan', value: '6' },
  { label: '1 Tahun', value: '12' },
  { label: '1.5 Tahun', value: '18' },
  { label: '2 Tahun', value: '24' },
]
export const RANGE_AGE = [
  { label: '18-24 Tahun', value: '18-24' },
  { label: '26-30 Tahun', value: '26-30' },
  { label: '31 Tahun Keatas', value: '31-50' },
]

export const STATUS_PROFILE_OPTIONS = [
  {
    label: 'Belum Lengkap',
    value: StatusProfile.FillingSubmissionStage,
  },
  {
    label: 'Lengkap',
    value: StatusProfile.SubmissionVerificationStage,
  },
  {
    label: 'Diterima',
    value: StatusProfile.SubmissionApproved,
  },
  {
    label: 'Ditolak',
    value: StatusProfile.SubmissionRejected,
  },
]

export const AI_RECOMENDATION_OPTIONS = [
  { label: 'Direkomendasikan', value: '1' },
  { label: 'Gagal', value: '0' },
]

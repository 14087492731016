import type { PropsWithChildren } from 'react'

import posthog from 'posthog-js'
import { PostHogProvider, usePostHog } from 'posthog-js/react'

import { useAuth } from '@/services/auth/use-auth'

import { POSTHOG_API_KEY, POSTHOG_PROXY } from '@/lib/configs/constant'

function YesPosthogProvider({ children }: PropsWithChildren) {
  return (
    <PostHogProvider
      apiKey={POSTHOG_API_KEY}
      options={{
        api_host: POSTHOG_PROXY,
        capture_pageleave: false,
        capture_pageview: true,
        autocapture: true,
        person_profiles: 'identified_only',
        // loaded(posthog_instance) {
        //   console.log('Yes Posthog loaded')
        // },
      }}
    >
      {children}
    </PostHogProvider>
  )
}

function initPosthogIdentity() {
  const { user } = useAuth.getState()
  if (user) {
    posthog.identify(String(user.id), {
      email: user.email,
      name: user.name,
      role: user.role,
    })
  } else {
    posthog.reset()
    posthog.resetGroupPropertiesForFlags()
  }
}

// Define the interface contract for PostHog event properties
type PhEvent = {
  'auth:login': { email: string }
  'auth:login:google': { email: string }
  'auth:logout': { email: string }
  'auth:register': { email: string; name: string }
  'auth:verification:phone': { phone: string }
  'auth:verification:req_otp': {}
  'auth:verification:submit_otp:success': {}
  'auth:verification:submit_otp:fail': {}
}

/**
 * Generic PostHog event capture function
 * Ensures all event properties follow the defined contract
 */
function capturePosthogEvent<T extends keyof PhEvent>(
  event: T,
  properties: PhEvent[T]
) {
  posthog.capture(event, properties)
}

export {
  usePostHog,
  YesPosthogProvider,
  initPosthogIdentity,
  capturePosthogEvent,
}

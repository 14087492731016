import React, { PropsWithChildren, useMemo, useState } from 'react'

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@radix-ui/react-collapsible'
import { CheckIcon, ChevronDownIcon } from '@radix-ui/react-icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createFileRoute, Link, useRouter, useNavigate } from '@tanstack/react-router'
import { formatDate } from 'date-fns'
import { toast } from 'sonner'

import { Gender, StatusProfile } from '@/_gql/graphql'

import { useAuth } from '@/services/auth/use-auth'
import { gqlFetchSafe } from '@/services/graphql/fetcher'
import { queryGetStatusJobVacancy } from '@/services/job/query'
import {
  ATTACHMENT_OPTIONS,
  EDUCATION_LEVEL_OPTIONS,
  EMPLOYMENT_STATUS_OPTIONS,
  FAMILY_RELATION_OPTIONS,
  HEALTH_STATUS_OPTIONS,
  LANGUAGE_OPTIONS,
  LOCATION_TYPE_OPTIONS,
  RELIGION_OPTIONS,
} from '@/services/user/constant'
import { GqlResubmitUserProfile } from '@/services/user/gql'
import {
  queryUserAttachment,
  queryUserEducation,
  queryUserExperience,
  queryUserFamily,
  queryUserHealth,
  queryUserInformation,
  queryUserLanguage,
  queryUserSubmissionProgress,
} from '@/services/user/query'

import { Container } from '@/components/layout/container'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

import { getFallbackName } from '@/lib/utils/utils'

import { HRDROOM_URL } from '@/lib/configs/constant'
import { cx } from '@/lib/cva-config'
import { PhaseStatus } from '../../admin-v2/job/applicant/$applicantId'
import GenerateCv from '../-components/dashboard/generate-cv'

export const Route = createFileRoute('/_user/digishoku/dashboard')({
  loader: async ({ context: { queryClient: qc } }) => {
    const [progress, userInformation] = await Promise.all([
      qc.fetchQuery(queryUserSubmissionProgress()),
      qc.fetchQuery(queryUserInformation()),
    ])

    return {
      progress,
      status:
        (progress?.user_status_profile.toUpperCase() as StatusProfile) ||
        StatusProfile.FillingSubmissionStage,
      refetchOnResubmit: async () => {
        await Promise.all([qc.refetchQueries(queryUserSubmissionProgress())])
      },
      userInformation,
    }
  },
  component: Component,
})

function Component() {
  const navigate = useNavigate()
  const user = useAuth((s) => s.user)

  if (!user) return null

  const handleProfileApplyJob = () => {
    navigate({ to: '/dashboard' })
  }
  return (
    <Container className="flex flex-col gap-6 space-y-0 py-12 lg:flex-row">
      <div className="flex flex-1 flex-col gap-4 overflow-auto lg:gap-6">
        <div className='flex '>
          <div className='flex bg-white rounded-md text-sm'>
            <div className='bg-primary-yes rounded-lg text-white py-2 px-3 cursor-pointer'>
              Profil Beasiswa
            </div>
            <div className='text-[#9E9E9E] rounded-lg cursor-pointer  py-2 px-3' onClick={handleProfileApplyJob}>
              Profil Apply Job
            </div>
          </div>
        </div>
        <CardProfile />
        <div className="space-y-4 lg:hidden">
          <CardProgressApply />

          <CardProgressChecklist />
          <CardProgressSummary />
        </div>
        <Content />
      </div>
      <div className="hidden h-min w-[400px] flex-col gap-y-4 lg:flex">
        <CardProgressApply />

        <CardProgressChecklist />
        <CardProgressSummary />
      </div>
    </Container>
  )
}

function CardProfile() {
  const user = useAuth((s) => s.user)
  const { userInformation } = Route.useLoaderData()
  const isUserTesting = user?.is_testing
  const fallbackName = useMemo(
    () => getFallbackName(user?.name || userInformation?.name || user?.email),
    [user],
  )

  if (!user?.id) return null

  return (
    <Card className="border-none shadow-sm">
      <CardContent className="flex flex-col flex-wrap items-center gap-4 py-4 md:flex-row">
        <Avatar className="size-16 border border-slate-600 shadow-sm">
          <AvatarImage src={userInformation?.photo || '#'} alt="Avatar" />
          <AvatarFallback className="bg-transparent text-lg font-bold uppercase">
            {fallbackName}
          </AvatarFallback>
        </Avatar>
        <div className="flex-1 space-y-1 text-center md:text-left">
          <p className="text-lg font-semibold capitalize">{user.name}</p>
          <p className="text-sm font-light">{user.email}</p>
        </div>
        <div className="flex w-full flex-wrap gap-2 md:ml-auto md:w-auto [&>*]:flex-1 [&>*]:md:flex-none">
          {isUserTesting ? <GenerateCv /> : <></>}
          <Button asChild variant="outline_yes" className="rounded-lg">
            <Link from={Route.fullPath} to="/digishoku/account/edit">
              Edit Akun
            </Link>
          </Button>
          <Button asChild className="rounded-lg bg-gradient-primary">
            <Link from={Route.fullPath} to="/digishoku/profile">
              Lanjutkan Isi Biodata
            </Link>
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

const OPTIONS = {
  personal: 'Informasi Pribadi',
  education: 'Pendidikan',
  experience: 'Riwayat Pekerjaan',
  // health: 'Riwayat Kesehatan',
  // family: 'Riwayat Keluarga',
  attachment: 'Berkas Lampiran',
  language: 'Bahasa',
} as const

type ContentOption = keyof typeof OPTIONS

function Content() {
  const [active, setActive] = useState<ContentOption>('personal')

  return (
    <Tabs
      value={active}
      onValueChange={(val) => setActive(val as ContentOption)}
      className="w-full max-w-full space-y-6 overflow-auto"
    >
      <div className="w-full overflow-auto rounded-lg bg-white shadow-sm">
        <TabsList className="flex h-auto w-full min-w-fit flex-nowrap bg-transparent p-2 [&>*]:flex-1">
          {Object.keys(OPTIONS).map((key) => (
            <TabsTrigger
              key={key}
              value={key}
              className="rounded-md py-2 text-[#9E9E9E] transition-colors duration-300 data-[state=active]:bg-gradient-primary data-[state=active]:text-white"
            >
              {OPTIONS[key as ContentOption]}
            </TabsTrigger>
          ))}
        </TabsList>
      </div>
      {(
        [
          ['personal', <ContentPersonal />],
          ['education', <ContentEducation />],
          ['experience', <ContentExperience />],
          // ['health', <ContentHealth />],
          // ['family', <ContentFamily />],
          ['attachment', <ContentAttachment />],
          ['language', <ContentLanguage />],
        ] satisfies [ContentOption, React.ReactNode][]
      ).map(([key, content]) => {
        return (
          <TabsContent value={key} key={key} className="focus-visible:ring-0">
            {content}
          </TabsContent>
        )
      })}
    </Tabs>
  )
}

interface CardProfileSummaryProps {
  title: string
}

function CardProfileSummary({
  title,
  children,
}: PropsWithChildren<CardProfileSummaryProps>) {
  return (
    <Card className="max-w-full border-t-8 border-t-[#D91F2E]">
      <CardHeader className="border-b-2 border-[#EAECF0] py-4">
        <CardTitle className="text-lg">{title}</CardTitle>
      </CardHeader>
      <CardContent className="py-6">{children}</CardContent>
    </Card>
  )
}

function ContentPersonal() {
  const { data, isLoading } = useQuery(queryUserInformation())

  return (
    <CardProfileSummary title="Informasi Pribadi">
      <div
        className={cx(
          'flex flex-col gap-y-4',
          'md:grid md:grid-cols-2 md:gap-y-6',
        )}
      >
        <DataItem label="Nama" value={data?.name} isLoading={isLoading} />
        <DataItem
          label="Nomor HP"
          value={data?.user.handphone_number}
          isLoading={isLoading}
        />
        <DataItem
          label="Email"
          value={data?.user.email}
          isLoading={isLoading}
        />
        <DataItem
          label="Tanggal Lahir"
          value={data?.birthdate ? formatDate(data.birthdate, 'PPPP') : null}
          isLoading={isLoading}
        />
        <DataItem
          label="Alamat"
          value={data?.address}
          className="col-span-2"
          isLoading={isLoading}
        />
        <DataItem
          label="Tempat lahir"
          className="col-span-2"
          value={
            data?.province
              ? [data.city.name, data.province.name, data.country.name].join(
                ', ',
              )
              : '-'
          }
          isLoading={isLoading}
        />
        <DataItem
          label="Jenis Kelamin"
          value={
            !data?.gender
              ? '-'
              : data?.gender.toUpperCase() === Gender.Men
                ? 'Laki-laki'
                : 'Perempuan'
          }
          isLoading={isLoading}
        />
        <DataItem
          label="Anak Tunggal"
          value={
            !data?.is_single_child
              ? '-'
              : data?.is_single_child
                ? 'Iya'
                : 'Tidak'
          }
          isLoading={isLoading}
        />
        <DataItem
          label="Status Pernikahan"
          value={
            <span className="capitalize">{data?.marital_status ?? '-'}</span>
          }
          isLoading={isLoading}
        />
        <DataItem
          label="Agama"
          value={<span className="capitalize">{data?.religion ?? '-'}</span>}
          isLoading={isLoading}
        />
        <DataItem
          label="Kewarganegaraan"
          value={
            <span className="capitalize">{data?.nationality?.name ?? '-'}</span>
          }
          isLoading={isLoading}
        />
        <DataItem
          label="SIM Mobil"
          value={data?.driver_license?.toUpperCase() ?? '-'}
          isLoading={isLoading}
        />
        <DataItem
          label="Golongan Darah"
          value={data?.blood_type?.toUpperCase() ?? '-'}
          isLoading={isLoading}
        />
        <DataItem
          label="Berat Badan"
          value={data?.weight}
          isLoading={isLoading}
        />
        <DataItem
          label="Tinggi Badan"
          value={data?.height}
          isLoading={isLoading}
        />
        <DataItem
          label="Pernah Keluar Negeri"
          value={
            !data?.is_ever_been_going_abroad
              ? '-'
              : data?.is_ever_been_going_abroad
                ? 'Iya'
                : 'Tidak'
          }
          isLoading={isLoading}
        />
        <DataItem
          label="Paspor"
          value={
            !data?.is_having_passport
              ? '-'
              : data?.is_having_passport
                ? 'Iya'
                : 'Tidak'
          }
          isLoading={isLoading}
        />
        <DataItem
          label="Ajukan Paspor"
          value={
            !data?.is_ever_proposing_passport
              ? '-'
              : data?.is_ever_proposing_passport
                ? 'Iya'
                : 'Tidak'
          }
          isLoading={isLoading}
        />
      </div>
    </CardProfileSummary>
  )
}

function ContentEducation() {
  const { data } = useQuery(queryUserEducation())

  return (
    <CardProfileSummary title="Pendidikan">
      <div
        className={cx('grid gap-x-6 divide-y overflow-auto')}
        style={{ gridTemplateColumns: 'repeat(4, minmax(150px, max-content))' }}
      >
        {data?.map((x) => (
          <div
            className="col-span-full grid grid-cols-subgrid gap-x-6 py-4"
            key={x.id}
          >
            <DataItem
              label="Tingkat Pendidikan"
              value={
                EDUCATION_LEVEL_OPTIONS.find(
                  ({ value }) => value === x.level.toUpperCase(),
                )?.label
              }
            />
            <DataItem label="Nama Kampus" value={x.school} />
            <DataItem label="Bidang Studi" value={x.major} />
            <DataItem
              label="Tanggal Mulai - Lulus"
              value={
                x.graduation_date
                  ? `${formatDate(x.start_date, 'dd/MM/yyyy')} - ${formatDate(x.graduation_date, 'dd/MM/yyyy')}`
                  : `${formatDate(x.start_date, 'dd/MM/yyyy')} - Sekarang`
              }
            />
          </div>
        ))}
      </div>
    </CardProfileSummary>
  )
}

function ContentExperience() {
  const { data } = useQuery(queryUserExperience())

  return (
    <CardProfileSummary title="Pekerjaan">
      <div
        className="grid divide-y overflow-auto"
        style={{ gridTemplateColumns: 'repeat(6, min-content)' }}
      >
        {data?.map((x) => (
          <div
            className={cx('col-span-full grid grid-cols-subgrid gap-x-6 py-4')}
            key={x.id}
          >
            <DataItem label="Jabatan" value={x.title} className="min-w-40" />
            <DataItem label="Nama Perusahaan" value={x.company_name} />
            <DataItem
              label="Status Karyawan"
              value={
                EMPLOYMENT_STATUS_OPTIONS.find(
                  (y) => y.value === x.employment_type.toUpperCase(),
                )?.label
              }
            />
            <DataItem
              label="Tanggal Mulai - Selesai"
              value={
                x.end_date
                  ? `${formatDate(x.start_date, 'dd/MM/yyyy')} - ${formatDate(x.end_date, 'dd/MM/yyyy')}`
                  : `${formatDate(x.start_date, 'dd/MM/yyyy')} - Sekarang`
              }
            />
            <DataItem label="Lokasi Pekerjaan" value={x.location} />
            <DataItem
              label="Tipe Lokasi"
              value={
                LOCATION_TYPE_OPTIONS.find(
                  (y) => y.value === x.location_type.toUpperCase(),
                )?.label
              }
            />
          </div>
        ))}
      </div>
    </CardProfileSummary>
  )
}

function ContentHealth() {
  const { data } = useQuery(queryUserHealth())

  return (
    <CardProfileSummary title="Kesehatan">
      <div
        className="grid divide-y overflow-auto"
        style={{ gridTemplateColumns: 'repeat(4, auto)' }}
      >
        {data?.map((x) => (
          <div
            className="col-span-full grid grid-cols-subgrid gap-x-6 py-4"
            key={x.id}
          >
            <DataItem label="Riwayat Penyakit" value={x.name} />
            <DataItem label="Tahun Sakit" value={x.year} />
            <DataItem
              label="Status Sakit"
              value={
                HEALTH_STATUS_OPTIONS.find(
                  (y) => y.value === x.status.toUpperCase(),
                )?.label ?? '-'
              }
            />
            <DataItem label="Keterangan" value={x.explaination} />
          </div>
        ))}
      </div>
    </CardProfileSummary>
  )
}

function ContentFamily() {
  const { data } = useQuery(queryUserFamily())

  return (
    <CardProfileSummary title="Riwayat Keluarga">
      <div
        className="grid divide-y overflow-auto"
        style={{ gridTemplateColumns: 'repeat(7, auto)' }}
      >
        {data?.map((x) => (
          <div
            className="col-span-full grid grid-cols-subgrid gap-x-6 py-4"
            key={x.id}
          >
            <DataItem label="Nama anggota keluarga" value={x.name} />
            <DataItem
              label="Hubungan"
              value={
                FAMILY_RELATION_OPTIONS.find(
                  (y) => y.value === x.relation.toUpperCase(),
                )?.label
              }
            />
            <DataItem label="Nomor HP" value={x.phone_number} />
            <DataItem
              label="Tanggal Lahir"
              value={formatDate(x.birthdate, 'dd/MM/yyyy')}
            />
            <DataItem
              label="Tempat Lahir"
              value={[x.city.name, x.province.name, x.country.name].join(', ')}
            />
            <DataItem
              label="Agama"
              value={
                RELIGION_OPTIONS.find(
                  (y) => y.value === x.religion.toUpperCase(),
                )?.label || '-'
              }
            />
            <DataItem
              label="Kewarganegaraan"
              value={x.nationality?.name ?? '-'}
            />
          </div>
        ))}
      </div>
    </CardProfileSummary>
  )
}

function ContentAttachment() {
  const { data, isLoading } = useQuery(queryUserAttachment())

  return (
    <CardProfileSummary title="Berkas Lampiran">
      <div
        className="grid divide-y overflow-auto"
        style={{ gridTemplateColumns: 'repeat(2, auto)' }}
      >
        {data?.map((x) => (
          <div
            className="col-span-full grid grid-cols-subgrid gap-x-6 py-4"
            key={x.id}
          >
            <DataItem
              label="Tipe"
              value={
                ATTACHMENT_OPTIONS.find(
                  (y) => y.value === x.attachment_type.toUpperCase(),
                )?.label
              }
            />
            <a href={x.url} target="_blank">
              <DataItem label="File" value={x.url} />
            </a>
          </div>
        ))}
      </div>
    </CardProfileSummary>
  )
}

function ContentLanguage() {
  const { data } = useQuery(queryUserLanguage())

  return (
    <CardProfileSummary title="Bahasa">
      <div
        className="grid divide-y overflow-auto"
        style={{ gridTemplateColumns: 'repeat(3, auto)' }}
      >
        {data?.map((x) => {
          const languageOptions = LANGUAGE_OPTIONS.find(
            (y) => y.value === x.language.toUpperCase(),
          )

          return (
            <div
              className="col-span-full grid grid-cols-subgrid gap-x-6 py-4"
              key={x.id}
            >
              <DataItem label="Bahasa" value={languageOptions?.label} />
              <DataItem
                label="Level Bahasa"
                value={
                  languageOptions?.level.find(
                    (y) => y.value === x.language_level?.toUpperCase(),
                  )?.label
                }
              />
              <DataItem label="Dokumen Pendukung" value={x.url} href={x.url} />
            </div>
          )
        })}
      </div>
    </CardProfileSummary>
  )
}

const STATUS_INFO: Record<
  StatusProfile,
  { img: string; label: string; desc?: string }
> = {
  [StatusProfile.FillingSubmissionStage]: {
    img: '/img/user-status/user-status-in-progress.svg',
    label: 'Biodata sedang proses pengisian',
  },
  [StatusProfile.SubmissionVerificationStage]: {
    img: '/img/user-status/user-status-in-verification.svg',
    label: 'Sedang dalam Tahap Verifikasi Biodata Diri',
  },
  [StatusProfile.SubmissionApproved]: {
    img: '/img/user-status/user-status-acceptance.svg',
    label: 'Verifikasi Berhasil! Pengajuan diterima',
    desc: 'Tim kami akan menghubungi untuk proses selanjutnya',
  },
  [StatusProfile.SubmissionRejected]: {
    img: '/img/user-status/user-status-rejected.svg',
    label: 'Verifikasi Gagal! Pengajuan ditolak',
  },
}

function CardProgressChecklist() {
  const { progress } = Route.useLoaderData()

  return (
    <Collapsible className="h-min" defaultOpen>
      <Card className="border-none shadow-sm">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 p-3">
          <CardTitle className="text-lg font-semibold">
            Progress Biodata
          </CardTitle>
          <CollapsibleTrigger asChild>
            <Button
              size="icon"
              variant="ghost"
              className="m-0 size-4 rounded-full p-0"
            >
              <ChevronDownIcon />
            </Button>
          </CollapsibleTrigger>
        </CardHeader>
        <CollapsibleContent>
          <CardContent className="flex flex-col gap-3 p-3 pt-1">
            {(
              [
                ['Informasi Pribadi', progress?.user_personal_info],
                ['Pendidikan', progress?.user_education],
                ['Riwayat Pekerjaan', progress?.user_experience],
                // ['Riwayat Kesehatan', progress?.user_health],
                // ['Riwayat Keluarga', progress?.user_family],
                ['Berkas Lampiran', progress?.user_attachment],
                ['Bahasa (opsional)', progress?.user_language],
              ] as [string, boolean][]
            ).map(([label, isDone]) => (
              <div className="flex justify-between gap-4" key={label}>
                <p>{label}</p>
                <div
                  className={cx(
                    'flex size-4 rounded-full transition-opacity duration-300',
                    isDone ? 'bg-[#6CDB70]' : 'bg-[#98A2B3]',
                  )}
                >
                  <CheckIcon className="m-auto size-3 text-white" />
                </div>
              </div>
            ))}
          </CardContent>
        </CollapsibleContent>
      </Card>
    </Collapsible>
  )
}

function CardProgressSummary() {
  const { status, progress, refetchOnResubmit } = Route.useLoaderData()
  const router = useRouter()

  const selected = STATUS_INFO[status || StatusProfile.FillingSubmissionStage]
  const isRejected = status === StatusProfile.SubmissionRejected
  const desc = isRejected ? progress?.reason : selected?.desc

  const { mutate: resubmit } = useMutation({
    mutationFn: async () => {
      const [res, resubmitError] = await gqlFetchSafe({
        query: GqlResubmitUserProfile,
      })

      if (resubmitError) {
        toast.error('Resubmit error')
        console.error({ resubmitError })
        throw resubmitError
      }

      router.invalidate()
      await refetchOnResubmit()

      toast.success('Resubmit success')
    },
  })

  return (
    <Collapsible defaultOpen className="w-full">
      <Card className="w-full border-none shadow-sm">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 p-3">
          <CardTitle className="flex items-center text-lg font-semibold">
            Status Progres
          </CardTitle>
          <CollapsibleTrigger asChild>
            <Button
              size="icon"
              variant="ghost"
              className="ml-auto size-4 rounded-full"
            >
              <ChevronDownIcon />
            </Button>
          </CollapsibleTrigger>
        </CardHeader>
        <CollapsibleContent>
          <CardContent className="flex flex-col gap-3 p-3 pt-1">
            <div
              className="flex flex-col items-center justify-center gap-2 rounded-lg p-3 text-center"
              style={{ backgroundColor: '#F7F8FF' }}
            >
              <img
                src={selected?.img}
                style={{
                  height: 'auto',
                  maxHeight: '100%',
                  width: '170px',
                }}
              />
              <h1 className="text-xl font-semibold">{selected?.label}</h1>
              {desc && <p className="text-sm">{desc}</p>}
              {isRejected && (
                <Button
                  onClick={() => resubmit()}
                  className="rounded-xl bg-gradient-primary"
                >
                  Saya Sudah Revisi
                </Button>
              )}
              {isRejected && (
                <p className="text-xs">
                  *Setelah selesai memperbaiki data harap submit ulang dengan
                  menggunakan tombol diatas.
                </p>
              )}
            </div>
          </CardContent>
        </CollapsibleContent>
      </Card>
    </Collapsible>
  )
}

function CardProgressApply() {
  const { data, isLoading, refetch } = useQuery(queryGetStatusJobVacancy())
  const userAlreadyApply = data && data?.job_user_selection_phase?.length !== 0

  const jobPhase = data && data?.job_user_selection_phase
  const lastJobPhase = jobPhase && jobPhase[jobPhase?.length - 1]
  const url = lastJobPhase?.job_user_selection_phase_hrdroom?.url
    ? lastJobPhase?.job_user_selection_phase_hrdroom?.url
    : `${HRDROOM_URL}/?kode=${lastJobPhase?.job_user_selection_phase_hrdroom?.test_token}`
  const job = data?.job

  return (
    <Card className="w-full border-none shadow-sm">
      <CardHeader className="flex flex-col space-y-0 p-3">
        <CardTitle className="flex items-center text-lg font-semibold">
          Status Lamar Pekerjaan
        </CardTitle>
        {userAlreadyApply ? (
          <CardContent className="mt-6 flex flex-col p-3">
            <div className="flex flex-row gap-3 py-3">
              <div>
                <img className="w-25" src="/img/landing/logo.jpg" />
              </div>
              <div>
                <div className="font-semibold text-[#131517]">{job?.title}</div>
                <div className="text-sm">YES Partner</div>
              </div>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <div>
                {
                  lastJobPhase?.job_selection_phase.master_job_selection_phase
                    .phase
                }
              </div>
              {/* @ts-expect-error */}
              <div>{<PhaseStatus status={lastJobPhase?.status} />}</div>
            </div>
            <div className="mt-4 w-full">
              {lastJobPhase?.job_user_selection_phase_hrdroom ? (
                <Link to={url} target="_blank">
                  <Button className="w-full">Lanjut Tes Psikologi</Button>
                </Link>
              ) : (
                <></>
                // <Button className="w-full">Lihat Detail Lamaran</Button>
              )}
            </div>
          </CardContent>
        ) : (
          <>
            <CardContent className="flex flex-col gap-3 p-3 pt-1">
              <div
                className="flex flex-row items-center gap-4 rounded-lg p-3 text-center"
                style={{ backgroundColor: '#F7F8FF' }}
              >
                <div>
                  <img
                    className="w-[60px]"
                    src="/img/user-status/status-appy-before-apply.svg"
                  />
                </div>
                <div className="flex-wrap pr-4 text-left text-lg font-semibold">
                  Belum melamar pekerjaan
                </div>
              </div>
              <div className="mt-4">
                <Button className="w-full rounded-lg py-6 text-lg" asChild>
                  <Link to="/" hash="#job-listing">
                    Lamar Pekerjaan Sekarang
                  </Link>
                </Button>
              </div>
            </CardContent>
          </>
        )}
      </CardHeader>
    </Card>
  )
}

interface DataItemProps {
  label: string
  value?: React.ReactNode
  className?: string
  isLoading?: boolean
  href?: string | null
}

function DataItem({ label, value, className, isLoading, href }: DataItemProps) {
  const Wrapper = href ? 'a' : 'div'
  return (
    <Wrapper
      className={cx('space-y-1', className)}
      href={href || undefined}
      target="__blank"
    >
      <p className="text-nowrap font-semibold">
        {isLoading ? <Skeleton className="h-6 w-full" /> : label}
      </p>
      <p className="overflow-auto">
        {isLoading ? <Skeleton className="h-6 w-full" /> : (value ?? '-')}
      </p>
    </Wrapper>
  )
}

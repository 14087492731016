import { queryOptions } from '@tanstack/react-query'

import { SearchUserParticipantRequest } from '@/_gql/graphql'

import { gqlFetch } from '../graphql/fetcher'
import {
  GqlGetJobUser,
  GqlGetJobUserDetail,
  GqlGetJobUserSelectionPhaseHrdRoom,
  GqlGetStatusJobVacancy,
  GqlHRDRoomGetModules,
  GqlHRDRoomGetTests,
  GqlJobCategoryDetail,
  GqlJobCategoryList,
  GqlJobCompanyDetail,
  GqlJobCompanyList,
  GqlJobDetail,
  GqlJobList,
  GqlJobMasterPhaseDetail,
  GqlJobMasterPhaseList,
  GqlJobSkillDetail,
  GqlJobSkillList,
  GqlJobTagDetail,
  GqlJobTagList,
} from './gql'

// #region JOB - Master

/**
 * List Job
 * role: admin
 */
export function queryJobList(isActive?: boolean, title?: string) {
  return queryOptions({
    queryKey: ['job-list', 'list'],
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlJobList,
        variables: { isActive: isActive, title: title },
      })

      return res.getJob.data
    },
  })
}

export function queryJobDetail(id: number) {
  return queryOptions({
    queryKey: ['job-detail', id],
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlJobDetail,
        variables: { id },
      })
      return res.getJobDetail.data
    },
  })
}

// #endregion JOB - Master

// #region JOB - Company

/**
 * List Companies
 * role: admin
 */
export function queryJobCompanyList() {
  return queryOptions({
    queryKey: ['job', 'company-list'],
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlJobCompanyList,
        variables: { name: null },
      })

      return res.getJobCompany.data
    },
  })
}

/**
 * Get Company
 * role: admin
 */
export function queryJobCompanyDetail(id: number) {
  return queryOptions({
    queryKey: ['job', 'company-detail', String(id)],
    enabled: Boolean(id),
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlJobCompanyDetail,
        variables: { id },
      })

      return res.getJobCompanyDetail.data
    },
  })
}

// #endregion JOB - Company

// #region JOB - Tag

/**
 * List Tags
 * role: admin
 */
export function queryJobTagList() {
  return queryOptions({
    queryKey: ['job', 'tag-list'],
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlJobTagList,
        variables: { name: null },
      })

      return res.getJobTag.data
    },
  })
}

/**
 * Get Job Tag
 * role: admin
 */
export function queryJobTagDetail(id: number) {
  return queryOptions({
    queryKey: ['job', 'tag-detail', String(id)],
    enabled: Boolean(id),
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlJobTagDetail,
        variables: { id },
      })

      return res.getJobTagDetail.data
    },
  })
}

// #endregion JOB - Tag

// #region JOB - Skill

/**
 * List Skills
 * role: admin
 */
export function queryJobSkillList() {
  return queryOptions({
    queryKey: ['job', 'skill-list'],
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlJobSkillList,
        variables: { name: null },
      })

      return res.getJobSkill.data
    },
  })
}

/**
 * Get Job Skill
 * role: admin
 */
export function queryJobSkillDetail(id: number) {
  return queryOptions({
    queryKey: ['job', 'skill-detail', String(id)],
    enabled: Boolean(id),
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlJobSkillDetail,
        variables: { id },
      })

      return res.getJobSkillDetail.data
    },
  })
}

// #endregion JOB - Skill

// #region JOB - Category

/**
 * List Job Category
 * role: admin
 */
export function queryJobCategoryList() {
  return queryOptions({
    queryKey: ['job', 'category-list'],
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlJobCategoryList,
        variables: { name: null },
      })

      return res.getJobCategory.data
    },
  })
}

/**
 * Get Job Category
 * role: admin
 */
export function queryJobCategoryDetail(id: number) {
  return queryOptions({
    queryKey: ['job', 'category-detail', String(id)],
    enabled: Boolean(id),
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlJobCategoryDetail,
        variables: { id },
      })

      return res.getJobCategoryDetail.data
    },
  })
}

// #endregion JOB - Category

// #region JOB - Master Phase

/**
 * List Master Phase
 * role: admin
 */
export function queryJobMasterPhaseList() {
  return queryOptions({
    queryKey: ['job', 'master-phase-list'],
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlJobMasterPhaseList,
      })

      return res.getMasterJobSelectionPhase.data
    },
  })
}

/**
 * Get Master Phase
 * role: admin
 */
export function queryJobMasterPhaseDetail(id: number) {
  return queryOptions({
    queryKey: ['job', 'master-phase-detail', String(id)],
    enabled: Boolean(id),
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlJobMasterPhaseDetail,
        variables: { id },
      })

      return res.getMasterJobSelectionPhaseDetail.data
    },
  })
}

// #endregion JOB - Master Phase

/**
 * Get Job User
 * role: admin
 */
export function queryGetJobUser(search: SearchUserParticipantRequest) {
  return queryOptions({
    queryKey: ['job', 'get-job-user', JSON.stringify(search)],
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlGetJobUser,
        variables: { request: search },
      })

      return res.getJobUser.data
    },
  })
}

export function queryGetJobUserDetail(id: string) {
  return queryOptions({
    queryKey: ['job', 'get-job-user-detail', String(id)],
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlGetJobUserDetail,
        variables: { id: Number(id) },
      })

      return res.getJobUserDetail.data
    },
  })
}

// #endregion JOB - Master Phase

export function queryGetStatusJobVacancy() {
  return queryOptions({
    queryKey: ['job', 'get-status-job-vacancy'],
    queryFn: async () => {
      try {
        const res = await gqlFetch({
          query: GqlGetStatusJobVacancy,
        })

        // Check for potential errors in the response
        if (!res || !res.getStatusJobVacancyUser) {
          throw new Error('Invalid response format')
        }

        return res.getStatusJobVacancyUser.data
      } catch (error: any) {
        // Throw the error so React Query knows the query failed
        return null
      }
    },
  })
}

// HRD Room
export function queryHRDRoomGetModules() {
  return queryOptions({
    queryKey: ['hrdroomGetModules'],
    queryFn: async () => {
      try {
        const res = await gqlFetch({
          query: GqlHRDRoomGetModules,
        })

        return res.hrdroomGetModules.data
      } catch (error: any) {
        // Throw the error so React Query knows the query failed
        return null
      }
    },
  })
}

export const queryHRDRoomGetTests = (moduleId: number) => {
  return queryOptions({
    queryKey: ['hrdroomGetTests', moduleId],
    queryFn: async () => {
      try {
        const res = await gqlFetch({
          query: GqlHRDRoomGetTests,
          variables: { module_id: moduleId },
        })

        return res.hrdroomGetTests.data
      } catch (error: any) {
        // Throw the error so React Query knows the query failed
        return null
      }
    },
  })
}

export const queryJobUserSelectionPhaseHrdRoom = (id: number) => {
  return queryOptions({
    queryKey: ['job', 'get-job-user-selection-phase-hrdroom', String(id)],
    queryFn: async () => {
      try {
        const res = await gqlFetch({
          query: GqlGetJobUserSelectionPhaseHrdRoom,
          variables: { id },
        })

        return res.getJobUserSelectionPhaseHRDRoom.data
      } catch (error: any) {
        // Throw the error so React Query knows the query failed
        return null
      }
    },
    enabled: Boolean(id),
  })
}

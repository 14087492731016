import { graphql } from '@/_gql'

// #region JOB - Master

/**
 * List Job
 * role: admin
 */
export const GqlJobList = graphql(`
  query JobList($title: String, $isActive: Boolean) {
    getJob(title: $title, is_active: $isActive) {
      data {
        id
        created_at
        updated_at
        deleted_at
        title
        job_company_id
        min
        max
        salary_currency
        salary_period
        description
        type
        location_city_id
        location_province_id
        location_country_id
        location_type
        is_active
        job_company {
          id
          created_at
          updated_at
          deleted_at
          name
          logo
          size_from
          size_to
        }
        job_skills {
          id
          created_at
          updated_at
          deleted_at
          name
        }
        tags {
          id
          created_at
          updated_at
          deleted_at
          name
        }
        categories {
          id
          created_at
          updated_at
          deleted_at
          name
        }
        country {
          id
          name
        }
        province {
          id
          name
        }
        city {
          id
          name
        }
        job_selection_phase {
          id
          created_at
          updated_at
          deleted_at
          job_id
          phase_number
          master_job_selection_phase_id
          required_application_document
          master_job_selection_phase {
            id
            created_at
            updated_at
            deleted_at
            phase
            description
          }
        }
      }
    }
  }
`)

/**
 * Detail Job
 * role: admin
 */
export const GqlJobDetail = graphql(`
  query JobDetail($id: Int!) {
    getJobDetail(id: $id) {
      data {
        id
        title
        description
        type
        location_city_id
        location_province_id
        location_country_id
        location_type
        is_active
        city {
          id
          name
        }
        province {
          id
          name
        }
        country {
          id
          name
        }
        job_skills {
          id
          name
        }
        job_company {
          id
          name
          size_from
          size_to
        }
        job_skills {
          id
          name
        }
        categories {
          id
          name
        }
        tags {
          id
          name
        }
        job_selection_phase {
          id
          created_at
          updated_at
          deleted_at
          job_id
          phase_number
          master_job_selection_phase_id
          required_application_document
          master_job_selection_phase {
            id
            created_at
            updated_at
            deleted_at
            phase
            description
          }
        }
        min
        max
        salary_currency
        salary_period
        created_at
        is_applied
      }
    }
  }
`)

/**
 * Upsert Job
 */
export const GqlJobUpsert = graphql(`
  mutation JobUpsert($request: UpsertJobRequest!) {
    upsertJob(request: $request) {
      success
    }
  }
`)

/**
 * Delete Job
 * role: admin
 */
export const GqlJobDelete = graphql(`
  mutation JobDelete($id: Int!) {
    deleteJob(id: $id) {
      success
    }
  }
`)

// #endregion JOB - Master

// #region JOB - Company

/**
 * List Companies
 * role: admin
 */
export const GqlJobCompanyList = graphql(`
  query JobCompanyList($name: String) {
    getJobCompany(name: $name) {
      data {
        id
        name
        jobs {
          id
          country {
            id
            name
          }
          province {
            id
            name
          }
          city {
            id
            name
          }
        }
      }
    }
  }
`)

/**
 * Get Company
 * role: admin
 */
export const GqlJobCompanyDetail = graphql(`
  query JobCompanyDetail($id: Int!) {
    getJobCompanyDetail(id: $id) {
      data {
        id
        created_at
        updated_at
        name
        logo
        size_from
        size_to
      }
    }
  }
`)

/**
 * Delete Job Company
 * role: admin
 */
export const GqlJobCompanyDelete = graphql(`
  mutation JobCompanyDelete($id: Int!) {
    deleteJobCompany(id: $id) {
      success
    }
  }
`)

// #endregion JOB - Company

// #region JOB - Tag

/**
 * List Tags
 * role: admin
 */
export const GqlJobTagList = graphql(`
  query JobTagList($name: String) {
    getJobTag(name: $name) {
      data {
        id
        created_at
        updated_at
        deleted_at
        name
      }
    }
  }
`)

/**
 * Get Job Tag
 * role: admin
 */
export const GqlJobTagDetail = graphql(`
  query JobTagDetail($id: Int!) {
    getJobTagDetail(id: $id) {
      data {
        id
        created_at
        updated_at
        deleted_at
        name
      }
    }
  }
`)

/**
 * Upsert Job Tag
 * role: admin
 */
export const GqlJobTagUpsert = graphql(`
  mutation JobTagUpsert($request: UpsertJobTagRequest!) {
    upsertJobTag(request: $request) {
      success
    }
  }
`)

/**
 * Delete Job Tag
 * role: admin
 */
export const GqlJobTagDelete = graphql(`
  mutation JobTagDelete($id: Int!) {
    deleteJobTag(id: $id) {
      success
    }
  }
`)

// #endregion JOB - Tag

// #region JOB - Skill

/**
 * List Skills
 * role: admin
 */
export const GqlJobSkillList = graphql(`
  query JobSkillList($name: String) {
    getJobSkill(name: $name) {
      data {
        id
        created_at
        updated_at
        deleted_at
        name
      }
    }
  }
`)

/**
 * Get Job Skill
 * role: admin
 */
export const GqlJobSkillDetail = graphql(`
  query JobSkillDetail($id: Int!) {
    getJobSkillDetail(id: $id) {
      data {
        id
        created_at
        updated_at
        deleted_at
        name
      }
    }
  }
`)

/**
 * Upsert Job Skill
 * role: admin
 */
export const GqlJobSkillUpsert = graphql(`
  mutation JobSkillUpsert($request: UpsertJobSkillRequest!) {
    upsertJobSkill(request: $request) {
      success
    }
  }
`)

export const GqlJobSkillDelete = graphql(`
  mutation JobSkillDelete($id: Int!) {
    deleteJobSkill(id: $id) {
      success
    }
  }
`)

// #endregion Job - Skill

// #region JOB - Category

/**
 * List Job Category
 * role: admin
 */
export const GqlJobCategoryList = graphql(`
  query JobCategoryList($name: String) {
    getJobCategory(name: $name) {
      data {
        id
        created_at
        updated_at
        deleted_at
        name
      }
    }
  }
`)

/**
 * Get Job Category
 * role: admin
 */
export const GqlJobCategoryDetail = graphql(`
  query JobCategoryDetail($id: Int!) {
    getJobCategoryDetail(id: $id) {
      data {
        id
        created_at
        updated_at
        deleted_at
        name
      }
    }
  }
`)

/**
 * Upsert Job Category
 * role: admin
 */
export const GqlJobCategoryUpsert = graphql(`
  mutation JobCategoryUpsert($request: UpsertJobCategoryRequest!) {
    upsertJobCategory(request: $request) {
      success
    }
  }
`)

/**
 * Delete Job Category
 * role: admin
 */
export const GqlJobCategoryDelete = graphql(`
  mutation JobCategoryDelete($id: Int!) {
    deleteJobCategory(id: $id) {
      success
    }
  }
`)

// #endregion JOB - Category

// #region JOB - Master Phase

export const GqlJobMasterPhaseList = graphql(`
  query JobMasterPhaseList {
    getMasterJobSelectionPhase {
      data {
        id
        created_at
        updated_at
        phase
        description
      }
    }
  }
`)

export const GqlJobMasterPhaseDetail = graphql(`
  query JobMasterPhaseDetail($id: Int!) {
    getMasterJobSelectionPhaseDetail(id: $id) {
      data {
        id
        created_at
        updated_at
        phase
        description
      }
    }
  }
`)

export const GqlJobMasterPhaseUpsert = graphql(`
  mutation JobMasterPhaseUpsert(
    $request: UpsertMasterJobSelectionPhaseRequest!
  ) {
    upsertMasterJobSelectionPhase(request: $request) {
      success
    }
  }
`)

export const GqlJobMasterPhaseDelete = graphql(`
  mutation JobMasterPhaseDelete($id: Int!) {
    deleteMasterJobSelectionPhase(id: $id) {
      success
    }
  }
`)

/**
 * Delete Job Master Phase
 * role: admin
 */

/**
 * Upsert Job Master Phase
 * role: admin
 */

// #endregion JOB - Master Phase

//Apply Job
export const GqlAppyJob = graphql(`
  mutation ApplyJob($request: ApplyJobRequest!) {
    applyJob(request: $request) {
      success
    }
  }
`)

//GET USER JOB
export const GqlGetJobUser = graphql(`
  query GetJobUser($request: SearchUserParticipantRequest!) {
    getJobUser(search: $request) {
      data {
        id
        created_at
        updated_at
        deleted_at
        user_id
        job_id
        user {
          id
          name
          email
          handphone_number
          role
          is_verified
          user_personal_info {
            id
            user_id
            name
            address
            birthdate
            country_id
            province_id
            city_id
            gender
            is_single_child
            marital_status
            religion
            nationality_id
            driver_license
            blood_type
            height
            weight
            is_ever_been_going_abroad
            is_having_passport
            is_ever_proposing_passport
            photo
            created_at
            country {
              id
              name
            }
            province {
              id
              name
            }
            city {
              id
              name
            }
            nationality {
              id
              name
            }
          }
          user_education {
            id
            user_id
            school
            level
            major
            start_date
            graduation_date
            created_at
          }
          user_experience {
            id
            user_id
            title
            company_name
            employment_type
            start_date
            end_date
            location
            location_type
            created_at
          }
          user_status_profile {
            id
            user_id
            status
            reason
            approver_id
            created_at
          }
          user_language {
                id
                user_id
                language
                language_level
                url
                created_at
                start_date
                end_date
            }
        }
        job {
          id
          created_at
          updated_at
          deleted_at
          job_company_id
          salary_currency
          salary_period
          description
          type
          location_city_id
          location_province_id
          location_country_id
          location_type
          title
          job_skills {
            id
            created_at
            updated_at
            deleted_at
            name
          }
          tags {
            id
            created_at
            updated_at
            deleted_at
            name
          }
          job_company {
            id
            created_at
            updated_at
            deleted_at
            name
            logo
            size_from
            size_to
          }
          min
          max
        }
        job_user_selection_phase {
          id
          created_at
          updated_at
          deleted_at
          job_user_id
          status
          url
          score
          coderbyte_metadata {
            test_id
            public_url
          }
          job_selection_phase_id
          job_selection_phase {
            id
            created_at
            updated_at
            deleted_at
            job_id
            phase_number
            master_job_selection_phase_id
            required_application_document
            master_job_selection_phase {
              id
              created_at
              updated_at
              deleted_at
              phase
              description
              ai_cv_recommender
              hrd_room
            }
          }
        }
        last_job_user_selection_phase {
          id
          created_at
          updated_at
          deleted_at
          job_user_id
          job_selection_phase_id
          status
          url
          score
          ai_recommender {
            is_recommended_by_ai
            ai_score
            ai_notes
          }
          job_selection_phase {
            master_job_selection_phase {
              id
              created_at
              updated_at
              deleted_at
              phase
              description
              ai_cv_recommender
              hrd_room
            }
          }
        }
      }
    }
  }
`)

export const GqlGetJobUserDetail = graphql(`
  query GetJobUserDetail($id: Int!) {
    getJobUserDetail(id: $id) {
      data {
        id
        created_at
        updated_at
        deleted_at
        user_id
        job_id
        user {
          id
          name
          email
          handphone_number
          role
          is_verified
          user_personal_info {
            id
            user_id
            name
            address
            birthdate
            country_id
            province_id
            city_id
            gender
            is_single_child
            marital_status
            religion
            nationality_id
            driver_license
            blood_type
            height
            weight
            is_ever_been_going_abroad
            is_having_passport
            is_ever_proposing_passport
            photo
            created_at
            country {
              id
              name
            }
            province {
              id
              name
            }
            city {
              id
              name
            }
            nationality {
              id
              name
            }
          }
          user_education {
            id
            user_id
            school
            level
            major
            start_date
            graduation_date
            created_at
          }
          user_experience {
            id
            user_id
            title
            company_name
            employment_type
            start_date
            end_date
            location
            location_type
            created_at
          }
          user_health {
            id
            user_id
            status
            name
            year
            explaination
            created_at
          }
          user_family {
            id
            user_id
            name
            relation
            birthdate
            country_id
            province_id
            city_id
            religion
            nationality_id
            phone_number
            created_at
            country {
              id
              name
            }
            province {
              id
              name
            }
            city {
              id
              name
            }
            nationality {
              id
              name
            }
          }
          user_attachment {
            id
            user_id
            attachment_type
            url
            created_at
          }
          user_language {
            id
            user_id
            language
            language_level
            url
            created_at
          }
          user_status_profile {
            id
            user_id
            status
            reason
            approver_id
            created_at
          }
        }
        job {
          id
          created_at
          updated_at
          deleted_at
          title
          job_company_id
          min
          max
          salary_currency
          salary_period
          description
          type
          location_city_id
          location_province_id
          location_country_id
          location_type
          job_skills {
            id
            created_at
            updated_at
            deleted_at
            name
          }
          tags {
            id
            created_at
            updated_at
            deleted_at
            name
          }
          categories {
            id
            created_at
            updated_at
            deleted_at
            name
          }
          job_company {
            id
            created_at
            updated_at
            deleted_at
            name
            logo
            size_from
            size_to
          }
          country {
            id
            name
          }
          province {
            id
            name
          }
          city {
            id
            name
          }
        }
        job_user_selection_phase {
          id
          created_at
          updated_at
          deleted_at
          job_user_id
          status
          url
          score
          coderbyte_metadata {
            test_id
            public_url
          }
          job_selection_phase_id
          job_selection_phase {
            id
            created_at
            updated_at
            deleted_at
            job_id
            phase_number
            master_job_selection_phase_id
            required_application_document
            master_job_selection_phase {
              id
              created_at
              updated_at
              deleted_at
              phase
              description
              ai_cv_recommender
              hrd_room
            }
          }
          ai_recommender {
            is_recommended_by_ai
            ai_score
            ai_notes
          }
          job_user_selection_phase_hrdroom {
            id
            created_at
            updated_at
            deleted_at
            job_user_selection_phase_id
            module_id
            test_token
            expired_at
          }
        }
        last_job_user_selection_phase {
          id
          created_at
          updated_at
          deleted_at
          job_user_id
          job_selection_phase_id
          status
          url
          score
          ai_recommender {
            is_recommended_by_ai
            ai_score
            ai_notes
          }
          job_selection_phase {
            id
            created_at
            updated_at
            deleted_at
            job_id
            phase_number
            master_job_selection_phase_id
            required_application_document
            master_job_selection_phase {
              id
              created_at
              updated_at
              deleted_at
              phase
              description
              ai_cv_recommender
              hrd_room
            }
          }
          job_user_selection_phase_hrdroom {
            id
            created_at
            updated_at
            deleted_at
            job_user_selection_phase_id
            module_id
            test_token
            expired_at
          }
        }
      }
    }
  }
`)

export const GqlGetStatusJobVacancy = graphql(`
  query GetStatusJobVacancyUser {
    getStatusJobVacancyUser {
      data {
        id
        created_at
        updated_at
        deleted_at
        user_id
        job_id
        user {
          id
          name
          email
          handphone_number
          role
          is_verified
          user_personal_info {
            id
            user_id
            name
            address
            birthdate
            country_id
            province_id
            city_id
            gender
            is_single_child
            marital_status
            religion
            nationality_id
            driver_license
            blood_type
            height
            weight
            is_ever_been_going_abroad
            is_having_passport
            is_ever_proposing_passport
            photo
            created_at
          }
        }
        job {
          id
          created_at
          updated_at
          deleted_at
          title
          job_company_id
          min
          max
          salary_currency
          salary_period
          description
          type
          location_city_id
          location_province_id
          location_country_id
          location_type
          job_company {
            id
            created_at
            updated_at
            deleted_at
            name
            logo
            size_from
            size_to
          }
        }
        job_user_selection_phase {
          id
          created_at
          updated_at
          deleted_at
          job_user_id
          job_selection_phase_id
          status
          url
          score
          coderbyte_metadata {
            test_id
            public_url
          }
          job_selection_phase {
            id
            created_at
            updated_at
            deleted_at
            job_id
            phase_number
            master_job_selection_phase_id
            required_application_document
            master_job_selection_phase {
              id
              created_at
              updated_at
              deleted_at
              phase
              description
            }
          }
          job_user_selection_phase_hrdroom {
            id
            created_at
            updated_at
            deleted_at
            job_user_selection_phase_id
            module_id
            test_token
            expired_at
            url
          }
        }
      }
    }
  }
`)

//CHANGE STATUS PHASE JOB USER
export const GqlChangeStatusJobUserSelection = graphql(`
  mutation ChangeStatusJobUserSelection(
    $request: changeStatusJobUserSelectionRequest!
  ) {
    changeStatusJobUserSelection(request: $request) {
      success
    }
  }
`)

// HRD Room
export const GqlHRDRoomGetModules = graphql(`
  query HrdroomGetModules {
    hrdroomGetModules {
      data {
        id
        name
        published
        created_at
        updated_at
      }
    }
  }
`)

export const GqlHRDRoomGetTests = graphql(`
  query HrdroomGetTests($module_id: Int!) {
    hrdroomGetTests(module_id: $module_id) {
      data {
        id
        name
        description
        quantity
        module_id
        module_name
        start_from
        expired_at
        created_at
        updated_at
        created_by
        status
        campaign
        geo_location
      }
    }
  }
`)

export const GqlSentHRDRoomTestToken = graphql(`
  mutation SentHRDRoomTestToken($request: SentHRDRoomTestTokenRequest!) {
    sentHRDRoomTestToken(request: $request) {
      success
    }
  }
`)

export const GqlGetJobUserSelectionPhaseHrdRoom = graphql(`
  query GetJobUserSelectionPhaseHRDRoom($id: Int!) {
    getJobUserSelectionPhaseHRDRoom(id: $id) {
      data {
        id
        created_at
        updated_at
        deleted_at
        module_id
        test_token
        expired_at
        url
        job_user_selection_phase_id
        module {
          id
          name
          published
          created_at
          updated_at
        }
        activation_code {
          id
          name
          description
          quantity
          module_id
          module_name
          start_from
          expired_at
          created_at
          updated_at
          created_by
          status
          campaign
          geo_location
        }
        test {
          token
          status
          score
          selection_status
          name
        }
      }
    }
  }
`)

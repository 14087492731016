import { createFileRoute, Outlet } from '@tanstack/react-router'

import { MainLayout } from '@/components/layout/main-layout'
import { SiteFooterAuth } from '@/components/layout/site-footer'
import { SiteHeader } from '@/components/layout/site-header'

export const Route = createFileRoute('/auth')({
  component: Component,
})

function Component() {
  return (
    <MainLayout
      header={<SiteHeader typeNav="auth" />}
      footer={<SiteFooterAuth />}
      bgPattern
    >
      <Outlet />
    </MainLayout>
  )
}

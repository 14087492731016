import { createFileRoute, useRouter } from '@tanstack/react-router'

import { useAuth } from '@/services/auth/use-auth'

import { Button } from '@/components/ui/button'

import { AuthCard } from './-components/auth-card'
import { AuthContainer } from './-components/auth-container'

export const Route = createFileRoute('/auth/verification/success')({
  component: Component,
})

function Component() {
  const router = useRouter()
  const navigate = Route.useNavigate()
  const revalidateSession = useAuth((s) => s.revalidateSession)

  const onSignin = async () => {
    await revalidateSession()
    router.invalidate()
    navigate({ to: '/dashboard' })
  }

  return (
    <AuthContainer>
      <AuthCard>
        <AuthCard.Content>
          <div className="flex flex-col items-center justify-center gap-8">
            <div className="relative" style={{ maxWidth: '320px' }}>
              <img
                className="pr-8"
                src="/img/verified.png"
                style={{ width: '300px', height: '252px' }}
              />
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-4 text-center">
              <p className="font-tomorrow text-2xl font-bold">
                Selamat bergabung di Yes Academy!
              </p>
              <p>Akun telah terverifikasi, silakan login</p>
              <Button
                className="mt-4 w-full rounded-xl bg-gradient-to-r from-[#D91F2E] to-[#AE000E]"
                onClick={onSignin}
              >
                Masuk
              </Button>
            </div>
          </div>
        </AuthCard.Content>
      </AuthCard>
    </AuthContainer>
  )
}

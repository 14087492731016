import { createFileRoute, redirect } from '@tanstack/react-router'
import { z } from 'zod'

const searchParams = z.object({
  code: z.string().nullish(),
})

export const Route = createFileRoute('/invite')({
  validateSearch: searchParams,
  loaderDeps(opts) {
    return opts.search
  },
  loader({ deps }) {
    throw redirect({
      to: '/auth/register',
      search: { code: deps.code || undefined },
    })
  },
  component: RouteComponent,
})

function RouteComponent() {
  return <div>Hello "/invite"!</div>
}

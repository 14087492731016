import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { gqlFetch } from '@/services/graphql/fetcher'
import { GqlJobUpsert } from '@/services/job/gql'
import { queryJobDetail } from '@/services/job/query'

import { AdminPage } from '@/components/layout/admin/page'
import { Form } from '@/components/ui/form'

import { invalidateQueryKeys } from '@/lib/utils/utils'

import { JobForm } from './-component/form'
import { FormDto, getDefaultValues, toPayload } from './-component/helper'

export const Route = createFileRoute('/admin-v2/job/vacancy/$id/edit')({
  loader: async ({ context, params }) => {
    const { queryClient } = context

    const job = await queryClient.fetchQuery(queryJobDetail(Number(params.id)))
    if (!job) throw new Error('Job not found')

    return {
      job,
    }
  },
  component: RouteComponent,
})

function RouteComponent() {
  const { job } = Route.useLoaderData()
  const navigate = Route.useNavigate()
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: getDefaultValues(job),
  })

  const { mutateAsync } = useMutation({
    mutationFn: async (data: FormDto) => {

      await gqlFetch({
        query: GqlJobUpsert,
        variables: {
          request: toPayload(data, {
            id: job.id,
            created_at: job.created_at,
          }),
        },
      })
    },
    onSuccess: () => {
      invalidateQueryKeys(['job-list', 'job-detail'])
      navigate({ to: '/admin-v2/job/vacancy' })
    },
  })

  const onSubmit = form.handleSubmit((data) => {
    toast.promise(mutateAsync(data), {
      loading: 'Menyimpan data job',
      success: 'Berhasil menyimpan',
      error: 'Gagal menyimpan',
    })
  }, console.error)

  return (
    <Form {...form}>
      <AdminPage className="overflow-y-auto">
        <AdminPage.Breadcrumb
          items={[
            ['Master Job Listing', '/admin-v2/job/vacancy'],
            [job.title, '/admin-v2/job/vacancy/$id'],
            ['Edit', '/admin-v2/job/vacancy/$id/edit'],
          ]}
        />
        <AdminPage.Header title="Edit Job Listing" />
        <form onSubmit={onSubmit}>
          <JobForm />
        </form>
      </AdminPage>
    </Form>
  )
}

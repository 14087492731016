import * as React from 'react'

import { HomeIcon } from '@radix-ui/react-icons'
import { Slot } from '@radix-ui/react-slot'
import { Link, LinkProps } from '@tanstack/react-router'
import { MoreHorizontal, Slash } from 'lucide-react'

import { cn } from '@/lib/cn'

const Breadcrumb = React.forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<'nav'> & {
    separator?: React.ReactNode
  }
>(({ ...props }, ref) => <nav ref={ref} aria-label="breadcrumb" {...props} />)
Breadcrumb.displayName = 'Breadcrumb'

const BreadcrumbList = React.forwardRef<
  HTMLOListElement,
  React.ComponentPropsWithoutRef<'ol'>
>(({ className, ...props }, ref) => (
  <ol
    ref={ref}
    className={cn(
      'flex flex-wrap items-center gap-1.5 break-words text-sm text-muted-foreground sm:gap-2.5',
      className
    )}
    {...props}
  />
))
BreadcrumbList.displayName = 'BreadcrumbList'

const BreadcrumbItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentPropsWithoutRef<'li'>
>(({ className, ...props }, ref) => (
  <li
    ref={ref}
    className={cn('inline-flex items-center gap-1.5', className)}
    {...props}
  />
))
BreadcrumbItem.displayName = 'BreadcrumbItem'

const BreadcrumbLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<'a'> & {
    asChild?: boolean
  }
>(({ asChild, className, ...props }, ref) => {
  const Comp = asChild ? Slot : 'a'

  return (
    <Comp
      ref={ref}
      className={cn('transition-colors hover:text-foreground', className)}
      {...props}
    />
  )
})
BreadcrumbLink.displayName = 'BreadcrumbLink'

const BreadcrumbPage = React.forwardRef<
  HTMLSpanElement,
  React.ComponentPropsWithoutRef<'span'>
>(({ className, ...props }, ref) => (
  <span
    ref={ref}
    role="link"
    aria-disabled="true"
    aria-current="page"
    className={cn('font-normal text-foreground', className)}
    {...props}
  />
))
BreadcrumbPage.displayName = 'BreadcrumbPage'

const BreadcrumbSeparator = ({
  children,
  className,
  ...props
}: React.ComponentProps<'li'>) => (
  <li
    role="presentation"
    aria-hidden="true"
    className={cn('[&>svg]:h-3.5 [&>svg]:w-3.5', className)}
    {...props}
  >
    {children ?? <Slash />}
  </li>
)
BreadcrumbSeparator.displayName = 'BreadcrumbSeparator'

const BreadcrumbEllipsis = ({
  className,
  ...props
}: React.ComponentProps<'span'>) => (
  <span
    role="presentation"
    aria-hidden="true"
    className={cn('flex h-9 w-9 items-center justify-center', className)}
    {...props}
  >
    <MoreHorizontal className="h-4 w-4" />
    <span className="sr-only">More</span>
  </span>
)
BreadcrumbEllipsis.displayName = 'BreadcrumbElipssis'

interface BreadcrumbTemplateProps {
  items?: Array<[label: string | React.ReactNode, to: LinkProps['to']]>
  home?: '/affiliate'
}

function BreadcrumbTemplate({ items = [], home }: BreadcrumbTemplateProps) {
  const renderItems = React.useMemo(() => {
    const rendered: React.ReactNode[] = []
    if (home) {
      rendered.push(
        <BreadcrumbItem key="home">
          <BreadcrumbLink asChild>
            <Link to={home}>
              <HomeIcon />
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )
    }

    const len = items.length
    items.forEach(([label, to], index) => {
      const isLast = index === len - 1
      rendered.push(<BreadcrumbSeparator key={`separator-${index}`} />)

      if (!isLast) {
        rendered.push(
          <BreadcrumbItem key={index} className="">
            <BreadcrumbLink
              asChild
              className="bg-transparent px-1 py-0.5 text-xs font-semibold hover:bg-yes-200 hover:text-yes-600"
            >
              <Link to={to}>{label}</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
        )
      } else {
        rendered.push(
          <BreadcrumbItem key={index}>
            <BreadcrumbPage className="rounded-md bg-yes-200 px-1 py-0.5 text-xs font-semibold text-yes-600">
              {label}
            </BreadcrumbPage>
          </BreadcrumbItem>
        )
      }
    })

    return rendered
  }, [items, home])

  return (
    <Breadcrumb>
      <BreadcrumbList>{renderItems}</BreadcrumbList>
    </Breadcrumb>
  )
}

export {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
  BreadcrumbEllipsis,
  BreadcrumbTemplate,
}

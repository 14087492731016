import { zodResolver } from '@hookform/resolvers/zod'
import { PlusIcon } from '@radix-ui/react-icons'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Trash2Icon } from 'lucide-react'
import { useFieldArray, useForm, useFormContext } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import {
  EmploymentType,
  GetUserExperienceQuery,
  LocationType,
  UpsertUserExperienceRequest,
} from '@/_gql/graphql'

import { gqlFetch } from '@/services/graphql/fetcher'
import {
  EMPLOYMENT_STATUS_OPTIONS,
  LOCATION_TYPE_OPTIONS,
} from '@/services/user/constant'
import { GqlUserExperienceUpsert } from '@/services/user/gql'
import {
  queryUserExperience,
  queryUserSubmissionProgress,
} from '@/services/user/query'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldDatePicker } from '@/components/ui/form-field/date-picker'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { FormFieldSelect } from '@/components/ui/form-field/select'
import { Input } from '@/components/ui/input'

import { formatDateOnly, formatDateTimeServer } from '@/lib/utils/utils'

import { queryClient } from '@/lib/configs/tanstack-query'
import { CardProfile } from './-components/card-profile'

export const Route = createFileRoute('/_user/digishoku/profile/experience')({
  loader: async ({ context: { queryClient: qc } }) => {
    const experience = await qc.fetchQuery(queryUserExperience())
    return {
      experience,
      refetch: async () => {
        await qc.refetchQueries(queryUserExperience())
      },
    }
  },
  component: Component,
})

function Component() {
  const { experience, refetch } = Route.useLoaderData()
  const navigate = Route.useNavigate()
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: getDefaultValues(experience),
  })

  const { mutateAsync } = useMutation({
    mutationFn: async (data: FormDto) => {
      await gqlFetch({
        query: GqlUserExperienceUpsert,
        variables: { request: { request: formDtoToPayload(data) } },
      })
      await refetch()
      await queryClient.invalidateQueries(queryUserSubmissionProgress())
      navigate({ to: '/profile/attachment' })
    },
  })

  const experiencesFields = useFieldArray({
    control: form.control,
    name: 'experiences',
    keyName: 'kId',
  })

  const showRemove = experiencesFields.fields.length > 1

  const handleOnSubmit = form.handleSubmit((data) => {
    toast.promise(mutateAsync(data), {
      loading: 'Simpan informasi',
      success: 'Informasi berhasil disimpan',
      error: 'Terjadi kesalahan saat menyimpan informasi',
    })
  }, console.error)

  return (
    <Form {...form}>
      <CardProfile title="Riwayat pekerjaan">
        <form onSubmit={handleOnSubmit}>
          <CardProfile.Content className="flex flex-col gap-4">
            {experiencesFields.fields.map((field, idx) => {
              return (
                <ExperienceFormItem
                  key={field.kId}
                  idx={idx}
                  showRemove={showRemove}
                  onRemove={() => {
                    experiencesFields.remove(idx)
                  }}
                />
              )
            })}
          </CardProfile.Content>

          <CardProfile.Footer>
            <Button
              className="w-full rounded-xl border-primary-yes text-primary-yes md:w-auto"
              variant="outline"
              onClick={() => {
                experiencesFields.append(defaultItem())
              }}
            >
              <PlusIcon className="mr-2" />
              Tambah Pekerjaan
            </Button>
            <CardProfile.SubmissionButton />
          </CardProfile.Footer>
        </form>
      </CardProfile>
    </Form>
  )
}

interface ExperienceFormItemProps {
  idx: number
  showRemove: boolean
  onRemove?: () => void
}

function ExperienceFormItem(props: ExperienceFormItemProps) {
  const { idx, showRemove, onRemove } = props
  const form = useFormContext<FormDto>()

  return (
    <div className="relative grid grid-cols-2 gap-x-2 gap-y-6 rounded-md border border-border p-4 py-6 md:grid-cols-6">
      {showRemove && (
        <div className="absolute right-0 top-0">
          <Button variant="ghost" size="icon" onClick={onRemove} type="button">
            <Trash2Icon className="size-16 text-primary-yes" />
          </Button>
        </div>
      )}

      <FormField
        control={form.control}
        name={`experiences.${idx}.title`}
        render={({ field }) => (
          <FormFieldset label="Jabatan" className="col-span-3">
            <Input placeholder="Masukkan jabatan" {...field} />
          </FormFieldset>
        )}
      />

      <FormField
        control={form.control}
        name={`experiences.${idx}.company_name`}
        render={({ field }) => (
          <FormFieldset label="Nama perusahaan" className="col-span-3">
            <Input placeholder="Masukkan Nama perusahaan" {...field} />
          </FormFieldset>
        )}
      />

      <FormField
        control={form.control}
        name={`experiences.${idx}.employment_type`}
        render={({ field }) => (
          <FormFieldset label="Status karyawan" className="col-span-2">
            <FormFieldSelect
              placeholder="Pilih status karyawan"
              items={EMPLOYMENT_STATUS_OPTIONS}
              value={field.value}
              onValueChange={field.onChange}
            />
          </FormFieldset>
        )}
      />

      <div className="col-span-2 space-y-2">
        <FormField
          control={form.control}
          name={`experiences.${idx}.start_date`}
          render={({ field }) => (
            <FormFieldset label="Tanggal mulai" noFormControl>
              <FormFieldDatePicker
                date={field.value}
                onSelect={field.onChange}
              />
            </FormFieldset>
          )}
        />
        <div className="flex items-center space-x-2">
          <Checkbox
            id="until-now"
            checked={form.watch(`experiences.${idx}.end_date`) === null}
            onCheckedChange={(checked) => {
              form.setValue(
                `experiences.${idx}.end_date`,
                checked ? null : undefined!,
              )
            }}
          />
          <label
            htmlFor="until-now"
            className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Saya masih bekerja di tempat ini
          </label>
        </div>
      </div>

      <FormField
        control={form.control}
        name={`experiences.${idx}.end_date`}
        render={({ field }) => (
          <FormFieldset
            label="Tanggal berakhir"
            className="col-span-2"
            noFormControl
          >
            <FormFieldDatePicker
              date={field.value ? field.value : undefined}
              onSelect={field.onChange}
              disabled={form.watch(`experiences.${idx}.end_date`) === null}
            />
          </FormFieldset>
        )}
      />

      <FormField
        control={form.control}
        name={`experiences.${idx}.location`}
        render={({ field }) => (
          <FormFieldset label="Lokasi pekerjaan" className="col-span-3">
            <Input placeholder="Masukkan Lokasi pekerjaan" {...field} />
          </FormFieldset>
        )}
      />

      <FormField
        control={form.control}
        name={`experiences.${idx}.location_type`}
        render={({ field }) => (
          <FormFieldset label="Tipe lokasi" className="col-span-3">
            <FormFieldSelect
              placeholder="Pilih tipe lokasi"
              items={LOCATION_TYPE_OPTIONS}
              value={field.value}
              onValueChange={field.onChange}
            />
          </FormFieldset>
        )}
      />
    </div>
  )
}

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z.object({
  experiences: z.array(
    z.object({
      id: z.coerce.number().optional(),
      title: z.string().min(1),
      company_name: z.string().min(1),
      employment_type: z.nativeEnum(EmploymentType),
      start_date: z.coerce.date(),
      end_date: z.coerce.date().nullable(),
      location: z.string().min(1),
      location_type: z.nativeEnum(LocationType),
      created_at: z.string().optional(),
    }),
  ),
})

type ExperienceData = GetUserExperienceQuery['getUserExperience']['data'] | null
function getDefaultValues(initial?: ExperienceData): FormDto {
  const data: FormDto = {
    experiences: (initial ?? []).map((x) => ({
      ...x,
      employment_type: x.employment_type.toUpperCase() as EmploymentType,
      start_date: x.start_date ? new Date(x.start_date) : undefined!,
      end_date: x.end_date ? new Date(x.end_date) : null,
      location_type: x.location_type.toUpperCase() as LocationType,
      created_at: x.created_at || undefined!,
    })),
  }

  data.experiences.length === 0 && data.experiences.push(defaultItem())
  return data
}

function defaultItem(): FormDto['experiences'][number] {
  return {
    id: undefined,
    title: '',
    company_name: '',
    employment_type: undefined!,
    start_date: undefined!,
    end_date: undefined!,
    location: '',
    location_type: undefined!,
    created_at: undefined!,
  }
}

function formDtoToPayload(
  data: FormDto,
): UpsertUserExperienceRequest['request'] {
  return data.experiences.map((x) => ({
    id: x.id,
    title: x.title,
    company_name: x.company_name,
    employment_type: x.employment_type,
    start_date: formatDateOnly(x.start_date),
    end_date: x.end_date ? formatDateOnly(x.end_date) : null,
    location: x.location,
    location_type: x.location_type,
    created_at: x.created_at ? formatDateTimeServer(x.created_at) : undefined,
  }))
}

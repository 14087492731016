import { useMemo, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { PlusIcon } from '@radix-ui/react-icons'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute, Link } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import { zodValidator } from '@tanstack/zod-adapter'
import { format } from 'date-fns'
import { Check, Eye, ListFilter, X } from 'lucide-react'
import { DateRange } from 'react-day-picker'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { JobUser, SearchUserParticipantRequest } from '@/_gql/graphql'

import { queryGetJobUser, queryJobCompanyList } from '@/services/job/query'
import { AI_RECOMENDATION_OPTIONS } from '@/services/user/constant'

import { AdminPage } from '@/components/layout/admin/page'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { DataTable } from '@/components/ui/data-table/data-table'
import { useDataTable } from '@/components/ui/data-table/use-data-table'
import { DatePickerRange } from '@/components/ui/date-picker-range'
import { DebouncedInput } from '@/components/ui/debounce-input'
import { Form, FormField } from '@/components/ui/form'
import { DatePickerWithRange } from '@/components/ui/form-field/date-range-picker'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { FormFieldSelect } from '@/components/ui/form-field/select'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Skeleton } from '@/components/ui/skeleton'

import { fuzzyFilterFn } from '@/lib/utils/datatable'

import { useFilters } from '@/hooks/use-filter'

const searchParamsSchema = z.object({
  job_id: z.string().nullish(),
  start_apply_date: z.string().nullish(),
  end_apply_date: z.string().nullish(),
  is_recommended_by_ai: z.boolean().nullish(),
})

export const Route = createFileRoute('/admin-v2/job/applicant/')({
  validateSearch: zodValidator(searchParamsSchema),
  component: RouteComponent,
})

function RouteComponent() {
  const [globalFilter, setGlobalFilter] = useState('')
  const search = Route.useSearch()

  const request: SearchUserParticipantRequest = {
    company_id: Number(search.job_id),
    start_apply_date: search.start_apply_date,
    end_apply_date: search.end_apply_date,
    is_recommended_by_ai: search.is_recommended_by_ai,
    search: '',
  }

  const { data, isLoading, refetch } = useQuery(queryGetJobUser(request))

  return (
    <AdminPage>
      <AdminPage.Breadcrumb
        items={[
          ['Master Job Listing', '/admin-v2/job/vacancy'],
          ['List Pelamar', '/admin-v2/job/applicant'],
        ]}
      />
      <AdminPage.Header
        title="List Pelamar"
        desc={
          isLoading ? (
            <Skeleton className="h-5 w-32" />
          ) : (
            `Jumlah pelamar: ${data?.length || 0} list`
          )
        }
      />

      {/* TODO */}
      {/* @ts-expect-error */}
      <ApplicantListSection data={data || []} />
    </AdminPage>
  )
}

interface ApplicantListSectionProps {
  data?: JobUser[] | undefined
}

function ApplicantListSection({ data }: ApplicantListSectionProps) {
  const { filters, setFilters, resetFilters } = useFilters(
    '/admin-v2/job/applicant/'
  )
  const [openFilter, setOpenFilter] = useState(false)

  const [globalFilter, setGlobalFilter] = useState('')

  const columns = useMemo(getColumns, [])

  const { table } = useDataTable({
    data: data || [],
    columns,
    manualPagination: false,
    globalFilterFn: 'fuzzy',
    onGlobalFilterChange: setGlobalFilter,
    state: { globalFilter },
  })

  return (
    <Card>
      <CardContent className="overflow-auto p-4">
        <div className="mb-4 flex justify-between gap-4">
          <div className="flex w-full gap-6">
            <div className="w-1/2">
              <DebouncedInput
                className="lg:w-full"
                value={globalFilter ?? ''}
                onChange={(value) => setGlobalFilter(String(value))}
                placeholder="Cari .."
              />
            </div>
            <div className="w-1/2">
              <FilterCompany
                defaultValue={filters.job_id}
                setFilter={setFilters}
              />
            </div>
          </div>
          <div>
            <FilterTable
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              resetFilter={resetFilters}
              setFilter={setFilters}
              defaultValue={{
                from: new Date(filters.start_apply_date ?? ''),
                to: new Date(filters.end_apply_date ?? ''),
              }}
            />
          </div>
        </div>
        <DataTable table={table} />
      </CardContent>
    </Card>
  )
}

const column = createColumnHelper<any>()

function getColumns() {
  return [
    column.accessor('id', {
      header: 'No',
    }),
    column.accessor('job.title', {
      header: 'Job Listing',
    }),
    column.accessor('job.job_company.name', {
      header: 'Perusahaan',
    }),
    column.accessor('user.name', {
      header: 'Pelamar',
    }),
    column.accessor('job_user_selection_phase[0].created_at', {
      header: 'Tanggal Apply',
      cell: (cell) => {
        const { job_user_selection_phase } = cell.row.original
        const apply_date = job_user_selection_phase[0]?.created_at
        const date = apply_date ? format(apply_date, 'dd LLL yyyy') : ''

        return <div>{date}</div>
      },
    }),
    column.accessor('test_result', {
      header: 'Hasil Rekomendasi',
      cell: (cell) => {
        const data = cell.row.original
        const last_job_user_selection_phase =
          data?.last_job_user_selection_phase
        const result =
          last_job_user_selection_phase?.ai_recommender?.is_recommended_by_ai
        const ai_cv_recommender =
          last_job_user_selection_phase?.job_selection_phase
            ?.master_job_selection_phase?.ai_cv_recommender

        if (
          last_job_user_selection_phase?.ai_recommender &&
          result &&
          ai_cv_recommender
        ) {
          return <div className="text-[#34C759]">Direkomendasikan</div>
        } else if (
          last_job_user_selection_phase?.ai_recommender &&
          !result &&
          ai_cv_recommender
        ) {
          return <div className="text-[#FF3B30]">Gagal</div>
        } else {
          return <div>Tidak memakai AI untuk rekomendasi</div>
        }
      },
    }),
    column.display({
      id: 'actions',
      header: 'Aksi',
      cell: ({ cell }) => {
        const { id, title } = cell.row.original
        return (
          <div className="flex gap-1.5">
            {/* <Button
              size="icon"
              className="size-8 text-primary-yes"
              variant="ghost"
            >
              <Check />
            </Button>
            <Button
              size="icon"
              className="size-8 text-primary-yes"
              variant="ghost"
            >
              <X />
            </Button> */}
            <Link to={`/admin-v2/job/applicant/${id}`}>
              <Button
                size="icon"
                className="size-8 text-primary-yes"
                variant="ghost"
              >
                <Eye />
              </Button>
            </Link>
          </div>
        )
      },
    }),
  ]
}

enum TestResult {
  RECOMENDED = 'RECOMENDED',
  FAILED = 'FAILED',
}

interface FilterCompanyProps {
  defaultValue?: string | undefined | null
  setFilter: (param: any) => void
}

function FilterCompany({ defaultValue, setFilter }: FilterCompanyProps) {
  const { data, isLoading, refetch } = useQuery(queryJobCompanyList())

  return (
    <>
      <div>
        <Select
          value={defaultValue ?? ''}
          onValueChange={(value) => {
            setFilter({ job_id: value })
          }}
        >
          <SelectTrigger className="h-10">
            <SelectValue placeholder={'Pilih Perusahaan'} />
          </SelectTrigger>
          <SelectContent side="top">
            {data?.map((company, _) => (
              <SelectItem key={_} value={`${company.id}`}>
                {company.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </>
  )
}

interface FilterDto extends z.infer<typeof FilterDto> { }

const FilterDto = z.object({
  apply_date: z.string().optional(),
  is_recommended_by_ai: z.string().optional(),
})

interface FilterTableProps {
  defaultValue?: DateRange | undefined
  setFilter: (param: any) => void
  openFilter: boolean
  setOpenFilter: (state: boolean) => void
  resetFilter: () => void
}
function FilterTable(props: FilterTableProps) {
  const { openFilter, setOpenFilter, setFilter, defaultValue, resetFilter } =
    props
  const [dateRange, setDateRange] = useState<DateRange | undefined>()

  const form = useForm<FilterDto>({
    resolver: zodResolver(FilterDto),
  })

  const handleOnSubmit = form.handleSubmit((data) => {
    setFilter({
      start_apply_date: dateRange?.from
        ? format(dateRange?.from ?? '', 'yyyy-MM-dd')
        : null,
      end_apply_date: dateRange?.to
        ? format(dateRange?.to ?? '', 'yyyy-MM-dd')
        : null,
      is_recommended_by_ai: Boolean(Number(data.is_recommended_by_ai)),
    })
    setOpenFilter(false)
  })

  const clearFilter = () => {
    form.reset({
      apply_date: '',
      is_recommended_by_ai: '',
    })
    resetFilter()
    setOpenFilter(false)
  }
  return (
    <Popover open={openFilter} onOpenChange={setOpenFilter}>
      <PopoverTrigger asChild>
        <Button variant={'outline_yes'}>
          <ListFilter /> Filter
        </Button>
      </PopoverTrigger>
      <PopoverContent className="mr-12 w-96">
        <Form {...form}>
          <form onSubmit={handleOnSubmit}>
            <div className="relative flex w-full flex-1 flex-col gap-6">
              <FormField
                control={form.control}
                name={`apply_date`}
                render={({ field }) => (
                  <FormFieldset label="Tanggal Apply" className="w-full">
                    <DatePickerRange
                      buttonClassName="w-full"
                      value={dateRange}
                      onChange={(value) => {
                        setDateRange(value)
                      }}
                    />
                  </FormFieldset>
                )}
              />
              <FormField
                control={form.control}
                name={`is_recommended_by_ai`}
                render={({ field }) => (
                  <FormFieldset label="Hasil Rekomendasi" className="w-full">
                    <FormFieldSelect
                      className="w-full"
                      placeholder="Direkomendasikan / Gagal"
                      items={AI_RECOMENDATION_OPTIONS}
                      value={field.value ?? ''}
                      onValueChange={field.onChange}
                    />
                  </FormFieldset>
                )}
              />
            </div>
            <div className="mt-4 flex justify-end gap-4">
              <Button
                type="button"
                variant={'outline_yes'}
                onClick={clearFilter}
              >
                Hapus Filter
              </Button>
              <Button
                type="submit"
                className="bg-primary-yes hover:bg-primary-yes"
              >
                Terapkan Filter
              </Button>
            </div>
          </form>
        </Form>
      </PopoverContent>
    </Popover>
  )
}

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { GqlAuthResetPassword } from '@/services/auth/gql'
import { GraphQLError } from '@/services/graphql/error'
import { gqlFetch } from '@/services/graphql/fetcher'

import { ButtonLoading } from '@/components/ui/button'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { InputPassword } from '@/components/ui/input-password'

import { AuthCard } from './-components/auth-card'
import { AuthContainer } from './-components/auth-container'

export const Route = createFileRoute('/auth/new-password/$token')({
  component: Component,
})

const TOAST_ID = 'NewPassword'

function Component() {
  const router = useRouter()
  const navigate = Route.useNavigate()
  const { token } = Route.useParams()
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: getDefaultValues(),
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = form

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (data: FormDto) => {
      await gqlFetch({
        query: GqlAuthResetPassword,
        variables: {
          request: { token, new_password: data.password },
        },
      })
      router.invalidate()
      navigate({ to: '/auth/login' })
      return 1
    },
    onError: (error) => {
      let msg = error.message
      if (GraphQLError.is(error)) {
        msg = error.getMessage()
      }
      toast.error(msg, { id: TOAST_ID })
    },
    onSuccess: () => {
      toast.success('Password berhasil diubah', { id: TOAST_ID })
    },
  })

  const handleOnSubmit = handleSubmit(async (data) => {
    await mutateAsync(data)
  }, console.error)

  return (
    <AuthContainer className="">
      <Form {...form}>
        <AuthCard className="max-w-screen-sm">
          <form onSubmit={handleOnSubmit}>
            <AuthCard.Header>
              <AuthCard.Title>Buat Password Baru</AuthCard.Title>
              <AuthCard.Subtitle>
                Halo, Selamat datang di YES Academy!
              </AuthCard.Subtitle>
            </AuthCard.Header>
            <AuthCard.Content className="">
              <FormField
                control={control}
                name="password"
                render={({ field }) => (
                  <FormFieldset label="Password Baru">
                    <InputPassword placeholder="Masukkan Password" {...field} />
                  </FormFieldset>
                )}
              />
              <FormField
                control={control}
                name="passwordConfirmation"
                render={({ field }) => (
                  <FormFieldset label="Konfirmasi Password Baru">
                    <InputPassword
                      placeholder="Masukkan ulang password"
                      {...field}
                    />
                  </FormFieldset>
                )}
              />
            </AuthCard.Content>
            <AuthCard.Footer className="flex-col gap-3 pt-3">
              <ButtonLoading
                className="w-full rounded-xl bg-gradient-primary"
                isLoading={isPending}
                type="submit"
              >
                Perbarui Password
              </ButtonLoading>
            </AuthCard.Footer>
          </form>
        </AuthCard>
      </Form>
    </AuthContainer>
  )
}

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z
  .object({
    password: z.string().min(1, 'Password is too short'),
    passwordConfirmation: z.string(),
  })
  .superRefine(({ password, passwordConfirmation }, ctx) => {
    if (password !== passwordConfirmation) {
      ctx.addIssue({
        message: 'Password tidak cocok',
        code: 'custom',
        path: ['password'],
      })
    }
  })

function getDefaultValues(): FormDto {
  return {
    password: '',
    passwordConfirmation: '',
  }
}

import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/admin-v2/')({
  loader: () => {
    throw redirect({ to: '/admin-v2/participant' })
  },
  component: RouteComponent,
})

function RouteComponent() {
  return <div>Hello "/admin-v2/"!</div>
}

import { graphql } from '@/_gql'

export const GqlUserInformationGet = graphql(`
  query GetUserPersonalInfo {
    getUserPersonalInfo {
      data {
        id
        user_id
        name
        address
        birthdate
        country_id
        province_id
        city_id
        gender
        is_single_child
        marital_status
        religion
        nationality_id
        driver_license
        blood_type
        height
        weight
        is_ever_been_going_abroad
        is_having_passport
        is_ever_proposing_passport
        photo
        created_at
        user {
          id
          name
          email
          handphone_number
          role
          is_verified
        }
        country {
          id
          name
        }
        province {
          id
          name
        }
        city {
          id
          name
        }
        nationality {
          id
          name
        }
      }
    }
  }
`)

export const GqlUserEducationUpsert = graphql(`
  mutation UpsertUserEducation($request: UpsertUserEducationRequest!) {
    upsertUserEducation(request: $request) {
      success
    }
  }
`)

export const GqlUserEducation = graphql(`
  query GetUserEducation {
    getUserEducation {
      data {
        id
        school
        level
        major
        start_date
        graduation_date
        created_at
        user_id
      }
    }
  }
`)

export const GqlUserExperienceUpsert = graphql(`
  mutation UpsertUserExperience($request: UpsertUserExperienceRequest!) {
    upsertUserExperience(request: $request) {
      success
    }
  }
`)

export const GqlUserExperience = graphql(`
  query GetUserExperience {
    getUserExperience {
      data {
        id
        user_id
        title
        company_name
        employment_type
        start_date
        end_date
        location
        location_type
        created_at
      }
    }
  }
`)

export const GqlUserHealthUpsert = graphql(`
  mutation UpsertUserHealth($request: UpsertUserHealthRequest!) {
    upsertUserHealth(request: $request) {
      success
    }
  }
`)

export const GqlUserHealth = graphql(`
  query GetUserHealth {
    getUserHealth {
      data {
        id
        user_id
        status
        name
        year
        explaination
        created_at
      }
    }
  }
`)

export const GqlUserFamilyUpsert = graphql(`
  mutation UpsertUserFamily($request: UpsertUserFamilyRequest!) {
    upsertUserFamily(request: $request) {
      success
    }
  }
`)

export const GqlUserFamily = graphql(`
  query GetUserFamily {
    getUserFamily {
      data {
        id
        user_id
        name
        relation
        birthdate
        country_id
        province_id
        city_id
        religion
        nationality_id
        phone_number
        created_at
        country {
          id
          name
        }
        province {
          id
          name
        }
        city {
          id
          name
        }
        nationality {
          id
          name
        }
      }
    }
  }
`)

export const GqlUserAttachments = graphql(`
  query GetUserAttachment {
    getUserAttachment {
      data {
        id
        user_id
        attachment_type
        url
        name
        created_at
        start_date
        end_date
      }
    }
  }
`)

export const GqlUserLanguages = graphql(`
  query GetUserLanguage {
    getUserLanguage {
      data {
        id
        user_id
        language
        language_level
        url
        created_at
        start_date
        end_date
      }
    }
  }
`)

export const GqlGetResume = graphql(`
  query GetUserResume {
    getUserResume {
      data {
        id
        user_id
        attachment_type
        url
        created_at
      }
    }
  }
`)

export const GqlUserSubmissionProgress = graphql(`
  query GetUserProgress($is_simplify: Boolean) {
    getUserProgress(is_simplify: $is_simplify) {
      data {
        user_personal_info
        user_education
        user_experience
        user_health
        user_family
        user_attachment
        user_language
        user_status_profile
        reason
      }
    }
  }
`)

export const GqlResubmitUserProfile = graphql(`
  mutation ResubmitUserStatusProfile {
    resubmitUserStatusProfile {
      success
    }
  }
`)

export const GqlGenerateCv = graphql(`
  query GenerateJapaneseCV {
    generateJapaneseCV {
      cv_summary
      name_in_japanese
      address_in_japanese
      summary_in_japanese
      phone_number
      user_personal_info {
        id
        user_id
        name
        address
        birthdate
        country_id
        province_id
        city_id
        gender
        is_single_child
        marital_status
        religion
        nationality_id
        driver_license
        blood_type
        height
        weight
        is_ever_been_going_abroad
        is_having_passport
        is_ever_proposing_passport
        photo
        created_at
      }
      user_experience {
        id
        user_id
        title
        company_name
        employment_type
        start_date
        end_date
        location
        location_type
        created_at
      }
      email
      user_attachment {
        id
        user_id
        attachment_type
        url
        created_at
      }
      user_education {
        id
        user_id
        school
        level
        major
        start_date
        graduation_date
        created_at
      }
    }
  }
`)

export const GqlGetCv = graphql(`
  query GetUserCV {
    getUserCV {
      id
      user_id
      name_in_kana
      address_in_kana
      summary
      created_at
      type_cv
    }
  }
`)

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute, redirect } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { jobCompanyApiUpsert } from '@/services/job/api'
import { queryJobCompanyDetail } from '@/services/job/query'

import { AdminPage } from '@/components/layout/admin/page'
import { Form } from '@/components/ui/form'

import { invalidateQueryKeys } from '@/lib/utils/utils'

import { CompanyFormDto, getDefaultValues } from './-components/dto'
import { CompanyForm } from './-components/form'

export const Route = createFileRoute('/admin-v2/master/company/$id')({
  loader: async ({ context: { queryClient: qc }, params: { id } }) => {
    const company = await qc.fetchQuery(queryJobCompanyDetail(Number(id)))

    if (!company) throw redirect({ to: '/admin-v2/master/company' })

    return {
      company,
    }
  },
  component: RouteComponent,
})

function RouteComponent() {
  const { company } = Route.useLoaderData()
  const navigate = Route.useNavigate()
  const form = useForm<CompanyFormDto>({
    resolver: zodResolver(CompanyFormDto),
    defaultValues: getDefaultValues(company),
  })

  const { mutateAsync } = useMutation({
    mutationFn: async (data: CompanyFormDto) => {
      await jobCompanyApiUpsert(data, {
        id: company.id,
        created_at: company.created_at,
      })
    },
    onSuccess: () => {
      invalidateQueryKeys(['company-list', 'company-detail'])
      navigate({ to: '/admin-v2/master/company' })
    },
  })

  const onSubmit = form.handleSubmit((data) => {
    toast.promise(mutateAsync(data), {
      loading: 'Memperbarui data company',
      success: 'Berhasil memperbarui',
      error: 'Gagal memperbarui',
    })
  }, console.error)

  return (
    <Form {...form}>
      <AdminPage>
        <AdminPage.Breadcrumb
          items={[
            ['Master Perusahaan', '/admin-v2/master/company'],
            ['Detail Perusahaan', '/admin-v2/master/company/$id'],
          ]}
        />
        <AdminPage.Header title="Detail Perusahaan" />
        <form onSubmit={onSubmit}>
          <CompanyForm />
        </form>
      </AdminPage>
    </Form>
  )
}

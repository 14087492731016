import '@/lib/styles/tailwind.css'

import React from 'react'

import ReactDOM from 'react-dom/client'

import { App } from './entry-app'

const rootElement = document.getElementById('root')
if (!rootElement) {
  throw new Error('No root element found')
}

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}

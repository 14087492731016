import { zodResolver } from '@hookform/resolvers/zod'
import { createFileRoute } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { ButtonLoading } from '@/components/ui/button'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { Input } from '@/components/ui/input'
import { InputPassword } from '@/components/ui/input-password'

import { AuthCard } from './-components/auth-card'
import { AuthContainer } from './-components/auth-container'

export const Route = createFileRoute('/auth/phone')({
  component: Component,
})

function Component() {
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: getDefaultValues(),
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = form

  const handleOnSubmit = handleSubmit((data) => {
    console.log(data)
  }, console.error)

  return (
    <AuthContainer className="">
      <Form {...form}>
        <AuthCard className="max-w-screen-sm">
          <form onSubmit={handleOnSubmit}>
            <AuthCard.Header>
              <AuthCard.Title>Lanjutkan untuk masuk</AuthCard.Title>
              <AuthCard.Subtitle>
                Halo, Selamat datang di YES Academy!
              </AuthCard.Subtitle>
            </AuthCard.Header>
            <AuthCard.Content className="">
              <FormField
                control={control}
                name="phone"
                render={({ field }) => (
                  <FormFieldset label="Nomor HP">
                    <InputPassword placeholder="Masukkan nomor hp" {...field} />
                  </FormFieldset>
                )}
              />
            </AuthCard.Content>
            <AuthCard.Footer className="flex-col gap-3 pt-3">
              <ButtonLoading
                className="w-full rounded-xl bg-gradient-primary"
                // isLoading={isPending}
                type="submit"
              >
                Kirim Kode OTP
              </ButtonLoading>
              <p className="my-1 w-full text-center text-sm">
                Nomor hp sudah terdaftar? Silakan Masuk
              </p>
            </AuthCard.Footer>
          </form>
        </AuthCard>
      </Form>
    </AuthContainer>
  )
}

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z.object({
  phone: z
    .string()
    .min(10, 'Phone number must be at least 10 digits')
    .max(15, 'Phone number must be at most 15 digits')
    .regex(/^\d+$/, 'Phone number must contain only digits'),
})

function getDefaultValues(): FormDto {
  return {
    phone: '',
  }
}

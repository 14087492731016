import { useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { useForm, useFormContext, useWatch } from 'react-hook-form'
import { toast } from 'sonner'
import { useDebounce } from 'use-debounce'
import { z } from 'zod'

import {
  BloodType,
  DriverLicense,
  Gender,
  GetUserPersonalInfoQuery,
  MaritalStatus,
  Religion,
} from '@/_gql/graphql'

import {
  queryGeoCities,
  queryGeoCountries,
  queryGeoNationalities,
  queryGeoProvinces,
} from '@/services/geo/query'
import { apiClient } from '@/services/rest/http'
import {
  BLOOD_TYPE_OPTIONS,
  DRIVER_LICENSE_OPTIONS,
  GENDER_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  RELIGION_OPTIONS,
} from '@/services/user/constant'
import { queryUserInformation } from '@/services/user/query'

import { Form, FormControl, FormField } from '@/components/ui/form'
import { FormFieldDatePicker } from '@/components/ui/form-field/date-picker'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { FileUploader } from '@/components/ui/form-field/file-uploader'
import { FormRadioGroupTab } from '@/components/ui/form-field/radio-group-tab'
import { FormFieldSelect } from '@/components/ui/form-field/select'
import { FormFieldSelectCombobox } from '@/components/ui/form-field/select-combobox'
import { Input } from '@/components/ui/input'

import {
  formatDateOnly,
  formatDateTimeServer,
  normalizeEnum,
  valueDateWithFallback,
  valueNumberWithFallback,
  valueWithFallback,
} from '@/lib/utils/utils'

import { CardProfile } from './-components/card-profile'

export const Route = createFileRoute('/_user/digishoku/profile/')({
  loader: async ({ context: { queryClient: qc } }) => {
    const [information] = await Promise.all([
      qc.fetchQuery(queryUserInformation()),
    ])

    return {
      information,
      async refetch() {
        await qc.refetchQueries(queryUserInformation())
      },
    }
  },
  component: Component,
})

function Component() {
  const { information, refetch } = Route.useLoaderData()
  const navigate = Route.useNavigate()
  const router = useRouter()
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: getDefaultValues(information),
  })

  const { mutateAsync } = useMutation({
    mutationFn: async (data: FormDto) => {
      const formData = constructPayload(
        data,
        information?.id
          ? { id: information.id, created_at: information.created_at }
          : undefined,
      )
      await apiClient.post('/api/user/personal-info/upsert', formData)
      await refetch()
      router.invalidate()
      navigate({ to: '/profile/education' })
      return true
    },
  })

  const handleOnSubmit = form.handleSubmit((data) => {
    toast.promise(mutateAsync(data), {
      loading: 'Menyimpan informasi pribadi',
      success: 'Informasi pribadi berhasil disimpan',
      error: 'Terjadi kesalahan saat menyimpan informasi',
    })
  }, console.error)

  return (
    <Form {...form}>
      <CardProfile title="Informasi Pribadi">
        <form onSubmit={handleOnSubmit}>
          <CardProfile.Content>
            <RowOne />
            <PhotoProfile />
            <RowTwo />
            <RowThree />
            <RowFour />
            <RowFive />
            <RowSix />
            <RowSeven />
          </CardProfile.Content>
          <CardProfile.Footer>
            <CardProfile.SubmissionButton />
          </CardProfile.Footer>
        </form>
      </CardProfile>
    </Form>
  )
}

function RowOne() {
  const form = useFormContext<FormDto>()

  return (
    <>
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <FormFieldset
            label="Nama sesuai KTP"
            className="col-span-full md:col-span-3"
          >
            <Input placeholder="Masukkan nama sesuai KTP" {...field} />
          </FormFieldset>
        )}
      />
      <FormField
        control={form.control}
        name="address"
        render={({ field }) => (
          <FormFieldset
            label="Alamat sesuai KTP"
            className="col-span-full md:col-span-3"
          >
            <Input placeholder="Masukkan alamat sesuai KTP" {...field} />
          </FormFieldset>
        )}
      />
    </>
  )
}

function PhotoProfile() {
  const form = useFormContext<FormDto>()

  return (
    <FormField
      control={form.control}
      name="photo_profile"
      render={({ field }) => (
        <FormFieldset label="Foto Profil" className="col-span-full">
          <FileUploader
            value={field.value ? [field.value] : []}
            onValueChange={(files) => {
              field.onChange(files?.[0] || null)
            }}
          />
        </FormFieldset>
      )}
    />
  )
}

function RowTwo() {
  const { information } = Route.useLoaderData()
  const form = useFormContext<FormDto>()
  const [sCountry, setSCountry] = useState(information?.country.name || '')
  const [sProvince, setSProvince] = useState(information?.province.name || '')
  const [sCity, setSCity] = useState(information?.city.name || '')

  const [countryDebounced] = useDebounce(sCountry, 500, { maxWait: 1000 })
  const [provinceDebounced] = useDebounce(sProvince, 500, { maxWait: 1000 })
  const [cityDebounced] = useDebounce(sCity, 500, { maxWait: 1000 })

  const countryId = useWatch({ control: form.control, name: 'country_id' })
  const provinceId = useWatch({ control: form.control, name: 'province_id' })

  const queryCountries = useQuery(queryGeoCountries({ name: countryDebounced }))
  const queryProvinces = useQuery(
    queryGeoProvinces({
      country_id: countryId,
      name: provinceDebounced,
    }),
  )
  const queryCities = useQuery(
    queryGeoCities({
      country_id: countryId,
      province_id: provinceId,
      name: cityDebounced,
    }),
  )

  return (
    <>
      <FormField
        control={form.control}
        name="birthdate"
        render={({ field }) => (
          <FormFieldset
            label="Tanggal lahir"
            className="col-span-full md:col-span-2"
            noFormControl
          >
            <FormFieldDatePicker date={field.value} onSelect={field.onChange} />
          </FormFieldset>
        )}
      />
      <div className="col-span-4 grid grid-cols-3 gap-2 md:gap-y-6">
        <FormField
          control={form.control}
          name="country_id"
          render={({ field }) => (
            <FormFieldset
              label="Tempat Lahir"
              className="col-span-full md:col-span-1"
            >
              <FormFieldSelectCombobox
                placeholder="Pilih negara lahir"
                items={queryCountries.data || []}
                value={field.value}
                disabled={queryCountries.isLoading}
                onValueChange={(val) => {
                  form.setValue('province_id', '')
                  form.setValue('city_id', '')
                  field.onChange(val)
                }}
                search={sCountry}
                setSearch={setSCountry}
                isLoading={queryCountries.isFetching}
              />
            </FormFieldset>
          )}
        />
        <FormField
          control={form.control}
          name="province_id"
          render={({ field }) => (
            <FormFieldset
              hideLabel
              noFormControl
              label="Province"
              className="col-span-full justify-end md:col-span-1"
            >
              <FormFieldSelectCombobox
                placeholder="Pilih provinsi"
                items={queryProvinces.data || []}
                value={field.value}
                disabled={!countryId}
                onValueChange={(val) => {
                  form.setValue('province_id', '')
                  form.setValue('city_id', '')
                  field.onChange(val)
                }}
                search={sProvince}
                setSearch={setSProvince}
                isLoading={queryProvinces.isFetching}
              />
            </FormFieldset>
          )}
        />
        <FormField
          control={form.control}
          name="city_id"
          render={({ field }) => (
            <FormFieldset
              label="City"
              hideLabel
              noFormControl
              className="col-span-full justify-end md:col-span-1"
            >
              <FormFieldSelectCombobox
                placeholder="Pilih kota"
                items={queryCities.data || []}
                value={field.value}
                disabled={!provinceId}
                onValueChange={field.onChange}
                search={sCity}
                setSearch={setSCity}
                isLoading={queryCities.isFetching}
              />
            </FormFieldset>
          )}
        />
      </div>
    </>
  )
}

function RowThree() {
  const form = useFormContext<FormDto>()
  return (
    <>
      <FormField
        control={form.control}
        name="gender"
        render={({ field }) => (
          <FormFieldset
            label="Jenis kelamin"
            className="col-span-full md:col-span-2"
          >
            <FormRadioGroupTab
              items={GENDER_OPTIONS}
              value={field.value}
              onValueChange={field.onChange}
            />
          </FormFieldset>
        )}
      />

      <FormField
        control={form.control}
        name="is_single_child"
        render={({ field }) => (
          <FormFieldset
            label="Apakah anda anak tunggal?"
            className="col-span-full md:col-span-2"
            noFormControl
          >
            <FormRadioGroupTab
              items={[
                { label: 'Ya', value: 'true' },
                { label: 'Tidak', value: 'false' },
              ]}
              value={field.value ? 'true' : 'false'}
              onValueChange={(val) => field.onChange(val === 'true')}
            />
          </FormFieldset>
        )}
      />

      <FormField
        control={form.control}
        name="marital_status"
        render={({ field }) => (
          <FormFieldset
            label="Status pernikahan"
            className="col-span-full md:col-span-2"
          >
            <FormRadioGroupTab
              items={MARITAL_STATUS_OPTIONS}
              value={field.value}
              onValueChange={field.onChange}
            />
          </FormFieldset>
        )}
      />
    </>
  )
}

function RowFour() {
  const { information } = Route.useLoaderData()
  const form = useFormContext<FormDto>()
  const [sNationality, setSNationality] = useState(
    information?.nationality?.name || '',
  )
  const [nationalityDebounced] = useDebounce(sNationality, 500, {
    maxWait: 1000,
  })

  const queryNationalities = useQuery(
    queryGeoNationalities({ name: nationalityDebounced }),
  )

  return (
    <>
      <FormField
        control={form.control}
        name="religion"
        render={({ field }) => (
          <FormFieldset label="Agama" className="col-span-full md:col-span-3">
            <FormFieldSelect
              placeholder="Pilih Agama"
              items={RELIGION_OPTIONS}
              value={field.value}
              onValueChange={field.onChange}
            />
          </FormFieldset>
        )}
      />
      <FormField
        control={form.control}
        name="nationality_id"
        render={({ field }) => (
          <FormFieldset
            label="Kewarganegaraan"
            className="col-span-full md:col-span-3"
            noFormControl
          >
            <FormFieldSelectCombobox
              placeholder="Pilih kewarganergaraan"
              items={queryNationalities.data ?? []}
              value={field.value}
              disabled={queryNationalities.isLoading}
              onValueChange={field.onChange}
              search={sNationality}
              setSearch={setSNationality}
              isLoading={queryNationalities.isFetching}
            />
          </FormFieldset>
        )}
      />
    </>
  )
}

function RowFive() {
  const form = useFormContext<FormDto>()
  return (
    <>
      <FormField
        control={form.control}
        name="driver_license"
        render={({ field }) => (
          <FormFieldset
            label="Memiliki SIM mobil?"
            className="col-span-full md:col-span-3"
          >
            <FormRadioGroupTab
              items={[
                ...DRIVER_LICENSE_OPTIONS,
                { label: 'Tidak', value: 'NONE' },
              ]}
              value={field.value || 'NONE'}
              onValueChange={field.onChange}
            />
          </FormFieldset>
        )}
      />
      <FormField
        control={form.control}
        name="blood_type"
        render={({ field }) => (
          <FormFieldset
            label="Golongan darah"
            className="col-span-full md:col-span-3"
          >
            <FormRadioGroupTab
              items={[...BLOOD_TYPE_OPTIONS, { label: '-', value: 'NONE' }]}
              value={field.value}
              onValueChange={field.onChange}
            />
          </FormFieldset>
        )}
      />
    </>
  )
}

function RowSix() {
  const form = useFormContext<FormDto>()
  return (
    <>
      <FormField
        control={form.control}
        name="height"
        render={({ field }) => (
          <FormFieldset
            label="Tinggi badan"
            className="col-span-full md:col-span-3"
            noFormControl
          >
            <div className="relative">
              <FormControl>
                <Input placeholder="Tinggi badan (dalam cm)" {...field} />
              </FormControl>
              <p className="absolute right-2 top-1/2 -translate-y-1/2 text-sm text-[#020617]">
                cm
              </p>
            </div>
          </FormFieldset>
        )}
      />
      <FormField
        control={form.control}
        name="weight"
        render={({ field }) => (
          <FormFieldset
            label="Berat badan"
            className="col-span-full md:col-span-3"
          >
            <div className="relative">
              <FormControl>
                <Input placeholder="Berat badan (dalam kg)" {...field} />
              </FormControl>
              <p className="absolute right-2 top-1/2 -translate-y-1/2 text-sm text-[#020617]">
                kg
              </p>
            </div>
          </FormFieldset>
        )}
      />
    </>
  )
}

function RowSeven() {
  const form = useFormContext<FormDto>()
  return (
    <>
      <FormField
        control={form.control}
        name="is_ever_been_going_abroad"
        render={({ field }) => (
          <FormFieldset
            label="Pernah ke luar negeri?"
            className="col-span-full md:col-span-2"
          >
            <FormRadioGroupTab
              items={[
                { label: 'Ya', value: 'true' },
                { label: 'Tidak', value: 'false' },
              ]}
              value={field.value ? 'true' : 'false'}
              onValueChange={(val) => field.onChange(val === 'true')}
            />
          </FormFieldset>
        )}
      />

      <FormField
        control={form.control}
        name="is_having_passport"
        render={({ field }) => (
          <FormFieldset
            label="Punya paspor?"
            className="col-span-full md:col-span-2"
          >
            <FormRadioGroupTab
              items={[
                { label: 'Punya', value: 'true' },
                { label: 'Belum punya', value: 'false' },
              ]}
              value={field.value ? 'true' : 'false'}
              onValueChange={(val) => field.onChange(val === 'true')}
            />
          </FormFieldset>
        )}
      />

      <FormField
        control={form.control}
        name="is_ever_proposing_passport"
        render={({ field }) => (
          <FormFieldset
            label="Pernah ajukan paspor?"
            className="col-span-full md:col-span-2"
          >
            <FormRadioGroupTab
              items={[
                { label: 'Pernah', value: 'true' },
                { label: 'Belum pernah', value: 'false' },
              ]}
              value={field.value ? 'true' : 'false'}
              onValueChange={(val) => field.onChange(val === 'true')}
            />
          </FormFieldset>
        )}
      />
    </>
  )
}

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z.object({
  name: z.string().min(1, 'Required'),
  address: z
    .string()
    .min(1, 'Address is required')
    .max(100, 'Address is too long'),
  photo_profile: z
    .instanceof(File)
    .refine((file) => file.size > 0, {
      message: 'Foto profil tidak boleh kosong',
    })
    .or(z.string().min(1, 'Foto profil tidak boleh kosong')),
  birthdate: z.coerce.date({ message: 'Required' }),
  country_id: z.string().min(1, 'Country is required'),
  province_id: z.string().min(1, 'Province is required'),
  city_id: z.string().min(1, 'City is required'),
  gender: z.nativeEnum(Gender),
  is_single_child: z.coerce.boolean(),
  marital_status: z.nativeEnum(MaritalStatus),
  religion: z.nativeEnum(Religion),
  nationality_id: z.string().min(1, 'Required'),
  driver_license: z.nativeEnum(DriverLicense).or(z.literal('NONE')),
  blood_type: z.nativeEnum(BloodType).or(z.literal('NONE')),
  height: z.coerce.number({ message: 'Required' }).min(1, 'Required'),
  weight: z.coerce.number({ message: 'Required' }).min(1, 'Required'),
  is_having_passport: z.coerce.boolean(),
  is_ever_been_going_abroad: z.coerce.boolean(),
  is_ever_proposing_passport: z.coerce.boolean(),
})

type PersonalInformationData =
  | GetUserPersonalInfoQuery['getUserPersonalInfo']['data']
  | null

function getDefaultValues(initial?: PersonalInformationData): FormDto {
  return {
    name: valueWithFallback(initial?.name, ''),
    address: valueWithFallback(initial?.address, ''),
    photo_profile: valueWithFallback(initial?.photo, ''),
    birthdate: valueDateWithFallback(initial?.birthdate),
    country_id: valueWithFallback(initial?.country_id),
    province_id: valueWithFallback(initial?.province_id),
    city_id: valueWithFallback(initial?.city_id),
    gender: valueWithFallback(normalizeEnum(initial?.gender), Gender.Men),
    is_single_child: valueWithFallback(initial?.is_single_child, false),
    marital_status: valueWithFallback(
      normalizeEnum(initial?.marital_status),
      MaritalStatus.Single,
    ),
    religion: valueWithFallback(normalizeEnum(initial?.religion)),
    nationality_id: valueWithFallback(initial?.nationality_id),
    driver_license: valueWithFallback<FormDto['driver_license']>(
      normalizeEnum(initial?.driver_license),
      'NONE',
    ),
    blood_type: valueWithFallback<FormDto['blood_type']>(
      normalizeEnum(initial?.blood_type),
      'NONE',
    ),
    height: valueNumberWithFallback(initial?.height),
    weight: valueNumberWithFallback(initial?.weight),
    is_having_passport: initial?.is_having_passport || false,
    is_ever_been_going_abroad: initial?.is_ever_been_going_abroad || false,
    is_ever_proposing_passport: initial?.is_ever_proposing_passport || false,
  }
}

function constructPayload(
  data: FormDto,
  meta?: { id: number; created_at: string },
) {
  const formData = new FormData()

  // Update
  if (meta) {
    formData.set('id', String(meta.id))
    formData.set('created_at', formatDateTimeServer(new Date(meta.created_at)))
  }

  if (data.photo_profile) {
    typeof data.photo_profile === 'string'
      ? formData.set('photo', data.photo_profile)
      : formData.set('photo_profile', data.photo_profile)
  }

  formData.set('name', data.name)
  formData.set('address', data.address)
  formData.set('birth_date', formatDateOnly(data.birthdate))
  formData.set('country_id', data.country_id)
  formData.set('province_id', data.province_id)
  formData.set('city_id', data.city_id)
  formData.set('gender', data.gender)
  formData.set('is_single_child', data.is_single_child ? 'true' : 'false')
  formData.set('marital_status', data.marital_status)
  formData.set('religion', data.religion)
  formData.set('nationality_id', data.nationality_id)

  data.driver_license !== 'NONE' &&
    formData.set('driver_license', data.driver_license)
  data.blood_type !== 'NONE' && formData.set('blood_type', data.blood_type)

  formData.set('height', String(data.height))
  formData.set('weight', String(data.weight))
  formData.set('is_having_passport', data.is_having_passport ? 'true' : 'false')
  formData.set(
    'is_ever_been_going_abroad',
    data.is_ever_been_going_abroad ? 'true' : 'false',
  )
  formData.set(
    'is_ever_proposing_passport',
    data.is_ever_proposing_passport ? 'true' : 'false',
  )

  return formData
}

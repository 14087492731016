import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { GqlAuthSendResetLink } from '@/services/auth/gql'
import { GraphQLError } from '@/services/graphql/error'
import { gqlFetch } from '@/services/graphql/fetcher'

import { ButtonLoading } from '@/components/ui/button'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { Input } from '@/components/ui/input'

import { AuthCard } from './-components/auth-card'
import { AuthContainer } from './-components/auth-container'

export const Route = createFileRoute('/auth/forgot-password')({
  component: Component,
})

const TOAST_ID = 'ForgotPassword'

function Component() {
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: getDefaultValues(),
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = form

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (data: FormDto) => {
      await gqlFetch({
        query: GqlAuthSendResetLink,
        variables: {
          request: { email: data.email },
        },
      })
      return 1
    },
    onSuccess: () => {
      toast.success('Email telah dikirim!', { id: TOAST_ID })
    },
    onError: (error) => {
      let msg = error.message
      if (GraphQLError.is(error)) {
        msg = error.getMessage()
      }
      toast.error(msg, { id: TOAST_ID })
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync(data)
  }, console.error)

  return (
    <AuthContainer className="">
      <Form {...form}>
        <AuthCard className="max-w-screen-md">
          <form onSubmit={onSubmit}>
            <AuthCard.Header>
              <AuthCard.Title>Lupa Password</AuthCard.Title>
              <AuthCard.Subtitle>
                Halo, Selamat datang di YES Academy!
              </AuthCard.Subtitle>
            </AuthCard.Header>
            <AuthCard.Content className="pb-0">
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <FormFieldset label="Email">
                    <Input placeholder="Masukkan Email" {...field} />
                  </FormFieldset>
                )}
              />
            </AuthCard.Content>
            <AuthCard.Footer className="flex-col gap-3 pt-3">
              <ButtonLoading
                className="w-full rounded-xl bg-gradient-primary"
                isLoading={isPending}
                type="submit"
              >
                Kirim Verifikasi
              </ButtonLoading>
              {/* <p className="my-1 w-full text-center text-sm">
                Email tidak terkirim? Kirim Ulang
              </p> */}
            </AuthCard.Footer>
          </form>
        </AuthCard>
      </Form>
    </AuthContainer>
  )
}

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z.object({
  email: z.string().email('Invalid email'),
})
function getDefaultValues(): FormDto {
  return {
    email: '',
  }
}

import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import { zodValidator } from '@tanstack/zod-adapter'
import { EditIcon, PlusIcon, Trash2Icon } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { JobSkillDetailQuery, JobSkillListQuery } from '@/_gql/graphql'

import { gqlFetch } from '@/services/graphql/fetcher'
import { GqlJobSkillDelete, GqlJobSkillUpsert } from '@/services/job/gql'
import { queryJobSkillDetail, queryJobSkillList } from '@/services/job/query'

import { AdminPage } from '@/components/layout/admin/page'
import { Button, ButtonLoading } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { DataTable } from '@/components/ui/data-table/data-table'
import { useDataTable } from '@/components/ui/data-table/use-data-table'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { Input } from '@/components/ui/input'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'
import { Skeleton } from '@/components/ui/skeleton'

import { formatDateTimeServer, invalidateQueryKeys } from '@/lib/utils/utils'

import { useFilters } from '@/hooks/use-filter'

const searchParamsSchema = z.object({
  s: z.string().nullish(),
})

export const Route = createFileRoute('/admin-v2/master/skill/')({
  validateSearch: zodValidator(searchParamsSchema),
  component: RouteComponent,
})

function RouteComponent() {
  const { filters, setFilters } = useFilters(Route.id)
  const { data, isLoading } = useQuery(queryJobSkillList())

  const columns = useMemo(getColumns, [])
  const { table } = useDataTable({
    data: data || [],
    state: {
      globalFilter: filters.s,
    },
    columns,
  })

  return (
    <SheetCtxProvider>
      <AdminPage>
        <AdminPage.Breadcrumb
          items={[['Master Skill', '/admin-v2/master/skill']]}
        />
        <AdminPage.Header
          title="Master Skills"
          desc={
            isLoading ? (
              <Skeleton className="h-5 w-32" />
            ) : (
              `Jumlah skills: ${data?.length || 0} list`
            )
          }
          action={<SkillSheetCreateButton />}
        />
        <SkillSheet />
        <Card className="max-w-full overflow-auto p-2">
          <CardContent className="relative space-y-2 p-0">
            <div>
              <Input
                className="max-w-80"
                placeholder="Cari skill"
                defaultValue={filters.s || undefined}
                onChange={(e) => {
                  setFilters({ s: e.target.value })
                }}
              />
            </div>
            <DataTable table={table} />
          </CardContent>
        </Card>
      </AdminPage>
    </SheetCtxProvider>
  )
}

type SkillItem = JobSkillListQuery['getJobSkill']['data'][number]

const column = createColumnHelper<SkillItem>()

function getColumns() {
  return [
    column.accessor('id', {
      header: 'ID',
      size: 1,
      cell: ({ cell }) => {
        return <div className="w-">{cell.row.original.id}</div>
      },
    }),
    column.accessor('name', {
      header: 'Nama skill',
    }),
    column.display({
      id: 'actions',
      header: 'Aksi',
      cell: ({ cell }) => {
        const { id, name } = cell.row.original
        const [, setState] = useSheetCtx()
        return (
          <div className="flex gap-1.5">
            <Button
              size="icon"
              className="size-8 text-primary-yes"
              variant="ghost"
              type="button"
              onClick={() => setState(id)}
            >
              <EditIcon />
            </Button>
            <RemoveActionButton skill={{ id, name }} />
          </div>
        )
      },
    }),
  ]
}

/**
 * val == null -> close
 * val <= -1 -> open (create)
 * val > 0 -> open (update)
 */
type SheetCtxValue = null | number
const SheetCtx = createContext<
  [SheetCtxValue, Dispatch<SetStateAction<SheetCtxValue>>]
>(null!)

function SheetCtxProvider({ children }: PropsWithChildren) {
  const state = useState<SheetCtxValue>(null)
  return <SheetCtx.Provider value={state}>{children}</SheetCtx.Provider>
}

function useSheetCtx() {
  const ctx = useContext(SheetCtx)
  if (!ctx) throw new Error()
  return ctx
}

function SkillSheetCreateButton() {
  const [, setState] = useSheetCtx()
  return (
    <Button size="sm" type="button" onClick={() => setState(-1)}>
      <PlusIcon />
      Tambah Skill
    </Button>
  )
}

function SkillSheet() {
  const [state, setState] = useSheetCtx()
  const open = typeof state === 'number'
  const mode = open && state > 0 ? 'update' : 'create'

  const title = mode === 'create' ? 'Buat Skill' : 'Ubah Skill'

  const { data: initialData, isLoading } = useQuery({
    ...queryJobSkillDetail(state!),
    enabled: mode === 'update',
    refetchOnMount: true,
  })

  return (
    <Sheet open={open} onOpenChange={() => setState(null)}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
          <SheetDescription className="sr-only">
            upsert job skill
          </SheetDescription>
        </SheetHeader>
        {mode === 'create' && <SkillForm />}
        {mode === 'update' && isLoading && <Skeleton className="mt-8 h-36" />}
        {mode === 'update' && initialData && (
          <SkillForm initialData={initialData} />
        )}
      </SheetContent>
    </Sheet>
  )
}

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z.object({
  name: z.string().trim().min(3),
})

interface SkillFormProps {
  initialData?: JobSkillDetailQuery['getJobSkillDetail']['data']
}

function SkillForm({ initialData }: SkillFormProps) {
  const [, setState] = useSheetCtx()
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: {
      name: initialData?.name ?? '',
    },
  })

  const { control } = form

  const { mutateAsync } = useMutation({
    mutationFn: async (data: FormDto) => {
      await gqlFetch({
        query: GqlJobSkillUpsert,
        variables: {
          request: {
            name: data.name,
            id: initialData?.id,
            created_at: initialData
              ? formatDateTimeServer(initialData.created_at)
              : undefined,
          },
        },
      })

      invalidateQueryKeys(['skill-list', 'skill-detail'])
      setState(null)
    },
  })

  const onSubmit = form.handleSubmit((data) => {
    toast.promise(mutateAsync(data), {
      loading: 'Menyimpan data skill',
      success: 'Berhasil simpan',
      error: 'Gagal menyimpan',
    })
  }, console.error)

  return (
    <Form {...form}>
      <form onSubmit={onSubmit} className="pt-8">
        <div className="min-h-28">
          <FormField
            control={control}
            name="name"
            render={({ field }) => (
              <FormFieldset label="Skill">
                <Input placeholder="Masukkan skill" {...field} />
              </FormFieldset>
            )}
          />
        </div>
        <div className="flex justify-end gap-2">
          <Button
            type="button"
            onClick={() => setState(null)}
            variant="outline_yes"
          >
            Batal
          </Button>
          <ButtonLoading>Simpan</ButtonLoading>
        </div>
      </form>
    </Form>
  )
}

interface DialogProps {
  skill: {
    id: number
    name: string
  }
}

function RemoveActionButton({ skill }: DialogProps) {
  const [open, setOpen] = useState(false)

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async () => {
      await gqlFetch({
        query: GqlJobSkillDelete,
        variables: { id: skill.id },
      })

      setOpen(false)
      invalidateQueryKeys(['skill-list', 'skill-detail'])
    },
  })

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="icon" className="size-8 text-primary-yes" variant="ghost">
          <Trash2Icon />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete skill</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete this skill? This action cannot be
            undone.
          </DialogDescription>
          <p className="w-min max-w-full text-nowrap rounded-md bg-slate-200 p-1.5 text-sm font-medium text-slate-700">
            {skill.name}
          </p>
        </DialogHeader>
        <DialogFooter>
          <ButtonLoading onClick={() => mutateAsync()} isLoading={isPending}>
            Delete
          </ButtonLoading>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export function Spinner() {
  return (
    <span className="inline-flex items-center">
      {[...Array.from({ length: 3 })].map((_, i) => (
        <span
          key={i}
          style={{
            animationDelay: `${0.2 * i}s`,
            width: '5px',
            height: '5px',
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0 1px',
          }}
          className="animate-blink bg-muted-foreground"
        />
      ))}
    </span>
  )
}

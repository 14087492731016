import { zodResolver } from '@hookform/resolvers/zod'
import { PlusIcon } from '@radix-ui/react-icons'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Trash2Icon } from 'lucide-react'
import { useFieldArray, useForm, useFormContext } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import {
  EducationLevel,
  GetUserEducationQuery,
  UpsertUserEducationRequest,
} from '@/_gql/graphql'

import { gqlFetch } from '@/services/graphql/fetcher'
import { EDUCATION_LEVEL_OPTIONS } from '@/services/user/constant'
import { GqlUserEducationUpsert } from '@/services/user/gql'
import {
  queryUserEducation,
  queryUserSubmissionProgress,
} from '@/services/user/query'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldDatePicker } from '@/components/ui/form-field/date-picker'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { FormFieldSelect } from '@/components/ui/form-field/select'
import { Input } from '@/components/ui/input'

import {
  formatDateOnly,
  formatDateTimeServer,
  normalizeEnum,
} from '@/lib/utils/utils'

import { CardProfile } from './-components/card-profile'

export const Route = createFileRoute('/_user/digishoku/profile/education')({
  loader: async ({ context: { queryClient: qc } }) => {
    const education = await qc.fetchQuery(queryUserEducation())
    return {
      education,
      refetch: async () => {
        await Promise.all([
          qc.refetchQueries(queryUserEducation()),
          qc.refetchQueries(queryUserSubmissionProgress()),
        ])
      },
    }
  },
  component: Component,
})

function Component() {
  const { education, refetch } = Route.useLoaderData()
  const navigate = Route.useNavigate()
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: getDefaultValues(education),
  })

  const { mutateAsync } = useMutation({
    mutationFn: async (data: FormDto) => {
      await gqlFetch({
        query: GqlUserEducationUpsert,
        variables: { request: { request: formDtoToPayload(data) } },
      })
      await refetch()
      navigate({ to: '/profile/experience' })
    },
  })

  const educationFields = useFieldArray({
    control: form.control,
    name: 'education',
    keyName: 'eId',
  })

  const showRemove = educationFields.fields.length > 1

  const handleOnSubmit = form.handleSubmit((data) => {
    toast.promise(mutateAsync(data), {
      loading: 'Menyimpan informasi penddikan',
      success: 'Informasi penddikan berhasil disimpan',
      error: 'Terjadi kesalahan saat menyimpan informasi',
    })
  }, console.error)

  return (
    <Form {...form}>
      <CardProfile title="Pendidikan">
        <form onSubmit={handleOnSubmit}>
          <CardProfile.Content>
            {educationFields.fields.map((field, idx) => (
              <EducationFormItem
                key={field.eId}
                idx={idx}
                showRemove={showRemove}
                onRemove={() => {
                  educationFields.remove(idx)
                }}
              />
            ))}
          </CardProfile.Content>
          <CardProfile.Footer>
            <Button
              className="w-full rounded-xl border-primary-yes text-primary-yes md:w-auto"
              variant="outline"
              type="button"
              onClick={() => educationFields.append(defaultItem())}
            >
              <PlusIcon />
              Tambah Pendidikan
            </Button>
            <CardProfile.SubmissionButton />
          </CardProfile.Footer>
        </form>
      </CardProfile>
    </Form>
  )
}

interface EducationFormItemProps {
  idx: number
  showRemove: boolean
  onRemove?: () => void
}

function EducationFormItem(props: EducationFormItemProps) {
  const { idx, showRemove, onRemove } = props
  const form = useFormContext<FormDto>()

  return (
    <div className="relative col-span-full grid grid-cols-subgrid gap-x-2 gap-y-6 rounded-md border border-border p-4 py-6">
      {showRemove && (
        <div className="absolute right-0 top-0">
          <Button variant="ghost" size="icon" onClick={onRemove} type="button">
            <Trash2Icon className="size-16 text-primary-yes" />
          </Button>
        </div>
      )}

      <FormField
        control={form.control}
        name={`education.${idx}.school`}
        render={({ field }) => (
          <FormFieldset
            label="Nama sekolah/kampus"
            className="col-span-full md:col-span-3"
          >
            <Input placeholder="Masukkan nama sekolah/kampus" {...field} />
          </FormFieldset>
        )}
      />
      <FormField
        control={form.control}
        name={`education.${idx}.level`}
        render={({ field }) => (
          <FormFieldset
            label="Tingkatan pendidikan"
            className="col-span-full md:col-span-3"
          >
            <FormFieldSelect
              placeholder="Pilih tingkatan pendidikan"
              items={EDUCATION_LEVEL_OPTIONS}
              value={field.value}
              onValueChange={field.onChange}
            />
          </FormFieldset>
        )}
      />
      <FormField
        control={form.control}
        name={`education.${idx}.major`}
        render={({ field }) => (
          <FormFieldset label="Bidang Studi" className="col-span-full">
            <Input placeholder="Masukkan Bidang Studi" {...field} />
          </FormFieldset>
        )}
      />
      <div className="col-span-full space-y-2 md:col-span-3">
        <FormField
          control={form.control}
          name={`education.${idx}.start_date`}
          render={({ field }) => (
            <FormFieldset label="Tanggal mulai" noFormControl>
              <FormFieldDatePicker
                date={field.value}
                onSelect={field.onChange}
              />
            </FormFieldset>
          )}
        />
        <div className="flex items-center space-x-2">
          <Checkbox
            id="until-now"
            checked={form.watch(`education.${idx}.graduation_date`) === null}
            onCheckedChange={(checked) => {
              form.setValue(
                `education.${idx}.graduation_date`,
                checked ? null : undefined!,
              )
            }}
          />
          <label
            htmlFor="until-now"
            className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Saya masih sekolah/kuliah di tempat ini
          </label>
        </div>
      </div>
      <FormField
        control={form.control}
        name={`education.${idx}.graduation_date`}
        render={({ field }) => (
          <FormFieldset
            label="Tanggal lulus"
            noFormControl
            className="col-span-full md:col-span-3"
          >
            <FormFieldDatePicker
              date={field.value!}
              onSelect={field.onChange}
              disabled={form.watch(`education.${idx}.graduation_date`) === null}
            />
          </FormFieldset>
        )}
      />
    </div>
  )
}

type EducationData = GetUserEducationQuery['getUserEducation']['data'] | null
function getDefaultValues(initial?: EducationData): FormDto {
  const data: FormDto = {
    education: (initial ?? [])?.map((x) => ({
      ...x,
      level: normalizeEnum(x.level),
      start_date: x.start_date ? new Date(x.start_date) : undefined!,
      graduation_date: x.graduation_date ? new Date(x.graduation_date) : null,
      created_at: x.created_at ? new Date(x.created_at) : undefined!,
    })),
  }

  data.education.length === 0 && data.education.push(defaultItem())
  return data
}

function defaultItem(): FormDto['education'][number] {
  return {
    id: undefined!,
    school: '',
    level: undefined!,
    major: '',
    start_date: undefined!,
    graduation_date: undefined!,
    created_at: undefined!,
  }
}

function formDtoToPayload(
  data: FormDto,
): UpsertUserEducationRequest['request'] {
  return data.education.map((x) => ({
    id: x.id ?? undefined,
    school: x.school,
    level: x.level,
    major: x.major,
    start_date: formatDateOnly(x.start_date),
    graduation_date: x.graduation_date
      ? formatDateOnly(x.graduation_date)
      : undefined,
    created_at: x.created_at ? formatDateTimeServer(x.created_at) : undefined,
  }))
}

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z.object({
  education: z.array(
    z.object({
      id: z.coerce.number().optional(),
      school: z.string().min(1, 'Nama sekolah tidak boleh kosong'),
      level: z.nativeEnum(EducationLevel),
      major: z.string().min(1, 'Nama sekolah tidak boleh kosong'),
      start_date: z.coerce.date(),
      graduation_date: z.coerce.date().nullish(),
      created_at: z.coerce.date().optional(),
    }),
  ),
})

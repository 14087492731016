import Cookies from 'js-cookie'
import { create } from 'zustand'

import { GetProfileQuery } from '@/_gql/graphql'

import { queryClient } from '@/lib/configs/tanstack-query'
import { queryAuthProfile } from './query'

type State = {
  isAuthenticated: boolean
  user: GetProfileQuery['getProfile']['user'] | null
}

interface Actions {
  login(token: string): Promise<State>
  logout(): Promise<void>
  revalidateSession(): Promise<void>
  getCookieToken(): string | null
  setCookieToken(token: string): void
  removeCookieToken(): void
  isAdmin(): boolean
  isAffiliator(): boolean
}

const initialState: State = {
  isAuthenticated: false,
  user: null,
}

const COOKIE_NAME = 'quinjet.auth.user'

export const useAuth = create<State & Actions>((set, get) => ({
  ...initialState,
  async login(token) {
    get().setCookieToken(token)
    const res = await queryClient.fetchQuery(queryAuthProfile())
    set({
      isAuthenticated: true,
      user: res.getProfile.user,
    })

    return get()
  },
  async revalidateSession() {
    const { getCookieToken } = get()
    const token = getCookieToken()
    if (token) {
      const res = await queryClient
        .fetchQuery(queryAuthProfile())
        .catch(() => null)

      if (res?.getProfile.user) {
        set({
          isAuthenticated: true,
          user: res.getProfile.user,
        })
        return
      }
      console.error('Revalidate session failed')
      get().logout()
    }

    set(initialState)
  },
  async logout() {
    queryClient.clear()
    get().removeCookieToken()
    set(initialState)
  },
  getCookieToken() {
    return Cookies.get(COOKIE_NAME) || null
  },
  setCookieToken(token) {
    Cookies.set(COOKIE_NAME, token)
  },
  removeCookieToken() {
    Cookies.remove(COOKIE_NAME)
  },
  isAdmin() {
    const { user } = get()
    const ROLE_ADMIN = 1
    return user?.role == ROLE_ADMIN
  },
  isAffiliator() {
    const { user } = get()
    const ROLE_AFFILIATOR = 3
    return user?.role == ROLE_AFFILIATOR
  },
}))

import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'

import { useAuth } from '@/services/auth/use-auth'

import AdminPanelLayout from '@/components/layout/panel/admin-panel-layout'

export const Route = createFileRoute('/affiliate')({
  loader: ({ preload }) => {
    if (preload) return
    const auth = useAuth.getState()
    if (!auth.isAffiliator()) {
      throw redirect({ to: '/auth/login' })
    }
  },
  component: RouteComponent,
})

function RouteComponent() {
  return (
    <AdminPanelLayout>
      <Outlet />
    </AdminPanelLayout>
  )
}

import { useState } from 'react'
import { queryUserInformation } from '@/services/user/query'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { useForm, useFormContext } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import {
    GetUserPersonalInfoQuery,
} from '@/_gql/graphql'
import {
    formatDateOnly,
    formatDateTimeServer,
    valueDateWithFallback,
    valueWithFallback,
} from '@/lib/utils/utils'
import { Form, FormControl, FormField } from '@/components/ui/form'
import { CardProfile } from '../digishoku/profile/-components/card-profile'
import { toast } from 'sonner'
import { apiClient } from '@/services/rest/http'
import { useMutation, useQuery } from '@tanstack/react-query'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { FileUploader } from '@/components/ui/form-field/file-uploader'
import { Input } from '@/components/ui/input'

import { FormFieldDatePicker } from '@/components/ui/form-field/date-picker'


export const Route = createFileRoute('/_user/profile/personal')({
    loader: async ({ context: { queryClient: qc } }) => {
        const [information] = await Promise.all([
            qc.fetchQuery(queryUserInformation()),
        ])

        return {
            information,
            async refetch() {
                await qc.refetchQueries(queryUserInformation())
            },
        }
    },
    component: RouteComponent,
})

function RouteComponent() {
    const { information, refetch } = Route.useLoaderData()
    const navigate = Route.useNavigate()
    const router = useRouter()
    const form = useForm<FormDto>({
        resolver: zodResolver(FormDto),
        defaultValues: getDefaultValues(information),
    })
    const { mutateAsync } = useMutation({
        mutationFn: async (data: FormDto) => {
            const formData = constructPayload(
                data,
                information?.id
                    ? { id: information.id, created_at: information.created_at }
                    : undefined,
            )
            await apiClient.post('/api/user/personal-info/simple-upsert', formData)
            await refetch()
            router.invalidate()
            navigate({ to: '/profile/experience' })
            return true
        },
    })

    const handleOnSubmit = form.handleSubmit((data) => {
        toast.promise(mutateAsync(data), {
            loading: 'Menyimpan informasi pribadi',
            success: 'Informasi pribadi berhasil disimpan',
            error: 'Terjadi kesalahan saat menyimpan informasi',
        })
    }, console.error)
    return (
        <Form {...form}>
            <CardProfile title="Informasi Pribadi">
                <form onSubmit={handleOnSubmit}>
                    <div className='p-4 !pt-0  md:p-6 gap-y-6 flex flex-col'>
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormFieldset
                                    label="Nama Lengkap"
                                    className="col-span-full md:col-span-3"
                                >
                                    <Input placeholder="Masukkan nama lengkap" {...field} />
                                </FormFieldset>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="photo_profile"
                            render={({ field }) => (
                                <FormFieldset label="Foto Profil" className="col-span-full">
                                    <FileUploader
                                        value={field.value ? [field.value] : []}
                                        onValueChange={(files) => {
                                            field.onChange(files?.[0] || null)
                                        }}
                                    />
                                </FormFieldset>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="birthdate"
                            render={({ field }) => (
                                <FormFieldset
                                    label="Tanggal lahir"
                                    className="col-span-full md:col-span-2"
                                    noFormControl
                                >
                                    <FormFieldDatePicker date={field.value} onSelect={field.onChange} />
                                </FormFieldset>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="address"
                            render={({ field }) => (
                                <FormFieldset
                                    label="Alamat Lengkap"
                                    className="col-span-full md:col-span-3"
                                >
                                    <Input placeholder="Masukkan alamat lengkap" {...field} />
                                </FormFieldset>
                            )}
                        />
                    </div>
                    <CardProfile.Footer>
                        <CardProfile.SubmissionButton />
                    </CardProfile.Footer>
                </form>
            </CardProfile>
        </Form>
    )
}



type PersonalInformationData =
    | GetUserPersonalInfoQuery['getUserPersonalInfo']['data']
    | null

function getDefaultValues(initial?: PersonalInformationData): FormDto {
    return {
        name: valueWithFallback(initial?.name, ''),
        address: valueWithFallback(initial?.address, ''),
        photo_profile: valueWithFallback(initial?.photo, ''),
        birthdate: valueDateWithFallback(initial?.birthdate),

    }
}

interface FormDto extends z.infer<typeof FormDto> { }
const FormDto = z.object({
    name: z.string().min(1, 'Required'),
    address: z
        .string()
        .min(1, 'Address is required')
        .max(100, 'Address is too long'),
    photo_profile: z
        .instanceof(File)
        .refine((file) => file.size > 0, {
            message: 'Foto profil tidak boleh kosong',
        })
        .or(z.string().min(1, 'Foto profil tidak boleh kosong')),
    birthdate: z.coerce.date({ message: 'Required' }),

})

function constructPayload(
    data: FormDto,
    meta?: { id: number; created_at: string },
) {
    const formData = new FormData()

    // Update
    if (meta) {
        formData.set('id', String(meta.id))
        formData.set('created_at', formatDateTimeServer(new Date(meta.created_at)))
    }

    if (data.photo_profile) {
        typeof data.photo_profile === 'string'
            ? formData.set('photo', data.photo_profile)
            : formData.set('photo_profile', data.photo_profile)
    }

    formData.set('name', data.name)
    formData.set('address', data.address)
    formData.set('birth_date', formatDateOnly(data.birthdate))


    return formData
}

import { queryOptions } from '@tanstack/react-query'

import { gqlFetch } from '../graphql/fetcher'
import {
  GqlDetailUserAffiliatedCount,
  GqlGetAffiliator,
  GqlListEWallet,
  GqlListPointExchangeHistory,
  GqlListUserAffiliated,
} from './query'

function affiliatorListQry() {
  return queryOptions({
    queryKey: ['affiliatorList'],
    queryFn: () => gqlFetch({ query: GqlGetAffiliator }),
  })
}

function affiliateDetailCountQry({
  userId,
  referralCode,
}: {
  userId: number
  referralCode: string
}) {
  return queryOptions({
    queryKey: ['affiliateDetailCount', userId, referralCode],
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlDetailUserAffiliatedCount,
        variables: { user_id: userId, referralCode },
      })

      return res.detailUserAffiliatedCount
    },
  })
}

function affiliatedUserListQry({ referralCode }: { referralCode: string }) {
  return queryOptions({
    queryKey: ['affiliatedUserList', referralCode],
    queryFn: async () => {
      const res = await gqlFetch({
        query: GqlListUserAffiliated,
        variables: { referralCode },
      })

      return res.listUserAffiliated
    },
  })
}

function listEWalletQry() {
  return queryOptions({
    queryKey: ['listEWallet'],
    queryFn: () => gqlFetch({ query: GqlListEWallet }),
  })
}

function listPointExchangeHistoryQry() {
  return queryOptions({
    queryKey: ['listPointExchange'],
    queryFn: () => gqlFetch({ query: GqlListPointExchangeHistory }),
    // refetchOnMount: true,
  })
}

export {
  affiliatorListQry,
  affiliateDetailCountQry,
  affiliatedUserListQry,
  listEWalletQry,
  listPointExchangeHistoryQry,
}

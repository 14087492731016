import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import {
  createFileRoute,
  Link,
  redirect,
  useRouter,
} from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { GqlAuthLogin } from '@/services/auth/gql'
import { useAuth } from '@/services/auth/use-auth'
import { GraphQLError } from '@/services/graphql/error'
import { gqlFetch } from '@/services/graphql/fetcher'

import { ButtonLoading } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormField, FormItem } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { Input } from '@/components/ui/input'
import { InputPassword } from '@/components/ui/input-password'
import { Label } from '@/components/ui/label'

import { capturePosthogEvent } from '@/lib/posthog'
import { AuthCard } from './-components/auth-card'
import { AuthContainer } from './-components/auth-container'
import { GoogleButton } from './-components/google-button'

export const Route = createFileRoute('/auth/login')({
  validateSearch: z.object({
    redirectTo: z.string().optional(),
  }),
  beforeLoad: async ({ search }) => {
    const auth = useAuth.getState()
    const { isAuthenticated } = auth

    if (isAuthenticated) {
      if (auth.isAdmin()) {
        throw redirect({ to: '/admin-v2/participant' })
      }

      if (auth.isAffiliator()) {
        throw redirect({ to: '/affiliate' })
      }

      throw redirect({ to: '/' })
    }
  },
  component: Component,
})

function Component() {
  const router = useRouter()
  const authLogin = useAuth((s) => s.login)
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: getDefaultValues(),
  })

  const { handleSubmit, setError, control } = form

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async ({ email, password }: FormDto) => {
      const res = await gqlFetch({
        query: GqlAuthLogin,
        variables: { request: { email, password } },
      })

      await authLogin(res.login.token)
      router.invalidate()
      capturePosthogEvent('auth:login', { email })
      return res.login
    },
    onError: (error) => {
      if (GraphQLError.is(error)) {
        const code = error.getErrorCode()
        if (code === 401) setError('password', { message: 'Password salah' })
        if (code === 404)
          setError('email', { message: 'Email tidak ditemukan' })
      }
    },
  })

  const handleOnSubmit = handleSubmit(async (data) => {
    toast.promise(mutateAsync(data), {
      id: 'login',
      loading: 'Masuk...',
      success: 'Berhasil masuk',
    })
  })

  return (
    <AuthContainer>
      <Form {...form}>
        <AuthCard>
          <form onSubmit={handleOnSubmit}>
            <AuthCard.Header className="py-4">
              <AuthCard.Title>Masuk</AuthCard.Title>
              <AuthCard.Subtitle>
                Halo, Selamat datang kembali di YES Academy!
              </AuthCard.Subtitle>
            </AuthCard.Header>
            <AuthCard.Content className="pb-0">
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <FormFieldset label="Email">
                    <Input placeholder="Masukkan Email" {...field} />
                  </FormFieldset>
                )}
              />
              <FormField
                control={control}
                name="password"
                render={({ field }) => (
                  <FormFieldset label="Password">
                    <InputPassword placeholder="Masukkan Password" {...field} />
                  </FormFieldset>
                )}
              />
              <ForgotPassword />
              <FormField
                control={control}
                name="remember"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-y-0">
                    <Checkbox
                      id={field.name}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <Label className="ml-1.5 font-normal" htmlFor={field.name}>
                      Simpan info login saya untuk sesi selanjutnya{' '}
                    </Label>
                  </FormItem>
                )}
              />
            </AuthCard.Content>
            <AuthCard.Footer className="flex-col gap-3 pt-3">
              <ButtonLoading
                className="w-full rounded-xl bg-gradient-primary"
                isLoading={isPending}
                type="submit"
              >
                Masuk ke YES Academy
              </ButtonLoading>
              <p className="my-1 w-full text-center text-sm">
                Atau masuk dengan menggunakan akun
              </p>
              <GoogleButton type="login" />
              <div className="flex justify-center text-sm">
                Belum memiliki akun? Silakan&nbsp;
                <Link
                  from={Route.fullPath}
                  to="/auth/register"
                  className="cursor-pointer font-semibold text-[#1C129D] underline"
                >
                  Daftar
                </Link>
              </div>
            </AuthCard.Footer>
          </form>
        </AuthCard>
      </Form>
    </AuthContainer>
  )
}

function ForgotPassword() {
  return (
    <Link
      to={'/auth/forgot-password'}
      className="my-2 block w-fit text-sm underline"
    >
      Lupa password?
    </Link>
  )
}

/**
 * Utils
 */

interface FormDto extends z.infer<typeof FormDto> {}
const FormDto = z.object({
  email: z.string().email('Invalid email'),
  password: z.string().min(1, 'Password is too short'),
  remember: z.boolean().optional(),
})

function getDefaultValues(): FormDto {
  return {
    email: '',
    password: '',
    remember: false,
  }
}

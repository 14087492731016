import { useEffect, useRef } from 'react'

import { createFileRoute, useRouter } from '@tanstack/react-router'

import Footer from './-components/footer'
import Hero from './-components/hero'
// import JobListing from './-components/job-listing'
import JobListing from './-components/job-listing'
import Navbar from './-components/navbar'
// import NewArticle from './-components/new-article'
import SectionIndustry from './-components/section-industry'
import SectionInformation from './-components/section-information'

export const Route = createFileRoute('/_landing/')({
  component: Component,
})

function Component() {
  const sectionRef = useRef<HTMLDivElement | null>(null)

  const router = useRouter()

  function handleClickLowongan() {
    if (sectionRef && sectionRef.current) {
      sectionRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    // Extract the hash from the current route
    const hash = router.state.location.hash // e.g., '#job-listing'
    if (hash) {
      const id = hash.substring(1) // Remove '#' from the hash
      const section = document.getElementById(id) // Find the section by ID
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' }) // Smooth scroll
      }
    }
  }, [router.state.location.hash])

  return (
    <>
      <Navbar />
      <Hero handleClick={handleClickLowongan} />
      <SectionIndustry />
      <JobListing id="job-listing" ref={sectionRef} />
      <SectionInformation />
      {/* <NewArticle /> */}
      <Footer />
    </>
  )
}

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { jobCompanyApiUpsert } from '@/services/job/api'

import { AdminPage } from '@/components/layout/admin/page'
import { Form } from '@/components/ui/form'

import { invalidateQueryKeys } from '@/lib/utils/utils'

import { CompanyFormDto, getDefaultValues } from './-components/dto'
import { CompanyForm } from './-components/form'

export const Route = createFileRoute('/admin-v2/master/company/create')({
  component: RouteComponent,
})

function RouteComponent() {
  const navigate = Route.useNavigate()
  const form = useForm<CompanyFormDto>({
    resolver: zodResolver(CompanyFormDto),
    defaultValues: getDefaultValues(),
  })

  const { mutateAsync } = useMutation({
    mutationFn: async (data: CompanyFormDto) => {
      await jobCompanyApiUpsert(data)
    },
    onSuccess: () => {
      invalidateQueryKeys(['company-list', 'company-detail'])
      navigate({ to: '/admin-v2/master/company' })
    },
  })

  const onSubmit = form.handleSubmit((data) => {
    toast.promise(mutateAsync(data), {
      loading: 'Menyimpan data company',
      success: 'Berhasil menyimpan',
      error: 'Gagal menyimpan',
    })
  }, console.error)

  return (
    <Form {...form}>
      <AdminPage>
        <AdminPage.Breadcrumb
          items={[
            ['Master Perusahaan', '/admin-v2/master/company'],
            ['Tambah Perusahaan', '/admin-v2/master/company/create'],
          ]}
        />
        <AdminPage.Header title="Buat Perusahaan" />
        <form onSubmit={onSubmit}>
          <CompanyForm />
        </form>
      </AdminPage>
    </Form>
  )
}

import { createFileRoute, Link, redirect } from '@tanstack/react-router'
import { MapPin, Users } from 'lucide-react'

import { queryJobDetail } from '@/services/job/query'

import { AdminPage } from '@/components/layout/admin/page'
import { Button } from '@/components/ui/button'

import { toIdrWithoutRp } from '@/lib/utils/number'

export const Route = createFileRoute('/admin-v2/job/vacancy/$id/')({
  loader: async ({ context, params }) => {
    const { queryClient } = context
    const { id } = params

    const job = await queryClient.fetchQuery(queryJobDetail(Number(id)))
    if (!job) throw redirect({ to: '/admin-v2/job/vacancy' })

    return {
      job,
    }
  },
  component: RouteComponent,
})

function RouteComponent() {
  const { job } = Route.useLoaderData()

  return (
    <AdminPage className="overflow-y-auto">
      <AdminPage.Breadcrumb
        items={[
          ['Master Job Listing', '/admin-v2/job/vacancy'],
          ['Detail Job Listing', '/admin-v2/job/vacancy/$id'],
        ]}
      />
      <AdminPage.Header
        title="Detail Job Listing"
        action={
          <Button variant="primary" size="sm" asChild>
            <Link from={Route.fullPath} to="/admin-v2/job/vacancy/$id/edit">
              Edit Job Listing
            </Link>
          </Button>
        }
      />
      <div className="rounded-lg border border-[#D4D4D4] p-5">
        <div className="flex items-center justify-between">
          <div className="text-xl font-semibold">{job?.title}</div>
        </div>
        <div className="mt-4 flex items-center gap-4">
          <div>
            <img className="w-25" src="/img/landing/logo.jpg" />
          </div>
          <div>
            <div className="font-medium text-[#131517]">YES Partner</div>
            <div className="flex items-center gap-2 text-sm">
              <Users className="w-4" />
              {`${job?.job_company?.size_from}-${job?.job_company?.size_to}`}
            </div>
          </div>
        </div>
        <div className="mt-4 flex items-center gap-2">
          <MapPin className="w-5 text-primary-yes" />
          <div className="text-sm font-medium">{`${job?.city.name}, ${job?.country.name}`}</div>
        </div>
        <div className="mt-4 flex gap-2 overflow-auto whitespace-nowrap">
          {job &&
            job.job_skills &&
            job.job_skills.map((skill) => (
              <div
                className="rounded-xl bg-[#FFF4F5] px-3 py-1 text-xs"
                key={skill.id}
              >
                {skill.name}
              </div>
            ))}
        </div>
        <div className="mt-4 font-medium">
          {/* IDR {`${toIdrWithoutRp(job?.salary ?? 0)}`}/ bulan */}
          IDR&nbsp;
          {`${toIdrWithoutRp(job?.min ?? 0)} - ${toIdrWithoutRp(job?.max ?? 0)}`}
          / bulan
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: job.description ?? '' }}
          className="no-margin prose prose-sm mt-4"
        />
      </div>
    </AdminPage>
  )
}

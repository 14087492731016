import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'

import { useAuth } from '@/services/auth/use-auth'

export const Route = createFileRoute('/auth/verification')({
  beforeLoad: async () => {
    const { isAuthenticated } = useAuth.getState()
    if (!isAuthenticated) return redirect({ to: '/auth/login' })
  },
  component: Outlet,
})

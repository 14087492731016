import * as React from 'react'

import { TooltipProvider } from '@radix-ui/react-tooltip'
import { QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider } from '@tanstack/react-router'

import { MediaQueryProvider } from '@/lib/hooks/use-media-query'

import { queryClient } from '@/lib/configs/tanstack-query'
import { createRouter } from '@/lib/configs/tanstack-router'
import { YesPosthogProvider } from '@/lib/posthog'

const router = createRouter()

export function App() {
  return (
    <YesPosthogProvider>
      <React.Suspense fallback={null}>
        <MediaQueryProvider query="(min-width: 768px)">
          <TooltipProvider>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} context={{ queryClient }} />
            </QueryClientProvider>
          </TooltipProvider>
        </MediaQueryProvider>
      </React.Suspense>
    </YesPosthogProvider>
  )
}

import { useMemo, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { CheckIcon, CopyIcon } from '@radix-ui/react-icons'
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import { formatDate } from 'date-fns'
import { Banknote } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'
import { toast } from 'sonner'
import { z } from 'zod'

import {
  ListUserAffiliatedQuery,
  PointExchangeHistoryStatus,
} from '@/_gql/graphql'

import {
  affiliateDetailCountQry,
  affiliatedUserListQry,
  listEWalletQry,
} from '@/services/affiliator/api'
import { GqlUpsertPointExchange } from '@/services/affiliator/query'
import { queryAuthProfile } from '@/services/auth/query'
import { useAuth } from '@/services/auth/use-auth'
import { gqlFetch } from '@/services/graphql/fetcher'

import { ContentLayout } from '@/components/layout/panel/content-layout'
import { Badge } from '@/components/ui/badge'
import { BreadcrumbTemplate } from '@/components/ui/breadcrumb'
import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardWithAccent,
} from '@/components/ui/card'
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import { DataTable } from '@/components/ui/data-table/data-table'
import { useDataTable } from '@/components/ui/data-table/use-data-table'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Form, FormField } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { FormFieldSelect } from '@/components/ui/form-field/select'
import { Input } from '@/components/ui/input'

import { cx } from '@/lib/cva-config'
import { dateFormatSafe } from '@/lib/formatter'

export const Route = createFileRoute('/affiliate/')({
  loader: async ({ context: { queryClient } }) => {
    const profile = await queryClient.ensureQueryData(queryAuthProfile())
    await queryClient.ensureQueryData(
      affiliateDetailCountQry({
        userId: profile.getProfile.user.id,
        referralCode: profile.getProfile.user.refferal_code!,
      })
    )
  },
  component: RouteComponent,
})

function RouteComponent() {
  const { data: profile } = useSuspenseQuery(queryAuthProfile())
  const { data: summary } = useSuspenseQuery(
    affiliateDetailCountQry({
      userId: profile.getProfile.user.id,
      referralCode: profile.getProfile.user.refferal_code!,
    })
  )

  const [withdrawOpen, setWithdrawOpen] = useState(false)
  const [copied, setCopied] = useState<boolean>(false)

  const referralLink = `${window.location.origin}/invite?code=${profile?.getProfile.user?.refferal_code}`
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => setCopied(false), 1500)
  }

  return (
    <ContentLayout>
      <div className="space-y-4">
        <BreadcrumbTemplate
          home="/affiliate"
          items={[['Dashboard', '/affiliate']]}
        />

        <h1 className="text-lg font-semibold">Dashboard</h1>
        <RequestWithdrawDialog open={withdrawOpen} setOpen={setWithdrawOpen} />
        <CardWithAccent className="flex w-full flex-wrap gap-x-4 gap-y-4 p-4">
          <div className="space-y-2">
            <h1 className="text-xl font-medium text-[#475467]">
              Ajak Teman, Raih Keuntungan!
            </h1>
            <p className="text-sm font-light leading-6 text-[#475467] md:max-w-sm">
              Punya teman yang sedang cari kerja? Referensikan mereka sekarang
              dan dapatkan kesempatan untuk meraih reward spesial jika mereka
              diterima!
            </p>
          </div>
          <div className="flex flex-1 flex-col justify-center">
            <p className="text-xs">
              Salin kode referralnya dan bagikan ke temanmu!
            </p>
            <div className="mt-2 flex items-center gap-x-2 rounded-md border p-1">
              <p className="pl-2 text-sm text-[#475467]">{referralLink}</p>
              <Button
                size="sm"
                className="ml-auto"
                onClick={() => handleCopy(referralLink)}
                disabled={copied}
              >
                <div className="relative size-4">
                  <span
                    className={cx(
                      'absolute left-0 transition-all',
                      copied ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                    )}
                  >
                    <CheckIcon
                      className="stroke-emerald-200"
                      aria-hidden="true"
                    />
                  </span>
                  <span
                    className={cx(
                      'absolute left-0 transition-all',
                      copied ? 'scale-0 opacity-0' : 'scale-100 opacity-100'
                    )}
                  >
                    <CopyIcon aria-hidden="true" />
                  </span>
                </div>
                Salin
              </Button>
            </div>
          </div>
        </CardWithAccent>
        <div className="flex flex-wrap gap-4">
          <CardWithAccent className="flex flex-1 flex-col justify-center space-y-2 px-3 py-4">
            <h1 className="text-sm text-muted-foreground">Poin Saat ini</h1>
            <div className="flex items-center justify-between gap-4">
              <p className="text-2xl font-semibold text-yes-600">
                {summary.poin_affiliator}
              </p>
              <Button
                size="sm"
                onClick={() => setWithdrawOpen(true)}
                disabled={summary.poin_affiliator <= 0}
              >
                <Banknote />
                Tukarkan Poin
              </Button>
            </div>
          </CardWithAccent>
          <CardWithAccent className="flex flex-1 flex-col justify-center space-y-2 px-3 py-4">
            <h1 className="text-nowrap text-sm text-muted-foreground">
              Jumlah User Referal
            </h1>
            <p className="text-2xl font-semibold">{summary.user_affiliated}</p>
          </CardWithAccent>
          <CardWithAccent className="flex flex-1 flex-col justify-center space-y-2 px-3 py-4">
            <h1 className="text-nowrap text-sm text-muted-foreground">
              Pelamar Pekerjaan
            </h1>
            <p className="text-2xl font-semibold">
              {summary.user_affiliated_applied}
            </p>
          </CardWithAccent>
          <CardWithAccent className="flex flex-1 flex-col justify-center space-y-2 px-3 py-4">
            <h1 className="text-nowrap text-sm text-muted-foreground">
              Diterima Pekerjaan
            </h1>
            <p className="text-2xl font-semibold">
              {summary.user_affiliated_hired}
            </p>
          </CardWithAccent>
        </div>

        <TableReferral />
      </div>
    </ContentLayout>
  )
}

const chartData = [
  { month: 'January', melamar: 186, diterima: 80 },
  { month: 'February', melamar: 305, diterima: 200 },
  { month: 'March', melamar: 237, diterima: 120 },
  { month: 'April', melamar: 73, diterima: 190 },
  { month: 'May', melamar: 209, diterima: 130 },
  { month: 'June', melamar: 214, diterima: 140 },
  { month: 'July', melamar: 214, diterima: 140 },
  { month: 'August', melamar: 214, diterima: 140 },
  { month: 'September', melamar: 214, diterima: 140 },
  { month: 'October', melamar: 214, diterima: 140 },
  { month: 'November', melamar: 214, diterima: 140 },
  { month: 'December', melamar: 214, diterima: 140 },
]

const chartConfig = {
  melamar: {
    label: 'Melamar',
    color: '#fb6e79',
  },
  diterima: {
    label: 'Diterima',
    color: '#d91f2e',
  },
} satisfies ChartConfig

function DashboardChart() {
  return (
    <Card>
      <CardHeader className="px-3 py-4">
        <CardTitle className="text-base font-semibold">
          Total Pelamar Pekerjaan & yang Lolos
        </CardTitle>
      </CardHeader>
      <CardContent className="p-3">
        <ChartContainer config={chartConfig}>
          <BarChart accessibilityLayer data={chartData}>
            <ChartLegend />
            <CartesianGrid vertical={false} />
            <YAxis tickLine={false} axisLine={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <ChartTooltip
              // cursor={false}
              content={<ChartTooltipContent indicator="dashed" />}
            />
            <Bar dataKey="melamar" fill="#fb6e79" radius={[4, 4, 0, 0]} />
            <Bar dataKey="diterima" fill="#d91f2e" radius={[4, 4, 0, 0]} />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}

const ch =
  createColumnHelper<ListUserAffiliatedQuery['listUserAffiliated'][number]>()

function TableReferral() {
  const { data: profile } = useSuspenseQuery(queryAuthProfile())
  const { data: affiliated, isLoading } = useQuery(
    affiliatedUserListQry({
      referralCode: profile?.getProfile.user?.refferal_code || '',
    })
  )

  const columns = useMemo(getColumns, [])
  const { table } = useDataTable({
    data: affiliated || [],
    columns,
  })

  if (isLoading) return null

  return (
    <Card>
      <CardHeader className="px-3 pb-2 pt-4">
        <CardTitle className="text-base font-semibold">
          Daftar Peserta dari Referal
        </CardTitle>
      </CardHeader>
      <CardContent className="p-3">
        <DataTable table={table} />
      </CardContent>
    </Card>
  )
}

function getColumns() {
  return [
    ch.accessor('name', { header: 'Nama' }),
    ch.accessor('created_at', {
      header: 'Tanggal',
      cell: ({ row }) => {
        return (
          <p>
            {formatDate(dateFormatSafe(row.original.created_at), 'dd/MM/yyyy')}
          </p>
        )
      },
    }),
    ch.accessor('status_account', {
      header: 'Status',
      cell: ({ row }) => {
        return <StatusTag status={row.original.status_account as Status} />
      },
    }),
  ]
}

type Status = 'hired' | 'applied' | 'registered' | 'rejected'

const STATUS_NAME = {
  registered: 'Akun Terdaftar',
  applied: 'Melamar Pekerjaan',
  hired: 'Diterima Pekerjaan',
  rejected: 'Ditolak Pekerjaan',
} satisfies Record<Status, string>

function StatusTag({ status }: { status: Status }) {
  return (
    <Badge
      className={cx(
        {
          'bg-[#CDFFD2] text-[#029E4A]': status === 'hired',
          'bg-red-500': status === 'rejected',
          'bg-[#FFF494] text-[#978803]': status === 'applied',
          'bg-[#A9EAFF] text-[#0093C2]': status === 'registered',
        },
        'text-xs font-semibold'
      )}
    >
      {STATUS_NAME[status] || status}
    </Badge>
  )
}

type FormDto = z.infer<typeof FormDto>
const FormDto = z.object({
  ewallet_id: z.coerce.number(),
  point: z.coerce.number(),
  no_hp: z
    .string()
    .min(10, 'Phone number must be at least 10 digits')
    .max(15, 'Phone number must be at most 15 digits')
    .regex(/^\d+$/, 'Phone number must contain only digits'),
})

function RequestWithdrawDialog({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (open: boolean) => void
}) {
  const { data: profile } = useSuspenseQuery(queryAuthProfile())
  const { data: summary } = useSuspenseQuery(
    affiliateDetailCountQry({
      userId: profile.getProfile.user.id,
      referralCode: profile.getProfile.user.refferal_code!,
    })
  )

  const { data } = useSuspenseQuery(listEWalletQry())
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: {
      point: summary.poin_affiliator,
      ewallet_id: data.getListEwallet?.[0]?.id || ('' as unknown as number),
      no_hp: '',
    },
  })
  const { control } = form

  const { mutateAsync } = useMutation({
    mutationFn: async (data: FormDto) => {
      await gqlFetch({
        query: GqlUpsertPointExchange,
        variables: {
          data: {
            ewallet_id: data.ewallet_id,
            point: data.point,
            no_hp: data.no_hp,
            status: PointExchangeHistoryStatus.Ongoing,
            user_id: profile.getProfile.user.id,
          },
        },
      })

      form.reset()
      setOpen(false)
    },
  })

  const onSubmit = form.handleSubmit(async (data) => {
    toast.promise(mutateAsync(data), {
      loading: 'Loading...',
      success: 'Success',
      error: 'Error',
    })
  }, console.error)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Form {...form}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-center">Tukar Poin</DialogTitle>
            <DialogDescription className="hidden">
              Tukar point descrption
            </DialogDescription>
          </DialogHeader>
          <div className="mt-4 flex flex-col items-center gap-2">
            <div className="my-8 max-w-40">
              <img src="/affiliator/withdraw.png" />
            </div>
            <p className="text-center text-sm">
              Apakah anda yakin untuk menukarkan {summary.poin_affiliator} Poin
              mu menjadi saldo e-wallet sebesar&nbsp;
              {new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(summary.poin_affiliator)}
            </p>
            <p className="mt-4 text-center text-sm">
              Jika ya, harap isi informasi e-wallet di bawah ini.
            </p>

            <div className="mt-2 flex w-full gap-2 py-4">
              <FormField
                control={control}
                name="ewallet_id"
                render={({ field }) => (
                  <FormFieldset
                    label="EWallet"
                    hideLabel
                    hideFormMessage
                    className="basis-1/2"
                    noFormControl
                  >
                    <FormFieldSelect
                      placeholder="Pilih e-wallet"
                      items={(data?.getListEwallet || []).map((x) => ({
                        label: x.name,
                        value: String(x.id),
                      }))}
                      value={field.value?.toString()}
                      onValueChange={field.onChange}
                    />
                  </FormFieldset>
                )}
              />
              <FormField
                control={control}
                name="no_hp"
                render={({ field }) => (
                  <FormFieldset
                    label=""
                    hideLabel
                    hideFormMessage
                    className="basis-1/2"
                  >
                    <Input placeholder="Masukkan nomor telepon" {...field} />
                  </FormFieldset>
                )}
              />
            </div>
          </div>
          <DialogFooter className="gap-2">
            <DialogClose asChild>
              <Button variant="outline" size={'sm'}>
                Batal
              </Button>
            </DialogClose>
            <Button size="sm" onClick={onSubmit}>
              Ya, Tukar Sekarang
            </Button>
          </DialogFooter>
        </DialogContent>
      </Form>
    </Dialog>
  )
}

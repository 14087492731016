import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'

import { APP_VERSION, IS_DEV } from './constant'

// Create a client for react-query
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      // staleTime: Infinity,
      staleTime: 0,
      throwOnError: IS_DEV,
    },
  },
  queryCache: new QueryCache({}),
  mutationCache: new MutationCache({
    // onSettled() {
    //   queryClient.invalidateQueries()
    // },
  }),
})

broadcastQueryClient({
  queryClient,
  broadcastChannel: APP_VERSION,
})

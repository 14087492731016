import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import { formatDate } from 'date-fns'

import {
  GetPointExchangeHistoryQuery,
  PointExchangeHistoryStatus,
} from '@/_gql/graphql'

import { listPointExchangeHistoryQry } from '@/services/affiliator/api'

import { ContentLayout } from '@/components/layout/panel/content-layout'
import { Badge } from '@/components/ui/badge'
import { BreadcrumbTemplate } from '@/components/ui/breadcrumb'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { DataTable } from '@/components/ui/data-table/data-table'
import { useDataTable } from '@/components/ui/data-table/use-data-table'

import { cx } from '@/lib/cva-config'
import { dateFormatSafe } from '@/lib/formatter'

export const Route = createFileRoute('/affiliate/history')({
  component: RouteComponent,
})

function RouteComponent() {
  return (
    <ContentLayout>
      <div className="space-y-4">
        <BreadcrumbTemplate
          home="/affiliate"
          items={[['Riwayat Tukar Poin', '/affiliate/history']]}
        />

        <h1 className="text-lg font-semibold">Riwayat Tukar Poin</h1>
        <TableHistory />
      </div>
    </ContentLayout>
  )
}

const ch =
  createColumnHelper<
    GetPointExchangeHistoryQuery['getPointExchangeHistory'][number]
  >()
function getColumns() {
  return [
    ch.accessor('created_at', {
      header: 'Tanggal',
      cell: ({ row }) => {
        return (
          <p>
            {formatDate(
              dateFormatSafe(row.original.created_at),
              'dd/MM/yyyy HH:mm'
            )}
          </p>
        )
      },
    }),
    ch.accessor('point', {
      header: 'Poin',
      cell: ({ row }) => {
        return (
          <p className="font-semibold text-yes-600">{row.original.point}</p>
        )
      },
    }),
    ch.accessor('status', {
      header: 'Status',
      cell: ({ row }) => {
        return <StatusTag status={row.original.status} />
      },
    }),
  ]
}

const STATUS_NAME = {
  [PointExchangeHistoryStatus.Ongoing]: 'Dalam Proses',
  [PointExchangeHistoryStatus.Completed]: 'Penukaran Berhasil',
  [PointExchangeHistoryStatus.Failed]: 'Penukaran Gagal',
} satisfies Record<PointExchangeHistoryStatus, string>

function StatusTag({ status }: { status: PointExchangeHistoryStatus }) {
  return (
    <Badge
      className={cx(
        {
          'bg-[#CDFFD2] text-[#029E4A]':
            status === PointExchangeHistoryStatus.Completed,
          'bg-[#FFCDD1] text-[#BC0000]':
            status === PointExchangeHistoryStatus.Failed,
          'bg-[#FFF494] text-[#978803]':
            status === PointExchangeHistoryStatus.Ongoing,
        },
        'text-xs font-semibold'
      )}
    >
      {STATUS_NAME[status]}
    </Badge>
  )
}

function TableHistory() {
  const { data } = useQuery(listPointExchangeHistoryQry())
  const columns = useMemo(getColumns, [])
  const { table } = useDataTable({
    data: data?.getPointExchangeHistory || [],
    columns,
  })

  return (
    <Card>
      <CardContent className="p-3">
        <DataTable table={table} />
      </CardContent>
    </Card>
  )
}

type Status = 'pending' | 'approved' | 'rejected'
type Item = {
  status: Status
  date: string
  point: number
}

const MOCK: Array<Item> = [
  {
    date: '2025-01-01',
    status: 'approved',
    point: -200,
  },
  {
    date: '2025-01-01',
    status: 'rejected',
    point: -200,
  },
  {
    date: '2025-01-01',
    status: 'approved',
    point: -200,
  },
  {
    date: '2025-01-01',
    status: 'pending',
    point: -200,
  },
  {
    date: '2025-01-01',
    status: 'approved',
    point: -200,
  },
]

import { createFileRoute } from '@tanstack/react-router'
import {
    queryUserExperience,
    queryUserSubmissionProgress,
    queryUserEducation
} from '@/services/user/query'
import { useFieldArray, useForm, useFormContext } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { EDUCATION_LEVEL_OPTIONS } from '@/services/user/constant'
import {
    EducationLevel,
    GetUserEducationQuery,
    UpsertUserEducationRequest,
    GetUserExperienceQuery,
    EmploymentType,
    LocationType,
    UpsertUserExperienceRequest,
} from '@/_gql/graphql'
import { Form, FormField } from '@/components/ui/form'
import {
    formatDateOnly,
    formatDateTimeServer,
    normalizeEnum,
} from '@/lib/utils/utils'
import { CardProfile } from '../digishoku/profile/-components/card-profile'
import { Button } from '@/components/ui/button'
import { PlusIcon } from '@radix-ui/react-icons'
import { Trash2Icon } from 'lucide-react'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { FormFieldSelect } from '@/components/ui/form-field/select'
import { Input } from '@/components/ui/input'
import { FormFieldDatePicker } from '@/components/ui/form-field/date-picker'
import { Checkbox } from '@/components/ui/checkbox'
import { CardTitle, } from '@/components/ui/card'
import { useMutation } from '@tanstack/react-query'
import { gqlFetch } from '@/services/graphql/fetcher'
import { GqlUserExperienceUpsert, GqlUserEducationUpsert } from '@/services/user/gql'
import { queryClient } from '@/lib/configs/tanstack-query'
import { toast } from 'sonner'


export const Route = createFileRoute('/_user/profile/experience')({
    loader: async ({ context: { queryClient: qc } }) => {
        const [experience, education] = await Promise.all([
            qc.fetchQuery(queryUserExperience()),
            qc.fetchQuery(queryUserEducation())
        ])

        return {
            experience,
            education,
            async refetch() {
                await qc.refetchQueries(queryUserExperience())
                await qc.refetchQueries(queryUserEducation())
            },
        }
    },
    component: RouteComponent,
})

function RouteComponent() {
    const { education, experience, refetch } = Route.useLoaderData()
    const navigate = Route.useNavigate()
    const form = useForm<FormDto>({
        resolver: zodResolver(FormDto),
        defaultValues: getDefaultValues(education, experience),
    })

    const educationFields = useFieldArray({
        control: form.control,
        name: 'education',
        keyName: 'eId',
    })

    const experiencesFields = useFieldArray({
        control: form.control,
        name: 'experiences',
        keyName: 'kId',
    })


    const { mutateAsync: mutateExperienceAsync } = useMutation({
        mutationFn: async (data: FormDto) => {
            await gqlFetch({
                query: GqlUserExperienceUpsert,
                variables: { request: { request: formDtoToPayloadExperiences(data) } },
            })
            await refetch()
            // await queryClient.invalidateQueries(queryUserSubmissionProgress())
            navigate({ to: '/profile/licences' })
            return true

        },
    })

    const { mutateAsync: mutateEducationAsync } = useMutation({
        mutationFn: async (data: FormDto) => {
            await gqlFetch({
                query: GqlUserEducationUpsert,
                variables: { request: { request: formDtoToPayloadEducation(data) } },
            })
            await refetch()
        },
    })


    const handleOnSubmit = form.handleSubmit((data) => {

        const promises = [];

        if (data.education.length > 0) {
            promises.push(mutateEducationAsync(data));
        }
        if (data.experiences.length > 0) {
            promises.push(mutateExperienceAsync(data));
        }

        if (promises.length === 0) {
            toast.error("No data to submit");
            return;
        }

        navigate({ to: "/profile/licences" });

        toast.promise(Promise.all(promises), {
            loading: 'Menyimpan informasi penddikan',
            success: 'Informasi penddikan berhasil disimpan',
            error: 'Terjadi kesalahan saat menyimpan informasi',
        })
    }, console.error)
    const showRemoveEduction = educationFields.fields.length > 1
    const showRemoveExperience = experiencesFields.fields.length > 1

    return (
        <>
            <Form {...form}>
                <form onSubmit={handleOnSubmit}>

                    <CardProfile title="Riwayat Pekerjaan">
                        <CardProfile.Content className='flex flex-col'>
                            {experiencesFields.fields.map((field, idx) => {
                                return (
                                    <ExperienceFormItem
                                        key={field.kId}
                                        idx={idx}
                                        showRemove={showRemoveExperience}
                                        onRemove={() => {
                                            experiencesFields.remove(idx)
                                        }}
                                    />
                                )
                            })}
                            <div className='col-span-3'>
                                <Button
                                    className="w-full rounded-xl border-primary-yes text-primary-yes md:w-auto"
                                    variant="outline"
                                    type="button"
                                    onClick={() => experiencesFields.append(defaultItemExperiences())}
                                >
                                    <PlusIcon />
                                    Tambah Riwayat Pekerjaan
                                </Button>
                            </div>
                        </CardProfile.Content>
                        <CardTitle className="text-xl p-4 md:p-6 font-semibold tracking-wide">
                            Riwayat Pendidikan
                        </CardTitle>
                        <CardProfile.Content>
                            {educationFields.fields.map((field, idx) => (
                                <EducationFormItem
                                    key={field.eId}
                                    idx={idx}
                                    showRemove={showRemoveEduction}
                                    onRemove={() => {
                                        educationFields.remove(idx)
                                    }}
                                />
                            ))}
                            <div className='col-span-3'>
                                <Button
                                    className="w-full rounded-xl border-primary-yes text-primary-yes md:w-auto"
                                    variant="outline"
                                    type="button"
                                    onClick={() => educationFields.append(defaultItemEducation())}
                                >
                                    <PlusIcon />
                                    Tambah Riwayat Pendidikan
                                </Button>
                            </div>
                        </CardProfile.Content>
                        <CardProfile.Footer>

                            <CardProfile.SubmissionButton />
                        </CardProfile.Footer>
                    </CardProfile>
                </form>

            </Form>
        </>
    )
}

interface FormDto extends z.infer<typeof FormDto> { }
const FormDto = z.object({
    education: z.array(
        z.object({
            id: z.coerce.number().optional(),
            school: z.string().min(1, 'Nama sekolah tidak boleh kosong'),
            level: z.nativeEnum(EducationLevel),
            major: z.string().min(1, 'Nama sekolah tidak boleh kosong'),
            start_date: z.coerce.date(),
            graduation_date: z.coerce.date().nullish(),
            created_at: z.coerce.date().optional(),
        }),
    ),
    experiences: z.array(
        z.object({
            id: z.coerce.number().optional(),
            title: z.string().min(1),
            company_name: z.string().min(1),
            start_date: z.coerce.date(),
            end_date: z.coerce.date().nullable(),
            created_at: z.string().optional(),
            location: z.string().optional(),
            location_type: z.string().optional(),
            employment_type: z.string().optional(),

        }),
    ),
})

interface EducationFormItemProps {
    idx: number
    showRemove: boolean
    onRemove?: () => void
}
function EducationFormItem(props: EducationFormItemProps) {
    const { idx, showRemove, onRemove } = props
    const form = useFormContext<FormDto>()

    return (
        <div className="relative col-span-full grid grid-cols-subgrid gap-x-2 gap-y-6 rounded-md border border-border p-4 py-6">
            {showRemove && (
                <div className="absolute right-0 top-0">
                    <Button variant="ghost" size="icon" onClick={onRemove} type="button">
                        <Trash2Icon className="size-16 text-primary-yes" />
                    </Button>
                </div>
            )}

            <FormField
                control={form.control}
                name={`education.${idx}.school`}
                render={({ field }) => (
                    <FormFieldset
                        label="Nama sekolah/kampus"
                        className="col-span-full md:col-span-3"
                    >
                        <Input placeholder="Masukkan nama sekolah/kampus" {...field} />
                    </FormFieldset>
                )}
            />
            <FormField
                control={form.control}
                name={`education.${idx}.level`}
                render={({ field }) => (
                    <FormFieldset
                        label="Tingkatan pendidikan"
                        className="col-span-full md:col-span-3"
                    >
                        <FormFieldSelect
                            placeholder="Pilih tingkatan pendidikan"
                            items={EDUCATION_LEVEL_OPTIONS}
                            value={field.value}
                            onValueChange={field.onChange}
                        />
                    </FormFieldset>
                )}
            />
            <FormField
                control={form.control}
                name={`education.${idx}.major`}
                render={({ field }) => (
                    <FormFieldset label="Bidang Studi" className="col-span-full">
                        <Input placeholder="Masukkan Bidang Studi" {...field} />
                    </FormFieldset>
                )}
            />
            <div className="col-span-full space-y-2 md:col-span-3">
                <FormField
                    control={form.control}
                    name={`education.${idx}.start_date`}
                    render={({ field }) => (
                        <FormFieldset label="Tanggal mulai" noFormControl>
                            <FormFieldDatePicker
                                date={field.value}
                                onSelect={field.onChange}
                            />
                        </FormFieldset>
                    )}
                />
                <div className="flex items-center space-x-2">
                    <Checkbox
                        id="until-now"
                        checked={form.watch(`education.${idx}.graduation_date`) === null}
                        onCheckedChange={(checked) => {
                            form.setValue(
                                `education.${idx}.graduation_date`,
                                checked ? null : undefined!,
                            )
                        }}
                    />
                    <label
                        htmlFor="until-now"
                        className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                        Saya masih sekolah/kuliah di tempat ini
                    </label>
                </div>
            </div>
            <FormField
                control={form.control}
                name={`education.${idx}.graduation_date`}
                render={({ field }) => (
                    <FormFieldset
                        label="Tanggal lulus"
                        noFormControl
                        className="col-span-full md:col-span-3"
                    >
                        <FormFieldDatePicker
                            date={field.value!}
                            onSelect={field.onChange}
                            disabled={form.watch(`education.${idx}.graduation_date`) === null}
                        />
                    </FormFieldset>
                )}
            />
        </div>
    )
}

interface ExperienceFormItemProps {
    idx: number
    showRemove: boolean
    onRemove?: () => void
}

function ExperienceFormItem(props: ExperienceFormItemProps) {
    const { idx, showRemove, onRemove } = props
    const form = useFormContext<FormDto>()

    return (
        <div className="relative grid grid-cols-2 gap-x-2 gap-y-6 rounded-md border border-border p-4 py-6 md:grid-cols-6">
            {showRemove && (
                <div className="absolute right-0 top-0">
                    <Button variant="ghost" size="icon" onClick={onRemove} type="button">
                        <Trash2Icon className="size-16 text-primary-yes" />
                    </Button>
                </div>
            )}

            <FormField
                control={form.control}
                name={`experiences.${idx}.title`}
                render={({ field }) => (
                    <FormFieldset label="Posisi" className="col-span-3">
                        <Input placeholder="Masukkan Posisi" {...field} />
                    </FormFieldset>
                )}
            />

            <FormField
                control={form.control}
                name={`experiences.${idx}.company_name`}
                render={({ field }) => (
                    <FormFieldset label="Nama perusahaan" className="col-span-3">
                        <Input placeholder="Masukkan Nama perusahaan" {...field} />
                    </FormFieldset>
                )}
            />



            <div className="col-span-3 space-y-2">
                <FormField
                    control={form.control}
                    name={`experiences.${idx}.start_date`}
                    render={({ field }) => (
                        <FormFieldset label="Tanggal mulai" noFormControl>
                            <FormFieldDatePicker
                                date={field.value}
                                onSelect={field.onChange}
                            />
                        </FormFieldset>
                    )}
                />
                <div className="flex items-center space-x-2">
                    <Checkbox
                        id="until-now"
                        checked={form.watch(`experiences.${idx}.end_date`) === null}
                        onCheckedChange={(checked) => {
                            form.setValue(
                                `experiences.${idx}.end_date`,
                                checked ? null : undefined!,
                            )
                        }}
                    />
                    <label
                        htmlFor="until-now"
                        className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                        Saya masih bekerja di tempat ini
                    </label>
                </div>
            </div>

            <FormField
                control={form.control}
                name={`experiences.${idx}.end_date`}
                render={({ field }) => (
                    <FormFieldset
                        label="Tanggal berakhir"
                        className="col-span-3"
                        noFormControl
                    >
                        <FormFieldDatePicker
                            date={field.value ? field.value : undefined}
                            onSelect={field.onChange}
                            disabled={form.watch(`experiences.${idx}.end_date`) === null}
                        />
                    </FormFieldset>
                )}
            />


        </div>
    )
}

type EducationData = GetUserEducationQuery['getUserEducation']['data'] | null
type ExperienceData = GetUserExperienceQuery['getUserExperience']['data'] | null

function getDefaultValues(initialEducation?: EducationData, initialExperiences?: ExperienceData): FormDto {
    const data: FormDto = {
        education: (initialEducation ?? [])?.map((x) => ({
            ...x,
            level: normalizeEnum(x.level),
            start_date: x.start_date ? new Date(x.start_date) : undefined!,
            graduation_date: x.graduation_date ? new Date(x.graduation_date) : null,
            created_at: x.created_at ? new Date(x.created_at) : undefined!,
        })),
        experiences: (initialExperiences ?? []).map((x) => ({
            ...x,
            employment_type: x.employment_type?.toUpperCase() as EmploymentType ?? '',
            start_date: x.start_date ? new Date(x.start_date) : undefined!,
            end_date: x.end_date ? new Date(x.end_date) : null,
            location_type: x.location_type?.toUpperCase() as LocationType ?? '',
            created_at: x.created_at || undefined!,
            location: '',
        })),
    }

    data.education.length === 0 && data.education.push(defaultItemEducation())
    data.experiences.length === 0 && data.experiences.push(defaultItemExperiences())

    return data
}

function defaultItemExperiences(): FormDto['experiences'][number] {
    return {
        id: undefined,
        title: '',
        company_name: '',
        start_date: undefined!,
        end_date: undefined!,
        created_at: undefined!,
        employment_type: undefined!,
        location: '',
        location_type: undefined!,
    }
}


function defaultItemEducation(): FormDto['education'][number] {
    return {
        id: undefined!,
        school: '',
        level: undefined!,
        major: '',
        start_date: undefined!,
        graduation_date: undefined!,
        created_at: undefined!,

    }
}



function formDtoToPayloadExperiences(
    data: FormDto,
): UpsertUserExperienceRequest['request'] {
    return data.experiences.map((x) => ({
        id: x.id,
        title: x.title,
        company_name: x.company_name,
        start_date: formatDateOnly(x.start_date),
        end_date: x.end_date ? formatDateOnly(x.end_date) : null,
        created_at: x.created_at ? formatDateTimeServer(x.created_at) : undefined,
        location: x.location ?? '',
        // location_type: x.location_type ?? undefined

    }))
}

function formDtoToPayloadEducation(
    data: FormDto,
): UpsertUserEducationRequest['request'] {
    return data.education.map((x) => ({
        id: x.id ?? undefined,
        school: x.school,
        level: x.level,
        major: x.major,
        start_date: formatDateOnly(x.start_date),
        graduation_date: x.graduation_date
            ? formatDateOnly(x.graduation_date)
            : undefined,
        created_at: x.created_at ? formatDateTimeServer(x.created_at) : undefined,

    }))
}


/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as InviteImport } from './routes/invite'
import { Route as AuthImport } from './routes/auth'
import { Route as UserImport } from './routes/_user'
import { Route as AffiliateRouteImport } from './routes/affiliate/route'
import { Route as AdminV2RouteImport } from './routes/admin-v2/route'
import { Route as AffiliateIndexImport } from './routes/affiliate/index'
import { Route as AdminV2IndexImport } from './routes/admin-v2/index'
import { Route as LandingIndexImport } from './routes/_landing/index'
import { Route as AuthVerificationImport } from './routes/auth/verification'
import { Route as AuthRegisterImport } from './routes/auth/register'
import { Route as AuthPhoneImport } from './routes/auth/phone'
import { Route as AuthLoginImport } from './routes/auth/login'
import { Route as AuthForgotPasswordImport } from './routes/auth/forgot-password'
import { Route as AuthCallbackImport } from './routes/auth/callback'
import { Route as AuthSplatImport } from './routes/auth/$'
import { Route as AffiliateHistoryImport } from './routes/affiliate/history'
import { Route as UserProfileImport } from './routes/_user/profile'
import { Route as UserDashboardImport } from './routes/_user/dashboard'
import { Route as AdminV2ReferralIndexImport } from './routes/admin-v2/referral/index'
import { Route as AdminV2ParticipantIndexImport } from './routes/admin-v2/participant/index'
import { Route as UserProfileIndexImport } from './routes/_user/profile/index'
import { Route as AuthVerificationSuccessImport } from './routes/auth/verification.success'
import { Route as AuthVerificationPhoneImport } from './routes/auth/verification.phone'
import { Route as AuthVerificationOtpImport } from './routes/auth/verification.otp'
import { Route as AuthNewPasswordTokenImport } from './routes/auth/new-password.$token'
import { Route as AdminV2ReferralPointHistoryImport } from './routes/admin-v2/referral/point-history'
import { Route as AdminV2ReferralIdImport } from './routes/admin-v2/referral/$id'
import { Route as UserProfilePersonalImport } from './routes/_user/profile/personal'
import { Route as UserProfileLicencesImport } from './routes/_user/profile/licences'
import { Route as UserProfileLanguagesImport } from './routes/_user/profile/languages'
import { Route as UserProfileExperienceImport } from './routes/_user/profile/experience'
import { Route as UserDigishokuProfileImport } from './routes/_user/digishoku/profile'
import { Route as UserDigishokuDashboardImport } from './routes/_user/digishoku/dashboard'
import { Route as UserAccountEditImport } from './routes/_user/account.edit'
import { Route as AdminV2ParticipantParticipantIdIndexImport } from './routes/admin-v2/participant/$participantId/index'
import { Route as AdminV2MasterTagIndexImport } from './routes/admin-v2/master/tag/index'
import { Route as AdminV2MasterSkillIndexImport } from './routes/admin-v2/master/skill/index'
import { Route as AdminV2MasterSchemaIndexImport } from './routes/admin-v2/master/schema/index'
import { Route as AdminV2MasterCompanyIndexImport } from './routes/admin-v2/master/company/index'
import { Route as AdminV2MasterCategoryIndexImport } from './routes/admin-v2/master/category/index'
import { Route as AdminV2JobVacancyIndexImport } from './routes/admin-v2/job/vacancy/index'
import { Route as AdminV2JobApplicantIndexImport } from './routes/admin-v2/job/applicant/index'
import { Route as UserDigishokuProfileIndexImport } from './routes/_user/digishoku/profile/index'
import { Route as AdminV2MasterCompanyCreateImport } from './routes/admin-v2/master/company/create'
import { Route as AdminV2MasterCompanyIdImport } from './routes/admin-v2/master/company/$id'
import { Route as AdminV2JobVacancyCreateImport } from './routes/admin-v2/job/vacancy/create'
import { Route as UserDigishokuProfileLanguagesImport } from './routes/_user/digishoku/profile/languages'
import { Route as UserDigishokuProfileExperienceImport } from './routes/_user/digishoku/profile/experience'
import { Route as UserDigishokuProfileEducationImport } from './routes/_user/digishoku/profile/education'
import { Route as UserDigishokuProfileAttachmentImport } from './routes/_user/digishoku/profile/attachment'
import { Route as UserDigishokuAccountEditImport } from './routes/_user/digishoku/account.edit'
import { Route as AdminV2JobVacancyIdIndexImport } from './routes/admin-v2/job/vacancy/$id.index'
import { Route as AdminV2JobApplicantVacancyIndexImport } from './routes/admin-v2/job/applicant/vacancy/index'
import { Route as AdminV2JobApplicantApplicantIdIndexImport } from './routes/admin-v2/job/applicant/$applicantId/index'
import { Route as AdminV2JobVacancyIdEditImport } from './routes/admin-v2/job/vacancy/$id.edit'
import { Route as AdminV2JobApplicantVacancyJobIdIndexImport } from './routes/admin-v2/job/applicant/vacancy/$jobId/index'

// Create/Update Routes

const InviteRoute = InviteImport.update({
  id: '/invite',
  path: '/invite',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any)

const UserRoute = UserImport.update({
  id: '/_user',
  getParentRoute: () => rootRoute,
} as any)

const AffiliateRouteRoute = AffiliateRouteImport.update({
  id: '/affiliate',
  path: '/affiliate',
  getParentRoute: () => rootRoute,
} as any)

const AdminV2RouteRoute = AdminV2RouteImport.update({
  id: '/admin-v2',
  path: '/admin-v2',
  getParentRoute: () => rootRoute,
} as any)

const AffiliateIndexRoute = AffiliateIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AffiliateRouteRoute,
} as any)

const AdminV2IndexRoute = AdminV2IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const LandingIndexRoute = LandingIndexImport.update({
  id: '/_landing/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthVerificationRoute = AuthVerificationImport.update({
  id: '/verification',
  path: '/verification',
  getParentRoute: () => AuthRoute,
} as any)

const AuthRegisterRoute = AuthRegisterImport.update({
  id: '/register',
  path: '/register',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPhoneRoute = AuthPhoneImport.update({
  id: '/phone',
  path: '/phone',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const AuthForgotPasswordRoute = AuthForgotPasswordImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => AuthRoute,
} as any)

const AuthCallbackRoute = AuthCallbackImport.update({
  id: '/callback',
  path: '/callback',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSplatRoute = AuthSplatImport.update({
  id: '/$',
  path: '/$',
  getParentRoute: () => AuthRoute,
} as any)

const AffiliateHistoryRoute = AffiliateHistoryImport.update({
  id: '/history',
  path: '/history',
  getParentRoute: () => AffiliateRouteRoute,
} as any)

const UserProfileRoute = UserProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => UserRoute,
} as any)

const UserDashboardRoute = UserDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => UserRoute,
} as any)

const AdminV2ReferralIndexRoute = AdminV2ReferralIndexImport.update({
  id: '/referral/',
  path: '/referral/',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const AdminV2ParticipantIndexRoute = AdminV2ParticipantIndexImport.update({
  id: '/participant/',
  path: '/participant/',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const UserProfileIndexRoute = UserProfileIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => UserProfileRoute,
} as any)

const AuthVerificationSuccessRoute = AuthVerificationSuccessImport.update({
  id: '/success',
  path: '/success',
  getParentRoute: () => AuthVerificationRoute,
} as any)

const AuthVerificationPhoneRoute = AuthVerificationPhoneImport.update({
  id: '/phone',
  path: '/phone',
  getParentRoute: () => AuthVerificationRoute,
} as any)

const AuthVerificationOtpRoute = AuthVerificationOtpImport.update({
  id: '/otp',
  path: '/otp',
  getParentRoute: () => AuthVerificationRoute,
} as any)

const AuthNewPasswordTokenRoute = AuthNewPasswordTokenImport.update({
  id: '/new-password/$token',
  path: '/new-password/$token',
  getParentRoute: () => AuthRoute,
} as any)

const AdminV2ReferralPointHistoryRoute =
  AdminV2ReferralPointHistoryImport.update({
    id: '/referral/point-history',
    path: '/referral/point-history',
    getParentRoute: () => AdminV2RouteRoute,
  } as any)

const AdminV2ReferralIdRoute = AdminV2ReferralIdImport.update({
  id: '/referral/$id',
  path: '/referral/$id',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const UserProfilePersonalRoute = UserProfilePersonalImport.update({
  id: '/personal',
  path: '/personal',
  getParentRoute: () => UserProfileRoute,
} as any)

const UserProfileLicencesRoute = UserProfileLicencesImport.update({
  id: '/licences',
  path: '/licences',
  getParentRoute: () => UserProfileRoute,
} as any)

const UserProfileLanguagesRoute = UserProfileLanguagesImport.update({
  id: '/languages',
  path: '/languages',
  getParentRoute: () => UserProfileRoute,
} as any)

const UserProfileExperienceRoute = UserProfileExperienceImport.update({
  id: '/experience',
  path: '/experience',
  getParentRoute: () => UserProfileRoute,
} as any)

const UserDigishokuProfileRoute = UserDigishokuProfileImport.update({
  id: '/digishoku/profile',
  path: '/digishoku/profile',
  getParentRoute: () => UserRoute,
} as any)

const UserDigishokuDashboardRoute = UserDigishokuDashboardImport.update({
  id: '/digishoku/dashboard',
  path: '/digishoku/dashboard',
  getParentRoute: () => UserRoute,
} as any)

const UserAccountEditRoute = UserAccountEditImport.update({
  id: '/account/edit',
  path: '/account/edit',
  getParentRoute: () => UserRoute,
} as any)

const AdminV2ParticipantParticipantIdIndexRoute =
  AdminV2ParticipantParticipantIdIndexImport.update({
    id: '/participant/$participantId/',
    path: '/participant/$participantId/',
    getParentRoute: () => AdminV2RouteRoute,
  } as any)

const AdminV2MasterTagIndexRoute = AdminV2MasterTagIndexImport.update({
  id: '/master/tag/',
  path: '/master/tag/',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const AdminV2MasterSkillIndexRoute = AdminV2MasterSkillIndexImport.update({
  id: '/master/skill/',
  path: '/master/skill/',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const AdminV2MasterSchemaIndexRoute = AdminV2MasterSchemaIndexImport.update({
  id: '/master/schema/',
  path: '/master/schema/',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const AdminV2MasterCompanyIndexRoute = AdminV2MasterCompanyIndexImport.update({
  id: '/master/company/',
  path: '/master/company/',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const AdminV2MasterCategoryIndexRoute = AdminV2MasterCategoryIndexImport.update(
  {
    id: '/master/category/',
    path: '/master/category/',
    getParentRoute: () => AdminV2RouteRoute,
  } as any,
)

const AdminV2JobVacancyIndexRoute = AdminV2JobVacancyIndexImport.update({
  id: '/job/vacancy/',
  path: '/job/vacancy/',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const AdminV2JobApplicantIndexRoute = AdminV2JobApplicantIndexImport.update({
  id: '/job/applicant/',
  path: '/job/applicant/',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const UserDigishokuProfileIndexRoute = UserDigishokuProfileIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => UserDigishokuProfileRoute,
} as any)

const AdminV2MasterCompanyCreateRoute = AdminV2MasterCompanyCreateImport.update(
  {
    id: '/master/company/create',
    path: '/master/company/create',
    getParentRoute: () => AdminV2RouteRoute,
  } as any,
)

const AdminV2MasterCompanyIdRoute = AdminV2MasterCompanyIdImport.update({
  id: '/master/company/$id',
  path: '/master/company/$id',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const AdminV2JobVacancyCreateRoute = AdminV2JobVacancyCreateImport.update({
  id: '/job/vacancy/create',
  path: '/job/vacancy/create',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const UserDigishokuProfileLanguagesRoute =
  UserDigishokuProfileLanguagesImport.update({
    id: '/languages',
    path: '/languages',
    getParentRoute: () => UserDigishokuProfileRoute,
  } as any)

const UserDigishokuProfileExperienceRoute =
  UserDigishokuProfileExperienceImport.update({
    id: '/experience',
    path: '/experience',
    getParentRoute: () => UserDigishokuProfileRoute,
  } as any)

const UserDigishokuProfileEducationRoute =
  UserDigishokuProfileEducationImport.update({
    id: '/education',
    path: '/education',
    getParentRoute: () => UserDigishokuProfileRoute,
  } as any)

const UserDigishokuProfileAttachmentRoute =
  UserDigishokuProfileAttachmentImport.update({
    id: '/attachment',
    path: '/attachment',
    getParentRoute: () => UserDigishokuProfileRoute,
  } as any)

const UserDigishokuAccountEditRoute = UserDigishokuAccountEditImport.update({
  id: '/digishoku/account/edit',
  path: '/digishoku/account/edit',
  getParentRoute: () => UserRoute,
} as any)

const AdminV2JobVacancyIdIndexRoute = AdminV2JobVacancyIdIndexImport.update({
  id: '/job/vacancy/$id/',
  path: '/job/vacancy/$id/',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const AdminV2JobApplicantVacancyIndexRoute =
  AdminV2JobApplicantVacancyIndexImport.update({
    id: '/job/applicant/vacancy/',
    path: '/job/applicant/vacancy/',
    getParentRoute: () => AdminV2RouteRoute,
  } as any)

const AdminV2JobApplicantApplicantIdIndexRoute =
  AdminV2JobApplicantApplicantIdIndexImport.update({
    id: '/job/applicant/$applicantId/',
    path: '/job/applicant/$applicantId/',
    getParentRoute: () => AdminV2RouteRoute,
  } as any)

const AdminV2JobVacancyIdEditRoute = AdminV2JobVacancyIdEditImport.update({
  id: '/job/vacancy/$id/edit',
  path: '/job/vacancy/$id/edit',
  getParentRoute: () => AdminV2RouteRoute,
} as any)

const AdminV2JobApplicantVacancyJobIdIndexRoute =
  AdminV2JobApplicantVacancyJobIdIndexImport.update({
    id: '/job/applicant/vacancy/$jobId/',
    path: '/job/applicant/vacancy/$jobId/',
    getParentRoute: () => AdminV2RouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/admin-v2': {
      id: '/admin-v2'
      path: '/admin-v2'
      fullPath: '/admin-v2'
      preLoaderRoute: typeof AdminV2RouteImport
      parentRoute: typeof rootRoute
    }
    '/affiliate': {
      id: '/affiliate'
      path: '/affiliate'
      fullPath: '/affiliate'
      preLoaderRoute: typeof AffiliateRouteImport
      parentRoute: typeof rootRoute
    }
    '/_user': {
      id: '/_user'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof UserImport
      parentRoute: typeof rootRoute
    }
    '/auth': {
      id: '/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/invite': {
      id: '/invite'
      path: '/invite'
      fullPath: '/invite'
      preLoaderRoute: typeof InviteImport
      parentRoute: typeof rootRoute
    }
    '/_user/dashboard': {
      id: '/_user/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof UserDashboardImport
      parentRoute: typeof UserImport
    }
    '/_user/profile': {
      id: '/_user/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof UserProfileImport
      parentRoute: typeof UserImport
    }
    '/affiliate/history': {
      id: '/affiliate/history'
      path: '/history'
      fullPath: '/affiliate/history'
      preLoaderRoute: typeof AffiliateHistoryImport
      parentRoute: typeof AffiliateRouteImport
    }
    '/auth/$': {
      id: '/auth/$'
      path: '/$'
      fullPath: '/auth/$'
      preLoaderRoute: typeof AuthSplatImport
      parentRoute: typeof AuthImport
    }
    '/auth/callback': {
      id: '/auth/callback'
      path: '/callback'
      fullPath: '/auth/callback'
      preLoaderRoute: typeof AuthCallbackImport
      parentRoute: typeof AuthImport
    }
    '/auth/forgot-password': {
      id: '/auth/forgot-password'
      path: '/forgot-password'
      fullPath: '/auth/forgot-password'
      preLoaderRoute: typeof AuthForgotPasswordImport
      parentRoute: typeof AuthImport
    }
    '/auth/login': {
      id: '/auth/login'
      path: '/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/auth/phone': {
      id: '/auth/phone'
      path: '/phone'
      fullPath: '/auth/phone'
      preLoaderRoute: typeof AuthPhoneImport
      parentRoute: typeof AuthImport
    }
    '/auth/register': {
      id: '/auth/register'
      path: '/register'
      fullPath: '/auth/register'
      preLoaderRoute: typeof AuthRegisterImport
      parentRoute: typeof AuthImport
    }
    '/auth/verification': {
      id: '/auth/verification'
      path: '/verification'
      fullPath: '/auth/verification'
      preLoaderRoute: typeof AuthVerificationImport
      parentRoute: typeof AuthImport
    }
    '/_landing/': {
      id: '/_landing/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof LandingIndexImport
      parentRoute: typeof rootRoute
    }
    '/admin-v2/': {
      id: '/admin-v2/'
      path: '/'
      fullPath: '/admin-v2/'
      preLoaderRoute: typeof AdminV2IndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/affiliate/': {
      id: '/affiliate/'
      path: '/'
      fullPath: '/affiliate/'
      preLoaderRoute: typeof AffiliateIndexImport
      parentRoute: typeof AffiliateRouteImport
    }
    '/_user/account/edit': {
      id: '/_user/account/edit'
      path: '/account/edit'
      fullPath: '/account/edit'
      preLoaderRoute: typeof UserAccountEditImport
      parentRoute: typeof UserImport
    }
    '/_user/digishoku/dashboard': {
      id: '/_user/digishoku/dashboard'
      path: '/digishoku/dashboard'
      fullPath: '/digishoku/dashboard'
      preLoaderRoute: typeof UserDigishokuDashboardImport
      parentRoute: typeof UserImport
    }
    '/_user/digishoku/profile': {
      id: '/_user/digishoku/profile'
      path: '/digishoku/profile'
      fullPath: '/digishoku/profile'
      preLoaderRoute: typeof UserDigishokuProfileImport
      parentRoute: typeof UserImport
    }
    '/_user/profile/experience': {
      id: '/_user/profile/experience'
      path: '/experience'
      fullPath: '/profile/experience'
      preLoaderRoute: typeof UserProfileExperienceImport
      parentRoute: typeof UserProfileImport
    }
    '/_user/profile/languages': {
      id: '/_user/profile/languages'
      path: '/languages'
      fullPath: '/profile/languages'
      preLoaderRoute: typeof UserProfileLanguagesImport
      parentRoute: typeof UserProfileImport
    }
    '/_user/profile/licences': {
      id: '/_user/profile/licences'
      path: '/licences'
      fullPath: '/profile/licences'
      preLoaderRoute: typeof UserProfileLicencesImport
      parentRoute: typeof UserProfileImport
    }
    '/_user/profile/personal': {
      id: '/_user/profile/personal'
      path: '/personal'
      fullPath: '/profile/personal'
      preLoaderRoute: typeof UserProfilePersonalImport
      parentRoute: typeof UserProfileImport
    }
    '/admin-v2/referral/$id': {
      id: '/admin-v2/referral/$id'
      path: '/referral/$id'
      fullPath: '/admin-v2/referral/$id'
      preLoaderRoute: typeof AdminV2ReferralIdImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/referral/point-history': {
      id: '/admin-v2/referral/point-history'
      path: '/referral/point-history'
      fullPath: '/admin-v2/referral/point-history'
      preLoaderRoute: typeof AdminV2ReferralPointHistoryImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/auth/new-password/$token': {
      id: '/auth/new-password/$token'
      path: '/new-password/$token'
      fullPath: '/auth/new-password/$token'
      preLoaderRoute: typeof AuthNewPasswordTokenImport
      parentRoute: typeof AuthImport
    }
    '/auth/verification/otp': {
      id: '/auth/verification/otp'
      path: '/otp'
      fullPath: '/auth/verification/otp'
      preLoaderRoute: typeof AuthVerificationOtpImport
      parentRoute: typeof AuthVerificationImport
    }
    '/auth/verification/phone': {
      id: '/auth/verification/phone'
      path: '/phone'
      fullPath: '/auth/verification/phone'
      preLoaderRoute: typeof AuthVerificationPhoneImport
      parentRoute: typeof AuthVerificationImport
    }
    '/auth/verification/success': {
      id: '/auth/verification/success'
      path: '/success'
      fullPath: '/auth/verification/success'
      preLoaderRoute: typeof AuthVerificationSuccessImport
      parentRoute: typeof AuthVerificationImport
    }
    '/_user/profile/': {
      id: '/_user/profile/'
      path: '/'
      fullPath: '/profile/'
      preLoaderRoute: typeof UserProfileIndexImport
      parentRoute: typeof UserProfileImport
    }
    '/admin-v2/participant/': {
      id: '/admin-v2/participant/'
      path: '/participant'
      fullPath: '/admin-v2/participant'
      preLoaderRoute: typeof AdminV2ParticipantIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/referral/': {
      id: '/admin-v2/referral/'
      path: '/referral'
      fullPath: '/admin-v2/referral'
      preLoaderRoute: typeof AdminV2ReferralIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/_user/digishoku/account/edit': {
      id: '/_user/digishoku/account/edit'
      path: '/digishoku/account/edit'
      fullPath: '/digishoku/account/edit'
      preLoaderRoute: typeof UserDigishokuAccountEditImport
      parentRoute: typeof UserImport
    }
    '/_user/digishoku/profile/attachment': {
      id: '/_user/digishoku/profile/attachment'
      path: '/attachment'
      fullPath: '/digishoku/profile/attachment'
      preLoaderRoute: typeof UserDigishokuProfileAttachmentImport
      parentRoute: typeof UserDigishokuProfileImport
    }
    '/_user/digishoku/profile/education': {
      id: '/_user/digishoku/profile/education'
      path: '/education'
      fullPath: '/digishoku/profile/education'
      preLoaderRoute: typeof UserDigishokuProfileEducationImport
      parentRoute: typeof UserDigishokuProfileImport
    }
    '/_user/digishoku/profile/experience': {
      id: '/_user/digishoku/profile/experience'
      path: '/experience'
      fullPath: '/digishoku/profile/experience'
      preLoaderRoute: typeof UserDigishokuProfileExperienceImport
      parentRoute: typeof UserDigishokuProfileImport
    }
    '/_user/digishoku/profile/languages': {
      id: '/_user/digishoku/profile/languages'
      path: '/languages'
      fullPath: '/digishoku/profile/languages'
      preLoaderRoute: typeof UserDigishokuProfileLanguagesImport
      parentRoute: typeof UserDigishokuProfileImport
    }
    '/admin-v2/job/vacancy/create': {
      id: '/admin-v2/job/vacancy/create'
      path: '/job/vacancy/create'
      fullPath: '/admin-v2/job/vacancy/create'
      preLoaderRoute: typeof AdminV2JobVacancyCreateImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/master/company/$id': {
      id: '/admin-v2/master/company/$id'
      path: '/master/company/$id'
      fullPath: '/admin-v2/master/company/$id'
      preLoaderRoute: typeof AdminV2MasterCompanyIdImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/master/company/create': {
      id: '/admin-v2/master/company/create'
      path: '/master/company/create'
      fullPath: '/admin-v2/master/company/create'
      preLoaderRoute: typeof AdminV2MasterCompanyCreateImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/_user/digishoku/profile/': {
      id: '/_user/digishoku/profile/'
      path: '/'
      fullPath: '/digishoku/profile/'
      preLoaderRoute: typeof UserDigishokuProfileIndexImport
      parentRoute: typeof UserDigishokuProfileImport
    }
    '/admin-v2/job/applicant/': {
      id: '/admin-v2/job/applicant/'
      path: '/job/applicant'
      fullPath: '/admin-v2/job/applicant'
      preLoaderRoute: typeof AdminV2JobApplicantIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/job/vacancy/': {
      id: '/admin-v2/job/vacancy/'
      path: '/job/vacancy'
      fullPath: '/admin-v2/job/vacancy'
      preLoaderRoute: typeof AdminV2JobVacancyIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/master/category/': {
      id: '/admin-v2/master/category/'
      path: '/master/category'
      fullPath: '/admin-v2/master/category'
      preLoaderRoute: typeof AdminV2MasterCategoryIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/master/company/': {
      id: '/admin-v2/master/company/'
      path: '/master/company'
      fullPath: '/admin-v2/master/company'
      preLoaderRoute: typeof AdminV2MasterCompanyIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/master/schema/': {
      id: '/admin-v2/master/schema/'
      path: '/master/schema'
      fullPath: '/admin-v2/master/schema'
      preLoaderRoute: typeof AdminV2MasterSchemaIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/master/skill/': {
      id: '/admin-v2/master/skill/'
      path: '/master/skill'
      fullPath: '/admin-v2/master/skill'
      preLoaderRoute: typeof AdminV2MasterSkillIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/master/tag/': {
      id: '/admin-v2/master/tag/'
      path: '/master/tag'
      fullPath: '/admin-v2/master/tag'
      preLoaderRoute: typeof AdminV2MasterTagIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/participant/$participantId/': {
      id: '/admin-v2/participant/$participantId/'
      path: '/participant/$participantId'
      fullPath: '/admin-v2/participant/$participantId'
      preLoaderRoute: typeof AdminV2ParticipantParticipantIdIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/job/vacancy/$id/edit': {
      id: '/admin-v2/job/vacancy/$id/edit'
      path: '/job/vacancy/$id/edit'
      fullPath: '/admin-v2/job/vacancy/$id/edit'
      preLoaderRoute: typeof AdminV2JobVacancyIdEditImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/job/applicant/$applicantId/': {
      id: '/admin-v2/job/applicant/$applicantId/'
      path: '/job/applicant/$applicantId'
      fullPath: '/admin-v2/job/applicant/$applicantId'
      preLoaderRoute: typeof AdminV2JobApplicantApplicantIdIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/job/applicant/vacancy/': {
      id: '/admin-v2/job/applicant/vacancy/'
      path: '/job/applicant/vacancy'
      fullPath: '/admin-v2/job/applicant/vacancy'
      preLoaderRoute: typeof AdminV2JobApplicantVacancyIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/job/vacancy/$id/': {
      id: '/admin-v2/job/vacancy/$id/'
      path: '/job/vacancy/$id'
      fullPath: '/admin-v2/job/vacancy/$id'
      preLoaderRoute: typeof AdminV2JobVacancyIdIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
    '/admin-v2/job/applicant/vacancy/$jobId/': {
      id: '/admin-v2/job/applicant/vacancy/$jobId/'
      path: '/job/applicant/vacancy/$jobId'
      fullPath: '/admin-v2/job/applicant/vacancy/$jobId'
      preLoaderRoute: typeof AdminV2JobApplicantVacancyJobIdIndexImport
      parentRoute: typeof AdminV2RouteImport
    }
  }
}

// Create and export the route tree

interface AdminV2RouteRouteChildren {
  AdminV2IndexRoute: typeof AdminV2IndexRoute
  AdminV2ReferralIdRoute: typeof AdminV2ReferralIdRoute
  AdminV2ReferralPointHistoryRoute: typeof AdminV2ReferralPointHistoryRoute
  AdminV2ParticipantIndexRoute: typeof AdminV2ParticipantIndexRoute
  AdminV2ReferralIndexRoute: typeof AdminV2ReferralIndexRoute
  AdminV2JobVacancyCreateRoute: typeof AdminV2JobVacancyCreateRoute
  AdminV2MasterCompanyIdRoute: typeof AdminV2MasterCompanyIdRoute
  AdminV2MasterCompanyCreateRoute: typeof AdminV2MasterCompanyCreateRoute
  AdminV2JobApplicantIndexRoute: typeof AdminV2JobApplicantIndexRoute
  AdminV2JobVacancyIndexRoute: typeof AdminV2JobVacancyIndexRoute
  AdminV2MasterCategoryIndexRoute: typeof AdminV2MasterCategoryIndexRoute
  AdminV2MasterCompanyIndexRoute: typeof AdminV2MasterCompanyIndexRoute
  AdminV2MasterSchemaIndexRoute: typeof AdminV2MasterSchemaIndexRoute
  AdminV2MasterSkillIndexRoute: typeof AdminV2MasterSkillIndexRoute
  AdminV2MasterTagIndexRoute: typeof AdminV2MasterTagIndexRoute
  AdminV2ParticipantParticipantIdIndexRoute: typeof AdminV2ParticipantParticipantIdIndexRoute
  AdminV2JobVacancyIdEditRoute: typeof AdminV2JobVacancyIdEditRoute
  AdminV2JobApplicantApplicantIdIndexRoute: typeof AdminV2JobApplicantApplicantIdIndexRoute
  AdminV2JobApplicantVacancyIndexRoute: typeof AdminV2JobApplicantVacancyIndexRoute
  AdminV2JobVacancyIdIndexRoute: typeof AdminV2JobVacancyIdIndexRoute
  AdminV2JobApplicantVacancyJobIdIndexRoute: typeof AdminV2JobApplicantVacancyJobIdIndexRoute
}

const AdminV2RouteRouteChildren: AdminV2RouteRouteChildren = {
  AdminV2IndexRoute: AdminV2IndexRoute,
  AdminV2ReferralIdRoute: AdminV2ReferralIdRoute,
  AdminV2ReferralPointHistoryRoute: AdminV2ReferralPointHistoryRoute,
  AdminV2ParticipantIndexRoute: AdminV2ParticipantIndexRoute,
  AdminV2ReferralIndexRoute: AdminV2ReferralIndexRoute,
  AdminV2JobVacancyCreateRoute: AdminV2JobVacancyCreateRoute,
  AdminV2MasterCompanyIdRoute: AdminV2MasterCompanyIdRoute,
  AdminV2MasterCompanyCreateRoute: AdminV2MasterCompanyCreateRoute,
  AdminV2JobApplicantIndexRoute: AdminV2JobApplicantIndexRoute,
  AdminV2JobVacancyIndexRoute: AdminV2JobVacancyIndexRoute,
  AdminV2MasterCategoryIndexRoute: AdminV2MasterCategoryIndexRoute,
  AdminV2MasterCompanyIndexRoute: AdminV2MasterCompanyIndexRoute,
  AdminV2MasterSchemaIndexRoute: AdminV2MasterSchemaIndexRoute,
  AdminV2MasterSkillIndexRoute: AdminV2MasterSkillIndexRoute,
  AdminV2MasterTagIndexRoute: AdminV2MasterTagIndexRoute,
  AdminV2ParticipantParticipantIdIndexRoute:
    AdminV2ParticipantParticipantIdIndexRoute,
  AdminV2JobVacancyIdEditRoute: AdminV2JobVacancyIdEditRoute,
  AdminV2JobApplicantApplicantIdIndexRoute:
    AdminV2JobApplicantApplicantIdIndexRoute,
  AdminV2JobApplicantVacancyIndexRoute: AdminV2JobApplicantVacancyIndexRoute,
  AdminV2JobVacancyIdIndexRoute: AdminV2JobVacancyIdIndexRoute,
  AdminV2JobApplicantVacancyJobIdIndexRoute:
    AdminV2JobApplicantVacancyJobIdIndexRoute,
}

const AdminV2RouteRouteWithChildren = AdminV2RouteRoute._addFileChildren(
  AdminV2RouteRouteChildren,
)

interface AffiliateRouteRouteChildren {
  AffiliateHistoryRoute: typeof AffiliateHistoryRoute
  AffiliateIndexRoute: typeof AffiliateIndexRoute
}

const AffiliateRouteRouteChildren: AffiliateRouteRouteChildren = {
  AffiliateHistoryRoute: AffiliateHistoryRoute,
  AffiliateIndexRoute: AffiliateIndexRoute,
}

const AffiliateRouteRouteWithChildren = AffiliateRouteRoute._addFileChildren(
  AffiliateRouteRouteChildren,
)

interface UserProfileRouteChildren {
  UserProfileExperienceRoute: typeof UserProfileExperienceRoute
  UserProfileLanguagesRoute: typeof UserProfileLanguagesRoute
  UserProfileLicencesRoute: typeof UserProfileLicencesRoute
  UserProfilePersonalRoute: typeof UserProfilePersonalRoute
  UserProfileIndexRoute: typeof UserProfileIndexRoute
}

const UserProfileRouteChildren: UserProfileRouteChildren = {
  UserProfileExperienceRoute: UserProfileExperienceRoute,
  UserProfileLanguagesRoute: UserProfileLanguagesRoute,
  UserProfileLicencesRoute: UserProfileLicencesRoute,
  UserProfilePersonalRoute: UserProfilePersonalRoute,
  UserProfileIndexRoute: UserProfileIndexRoute,
}

const UserProfileRouteWithChildren = UserProfileRoute._addFileChildren(
  UserProfileRouteChildren,
)

interface UserDigishokuProfileRouteChildren {
  UserDigishokuProfileAttachmentRoute: typeof UserDigishokuProfileAttachmentRoute
  UserDigishokuProfileEducationRoute: typeof UserDigishokuProfileEducationRoute
  UserDigishokuProfileExperienceRoute: typeof UserDigishokuProfileExperienceRoute
  UserDigishokuProfileLanguagesRoute: typeof UserDigishokuProfileLanguagesRoute
  UserDigishokuProfileIndexRoute: typeof UserDigishokuProfileIndexRoute
}

const UserDigishokuProfileRouteChildren: UserDigishokuProfileRouteChildren = {
  UserDigishokuProfileAttachmentRoute: UserDigishokuProfileAttachmentRoute,
  UserDigishokuProfileEducationRoute: UserDigishokuProfileEducationRoute,
  UserDigishokuProfileExperienceRoute: UserDigishokuProfileExperienceRoute,
  UserDigishokuProfileLanguagesRoute: UserDigishokuProfileLanguagesRoute,
  UserDigishokuProfileIndexRoute: UserDigishokuProfileIndexRoute,
}

const UserDigishokuProfileRouteWithChildren =
  UserDigishokuProfileRoute._addFileChildren(UserDigishokuProfileRouteChildren)

interface UserRouteChildren {
  UserDashboardRoute: typeof UserDashboardRoute
  UserProfileRoute: typeof UserProfileRouteWithChildren
  UserAccountEditRoute: typeof UserAccountEditRoute
  UserDigishokuDashboardRoute: typeof UserDigishokuDashboardRoute
  UserDigishokuProfileRoute: typeof UserDigishokuProfileRouteWithChildren
  UserDigishokuAccountEditRoute: typeof UserDigishokuAccountEditRoute
}

const UserRouteChildren: UserRouteChildren = {
  UserDashboardRoute: UserDashboardRoute,
  UserProfileRoute: UserProfileRouteWithChildren,
  UserAccountEditRoute: UserAccountEditRoute,
  UserDigishokuDashboardRoute: UserDigishokuDashboardRoute,
  UserDigishokuProfileRoute: UserDigishokuProfileRouteWithChildren,
  UserDigishokuAccountEditRoute: UserDigishokuAccountEditRoute,
}

const UserRouteWithChildren = UserRoute._addFileChildren(UserRouteChildren)

interface AuthVerificationRouteChildren {
  AuthVerificationOtpRoute: typeof AuthVerificationOtpRoute
  AuthVerificationPhoneRoute: typeof AuthVerificationPhoneRoute
  AuthVerificationSuccessRoute: typeof AuthVerificationSuccessRoute
}

const AuthVerificationRouteChildren: AuthVerificationRouteChildren = {
  AuthVerificationOtpRoute: AuthVerificationOtpRoute,
  AuthVerificationPhoneRoute: AuthVerificationPhoneRoute,
  AuthVerificationSuccessRoute: AuthVerificationSuccessRoute,
}

const AuthVerificationRouteWithChildren =
  AuthVerificationRoute._addFileChildren(AuthVerificationRouteChildren)

interface AuthRouteChildren {
  AuthSplatRoute: typeof AuthSplatRoute
  AuthCallbackRoute: typeof AuthCallbackRoute
  AuthForgotPasswordRoute: typeof AuthForgotPasswordRoute
  AuthLoginRoute: typeof AuthLoginRoute
  AuthPhoneRoute: typeof AuthPhoneRoute
  AuthRegisterRoute: typeof AuthRegisterRoute
  AuthVerificationRoute: typeof AuthVerificationRouteWithChildren
  AuthNewPasswordTokenRoute: typeof AuthNewPasswordTokenRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthSplatRoute: AuthSplatRoute,
  AuthCallbackRoute: AuthCallbackRoute,
  AuthForgotPasswordRoute: AuthForgotPasswordRoute,
  AuthLoginRoute: AuthLoginRoute,
  AuthPhoneRoute: AuthPhoneRoute,
  AuthRegisterRoute: AuthRegisterRoute,
  AuthVerificationRoute: AuthVerificationRouteWithChildren,
  AuthNewPasswordTokenRoute: AuthNewPasswordTokenRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '/admin-v2': typeof AdminV2RouteRouteWithChildren
  '/affiliate': typeof AffiliateRouteRouteWithChildren
  '': typeof UserRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/invite': typeof InviteRoute
  '/dashboard': typeof UserDashboardRoute
  '/profile': typeof UserProfileRouteWithChildren
  '/affiliate/history': typeof AffiliateHistoryRoute
  '/auth/$': typeof AuthSplatRoute
  '/auth/callback': typeof AuthCallbackRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/phone': typeof AuthPhoneRoute
  '/auth/register': typeof AuthRegisterRoute
  '/auth/verification': typeof AuthVerificationRouteWithChildren
  '/': typeof LandingIndexRoute
  '/admin-v2/': typeof AdminV2IndexRoute
  '/affiliate/': typeof AffiliateIndexRoute
  '/account/edit': typeof UserAccountEditRoute
  '/digishoku/dashboard': typeof UserDigishokuDashboardRoute
  '/digishoku/profile': typeof UserDigishokuProfileRouteWithChildren
  '/profile/experience': typeof UserProfileExperienceRoute
  '/profile/languages': typeof UserProfileLanguagesRoute
  '/profile/licences': typeof UserProfileLicencesRoute
  '/profile/personal': typeof UserProfilePersonalRoute
  '/admin-v2/referral/$id': typeof AdminV2ReferralIdRoute
  '/admin-v2/referral/point-history': typeof AdminV2ReferralPointHistoryRoute
  '/auth/new-password/$token': typeof AuthNewPasswordTokenRoute
  '/auth/verification/otp': typeof AuthVerificationOtpRoute
  '/auth/verification/phone': typeof AuthVerificationPhoneRoute
  '/auth/verification/success': typeof AuthVerificationSuccessRoute
  '/profile/': typeof UserProfileIndexRoute
  '/admin-v2/participant': typeof AdminV2ParticipantIndexRoute
  '/admin-v2/referral': typeof AdminV2ReferralIndexRoute
  '/digishoku/account/edit': typeof UserDigishokuAccountEditRoute
  '/digishoku/profile/attachment': typeof UserDigishokuProfileAttachmentRoute
  '/digishoku/profile/education': typeof UserDigishokuProfileEducationRoute
  '/digishoku/profile/experience': typeof UserDigishokuProfileExperienceRoute
  '/digishoku/profile/languages': typeof UserDigishokuProfileLanguagesRoute
  '/admin-v2/job/vacancy/create': typeof AdminV2JobVacancyCreateRoute
  '/admin-v2/master/company/$id': typeof AdminV2MasterCompanyIdRoute
  '/admin-v2/master/company/create': typeof AdminV2MasterCompanyCreateRoute
  '/digishoku/profile/': typeof UserDigishokuProfileIndexRoute
  '/admin-v2/job/applicant': typeof AdminV2JobApplicantIndexRoute
  '/admin-v2/job/vacancy': typeof AdminV2JobVacancyIndexRoute
  '/admin-v2/master/category': typeof AdminV2MasterCategoryIndexRoute
  '/admin-v2/master/company': typeof AdminV2MasterCompanyIndexRoute
  '/admin-v2/master/schema': typeof AdminV2MasterSchemaIndexRoute
  '/admin-v2/master/skill': typeof AdminV2MasterSkillIndexRoute
  '/admin-v2/master/tag': typeof AdminV2MasterTagIndexRoute
  '/admin-v2/participant/$participantId': typeof AdminV2ParticipantParticipantIdIndexRoute
  '/admin-v2/job/vacancy/$id/edit': typeof AdminV2JobVacancyIdEditRoute
  '/admin-v2/job/applicant/$applicantId': typeof AdminV2JobApplicantApplicantIdIndexRoute
  '/admin-v2/job/applicant/vacancy': typeof AdminV2JobApplicantVacancyIndexRoute
  '/admin-v2/job/vacancy/$id': typeof AdminV2JobVacancyIdIndexRoute
  '/admin-v2/job/applicant/vacancy/$jobId': typeof AdminV2JobApplicantVacancyJobIdIndexRoute
}

export interface FileRoutesByTo {
  '': typeof UserRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/invite': typeof InviteRoute
  '/dashboard': typeof UserDashboardRoute
  '/affiliate/history': typeof AffiliateHistoryRoute
  '/auth/$': typeof AuthSplatRoute
  '/auth/callback': typeof AuthCallbackRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/phone': typeof AuthPhoneRoute
  '/auth/register': typeof AuthRegisterRoute
  '/auth/verification': typeof AuthVerificationRouteWithChildren
  '/': typeof LandingIndexRoute
  '/admin-v2': typeof AdminV2IndexRoute
  '/affiliate': typeof AffiliateIndexRoute
  '/account/edit': typeof UserAccountEditRoute
  '/digishoku/dashboard': typeof UserDigishokuDashboardRoute
  '/profile/experience': typeof UserProfileExperienceRoute
  '/profile/languages': typeof UserProfileLanguagesRoute
  '/profile/licences': typeof UserProfileLicencesRoute
  '/profile/personal': typeof UserProfilePersonalRoute
  '/admin-v2/referral/$id': typeof AdminV2ReferralIdRoute
  '/admin-v2/referral/point-history': typeof AdminV2ReferralPointHistoryRoute
  '/auth/new-password/$token': typeof AuthNewPasswordTokenRoute
  '/auth/verification/otp': typeof AuthVerificationOtpRoute
  '/auth/verification/phone': typeof AuthVerificationPhoneRoute
  '/auth/verification/success': typeof AuthVerificationSuccessRoute
  '/profile': typeof UserProfileIndexRoute
  '/admin-v2/participant': typeof AdminV2ParticipantIndexRoute
  '/admin-v2/referral': typeof AdminV2ReferralIndexRoute
  '/digishoku/account/edit': typeof UserDigishokuAccountEditRoute
  '/digishoku/profile/attachment': typeof UserDigishokuProfileAttachmentRoute
  '/digishoku/profile/education': typeof UserDigishokuProfileEducationRoute
  '/digishoku/profile/experience': typeof UserDigishokuProfileExperienceRoute
  '/digishoku/profile/languages': typeof UserDigishokuProfileLanguagesRoute
  '/admin-v2/job/vacancy/create': typeof AdminV2JobVacancyCreateRoute
  '/admin-v2/master/company/$id': typeof AdminV2MasterCompanyIdRoute
  '/admin-v2/master/company/create': typeof AdminV2MasterCompanyCreateRoute
  '/digishoku/profile': typeof UserDigishokuProfileIndexRoute
  '/admin-v2/job/applicant': typeof AdminV2JobApplicantIndexRoute
  '/admin-v2/job/vacancy': typeof AdminV2JobVacancyIndexRoute
  '/admin-v2/master/category': typeof AdminV2MasterCategoryIndexRoute
  '/admin-v2/master/company': typeof AdminV2MasterCompanyIndexRoute
  '/admin-v2/master/schema': typeof AdminV2MasterSchemaIndexRoute
  '/admin-v2/master/skill': typeof AdminV2MasterSkillIndexRoute
  '/admin-v2/master/tag': typeof AdminV2MasterTagIndexRoute
  '/admin-v2/participant/$participantId': typeof AdminV2ParticipantParticipantIdIndexRoute
  '/admin-v2/job/vacancy/$id/edit': typeof AdminV2JobVacancyIdEditRoute
  '/admin-v2/job/applicant/$applicantId': typeof AdminV2JobApplicantApplicantIdIndexRoute
  '/admin-v2/job/applicant/vacancy': typeof AdminV2JobApplicantVacancyIndexRoute
  '/admin-v2/job/vacancy/$id': typeof AdminV2JobVacancyIdIndexRoute
  '/admin-v2/job/applicant/vacancy/$jobId': typeof AdminV2JobApplicantVacancyJobIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/admin-v2': typeof AdminV2RouteRouteWithChildren
  '/affiliate': typeof AffiliateRouteRouteWithChildren
  '/_user': typeof UserRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/invite': typeof InviteRoute
  '/_user/dashboard': typeof UserDashboardRoute
  '/_user/profile': typeof UserProfileRouteWithChildren
  '/affiliate/history': typeof AffiliateHistoryRoute
  '/auth/$': typeof AuthSplatRoute
  '/auth/callback': typeof AuthCallbackRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/phone': typeof AuthPhoneRoute
  '/auth/register': typeof AuthRegisterRoute
  '/auth/verification': typeof AuthVerificationRouteWithChildren
  '/_landing/': typeof LandingIndexRoute
  '/admin-v2/': typeof AdminV2IndexRoute
  '/affiliate/': typeof AffiliateIndexRoute
  '/_user/account/edit': typeof UserAccountEditRoute
  '/_user/digishoku/dashboard': typeof UserDigishokuDashboardRoute
  '/_user/digishoku/profile': typeof UserDigishokuProfileRouteWithChildren
  '/_user/profile/experience': typeof UserProfileExperienceRoute
  '/_user/profile/languages': typeof UserProfileLanguagesRoute
  '/_user/profile/licences': typeof UserProfileLicencesRoute
  '/_user/profile/personal': typeof UserProfilePersonalRoute
  '/admin-v2/referral/$id': typeof AdminV2ReferralIdRoute
  '/admin-v2/referral/point-history': typeof AdminV2ReferralPointHistoryRoute
  '/auth/new-password/$token': typeof AuthNewPasswordTokenRoute
  '/auth/verification/otp': typeof AuthVerificationOtpRoute
  '/auth/verification/phone': typeof AuthVerificationPhoneRoute
  '/auth/verification/success': typeof AuthVerificationSuccessRoute
  '/_user/profile/': typeof UserProfileIndexRoute
  '/admin-v2/participant/': typeof AdminV2ParticipantIndexRoute
  '/admin-v2/referral/': typeof AdminV2ReferralIndexRoute
  '/_user/digishoku/account/edit': typeof UserDigishokuAccountEditRoute
  '/_user/digishoku/profile/attachment': typeof UserDigishokuProfileAttachmentRoute
  '/_user/digishoku/profile/education': typeof UserDigishokuProfileEducationRoute
  '/_user/digishoku/profile/experience': typeof UserDigishokuProfileExperienceRoute
  '/_user/digishoku/profile/languages': typeof UserDigishokuProfileLanguagesRoute
  '/admin-v2/job/vacancy/create': typeof AdminV2JobVacancyCreateRoute
  '/admin-v2/master/company/$id': typeof AdminV2MasterCompanyIdRoute
  '/admin-v2/master/company/create': typeof AdminV2MasterCompanyCreateRoute
  '/_user/digishoku/profile/': typeof UserDigishokuProfileIndexRoute
  '/admin-v2/job/applicant/': typeof AdminV2JobApplicantIndexRoute
  '/admin-v2/job/vacancy/': typeof AdminV2JobVacancyIndexRoute
  '/admin-v2/master/category/': typeof AdminV2MasterCategoryIndexRoute
  '/admin-v2/master/company/': typeof AdminV2MasterCompanyIndexRoute
  '/admin-v2/master/schema/': typeof AdminV2MasterSchemaIndexRoute
  '/admin-v2/master/skill/': typeof AdminV2MasterSkillIndexRoute
  '/admin-v2/master/tag/': typeof AdminV2MasterTagIndexRoute
  '/admin-v2/participant/$participantId/': typeof AdminV2ParticipantParticipantIdIndexRoute
  '/admin-v2/job/vacancy/$id/edit': typeof AdminV2JobVacancyIdEditRoute
  '/admin-v2/job/applicant/$applicantId/': typeof AdminV2JobApplicantApplicantIdIndexRoute
  '/admin-v2/job/applicant/vacancy/': typeof AdminV2JobApplicantVacancyIndexRoute
  '/admin-v2/job/vacancy/$id/': typeof AdminV2JobVacancyIdIndexRoute
  '/admin-v2/job/applicant/vacancy/$jobId/': typeof AdminV2JobApplicantVacancyJobIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/admin-v2'
    | '/affiliate'
    | ''
    | '/auth'
    | '/invite'
    | '/dashboard'
    | '/profile'
    | '/affiliate/history'
    | '/auth/$'
    | '/auth/callback'
    | '/auth/forgot-password'
    | '/auth/login'
    | '/auth/phone'
    | '/auth/register'
    | '/auth/verification'
    | '/'
    | '/admin-v2/'
    | '/affiliate/'
    | '/account/edit'
    | '/digishoku/dashboard'
    | '/digishoku/profile'
    | '/profile/experience'
    | '/profile/languages'
    | '/profile/licences'
    | '/profile/personal'
    | '/admin-v2/referral/$id'
    | '/admin-v2/referral/point-history'
    | '/auth/new-password/$token'
    | '/auth/verification/otp'
    | '/auth/verification/phone'
    | '/auth/verification/success'
    | '/profile/'
    | '/admin-v2/participant'
    | '/admin-v2/referral'
    | '/digishoku/account/edit'
    | '/digishoku/profile/attachment'
    | '/digishoku/profile/education'
    | '/digishoku/profile/experience'
    | '/digishoku/profile/languages'
    | '/admin-v2/job/vacancy/create'
    | '/admin-v2/master/company/$id'
    | '/admin-v2/master/company/create'
    | '/digishoku/profile/'
    | '/admin-v2/job/applicant'
    | '/admin-v2/job/vacancy'
    | '/admin-v2/master/category'
    | '/admin-v2/master/company'
    | '/admin-v2/master/schema'
    | '/admin-v2/master/skill'
    | '/admin-v2/master/tag'
    | '/admin-v2/participant/$participantId'
    | '/admin-v2/job/vacancy/$id/edit'
    | '/admin-v2/job/applicant/$applicantId'
    | '/admin-v2/job/applicant/vacancy'
    | '/admin-v2/job/vacancy/$id'
    | '/admin-v2/job/applicant/vacancy/$jobId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/auth'
    | '/invite'
    | '/dashboard'
    | '/affiliate/history'
    | '/auth/$'
    | '/auth/callback'
    | '/auth/forgot-password'
    | '/auth/login'
    | '/auth/phone'
    | '/auth/register'
    | '/auth/verification'
    | '/'
    | '/admin-v2'
    | '/affiliate'
    | '/account/edit'
    | '/digishoku/dashboard'
    | '/profile/experience'
    | '/profile/languages'
    | '/profile/licences'
    | '/profile/personal'
    | '/admin-v2/referral/$id'
    | '/admin-v2/referral/point-history'
    | '/auth/new-password/$token'
    | '/auth/verification/otp'
    | '/auth/verification/phone'
    | '/auth/verification/success'
    | '/profile'
    | '/admin-v2/participant'
    | '/admin-v2/referral'
    | '/digishoku/account/edit'
    | '/digishoku/profile/attachment'
    | '/digishoku/profile/education'
    | '/digishoku/profile/experience'
    | '/digishoku/profile/languages'
    | '/admin-v2/job/vacancy/create'
    | '/admin-v2/master/company/$id'
    | '/admin-v2/master/company/create'
    | '/digishoku/profile'
    | '/admin-v2/job/applicant'
    | '/admin-v2/job/vacancy'
    | '/admin-v2/master/category'
    | '/admin-v2/master/company'
    | '/admin-v2/master/schema'
    | '/admin-v2/master/skill'
    | '/admin-v2/master/tag'
    | '/admin-v2/participant/$participantId'
    | '/admin-v2/job/vacancy/$id/edit'
    | '/admin-v2/job/applicant/$applicantId'
    | '/admin-v2/job/applicant/vacancy'
    | '/admin-v2/job/vacancy/$id'
    | '/admin-v2/job/applicant/vacancy/$jobId'
  id:
    | '__root__'
    | '/admin-v2'
    | '/affiliate'
    | '/_user'
    | '/auth'
    | '/invite'
    | '/_user/dashboard'
    | '/_user/profile'
    | '/affiliate/history'
    | '/auth/$'
    | '/auth/callback'
    | '/auth/forgot-password'
    | '/auth/login'
    | '/auth/phone'
    | '/auth/register'
    | '/auth/verification'
    | '/_landing/'
    | '/admin-v2/'
    | '/affiliate/'
    | '/_user/account/edit'
    | '/_user/digishoku/dashboard'
    | '/_user/digishoku/profile'
    | '/_user/profile/experience'
    | '/_user/profile/languages'
    | '/_user/profile/licences'
    | '/_user/profile/personal'
    | '/admin-v2/referral/$id'
    | '/admin-v2/referral/point-history'
    | '/auth/new-password/$token'
    | '/auth/verification/otp'
    | '/auth/verification/phone'
    | '/auth/verification/success'
    | '/_user/profile/'
    | '/admin-v2/participant/'
    | '/admin-v2/referral/'
    | '/_user/digishoku/account/edit'
    | '/_user/digishoku/profile/attachment'
    | '/_user/digishoku/profile/education'
    | '/_user/digishoku/profile/experience'
    | '/_user/digishoku/profile/languages'
    | '/admin-v2/job/vacancy/create'
    | '/admin-v2/master/company/$id'
    | '/admin-v2/master/company/create'
    | '/_user/digishoku/profile/'
    | '/admin-v2/job/applicant/'
    | '/admin-v2/job/vacancy/'
    | '/admin-v2/master/category/'
    | '/admin-v2/master/company/'
    | '/admin-v2/master/schema/'
    | '/admin-v2/master/skill/'
    | '/admin-v2/master/tag/'
    | '/admin-v2/participant/$participantId/'
    | '/admin-v2/job/vacancy/$id/edit'
    | '/admin-v2/job/applicant/$applicantId/'
    | '/admin-v2/job/applicant/vacancy/'
    | '/admin-v2/job/vacancy/$id/'
    | '/admin-v2/job/applicant/vacancy/$jobId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AdminV2RouteRoute: typeof AdminV2RouteRouteWithChildren
  AffiliateRouteRoute: typeof AffiliateRouteRouteWithChildren
  UserRoute: typeof UserRouteWithChildren
  AuthRoute: typeof AuthRouteWithChildren
  InviteRoute: typeof InviteRoute
  LandingIndexRoute: typeof LandingIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  AdminV2RouteRoute: AdminV2RouteRouteWithChildren,
  AffiliateRouteRoute: AffiliateRouteRouteWithChildren,
  UserRoute: UserRouteWithChildren,
  AuthRoute: AuthRouteWithChildren,
  InviteRoute: InviteRoute,
  LandingIndexRoute: LandingIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/admin-v2",
        "/affiliate",
        "/_user",
        "/auth",
        "/invite",
        "/_landing/"
      ]
    },
    "/admin-v2": {
      "filePath": "admin-v2/route.tsx",
      "children": [
        "/admin-v2/",
        "/admin-v2/referral/$id",
        "/admin-v2/referral/point-history",
        "/admin-v2/participant/",
        "/admin-v2/referral/",
        "/admin-v2/job/vacancy/create",
        "/admin-v2/master/company/$id",
        "/admin-v2/master/company/create",
        "/admin-v2/job/applicant/",
        "/admin-v2/job/vacancy/",
        "/admin-v2/master/category/",
        "/admin-v2/master/company/",
        "/admin-v2/master/schema/",
        "/admin-v2/master/skill/",
        "/admin-v2/master/tag/",
        "/admin-v2/participant/$participantId/",
        "/admin-v2/job/vacancy/$id/edit",
        "/admin-v2/job/applicant/$applicantId/",
        "/admin-v2/job/applicant/vacancy/",
        "/admin-v2/job/vacancy/$id/",
        "/admin-v2/job/applicant/vacancy/$jobId/"
      ]
    },
    "/affiliate": {
      "filePath": "affiliate/route.tsx",
      "children": [
        "/affiliate/history",
        "/affiliate/"
      ]
    },
    "/_user": {
      "filePath": "_user.tsx",
      "children": [
        "/_user/dashboard",
        "/_user/profile",
        "/_user/account/edit",
        "/_user/digishoku/dashboard",
        "/_user/digishoku/profile",
        "/_user/digishoku/account/edit"
      ]
    },
    "/auth": {
      "filePath": "auth.tsx",
      "children": [
        "/auth/$",
        "/auth/callback",
        "/auth/forgot-password",
        "/auth/login",
        "/auth/phone",
        "/auth/register",
        "/auth/verification",
        "/auth/new-password/$token"
      ]
    },
    "/invite": {
      "filePath": "invite.tsx"
    },
    "/_user/dashboard": {
      "filePath": "_user/dashboard.tsx",
      "parent": "/_user"
    },
    "/_user/profile": {
      "filePath": "_user/profile.tsx",
      "parent": "/_user",
      "children": [
        "/_user/profile/experience",
        "/_user/profile/languages",
        "/_user/profile/licences",
        "/_user/profile/personal",
        "/_user/profile/"
      ]
    },
    "/affiliate/history": {
      "filePath": "affiliate/history.tsx",
      "parent": "/affiliate"
    },
    "/auth/$": {
      "filePath": "auth/$.tsx",
      "parent": "/auth"
    },
    "/auth/callback": {
      "filePath": "auth/callback.tsx",
      "parent": "/auth"
    },
    "/auth/forgot-password": {
      "filePath": "auth/forgot-password.tsx",
      "parent": "/auth"
    },
    "/auth/login": {
      "filePath": "auth/login.tsx",
      "parent": "/auth"
    },
    "/auth/phone": {
      "filePath": "auth/phone.tsx",
      "parent": "/auth"
    },
    "/auth/register": {
      "filePath": "auth/register.tsx",
      "parent": "/auth"
    },
    "/auth/verification": {
      "filePath": "auth/verification.tsx",
      "parent": "/auth",
      "children": [
        "/auth/verification/otp",
        "/auth/verification/phone",
        "/auth/verification/success"
      ]
    },
    "/_landing/": {
      "filePath": "_landing/index.tsx"
    },
    "/admin-v2/": {
      "filePath": "admin-v2/index.tsx",
      "parent": "/admin-v2"
    },
    "/affiliate/": {
      "filePath": "affiliate/index.tsx",
      "parent": "/affiliate"
    },
    "/_user/account/edit": {
      "filePath": "_user/account.edit.tsx",
      "parent": "/_user"
    },
    "/_user/digishoku/dashboard": {
      "filePath": "_user/digishoku/dashboard.tsx",
      "parent": "/_user"
    },
    "/_user/digishoku/profile": {
      "filePath": "_user/digishoku/profile.tsx",
      "parent": "/_user",
      "children": [
        "/_user/digishoku/profile/attachment",
        "/_user/digishoku/profile/education",
        "/_user/digishoku/profile/experience",
        "/_user/digishoku/profile/languages",
        "/_user/digishoku/profile/"
      ]
    },
    "/_user/profile/experience": {
      "filePath": "_user/profile/experience.tsx",
      "parent": "/_user/profile"
    },
    "/_user/profile/languages": {
      "filePath": "_user/profile/languages.tsx",
      "parent": "/_user/profile"
    },
    "/_user/profile/licences": {
      "filePath": "_user/profile/licences.tsx",
      "parent": "/_user/profile"
    },
    "/_user/profile/personal": {
      "filePath": "_user/profile/personal.tsx",
      "parent": "/_user/profile"
    },
    "/admin-v2/referral/$id": {
      "filePath": "admin-v2/referral/$id.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/referral/point-history": {
      "filePath": "admin-v2/referral/point-history.tsx",
      "parent": "/admin-v2"
    },
    "/auth/new-password/$token": {
      "filePath": "auth/new-password.$token.tsx",
      "parent": "/auth"
    },
    "/auth/verification/otp": {
      "filePath": "auth/verification.otp.tsx",
      "parent": "/auth/verification"
    },
    "/auth/verification/phone": {
      "filePath": "auth/verification.phone.tsx",
      "parent": "/auth/verification"
    },
    "/auth/verification/success": {
      "filePath": "auth/verification.success.tsx",
      "parent": "/auth/verification"
    },
    "/_user/profile/": {
      "filePath": "_user/profile/index.tsx",
      "parent": "/_user/profile"
    },
    "/admin-v2/participant/": {
      "filePath": "admin-v2/participant/index.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/referral/": {
      "filePath": "admin-v2/referral/index.tsx",
      "parent": "/admin-v2"
    },
    "/_user/digishoku/account/edit": {
      "filePath": "_user/digishoku/account.edit.tsx",
      "parent": "/_user"
    },
    "/_user/digishoku/profile/attachment": {
      "filePath": "_user/digishoku/profile/attachment.tsx",
      "parent": "/_user/digishoku/profile"
    },
    "/_user/digishoku/profile/education": {
      "filePath": "_user/digishoku/profile/education.tsx",
      "parent": "/_user/digishoku/profile"
    },
    "/_user/digishoku/profile/experience": {
      "filePath": "_user/digishoku/profile/experience.tsx",
      "parent": "/_user/digishoku/profile"
    },
    "/_user/digishoku/profile/languages": {
      "filePath": "_user/digishoku/profile/languages.tsx",
      "parent": "/_user/digishoku/profile"
    },
    "/admin-v2/job/vacancy/create": {
      "filePath": "admin-v2/job/vacancy/create.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/master/company/$id": {
      "filePath": "admin-v2/master/company/$id.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/master/company/create": {
      "filePath": "admin-v2/master/company/create.tsx",
      "parent": "/admin-v2"
    },
    "/_user/digishoku/profile/": {
      "filePath": "_user/digishoku/profile/index.tsx",
      "parent": "/_user/digishoku/profile"
    },
    "/admin-v2/job/applicant/": {
      "filePath": "admin-v2/job/applicant/index.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/job/vacancy/": {
      "filePath": "admin-v2/job/vacancy/index.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/master/category/": {
      "filePath": "admin-v2/master/category/index.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/master/company/": {
      "filePath": "admin-v2/master/company/index.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/master/schema/": {
      "filePath": "admin-v2/master/schema/index.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/master/skill/": {
      "filePath": "admin-v2/master/skill/index.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/master/tag/": {
      "filePath": "admin-v2/master/tag/index.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/participant/$participantId/": {
      "filePath": "admin-v2/participant/$participantId/index.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/job/vacancy/$id/edit": {
      "filePath": "admin-v2/job/vacancy/$id.edit.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/job/applicant/$applicantId/": {
      "filePath": "admin-v2/job/applicant/$applicantId/index.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/job/applicant/vacancy/": {
      "filePath": "admin-v2/job/applicant/vacancy/index.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/job/vacancy/$id/": {
      "filePath": "admin-v2/job/vacancy/$id.index.tsx",
      "parent": "/admin-v2"
    },
    "/admin-v2/job/applicant/vacancy/$jobId/": {
      "filePath": "admin-v2/job/applicant/vacancy/$jobId/index.tsx",
      "parent": "/admin-v2"
    }
  }
}
ROUTE_MANIFEST_END */

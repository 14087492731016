import { graphql } from '@/_gql'

export const GqlAuthLogin = graphql(`
  mutation Login($request: LoginRequest!) {
    login(request: $request) {
      token
      user {
        id
        name
        email
        handphone_number
        role
        is_verified
      }
    }
  }
`)

export const GqlAuthRegister = graphql(`
  mutation Register($request: RegisterRequest!) {
    register(request: $request) {
      token
      user {
        id
        name
        email
        handphone_number
        role
        is_verified
      }
    }
  }
`)

export const GqlAuthGoogleLogin = graphql(`
  mutation GoogleLogin($request: GoogleLoginRequest!) {
    googleLogin(request: $request) {
      token
      user {
        handphone_number
        role
        is_verified
      }
    }
  }
`)

export const GqlAuthVerifyOTP = graphql(`
  mutation VerifyOTP($request: VerifyOTPRequest!) {
    verifyOTP(request: $request) {
      success
    }
  }
`)

export const GqlAuthSendOTP = graphql(`
  mutation SendOTP($request: SendOTPRequest!) {
    sendOTP(request: $request) {
      success
    }
  }
`)

export const GqlAuthProfile = graphql(`
  query GetProfile {
    getProfile {
      user {
        id
        name
        email
        handphone_number
        role
        is_verified
        is_testing
        refferal_code
      }
    }
  }
`)

export const GqlAuthSendResetLink = graphql(`
  mutation SendResetLink($request: SendResetLinkRequest!) {
    sendResetLink(request: $request) {
      success
    }
  }
`)

export const GqlAuthResetPassword = graphql(`
  mutation ResetPassword($request: ResetPasswordRequest!) {
    resetPassword(request: $request) {
      success
    }
  }
`)

export const GqlAuthUpdatePhoneNumber = graphql(`
  mutation UpdatePhoneNumber($request: UpdateUserRequest!) {
    updateUser(request: $request) {
      success
    }
  }
`)

export const GqlAuthUpdateProfile = graphql(`
  mutation UpdatePhoneNumber($request: UpdateUserRequest!) {
    updateUser(request: $request) {
      success
    }
  }
`)

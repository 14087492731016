import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createFileRoute, Link } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { GqlAuthUpdateProfile } from '@/services/auth/gql'
import { queryAuthProfile } from '@/services/auth/query'
import { gqlFetch } from '@/services/graphql/fetcher'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Form, FormControl, FormField } from '@/components/ui/form'
import { FormFieldset } from '@/components/ui/form-field/fieldset'
import { Input } from '@/components/ui/input'

// import { AuthCard } from '../../auth/-components/auth-card'
// import { AuthContainer } from '../../auth/-components/auth-container'
import { AuthCard } from '../auth/-components/auth-card'
import { AuthContainer } from '../auth/-components/auth-container'
export const Route = createFileRoute('/_user/account/edit')({
  loader: async ({ context: { queryClient: qc } }) => {
    const res = await qc.fetchQuery(queryAuthProfile())
    return {
      name: res.getProfile.user.name,
      email: res.getProfile.user.email,
      phone: res.getProfile.user.handphone_number,
    }
  },
  component: Component,
})

function Component() {
  const qc = useQueryClient()
  const navigate = Route.useNavigate()
  const { name, email } = Route.useLoaderData()
  const form = useForm<FormDto>({
    resolver: zodResolver(FormDto),
    defaultValues: {
      name,
      email,
    },
  })
  const { control } = form

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (data: FormDto) => {
      await gqlFetch({
        query: GqlAuthUpdateProfile,
        variables: {
          request: {
            name: data.name,
            email: data.email,
          },
        },
      })
      qc.clear()
      navigate({ to: '/dashboard' })
    },
  })

  const onSubmit = form.handleSubmit(async (data) => {
    toast.promise(mutateAsync(data), {
      loading: 'Memperbarui profil...',
      success: 'Berhasil memperbarui profil',
      error: 'Gagal memperbarui profil',
    })
  })

  return (
    <AuthContainer>
      <Form {...form}>
        <AuthCard>
          <form onSubmit={onSubmit}>
            <AuthCard.Header className="py-4">
              <AuthCard.Title>Edit Akun</AuthCard.Title>
            </AuthCard.Header>
            <AuthCard.Content>
              <FormField
                control={control}
                name="name"
                render={({ field }) => (
                  <FormFieldset label="Nama">
                    <Input placeholder="Masukkan nama lengkap" {...field} />
                  </FormFieldset>
                )}
              />
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <FormFieldset label="Email">
                    <Input placeholder="Masukkan Email" {...field} />
                  </FormFieldset>
                )}
              />
              {/* <FormField
                control={control}
                name="phone"
                render={({ field }) => (
                  <FormFieldset label="Nomor aktif whatsapp" noFormControl>
                    <div className="flex gap-2">
                      <FormControl>
                        <Input
                          placeholder="Masukkan nomor hp"
                          {...field}
                          required
                        />
                      </FormControl>
                      <Button
                        className="rounded-xl bg-gradient-primary"
                        type="button"
                        disabled={field.value === phone}
                      >
                        Kirim OTP
                      </Button>
                    </div>
                  </FormFieldset>
                )}
              /> */}
            </AuthCard.Content>
            <AuthCard.Footer className="flex flex-row justify-between gap-4">
              <Button variant="outline_yes" className="rounded-xl" asChild>
                <Link from={Route.fullPath} to="/" disabled={isPending}>
                  Kembali
                </Link>
              </Button>
              <Button
                className="rounded-xl bg-gradient-primary"
                disabled={isPending}
              >
                Simpan Perubahan
              </Button>
            </AuthCard.Footer>
          </form>
        </AuthCard>
      </Form>
    </AuthContainer>
  )
}

interface DialogOtpVerificationProps { }

function DialogOtpVerification() {
  return (
    <Dialog>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you absolutely sure?</DialogTitle>
          <DialogDescription>
            This action cannot be undone. This will permanently delete your
            account and remove your data from our servers.
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

interface FormDto extends z.infer<typeof FormDto> { }
const FormDto = z.object({
  name: z.string(),
  email: z.string().email('Email tidak valid'),
})
